import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { IOrderDetails } from '../../../models/order-details.model';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/core/services/user.service';
import { ConsolidateShipmentService } from '../../../../../core/services/consolidate-shipments.service';
import { ConsolShipmentInvoiceType } from 'src/app/shared/enums/consol-shipment-invoice-type.enum';
import { OrderInvoicesService } from '../../../services/consolidate-shipments-invoices.service';
import { InvoiceType } from 'src/app/shared/enums/invoice-type.enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PaymentsService } from 'src/app/modules/payments/payments.service';
import { InvoiceManagementService } from 'src/app/modules/invoice-manager/invoice-management.service';
import {forkJoin} from 'rxjs';
import { SaleOrderInvoiceType } from 'src/app/shared/enums/sale-order-invoice-type.enum';
import {MatDialog} from '@angular/material/dialog';
import { InvoiceFormComponent } from 'src/app/modules/invoice-manager/components/invoice-forms/invoice-form.component';
@Component({
  selector: 'eci-consolidate-shipments-invoice-list',
  templateUrl: './consolidate-shipments-invoice-list.component.html',
  styleUrls: ['./../consolidate-shipments-details.component.scss']
})
export class ConsolidateShipmentsInvoiceListComponent implements OnInit {

  pageParams: PageParams = new PageParams();
  showLoader: boolean = false;
  @Input() summaryPage = false;
  @Input() info: any;
  @Input() isCreateMode: boolean = true;
  @Input() availableTypes: IAvailableTypes;
  @Input() availableFields: string[];

  @Output() infoChange = new EventEmitter<IOrderDetails>();

  invoiceList: any[]
  dataSource: MatTableDataSource<any>;
  serviceInvoice = InvoiceType.SERVICE_INVOICE;
  commercialInvoice = InvoiceType.SHIPPING_INVOICE;

  listOfSelectedItems: any[] = [];
  isFedex: boolean = false;

  displayedColumns: string[] = [
    'dateCreated',
    'invoiceType',
    // 'invoiceStatus',
    'invoiceAmount',
    'actions',
    'linkToView',
    // 'delete'
  ];

  constructor(
    public uploadFileSvc: UploadFileService,
    private userSvc: UserService,
    private consolidateShipmentSvc: ConsolidateShipmentService,
    private orderInvoicesService: OrderInvoicesService,
    private notify: NotificationService,
    private notifySvc: NotificationService,
    private paymentsService: PaymentsService,
    private invoiceManagementService: InvoiceManagementService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (this.info && this.info.id && this.info.invoices) {
      if (this.info?.company?.isFedex) {
        this.isFedex = true;
        this.displayedColumns.splice(0, 0, 'checkboxes');
      }

      this.invoiceList = [...this.info.invoices?.filter(el => {
          return el.isSpecific || el.invoiceType !== (ConsolShipmentInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE && ConsolShipmentInvoiceType.PACKING_LIST)
      })];
    }

    this.dataSource = new MatTableDataSource(this.invoiceList);
  }

  invoiceSave(id): void {
    this.consolidateShipmentSvc.downloadInvoice(id);
  }

  uploadSave(id): void {
    this.consolidateShipmentSvc.uploadInvoice(id);
  }

  public uploadInvoice(e: any, invoiceId) {
    if (!e.target.files) {
      return;
    }
    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    this.uploadFileSvc.uploadSeveralFile(files, `uploads/consol-shipments-invoice/${invoiceId}`, invoiceId)
    this.uploadFileSvc.allFilesLoaded().subscribe(data => {
      if (!data) return;
      const file = data.find(el => el.fileId === invoiceId)
      if (!file) return;

      const fileDetails = JSON.parse(file.data);
      const invoice = this.invoiceList.find(el => el.id === invoiceId);

      if (invoice && fileDetails) {
        invoice.asset.fileName = fileDetails.fileName;
        invoice.asset.file = fileDetails.file;
        invoice.asset.fileType = fileDetails.fileType;
      }

      this.dataSource.filteredData = [...this.invoiceList];
    });
    setTimeout(() => {this.notify.showSuccessMessage("Document uploaded");}, 3000);
  }

  get model(): any {
    return {
      ...this.info,
      invoices: this.invoiceList
    }
  }

  get hideCol(): boolean {
    return !this.userSvc.isAdmin;
  }

  deleteShow(element): boolean {
    if (element.invoiceType !== InvoiceType.SHIPPING_INVOICE) {
      return false;
    } else {
      return this.userSvc.isAdmin && !!element.asset?.file;
    }
  }

  deleteInvoice(invoiceId, id): void {
    this.consolidateShipmentSvc.deleteInvoice(id).subscribe(value => {
      const invoice = this.invoiceList.find(el => el.id === invoiceId);

      invoice.asset.fileName = '';
      invoice.asset.file = '';
      invoice.asset.fileType = '';

      this.dataSource.filteredData = [...this.invoiceList];
      this.notify.showSuccessMessage("Document deleted");
    });
  }

  selectAllChanged(event: MatCheckboxChange): void {
    if (event.checked) {
      this.listOfSelectedItems = this.dataSource?.filteredData?.filter(val => !!val.asset.file).map(el => el.asset?.id);
    } else {
      this.listOfSelectedItems = [];
    }
  }

  selectionChanged(event: MatCheckboxChange, element): void {
    if (!element.asset?.file) return;
    if (event.checked) {
      if (!this.isChecked(element?.asset?.id)) {
        this.listOfSelectedItems.push(element?.asset?.id);
      }
    } else {
      if (this.isChecked(element?.asset?.id)) {
        this.listOfSelectedItems.splice(this.listOfSelectedItems.findIndex(el => el === element?.asset?.id), 1);
      }
    }
  }

  isChecked(id: number): boolean {
    return !!this.listOfSelectedItems.find(el => el === id);
  }

  sendMails(): void {
    this.consolidateShipmentSvc.sendInvoices({
      ids: this.listOfSelectedItems,
    }).subscribe(res => {
      this.notifySvc.showSuccessMessage('Mail sent to Fedex');
      this.listOfSelectedItems = [];
    });
  }

  get isAdmin(): boolean {
    return this.userSvc.isAdmin;
  }

  regenerateInvoice(){
    this.showLoader = true;
    this.orderInvoicesService.regenerateShipmentInvoice(this.info.id).subscribe(data =>  {
        if (data) {
          data.invoices = [...data.invoices.filter(el => el.invoiceType !== (ConsolShipmentInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE && ConsolShipmentInvoiceType.PACKING_LIST))];
          this.dataSource.data = [...data.invoices];
          this.notify.showSuccessMessage('Invoice Regenerated successfully');
        }
        this.showLoader = false;
      }, err => {
        this.showLoader = false;
        this.notify.showError('Something went wrong');
    });
  }

  public editInvoice(data: any) {
    this.showLoader = false;
    forkJoin([
        this.consolidateShipmentSvc.getInvoice(data.id),
        this.invoiceManagementService.getTemplateData(SaleOrderInvoiceType[data.invoiceType])
    ]).subscribe(res => {
        let dialogRef = this.dialog.open(InvoiceFormComponent, {
            maxHeight: '80vh',
            width: '890px',
            panelClass: 'inv-man-dialog'
        });
        dialogRef.componentInstance.type = data.invoiceType;
        dialogRef.componentInstance.tabType = 0;
        dialogRef.componentInstance.invoice = res[0];
        dialogRef.componentInstance.template = res[1].data?.template;
        dialogRef.componentInstance.isInvoice = true;
        dialogRef.componentInstance.isPaymentEdit = true;
        dialogRef.afterClosed().subscribe(value => {
            if (!value) return;
            this.showLoader = true;
            const payload = {
                ...value,
                invoiceId: data?.id,
                invoiceType: data?.invoiceType
            };
            this.consolidateShipmentSvc.updateInvoice(payload).subscribe((res) => {
                if (res) {
                  this.showLoader = false;
                  this.notify.showSuccessMessage('Updated successfully');
                }
            }, error => {
                this.showLoader = false;
                this.notify.showError(error.message);
            });
        });
    }, error => {
        this.notify.showError('Invoice is not available due to some error');
        this.showLoader = false;
    });
  }

  startEditing(element: any) {
    element.isEditing = true;
    const amount = this.formatAmount(element.grandTotal);
    element.updatedAmount = amount;
  }

  updateAmount(element: any) {
    element.grandTotal = element.updatedAmount;
    element.isEditing = false;

    const payload = {
      invoiceId: element?.id,
      grandTotal: element?.grandTotal
    };
    this.consolidateShipmentSvc.updateServiceInvoiceTotal(payload).subscribe((res) => {
        if (res) {
          this.showLoader = false;
          this.notify.showSuccessMessage('Updated successfully');
        }
    }, error => {
        this.showLoader = false;
        this.notify.showError(error.message);
    });
  }

  cancelEditing(element: any) {
    element.isEditing = false;
  }

  formatAmount(amount: any): string {
    const numericAmount = parseFloat(amount);
    const roundedAmount = isNaN(numericAmount) ? 0 : parseFloat(numericAmount.toFixed(2));
    return isNaN(numericAmount) ? 'Invalid Amount' : roundedAmount.toString();
  }
}
