import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { IAgentInfo } from 'src/app/core/models/agent.interface';
import { IAvailableCourierItem } from 'src/app/core/models/available-courier.interface';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { AIR_DENOMINATOR, MONTHLY_COMMISSION, OCEAN_DENOMINATOR } from '../../../../shared/constants/denominator-name.cont';
import { GlobalSettingsService } from '../../../setting/services/global-settings.service';
import { IOrderHistory } from '../../models/order-history.interface';
import { ICompany } from 'src/app/modules/customer-registration/models/company.model';
import { ISaleOrder } from 'src/app/modules/sales-order-management/models/sale-order.interface';
// import { ConsolidateShipmentService } from '../../services/consolidate-shipments.service';
import { ConsolidateShipmentService } from '../../../../core/services/consolidate-shipments.service';
import { OrderType } from 'src/app/shared/enums/order-type.enum';
import { InvoiceType } from 'src/app/shared/enums/invoice-type.enum';
import { PaymentService } from 'src/app/modules/customers-order-management/services/payment.service';
import { StripeFormComponent } from 'src/app/core/components/stripe-form/stripe-form.component';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { CargoType } from 'src/app/shared/enums/cargo-type.enum';
import { ShipmentStatus } from 'src/app/shared/enums/shipment-status.enum';
import { ConfirmPopupDialog } from 'src/app/shared/popups/confirm-popup/confirm-popup.component';
import { ShipmentSummaryComponent } from '../summary/summary.component';
import { CustomerManagementService } from 'src/app/modules/customer-management/services/customer-management.service';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';
import { ShipmentSubType } from 'src/app/shared/enums/shipment-subtype.enum';
import { SaleOrderStatus } from 'src/app/shared/enums/sale-order-status.enum';
import { ShipmentConsolStatus } from 'src/app/shared/enums/shipment-consol-status.enum';
import { ProductStatus } from 'src/app/shared/enums/product-status.enum';

export const AMOUNT_OF_DOCUMENTS = 6;

@Component({
    selector: 'eci-consolidate-shipments-details',
    templateUrl: './consolidate-shipments-details.component.html',
    styleUrls: ['./consolidate-shipments-details.component.scss']
})
export class ConsolidateShipmentsDetailsComponent implements OnInit {

    TABS;
    summaryPage = false;
    header = 'Consolidate Shipment #';
    marginTop = '80px';
    createMode = false;
    shipmentOrderType = OrderType.Import;
    soOrderType: any;
    info: any;
    orderHistory: IOrderHistory[];
    availableTypes: IAvailableTypes;

    availableCourier: IAvailableCourierItem[];
    // availableAgents: IAgentInfo[];
    currentOrderId: number;

    saleOrders: ISaleOrder[];
    saleOrderProducts: any[] = [];

    availableStates: string[];
    statusChanged = false;
    actualDate: string;
    companyId: number;
    companyInfo: ICompany;
    currentOrderStatus: number;
    currShipmentType: number;

    showErrors = false;
    selectedTab = 0;
    showLoader = false;
    destinationPortCountryCode: string;
    orderType: number;
    isLastMile: boolean;

    isOnlyUK: boolean = false;
    isEditable: boolean;

    subtype: ShipmentSubType;
    statusDetailsTooltip;
    consolShipmentStatusPending = ShipmentConsolStatus.PENDING;

    originPorts: any[] = [];
    destinationPorts: any[] = [];
    countries: any[] = [];
    allWarehouses: IWarehouseInfo[];
    shipmentDraftStatus = ShipmentConsolStatus.DRAFT;
    errors = {
        'Details': false,
        'Shipment': false,
        'Packing Information': false,
        'Pickup info': false,
        'Labels': false,
        'LastMile': false
    }

    currentStepName: string = 'Details';
    denominators: {
        airDenominator,
        oceanDenominator
    } = {
            airDenominator: 5000,
            oceanDenominator: 1000000
        };


    // get totalPay(): string {
    //     return this.info?.invoices?.find(el => el.invoiceType === InvoiceType.SERVICE_INVOICE)?.grandTotal?.toFixed(2);
    // }

    get totalPay(): string {
        const invoice = this.info?.invoices?.find(el => el.invoiceType === InvoiceType.SERVICE_INVOICE);
        if (invoice) {
            const numericGrandTotal = typeof invoice.grandTotal === 'string' ? parseFloat(invoice.grandTotal) : invoice.grandTotal;
            if (!isNaN(numericGrandTotal)) {
                return numericGrandTotal.toFixed(2);
            }
        }
        return 'Invalid Amount';
    }

    get isPaid(): boolean {
        if (!this.info) {
            return;
        }
        return this.info?.invoices?.find(el => el.invoiceType === InvoiceType.SERVICE_INVOICE)?.isPayed;
    }

    constructor(
        public userSvc: UserService,
        private constSvc: ConstantService,
        private route: ActivatedRoute,
        public dialogRef: MatDialogRef<ConsolidateShipmentsDetailsComponent>,
        private notify: NotificationService,
        private router: Router,
        private consolShipmentSvc: ConsolidateShipmentService,
        private paymentSvc: PaymentService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private customerManSvc: CustomerManagementService,
        private globalSettingsSvc: GlobalSettingsService,
    ) {
    }

    ngOnInit(): void {
        this.getAvailableTypes();

        this.route.queryParams.subscribe(params => {
            const lastMileFlag = params['islastMile'];
            if (lastMileFlag) {
                this.isLastMile = lastMileFlag;
            }
        });

        this.showLoader = true;
        this.route.params.subscribe(values => {
            if (!!values.id) {
                if (values?.id.includes("CON")) {
                    this.consolShipmentSvc.getShipmentByConsolNumber(values.id).subscribe(data => {
                        if (!!data) {
                            this.header = `Consolidate shipment #${data.consolNumber}`;
                            this.info = data;
                            this.saleOrders = data?.saleOrders;
                            this.setupSaleOrderProducts();
                            if (this.isLastMile) {
                                this.currentStepName = 'Last Mile (Optional)';
                            }
                            if (this.info) {
                                this.setStatusDetails();
                            }
                        }
                    });
                }
                else {
                    this.consolShipmentSvc.getShipmentById(values.id).subscribe(data => {
                        if (!!data) {
                            this.header = `Consolidate shipment #${data.consolNumber}`;
                            this.info = data;
                            this.saleOrders = data?.saleOrders;
                            this.setupSaleOrderProducts();
                            if (this.isLastMile) {
                                this.currentStepName = 'Last Mile (Optional)';
                            }
                            if (this.info) {
                                this.setStatusDetails();
                            }
                        }
                    });
                }
            } else {
                this.setupSaleOrderProducts();
            }
        });

        this.constSvc.availableCourier$.subscribe(couriers => {
            if (!couriers) {
                return;
            }
            this.availableCourier = couriers;
        });

        if (!this.createMode) {
            // this.errors = [false, false, false, false];
            this.TABS = {
                'Details': 0,
                'Shipment': 1,
                'Packing Information': 2,
                'Pickup Info (Optional)': 3,
                'Products List': 4,
                'Labels': 5,
                'Invoices/Documents': 6,
                //'Invoices': 7,
                //'Documents List': 8,
                'Last Mile (Optional)': 7
            };

            this.errors = {
                'Details': false,
                'Shipment': false,
                'Packing Information': false,
                'Pickup info': false,
                'Labels': false,
                'LastMile': false
            }
        } else {
            this.TABS = {
                'Details': 0,
                'Shipment': 1,
                'Packing Information': 2,
                'Pickup Info (Optional)': 3,
                'Products List': 4,
                'Labels': 5,
                'Documents List': this.isEditable ? 6 : undefined,
                'Last Mile (Optional)': this.isEditable ? 7 : 6
            };
            if (!this.isEditable) {
                delete this.TABS['Documents List'];
            }
        }
        if (this.info?.id) {
            this.loadWarehouses(1, 1000, '', this.info.company.id);
        }
        if (!this.userSvc.isAdmin) {
            this.loadWarehouses(1, 1000, '', this.userSvc.company.id);
        }
        this.userSvc.changeCustomerInConsolShipment.subscribe((value) => {
            if (value != null) {
                this.loadWarehouses(1, 1000, '', value);
            }
        });

        combineLatest([
            this.globalSettingsSvc.getSettingByName(AIR_DENOMINATOR),
            this.globalSettingsSvc.getSettingByName(OCEAN_DENOMINATOR),
            this.globalSettingsSvc.getSettingByName(MONTHLY_COMMISSION),
        ]).subscribe(value => {
            if (!value) {
                return;
            }
            this.denominators = {
                airDenominator: parseFloat(value[0]?.value),
                oceanDenominator: parseFloat(value[1]?.value)
            };
        });
    }

    setStatusDetails() {
        if (this.info && this.info.status === ShipmentConsolStatus.PENDING) {
            this.statusDetailsTooltip = "";
            if (Number(this.info.shipment.freightCost) <= 0) {
                this.statusDetailsTooltip += '• ' + "Freight cost is zero\n";
            }
            const arr: any = Array.prototype.concat.apply([], this.info?.shipment?.shipmentConsolPalletCarton?.map(x => x.shipmentConsolPalletCartonDetail));

            if (this.info?.saleOrders && this.info?.saleOrders.length > 0) {
                for (let saleOrder of this.info?.saleOrders) {
                    for (let productOrder of saleOrder?.products) {
                        if (productOrder) {
                            const count = arr.filter(x => x.productId == productOrder.id).reduce((sum, el) => sum + el.quantity, 0);
                            if (productOrder?.units !== count) {
                                this.statusDetailsTooltip += '• ' + productOrder.product.name + " not all units are not allocated\n";
                            }
                            if (productOrder?.product?.status === ProductStatus.NotActive) {
                                this.statusDetailsTooltip += '• ' + productOrder.product.name + " is not active\n";
                            }
                            if (!productOrder?.product?.categoryId) {
                                this.statusDetailsTooltip += '• ' + productOrder.product.name + " does not have category\n";
                            }
                            if (!productOrder?.product?.hsCodeId) {
                                this.statusDetailsTooltip += '• ' + productOrder.product.name + " does not have an hscode\n";
                            }
                        }
                        else {
                            this.statusDetailsTooltip += "This shipment does not have products";
                        }
                    }
                }
            }
            else {
                this.statusDetailsTooltip += "This shipment does not have sale orders";
            }
        }
    }

    loadWarehouses(
        page: number = 1,
        limit: number = 1000,
        q: string = '',
        companyId: number = this.info.companyId,
        orderField: string = '',
        orderDir: string = ''
    ): void {
        this.customerManSvc.getWareHouses(page, limit, q, companyId, orderField, orderDir).subscribe(data => {
            if (!data) return;
            this.allWarehouses = data;
        });
    }

    get isAdmin(): boolean {
        return this.userSvc.isAdmin || this.userSvc.isDelegatedAdmin;
    }

    handleSwitch() {
        this.route.params.subscribe(values => {
            if (!!values.id) {
                const ids = [values.id];
                this.showLoader = true;
                this.consolShipmentSvc.switchHandled(ids).subscribe(data => {
                    this.showLoader = false;
                });
            }
        });

    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }
    onNextClick(index: any): void {
        this.selectedTab = index._indexToSelect++;
    }

    setupSaleOrderProducts(): void {
        this.isOnlyUK = !!this.saleOrders.find(el => el.marketplace.buyer?.shippingAddress?.country?.code === 'GB') || this.info?.shipment?.destinationCountryCode === 'GB';
        this.saleOrders?.forEach(el => {
            if (!el) {
                return;
            }
            el.products?.forEach(product => {
                const findProduct = this.saleOrderProducts?.findIndex(prod =>
                    product?.product?.sku === prod?.product?.sku &&
                    product?.costPriceUnit === prod?.costPriceUnit
                );

                if (findProduct !== -1) {
                    this.saleOrderProducts[findProduct].units += product.units;
                } else {
                    this.saleOrderProducts = [...this.saleOrderProducts, product];
                }
            });
        });

        this.showLoader = false;
    }

    getAvailableTypes(): void {
        this.constSvc.availableTypes.subscribe(data => {
            this.availableTypes = data;
        });
        // this.constSvc.getAvailableTypes();
    }

    checkDuplicateTrackingNumber(): boolean {
        const shipmentTrackingNumber = this.info?.shipment?.trackingNumber;

        const lastMileTrackingNumbers = this.info?.lastMile?.map(item => item.trackingNumber) || [];
        const allTrackingNumbers = [...lastMileTrackingNumbers, shipmentTrackingNumber].filter(item => item !== null);

        const duplicateTrackingNumbers = allTrackingNumbers.filter(
            (item, index) => allTrackingNumbers.indexOf(item) !== index
        );

        return duplicateTrackingNumbers.length > 0;
    }

    save(): void {
        const isDuplicateTrackingNumber = this.checkDuplicateTrackingNumber();
        if (isDuplicateTrackingNumber) {
            this.notify.showError("Please enter unique tracking numbers in shipment and last mile details")
            return;
        }
        this.showErrors = true;
        this.info.shipmentStatus = ShipmentStatus.NEW;

        if (this.subtype !== undefined) {
            this.info.subtype = this.subtype;
        }

        if (!this.canSave) {
            if (this.info?.shipment?.cargoType !== CargoType.FCL && Number(this.info?.packageDetails?.chargeableWeight) <= 0) {
                this.notify.showError('Chargeable weight should be more than 0');
                this.currentStepName = 'Packing Information';
                return;
            }

            this.notify.showError('Not all fields are filled');
            for (const k in this.errors) {
                if (this.errors[k]) {
                    this.currentStepName = k;
                    return;
                }
            }
            return;
        }

        if (this.info?.packageDetails?.pallets?.length <= 0 && !this.info?.packageDetails?.isNew) {
            this.notify.showError('Should have at least one pallet');
            // this.selectedTab = 2;
            this.currentStepName = 'Packing Information'
            return;
        }

        this.showLoader = true;

        if (this.info?.shipment?.freightCost === 0 || this.info?.shipment?.freightCost === null) {
            if (this.createMode) {
                const confirmDialog = this.dialog.open(ConfirmPopupDialog);
                confirmDialog.componentInstance.message = 'Shipment will be in status pending until you update the freight cost!';
                confirmDialog.componentInstance.rightBtnText = 'Confirm';
                confirmDialog.componentInstance.showLeftBtn = false;

                confirmDialog.afterClosed().subscribe(val => {
                    this.showSummaryPage();
                });

            }
        }
        else {
            this.showSummaryPage();
        }
    }

    showSummaryPage() {
        const dialogSummary = this.dialog.open(ShipmentSummaryComponent, {
            height: '600px',
        });

        dialogSummary.componentInstance.info = this.info;
        dialogSummary.componentInstance.availableTypes = this.availableTypes;
        dialogSummary.componentInstance.originPorts = this.originPorts;
        dialogSummary.componentInstance.destinationPorts = this.destinationPorts;
        dialogSummary.componentInstance.saleOrders = this.saleOrders;
        dialogSummary.componentInstance.countries = this.countries;
        dialogSummary.componentInstance.companyList = [this.companyInfo];
        dialogSummary.componentInstance.saleOrderProducts = this.saleOrderProducts;
        dialogSummary.componentInstance.allWarehouses = this.allWarehouses;
        dialogSummary.afterClosed().subscribe(val => {
            if (val == 'edit') {
                this.showLoader = false;
                this.currentStepName = 'Details';
                // this.dialogRef.close({type: 'createPage', data: this.info, status: 'open'});
            } else {
                this.dialogRef.close();
            }
        });
        // this.dialogRef.close({ type: 'summaryPage', data: this.info, status: 'open' });
    }

    editShipment() {
        this.dialogRef.close({ type: 'createPage', data: this.info, status: 'open' });
    }

    saveDraft() {
        this.info.status = ShipmentConsolStatus.DRAFT;
        this.confirm(false);
    }

    confirm(checkProducts = true) {
        const orderIds = [];
        this.saleOrders.forEach(d => {
            orderIds.push(d.id);
        });
        if (checkProducts) {
            this.info.productAllocation.forEach(prdAlcd => {
                if (Number(prdAlcd.not_allocated) !== 0) {
                    this.info.shipment.shipmentStatus = ShipmentStatus.PENDING;
                }
            });
        }

        if (!Array.isArray(this.info?.pickupInfos)) {
            this.info.pickupInfos = [];
        }

        this.info.orderIds = orderIds;
        // this.info.saleOrders = this.saleOrders;
        delete this.info['paletform'];
        delete this.info['cartonform'];
        this.consolShipmentSvc.addShipment(this.info).subscribe(data => {
            if (!data) {
                this.notify.showError('Consol shipment not created for this user !!!');
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.dialogRef.close();
                this.router.navigate([`/consolidate-shipments/consol-shipment-details/${data.id}`]);
            }
        }, err => {
            this.notify.showError(err?.error?.errors?.data);
            this.showLoader = false;
        });
    }

    get canSave(): boolean {
        let canSave = true;
        for (const k in this.errors) {
            if (this.errors[k]) {
                canSave = false;
            }
        }
        return canSave;
    }

    get sum(): string {
        if (!this.info) {
            return "0.00";
        }

        const cifValue = parseFloat(this.info?.cif);
        const freightCostValue = parseFloat(this.info?.shipment?.freightCost);

        if (isNaN(cifValue) || isNaN(freightCostValue)) {
            return cifValue.toString();
        }

        const totalValue = cifValue + freightCostValue;
        return totalValue.toFixed(2).replace(/,/g, '');
    }

    exit(): void {
        this.dialogRef.close();
    }

    get isAllFilesUploaded(): boolean {
        return this.info?.documents?.length >= AMOUNT_OF_DOCUMENTS; // for now amount of documents equal to 6
    }

    update(): void {
        this.showErrors = true;
        if (!this.canSave) {
            if (this.info?.shipment?.cargoType !== CargoType.FCL && Number(this.info?.packageDetails?.chargeableWeight) <= 0) {
                this.notify.showError('Chargeable weight should be more than 0');
                // this.selectedTab = 2;
                this.currentStepName = 'Packing Information';
                return;
            }

            this.notify.showError('Not all fields are filled');
            for (const k in this.errors) {
                if (this.errors[k]) {
                    this.currentStepName = k;
                    return;
                }
            }
            return;
        }

        if (this.info?.packageDetails?.pallets?.length <= 0 && !this.info?.packageDetails?.isNew) {
            this.notify.showError('Should have at least one pallet');
            // this.selectedTab = 2;
            this.currentStepName = 'Packing Information';
            return;
        }

        this.showLoader = true;

        this.info.orderIds = this.saleOrders.map(el => el.id);
        // TO DO - Temporary solution, don't know why it comes like that
        delete this.info['paletform'];
        delete this.info['cartonform'];
        this.consolShipmentSvc.updateShipment(this.info?.id, this.info).subscribe(data => {
            if (data.status && data.status === ShipmentStatus.PENDING && !data.changed) {
                this.notify.showError('Price Qoute document has not been uploaded. You could not change status.');
            }
            this.reload();
        }, err => {
            this.notify.showError(err?.error?.errors?.data);
            this.showLoader = false;
        });
    }

    reload(): void {
        this.showLoader = true;
        this.consolShipmentSvc.getShipmentById(this.info.id).subscribe(value => {
            this.info = value;
            this.showLoader = false;
        });
    }

    pay() {
        this.paymentSvc.getShipmentConsolClientSecret(this.info.id).subscribe(data => {
            const dialogRef = this.dialog.open(StripeFormComponent, {
                // opening dialog here which will give us back stripeToken
                width: '400px',
                data: this.info
            });
            dialogRef.componentInstance.clientSecret = data.clientSecret;
            dialogRef.componentInstance.type = 'shipment-consol';
            dialogRef.afterClosed()
                // waiting for stripe token that will be given back
                .subscribe((result: any) => {
                    if (result) {
                        if (result.error) {
                            this.notify.showError(result.error.message);
                        } else {
                            this.notify.showSuccessMessage('Payment completed successfully');
                        }
                        this.paymentSvc.createShipmentConsolPayment(this.info.id, result).subscribe(() => {
                            this.consolShipmentSvc.getShipmentById(this.info.id).subscribe(data => {
                                this.info = data;
                                this.currentOrderStatus = Number(this.info.status);
                            });
                        });
                    }
                });
        }, (err => {
            this.notify.showError(err.error.message);
        }));
    }

    next() {
        if (!this.companyId) {
            return;
        }
        const isDuplicateTrackingNumber = this.checkDuplicateTrackingNumber();
        if (isDuplicateTrackingNumber) {
            this.notify.showError("Please enter unique tracking numbers in shipment and last mile details")
            return;
        }
        let tabsK = Object.keys(this.TABS);
        this.currentStepName = tabsK[tabsK.indexOf(this.currentStepName) + 1];
    }

    tabChange(e: MatTabChangeEvent) {
        this.currentStepName = e.tab.textLabel;
    }

    back() {
        let tabsK = Object.keys(this.TABS);
        this.currentStepName = tabsK[tabsK.indexOf(this.currentStepName) - 1];
    }

    getDeliveredCount(data: any) {
        if (data?.saleOrders && data?.saleOrders.length > 0) {
            return data?.saleOrders.filter(item => item.status === SaleOrderStatus.DELIVERED).length;
        }
    }

    openDeleteConfirmation(shipmentConsoleId: number) {
        const confirmDialog = this.dialog.open(ConfirmPopupDialog);
        confirmDialog.componentInstance.message = 'Are you sure, you want to delete this Draft shipment?';
        confirmDialog.componentInstance.rightBtnText = 'Confirm';
        //confirmDialog.componentInstance.showLeftBtn = false;

        confirmDialog.afterClosed().subscribe((result: boolean) => {
            if (result === true) {
                // User confirmed the delete action
                this.deleteDraftShipment(shipmentConsoleId);
            } else {
                // User canceled the delete action or closed the dialog
                console.log('Delete action canceled');
            }
        });
    }

    deleteDraftShipment(shipmentConsoleId) {
        this.consolShipmentSvc.delete([shipmentConsoleId]).subscribe(data => {
            if (!data) {
                this.notify.showError('Something wet wrong, please try again!');
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.dialogRef.close();
                this.notify.showSuccessMessage('Draft Console Shipment deleted successfully!');
            }
        }, err => {
            this.notify.showError(err?.error?.errors?.data);
            this.showLoader = false;
        });
    }
}
