import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '../core/core.module';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {LoginComponent} from './login/login.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import {RegistrationComponent} from './registration/registration.component';
import {SliderSectionComponent} from './slider-section/slider-section.component';
import { SpinnerLoaderComponent } from './spinner-loader/spinner-loader.component';
import {SubscriberComponent} from './subscriber/subscriber.component';
import {BannerComponent} from './banner/banner.component';
import {SearchComponent} from './search/search.component';
import {CategoriesComponent} from './categories/categories.component';
import {ProductCardComponent} from './product-card/product-card.component';
import {RatingBarComponent} from './rating-bar/rating-bar.component';
import {RoundCheckboxComponent} from './round-checkbox/round-checkbox.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ImageSliderComponent} from './image-slider/image-slider.component';
import {CounterComponent} from './counter/counter.component';
import {StepperComponent} from './stepper/stepper.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterComponent} from './filter/filter.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        CoreModule,
    ],
    declarations: [
        SubscriberComponent,
        BannerComponent,
        SearchComponent,
        CategoriesComponent,
        ProductCardComponent,
        RatingBarComponent,
        RoundCheckboxComponent,
        PageNotFoundComponent,
        ImageSliderComponent,
        CounterComponent,
        StepperComponent,
        FilterComponent,
        SliderSectionComponent,
        SpinnerLoaderComponent,
        RegistrationComponent,
        LoginComponent,
        ForgotPasswordComponent,
        MatSelectSearchComponent
    ],
    exports: [
        SubscriberComponent,
        BannerComponent,
        SearchComponent,
        CategoriesComponent,
        ProductCardComponent,
        RatingBarComponent,
        RoundCheckboxComponent,
        ImageSliderComponent,
        CounterComponent,
        StepperComponent,
        FilterComponent,
        SliderSectionComponent,
        SpinnerLoaderComponent,
        RegistrationComponent,
        LoginComponent,
        ForgotPasswordComponent,
        MatSelectSearchComponent,
    ],
})
export class ComponentsModule {
}
