import {Pipe, PipeTransform} from '@angular/core';
import {ERemarkPaymentType} from '../payments-type.enum';

@Pipe({
    name: 'remarkType'
})
export class RemarkTypePipe implements PipeTransform {

    transform(value: ERemarkPaymentType, ...args: unknown[]): string {
        switch (value) {
            case ERemarkPaymentType.Charge: return 'Charge';
            case ERemarkPaymentType.OnHold: return 'On Hold';
            case ERemarkPaymentType.Refund: return 'Refund';
            default: return '';

        }
    }

}
