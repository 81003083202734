import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './core/services/auth-guard.service';
import {ErrorHandlerInterceptor} from '../app/core/intereptors/auth-header.interceptor';
import { CronJobManuallyModule } from './modules/cron-job-manually/cron-job-manually.module';
import { ShipmentPriceModule } from './modules/shipment-price/shipment-price.module';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth/login',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'ec-payment/:id',
        loadChildren: () => import('./modules/payment-page/payment-page.module').then(m => m.PaymentPageModule)
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['CUSTOMER', 'DELICATE_CUSTOMER', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'admin-profile',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER', 'SHIPMENTS_ADMIN']},
        loadChildren: () => import('./modules/admin-profile/admin-profile.module').then(m => m.AdminProfileModule)
    },
    {
        path: 'shipment-price',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN']},
        loadChildren: () => import('./modules/shipment-price/shipment-price.module').then(m => m.ShipmentPriceModule)
    },
    {
        path: 'reports-setting',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule)
    },
    {
        path: 'b2bclints',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['CUSTOMER', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/b2bclients/b2bclients.module').then(m => m.b2bclientsModule)
    },
    {
        path: 'customers-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER', 'SHIPMENTS_ADMIN']},
        loadChildren: () => import('./modules/customer-management/customer-management.module').then(m => m.CustomerManagementModule)
    },
    {
        path: 'partners-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN', 'PARTNER_ACCOUNT', 'DELEGATE_PARTNER_ACCOUNT']},
        loadChildren: () => import('./modules/partner-management/partner-management.module').then(m => m.PartnerManagementModule)
    },
    {
        path: 'customers-order',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/customers-order-management/customers-order-management.module').then(m => m.CustomersOrderManagementModule)
    },
    {
        path: 'consolidate-shipments',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/consolidate-shipments/consolidate-shipments.module').then(m => m.ConsolidateShipmentModule)
    },
    {
        path: 'customers-registration',
        loadChildren: () => import('./modules/customer-registration/customer-registration.module').then(m => m.CustomerRegistrationModule)
    },
    {
        path: 'batch-management',
        canActivate: [AuthGuard],
        loadChildren: () => import('./modules/batch/batch.module').then(m => m.BatchModule)
    },
    {
        path: 'sales-order-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'MARKETPLACE', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'PARTNER_ACCOUNT', 'DELICATE_PARTNER_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER', 'SHIPMENTS_ADMIN']},
        loadChildren: () => import('./modules/sales-order-management/sales-order-management.module').then(m => m.SalesOrderManagementModule)
    },
    {
        path: 'purchase-order-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT']},
        loadChildren: () => import('./modules/purchase-order-management/purchase-order-management.module').then(m => m.PurchaseOrderManagementModule)
    },
    /*{
        path: 'tax-report-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN']},
        loadChildren: () => import('./modules/tax-report-management/tax-report-management.module').then(m => m.TaxReportManagementModule)
    },*/
    /*{
        path: 'new-tax-report-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN']},
        loadChildren: () => import('./modules/new-tax-report-management/tax-report-management.module').then(m => m.TaxReportManagementModule)
    },*/
    {
        path: 'indvat-tax-users',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN']},
        loadChildren: () => import('./modules/indvat-tax-users/indvat-tax-users.module').then(m => m.IndvatTaxUsersModule)
    },
    {
        path: 'rep-tool-tax-reports',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'IND_VAT_ACCOUNT', 'DELICATE_ADMIN']},
        loadChildren: () => import('./modules/reporting-tool/reporting-tool.module').then(m => m.ReportingToolModule)
    },
    /*{
        path: 'indvat-tax-report-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN', 'IND_VAT_ACCOUNT']},
        loadChildren: () => import('./modules/indvat-tax-report-management/indvat-report-management.module').then(m => m.IndvatReportManagementModule)
    },*/
    {
        path: 'partner-payments',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN', 'PARTNER_ACCOUNT','CUSTOMER','DELEGATE_PARTNER_ACCOUNT','IND_VAT_ACCOUNT']},
        loadChildren: () => import('./modules/partner-payments/partner-payments.module').then(m => m.PartnerPaymentsModule)
    },
    {
        path: 'partner-customer-commission',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN', 'PARTNER_ACCOUNT','CUSTOMER','DELEGATE_PARTNER_ACCOUNT','IND_VAT_ACCOUNT']},
        loadChildren: () => import('./modules/partner-customer-commission/partner-customer-commission.module').then(m => m.PartnerCustomerCommissionModule)
    },
    {
        path: 'payment-history',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/payment-history/payment-history.module').then(m => m.PaymentHistoryModule)
    },
    {
        path: 'monthly-payment',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/monthly-payment/monthly-payment.module').then(m => m.MonthlyPaymentHistoryModule)
    },
    {
        path: 'commission-payment',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/commission-payment/commission-payment.module').then(m => m.CommissionPaymentHistoryModule)
    },
    {
        path: 'payout-history',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/payoneer-payout-history/payoneer-payout-history.module').then(m => m.PayoneerPayoutHistoryModule)
    },
    {
        path: 'payin-history',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN']},
        loadChildren: () => import('./modules/payoneer-payin/payoneer-payin-history.module').then(m => m.PayoneerPayinHistoryModule)
    },
    {
        path: 'payin-out-report',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN']},
        loadChildren: () => import('./modules/payin-out-report/payin-out-report.module').then(m => m.PayinOutReportModule)
    },
    {
        path: 'categories',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'DELICATE_ADMIN']},
        loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
    },
    {
        path: 'products',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
    },
    {
        path: 'logs',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule)
    },
    {
        path: 'debug-monthly-fees',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT']},
        loadChildren: () => import('./modules/debug-monthly-fees/debug-monthly-fees.module').then(m => m.DebugMonthlyFeesModule)
    },
    {
        path: 'debug-partner-fees',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN','DELICATE_ADMIN',]},
        loadChildren: () => import('./modules/debug-partner-fees/debug-partner-fees.module').then(m => m.DebugPartnerFeesModule)
    },
    {
        path: 'sale-order-errors',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN']},
        loadChildren: () => import('./modules/sale-order-errors/sale-order-errors.module').then(m => m.SaleOrderErrorsModule)
    },
    {
        path: 'registration',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        // data: { roles: ['ADMIN'] },
        loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
    },
    {
        path: 'stripe-plugin',
        loadChildren: () => import('./modules/stripe-plugin/stripe-plugin.module').then(m => m.StripePluginModule)
    },
    {
        path: 'admin-iframe',
        loadChildren: () => import('./modules/admin-iframe/admin-iframe.module').then(m => m.AdminIframeModule)
    },
    {
        path: 'thankyou',
        loadChildren: () => import('./modules/thankyou/thankyou.module').then(m => m.ThankyouModule)
    },
    {
        path: 'bussiness-type',
        loadChildren: () => import('./modules/bussiness-type/business-type.module').then(m => m.BussinessTypeModule),
    },
    {
        path: 'payoneer-payin-excel-history',
        loadChildren: () => import('./modules/payoneer-payin-history/payoneer-payin-history-excel.module').then(m => m.PayoneerPayinHistoryExcelReportModule)
    },
    {
        path: 'payinout-sales-order-detailed-report',
        loadChildren: () => import('./modules/payinout-sales-order-detailed-report/payinout-sales-order-detailed-report.module').then(m => m.PayinoutSalesOrderDetailedReportModule)
    },
    {
        path: 'stripe-payinout-sales-order-detailed-report',
        loadChildren: () => import('./modules/stripe-payinout-sales-order-detailed-report/stripe-payinout-sales-order-detailed-report.module').then(m => m.StripePayinoutSalesOrderDetailedReportModule)
    },
    {
        path: 'stripe-payinout-report',
        loadChildren: () => import('./modules/stripe-payin-out-report/stripe-payin-out-report.module').then(m => m.StripePayinOutReportModule)
    },
    {
        path: 'payment-plugin/:id',
        loadChildren: () => import('./modules/payment-plugging/payment-plugging.module').then(m => m.PaymentPluggingModule)
    },
    {
        path: 'coupons',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'IND_VAT_ACCOUNT']},
        loadChildren: () => import('./modules/coupons/coupons.module').then(m => m.CouponsModule)
    },
    {
        path: 'alerts',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN']},
        loadChildren: () => import('./modules/vban-alerts/vban-alerts.module').then(m => m.VbanAlertsModule)
    },
    {
        path: 'shortcuts',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN']},
        loadChildren: () => import('./modules/shortcuts/shortcuts.module').then(m => m.ShortcutsModule)
    },
    {
        path: 'carriers',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN']},
        loadChildren: () => import('./modules/carriers/carriers.module')
            .then(m => m.CarriersModule)
    },
    {
        loadChildren: () => import('./modules/empact-shop/empact-shop.module')
            .then((m) => m.EmpactShopModule),
        path: 'ecommerce/:id',
    },
    {
        path: 'offline-shop-products',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'OFFLINE_SHOP_OWNER', 'DELICATE_ADMIN', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/offline-shop-admin/offline-shop-admin.module').then(m => m.OfflineShopAdminModule)
    },
    {
        path: 'hs-codes',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'MARKETPLACE', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'PARTNER_ACCOUNT', 'DELICATE_PARTNER_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/hs-codes/hs-codes.module').then(m => m.HsCodesModule)
    },
    {
        path: 'prefex-management',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'MARKETPLACE', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'PARTNER_ACCOUNT', 'DELICATE_PARTNER_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/prefix-management/prefix-management.module').then(m => m.PrefixManagementModule)
    },
    {
        path: 'sso/:business',
        loadChildren: () => import('./modules/sso/sso.module').then(m => m.SsoModule),
    },
    {
        path: 'wms',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'MARKETPLACE', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'PARTNER_ACCOUNT', 'DELICATE_PARTNER_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/wms/wms.module').then(m => m.WmsModule),
    },
    {
        path: 'cron-job-history',
        loadChildren: () => import('./modules/cron-job-history/cron-job-history.module').then(m => m.CronJobHistoryModule),
    },
    {
        path: 'cron-job-manually',
        loadChildren: () => import('./modules/cron-job-manually/cron-job-manually.module').then(m => m.CronJobManuallyModule),
    },
    {
        path: 'invoice-manager',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'MARKETPLACE', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT', 'PARTNER_ACCOUNT', 'DELICATE_PARTNER_ACCOUNT', 'DELEGATE_OFFLINE_SHOP_OWNER']},
        loadChildren: () => import('./modules/invoice-manager/invoice-manager.module').then(m => m.InvoiceManagerModule)
    },
    {
        path: 'payments',
        loadChildren: () => import('./modules/payments/payments.module').then(m => m.PaymentsModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true},
    ]
})
export class AppRoutingModule {
}
