
export enum BatchConsoleType {
    FULL_BATCH_CONSOLE = 0,
    THRESHOLD_BATCH_CONSOLE = 1,
    FIRST_FULL_BATCH_CONSOLE =  2,
    SECOND_FULL_BATCH_CONSOLE =  3,
}


export enum BatchConsoleTypeView {
    FULL_BATCH_CONSOLE = 'Full Batch Console',
    THRESHOLD_BATCH_CONSOLE = 'Threshold Batch Console',
    FIRST_FULL_BATCH_CONSOLE = '1st Full Batch Console',
    SECOND_FULL_BATCH_CONSOLE = '2nd Full Batch Console'
}
