import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {finalize} from 'rxjs/operators';
import {EAssignType} from '../../editor/editor.component';

@Component({
    selector: 'eci-confirm-assign',
    templateUrl: './confirm-assign.component.html',
    styleUrls: ['./confirm-assign.component.scss']
})
export class ConfirmAssignComponent implements OnInit {

    selectedOrders: any[] = []
    templateName: string;
    type: number;

    constructor(
        public dialogRef: MatDialogRef<ConfirmAssignComponent>,
    ) {
    }

    ngOnInit(): void {
        console.log(this.selectedOrders);
    }
    onNoClick(): void {
        this.dialogRef.close();
    }

    getID(item: any): string {
        switch (this.type) {
            case EAssignType.B2C:
                return item.orderId;
            case EAssignType.B2B:
                return item.orderId;
            case EAssignType.Shipment:
                return item.orderId;
            case EAssignType.ConsolShimpent:
                return item.consolNumber;
        }
    }
}
