import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class CityService extends ApiService {

    constructor(
        public http: HttpClient,
        storageService: LocalStorageService,
    ) {
        super(http, storageService);
    }

    getAllByCountryId(countryId: number | string): Observable<any> {
        return this.request({
            method: 'GET',
            path: `cities/${countryId}`,
        });
    }

    getAllCities(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        orderType?: number,
        companyId?: number,
        isEU: boolean = false,
        countryCode: string = ''
    ): Observable<any> {
        return this.request({
            method: 'POST',
            path: `cities/all/list`,
            body: {
                cityName: q,
                limit: limit,
                page: page,
                orderType: orderType,
                companyId: companyId ? Number(companyId) : null,
                isEU,
                countryCode
            }
        });
    }

    create(countryId: number | string, cityData: any): Observable<any> {
        return this.request({
            method: 'POST',
            path: `cities`,
            body: {
                countryId,
                ...cityData,
            }
        });
    }
}
