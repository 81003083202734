import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ecommerce-spinner-loader',
    templateUrl: './spinner-loader.component.html',
    styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent implements OnInit {

    @Input() showLoader: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

}
