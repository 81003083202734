import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    FormControl,
    FormArray,
    FormGroupDirective,
    NgForm,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { StringIsNumber } from 'src/app/core/helpers/helpers.function';
import {
    IAvailableTypes,
    IConstantItem,
} from 'src/app/core/models/available-types.interface';
import { ICountry } from 'src/app/core/models/country.interface';
import { CityService } from 'src/app/core/services/city.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { ConstantService } from 'src/app/core/services/constant.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { TypeBusinesses } from 'src/app/shared/enums/type-business';
import { AddCityPopup } from 'src/app/shared/popups/add-city-popup/add-city-popup.component';
import { environment } from 'src/environments/environment';
import { ReportingToolService } from '../../../../../reporting-tool/reporting-tool.service';
import { AccountType } from 'src/app/shared/enums/account-type';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RegistrationService } from 'src/app/modules/customer-registration/services/registration.service';

export class ErrorMatcher implements ErrorStateMatcher {
    isErrorState(
        control: FormControl | null,
        form: FormGroupDirective | NgForm | null
    ): boolean {
        return control && (control.value || control.touched) && control.invalid;
    }
}

export function numbersValidator(mainNumber, mainCountry): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const regNumbers = control.get('registrationNumberAdd')?.value;
        let duplicate = null;
        let duplicateCountry = null;

        (regNumbers as any[])?.map((n, i) => {
            (regNumbers as any[]).map((n2, i2) => {
                if ((n.code === n2.code && i != i2) || n.code === mainNumber) {
                    duplicate = i;
                }
                if (
                    (n.countryCode === n2.countryCode && i != i2) ||
                    n.countryCode === mainCountry
                ) {
                    duplicateCountry = i;
                }
            });

            let errors = (control.get('registrationNumberAdd') as FormArray)
                .at(i)
                ?.get('code').errors;
            if (errors && errors.duplicate) {
                (control.get('registrationNumberAdd') as FormArray)
                    .at(i)
                    .get('code')
                    .setErrors(null);
            }

            let errorsCountry = (
                control.get('registrationNumberAdd') as FormArray
            )
                .at(i)
                ?.get('countryCode').errors;
            if (errorsCountry && errorsCountry.duplicate) {
                (control.get('registrationNumberAdd') as FormArray)
                    .at(i)
                    .get('countryCode')
                    .setErrors(null);
            }
        });

        if (duplicate != null) {
            let errors = (control.get('registrationNumberAdd') as FormArray)
                .at(duplicate)
                ?.get('code').errors;
            if (!errors) {
                const c = (control.get('registrationNumberAdd') as FormArray)
                    .at(duplicate)
                    ?.get('code');
                c.setErrors({
                    duplicate: 'Duplicate numbers',
                });
                c.markAsTouched();
            }
        }
        if (duplicateCountry != null) {
            let errors = (control.get('registrationNumberAdd') as FormArray)
                .at(duplicateCountry)
                ?.get('countryCode').errors;
            if (!errors) {
                const c = (control.get('registrationNumberAdd') as FormArray)
                    .at(duplicateCountry)
                    ?.get('countryCode');
                c.setErrors({
                    duplicate: 'Duplicate country',
                });
                c.markAsTouched();
            }
        }
        return null;
    };
}

@Component({
    selector: 'eci-user-main-details',
    templateUrl: './user-main-details.component.html',
    styleUrls: ['./user-main-details.component.scss'],
})
export class UserMainDetailsComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() hasError: boolean;
    @Input() info: any;
    @Input() businessDetails: any;
    @Input() availableTypes: IAvailableTypes;
    @Input() submitEvents: Observable<void>;

    @Output() hasErrorChange = new EventEmitter<boolean>();
    @Output() infoChange = new EventEmitter<any>();
    @Output() businessDetailsChange = new EventEmitter<any>();

    countries: ICountry[];
    secondaryVatCountries: ICountry[];
    states: any[];
    cities: any[];

    searchDescription: FormControl = new FormControl('');
    productGeneralDescriptions: any[] = [];

    telOptions = {
        separateDialCode: true,
        autoHideDialCode: false,
    };

    submitted = false;
    matcher = new ErrorMatcher();
    accountTypes: any[];
    searchCustomerType: FormControl = new FormControl('');
    accountType: number;
    partnerAccount = false;
    isPartnerCouponValid = true;
    private eventsSubscription: Subscription;

    get volumeOfActivity(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.ActivityCurrencyRange;
    }

    get regNumbers(): FormArray {
        return this.form?.controls['registrationNumberAdd'] as FormArray;
    }

    /*get countriesFiltered() {
        if (this.info.users) {
            return this.countries.filter(c => c.code != this.info.users[0]?.countryId && c.code != this.info.address?.state?.country.code);
        } else {
            return this.countries;
        }
    }*/

    get model(): any {
        const values = this.form.value;
        delete values['searchCountry'];
        delete values['searchCity'];
        delete values['searchState'];
        return {
            users: [
                {
                    ...values,
                },
            ],
        };
    }

    get businessDetailsModel(): any {
        return {
            name: this.form?.controls?.name?.value,
            companyName: this.form?.controls?.name?.value,
            companyCode: this.form?.controls?.registrationNumber?.value,
            eoriNumber: this.form?.controls?.eoriNumber?.value,
            companyCodeAdd: this.form?.controls.registrationNumberAdd?.value,
            address: {
                name: this.form.get('address').value,
                city: this.form.get('cityName').value,
                stateId: this.form.get('stateId').value,
                zipCode: this.form.get('zipCode').value,
            },
            productGeneralDescription: Number(
                this.form?.controls?.productGeneralDescription?.value
            ),
            volumeOfActivity: this.form?.controls?.volumeOfActivity?.value,
            type: TypeBusinesses['Reporting Tool'],
            accountType: Number(this.form?.controls?.accountType?.value),
            yearlyTurnover: {
                id: this.form?.controls?.contentId?.value,
            },
        };
    }

    constructor(
        private constSvc: ConstantService,
        private citySvc: CityService,
        private companySvc: CompanyService,
        private fb: FormBuilder,
        private reportingToolService: ReportingToolService,
        private dialog: MatDialog,
        private notify: NotificationService,
        private localStorageSvc: LocalStorageService,
        private regSvc: RegistrationService
    ) {}

    ngOnInit(): void {
        this.form = this.initializeForm(this.info, this.businessDetails);
        this.getCountriesList();
        this.subscribeOnCountries();
        this.subscribeOnFormChanges();
        this.eventsSubscription = this.submitEvents.subscribe(() =>
            this.onSubmit()
        );
        this.getProductGeneralDescList();
        this.reportingToolService.userNewCodeEmitter.subscribe((code) => {
            if (code) {
                const regNumber = this.fb.group({
                    countryCode: [code.countryCode, Validators.required],
                    code: [
                        code.code,
                        [
                            Validators.required,
                            Validators.pattern('[0-9a-zA-Z]{8,15}'),
                        ],
                    ],
                    eoriCode: [
                        code.eoriCode,
                        [Validators.pattern('[0-9a-zA-Z]{8,15}')],
                    ],
                    searchCountry: [''],
                });
                (this.form.controls['registrationNumberAdd'] as FormArray).push(
                    regNumber
                );
            }
        });

        this.accountTypes = this.getAccountType();

        const partnerCoupon = this.localStorageSvc.get('partnerCoupon') || '';
        if (!partnerCoupon) {
            this.form.controls.accountType.setValue(0);
        }

        this.form.get('accountType').valueChanges.subscribe((value) => {
            this.accountType = value;
            if (this.accountType === 1) {
                this.form
                    .get('partnerCouponCode')
                    .setValidators([Validators.required]);
                this.form
                    .get('partnerCouponValidStatus')
                    .setValidators([Validators.required]);
                this.form.get('partnerCouponValidStatus').setValue('');
            } else {
                this.form.get('partnerCouponCode').setValidators([]);
                this.form.get('partnerCouponValidStatus').setValidators([]);
                this.form.get('partnerCouponValidStatus').setValue(' ');
                this.form.get('partnerCouponCode').setValue('');
            }
        });

        this.form
            .get('partnerCouponCode')
            .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe((data) => {
                if (data.toLowerCase().startsWith('partner_')) {
                    this.partnerAccount = true;
                    this.validatePartnerAccount();
                } else {
                    this.partnerAccount = false;
                    this.isPartnerCouponValid = false;
                    // if (data !== '') {
                    //   this.notify.showError("Invalid coupon!");
                    // }
                }
            });
    }

    validatePartnerAccount() {
        const packageName = this.localStorageSvc.get('packageName');
        const tmpArry = this.form.get('partnerCouponCode').value.split('_');
        const parterID = tmpArry[1];
        const body: any = {
            couponCode: this.form.get('partnerCouponCode').value,
            partnerId: parterID,
            packageName: packageName,
        };
        this.regSvc.checkPartnerCoupon(body).subscribe(
            (value) => {
                this.localStorageSvc.set('partnerCoupon', value);
                this.form.get('partnerCouponValidStatus').setValue('applied');
                this.notify.showSuccessMessage(
                    'Coupon code was applied successfully!'
                );
                this.isPartnerCouponValid = true;
            },
            (error) => {
                this.localStorageSvc.remove('partnerCoupon');
                this.form.get('partnerCouponValidStatus').setValue('');
                this.isPartnerCouponValid = false;
                switch (error.error.message) {
                    case 'COUPON.ERRORS.NOT_FOUND':
                        this.notify.showError('Coupon not found');
                        return;
                    case 'COUPON.ERRORS.ALREADY_USED':
                        this.notify.showError('Coupon Already Used');
                        return;
                }
            }
        );
    }

    getAccountType(): any[] {
        return Object.keys(AccountType)
            .filter(StringIsNumber)
            .map((key) => {
                return { id: Number(key), name: AccountType[key] };
            });
    }

    subscribeOnFormChanges(): void {
        this.form.valueChanges.subscribe((value) => {
            this.info = this.model;
            this.businessDetails = this.businessDetailsModel;

            this.infoChange.emit(this.info);
            this.businessDetailsChange.emit(this.businessDetails);
            this.hasError = this.form?.invalid;
            this.hasErrorChange.emit(this.hasError);
        });
    }

    getProductGeneralDescList(): void {
        this.companySvc
            .getProductGeneralDescriptionList()
            .subscribe((values) => {
                this.productGeneralDescriptions = values;
            });
    }

    hasErrors(error): void {
        if (!error) {
            this.form.controls.mobilePhone.setErrors({ incorrect: !error });
        } else {
            // this.form.controls.mobilePhone.setErrors(null);
        }

        this.hasError = this.form?.invalid;
        this.hasErrorChange.emit(this.hasError);
    }

    getNumber(e): void {
        const value = e.replace(
            `+${this.form?.controls?.countryPhoneCode.value}`,
            ''
        );
        this.form.controls.mobilePhone.setValue(value);
    }

    onCountryChange(countryPhoneCode): void {
        this.form?.controls?.countryIso2.setValue(countryPhoneCode.iso2);
        this.form?.controls?.countryPhoneCode.setValue(
            countryPhoneCode.dialCode
        );
    }

    fileUploadDoc(e): void {
        const file = e.target?.files[0];
        let result;
        if (file) {
            this.form.controls.content.setValue(file);

            const reader = new FileReader();
            reader.onload = (evt) => {
                const metadata = `name: ${file.name}, type: ${file.type}, size: ${file.size}, contents:`;
                const contents = evt.target.result;
                // this.form.controls.content.setValue(contents);
            };
            result = reader.readAsBinaryString(file);
            this.form.controls.contentName.setValue(file.name);
            e.target.value = null;
        }
    }

    deleteFile(): void {
        this.form.controls.content.setValue(null);
        this.form.controls.contentName.setValue(null);
    }

    subscribeOnCountries(): void {
        this.constSvc
            .getAvailableCountries(1, 1000, true, true)
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.secondaryVatCountries = data;
                // this.countriesFiltered = this.countries.filter(c =>
                //     c.code != this._mainNumberCountryCode
                // );
            });
    }

    getCountriesList(page: number = 1, limit: number = 1000): void {
        this.constSvc.getAvailableCountries(page, limit).subscribe((data) => {
            if (!data) {
                return;
            }
            this.countries = data;
            this.form?.controls?.countryId.valueChanges.subscribe((value) => {
                const selectedCountry = this.countries?.find(
                    (el) => el.code === value
                );
                this.getStatesByCountryId(value);
                this.getCitiesByCountryId(selectedCountry?.id);
                this.form.clearValidators();
                this.form.setValidators(
                    numbersValidator(
                        this.form.get('registrationNumber').value,
                        this.form.get('countryId').value
                    )
                );
                this.form.updateValueAndValidity();
            });

            this.form?.controls?.countryId?.setValue(
                this.businessDetails?.address?.state?.country?.code
            );
        });
    }

    getStatesByCountryId(countryID: string | number): void {
        if (!countryID || countryID === 'undefined' || countryID === 'null') {
            return;
        }
        this.constSvc.getAvailableStatesById(countryID).subscribe((states) => {
            if (!states) {
                return;
            }
            this.states = states;
            if (!states) {
                this.form?.controls?.stateId.setValue(null);
                return;
            }
            this.form?.controls?.stateId.setValue(states[0]?.id || null);
        });
    }

    getCitiesByCountryId(countryID: string | number): void {
        if (!countryID || countryID === 'undefined' || countryID === 'null') {
            return;
        }
        this.citySvc.getAllByCountryId(countryID).subscribe((cities) => {
            if (!cities) {
                return;
            }
            this.cities = cities;
            if (!cities) {
                this.form.controls.cityName.setValue(null);
                return;
            }
            this.form.controls.cityName.setValue(cities[0]?.city || null);
        });
    }

    initializeForm(info?: any, businessDetails?: any): FormGroup {
        const form = this.fb.group(
            {
                name: [
                    businessDetails?.name,
                    [Validators.required, Validators.maxLength(30)],
                ],
                registrationNumber: [
                    businessDetails?.companyCode,
                    [
                        Validators.required,
                        Validators.pattern('[0-9a-zA-Z]{8,15}'),
                    ],
                ],
                eoriNumber: [
                    businessDetails?.eoriNumber,
                    [Validators.pattern('[0-9a-zA-Z]{8,15}')],
                ],
                iossNumber: [
                    info?.iossNumber,
                    [Validators.pattern('[0-9a-zA-Z]{8,15}')],
                ],
                ossNumber: [
                    info?.ossNumber,
                    [Validators.pattern('[0-9a-zA-Z]{8,15}')],
                ],
                registrationNumberAdd: this.fb.array([]),
                volumeOfActivity: [
                    businessDetails?.volumeOfActivity,
                    [Validators.required],
                ],
                searchVolumeOfActivity: [''],
                productGeneralDescription: [
                    businessDetails?.productGeneralDescription,
                    [Validators.required],
                ],
                countryId: [
                    businessDetails?.address?.state?.country?.code,
                    [Validators.required],
                ],
                searchCountry: [''],
                stateId: [
                    businessDetails?.address?.state?.id,
                    [Validators.required],
                ],
                searchState: [''],
                cityName: [
                    businessDetails?.address?.city,
                    [Validators.required],
                ],
                searchCity: [''],
                address: [
                    businessDetails?.address?.name,
                    [Validators.required],
                ],
                zipCode: [
                    businessDetails?.address?.zipCode,
                    [Validators.required],
                ],
                contactName: [info?.contactName, [Validators.required]],
                position: [info?.position, [Validators.required]],
                mobilePhone: [info?.phoneNumber, [Validators.required]],
                documentType: [
                    Number(info?.user_doc?.type) || 0,
                    [Validators.required],
                ],
                contentName: [info?.user_doc?.file],
                content: [{}],
                countryIso2: [info?.countryIso2],
                countryPhoneCode: [info?.countryPhoneCode || '1'],
                accountType: [
                    businessDetails?.accountType,
                    [Validators.required],
                ],
                partnerCouponCode: [''],
                partnerCouponValidStatus: [''],
            },
            {
                validators: [
                    numbersValidator(
                        businessDetails?.companyCode,
                        businessDetails?.address?.state?.country?.code
                    ),
                ],
            }
        );

        if (
            businessDetails &&
            businessDetails.companyCodeAdd &&
            businessDetails.companyCodeAdd.length > 0
        ) {
            for (let n of businessDetails.companyCodeAdd) {
                const regNumber = this.fb.group({
                    countryCode: [n.country.code, Validators.required],
                    code: [
                        n.code,
                        [
                            Validators.required,
                            Validators.pattern('[0-9a-zA-Z]{8,15}'),
                        ],
                    ],
                    eoriCode: [
                        n.eoriCode,
                        [Validators.pattern('[0-9a-zA-Z]{8,15}')],
                    ],
                    searchCountry: [''],
                });
                (form.controls['registrationNumberAdd'] as FormArray).push(
                    regNumber
                );
            }
        }

        return form;
    }

    onSubmit(): void {
        Object.keys(this.form.controls).forEach((field) => {
            const control = this.form.get(field);
            control.markAsTouched({ onlySelf: true });
        });
        this.submitted = true;
        if (!this.form.valid) {
            this.hasError = this.form?.invalid;
            this.hasErrorChange.emit(this.hasError);
            return;
        }
    }

    ngOnDestroy() {
        this.eventsSubscription?.unsubscribe();
    }

    getRegisteredCompany(): string {
        return this.countries?.find(
            (el) => el.code === this.form?.controls?.countryId?.value
        )?.name;
    }

    getShippingCompany(): string {
        return this.countries?.find(
            (el) => el.code === this.form?.controls?.shippingCountryId?.value
        )?.name;
    }

    cleanAllSearch(): void {
        this.form.controls.searchVolumeOfActivity.setValue(null);
        this.searchDescription.setValue(null);
        this.form.controls.searchCountry.setValue(null);
        this.form.controls.searchCity.setValue(null);
        this.form.controls.searchState.setValue(null);
    }

    inputEvent(event): void {
        document.getElementById(event?.srcElement?.id).blur();
        document.getElementById(event?.srcElement?.id).focus();
    }

    addRegNumber() {
        const regNumber = this.fb.group({
            countryCode: ['', Validators.required],
            code: [
                '',
                [Validators.required, Validators.pattern('[0-9a-zA-Z]{8,15}')],
            ],
            eoriCode: ['', [Validators.pattern('[0-9a-zA-Z]{8,15}')]],
            searchCountry: [''],
        });
        this.regNumbers.push(regNumber);
    }

    removeRegNumber(i: number) {
        this.regNumbers.removeAt(i);
    }

    getFlagClass(code: string) {
        return `iti__${code.toLowerCase()}`;
    }

    getCountryNameByCode(value: any) {
        return this.countries?.find((c) => c.code === value)?.name;
    }

    addNewCity(countryCode: string): void {
        if (!countryCode) {
            return;
        }
        let countryId = this.countries.find((el) => el.code === countryCode).id;
        let dialogRef = this.dialog.open(AddCityPopup);

        dialogRef.afterClosed().subscribe((val) => {
            if (!val) {
                return undefined;
            }

            this.citySvc.create(countryId, val).subscribe(
                (res) => {
                    this.notify.showSuccessMessage('City created successfully');
                    this.form.controls.cityName.setValue(val);
                    this.getCitiesByCountryId(countryId);
                },
                (e) => {
                    if (!e?.error) {
                        return;
                    }
                    switch (e.error.message) {
                        case 'CITY.ERRORS.ALREADY_EXIST':
                            this.notify.showError('City Already Exist');
                            break;
                        case 'COUNTRY.ERRORS.NOT_FOUND':
                            this.notify.showError('Country not found');
                            break;
                        default:
                            this.notify.showError(e.error.message);
                    }
                }
            );
        });
    }
}

