import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IInvoiceBaseTemplateData} from './invoice-form.component';

@Component({
    template:''
})
export class InvoiceTemplateBase {
    @Input() tabType: any;
    @Input() template: IInvoiceBaseTemplateData;
    @Input() templateId: string;
    @Input() invoice: any;
    @Input() isInvoice = false;
    @Input() isPaymentEdit = false;
    @Output() submit: EventEmitter<any> = new EventEmitter<any>();
    model: IInvoiceBaseTemplateData;

    onSubmit(): void {
        this.submit.emit(this.model);
    }
}
