import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'sale-order-vat-invoice-edit-form',
  templateUrl: './sale-order-vat-invoice-edit-form.component.html',
  styleUrls: ['./sale-order-vat-invoice-edit-form.component.scss']
})
export class SaleOrderVatInvoiceEditForm implements OnInit {

  @Input() data;
  @Input() customerCompany

  constructor(
    public dialogRef: MatDialogRef<SaleOrderVatInvoiceEditForm>,
    public userSvc: UserService
  ) {

  }

  ngOnInit(): void {
    // Need to remove this logic after packing details will be available from the shipment (Relation with B2B sale order).
    if (!!this.data) {
      // To set the shipment packaging information
      for(let i = 0; i < this.data.palettes.length; i++) {
        this.data.palettes[i].dimensions = this.data.palettes[i].length + ' X ' + this.data.palettes[i].width + ' X ' + this.data.palettes[i].height;
      }
    }
  }

  onSubmit(): void {
    this.dialogRef.close(this.data);
  }
}
