import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentSubType } from './../enums/shipment-subtype.enum';

@Pipe({ name: 'shipmentSubtype' })
export class ShipmentSubtypePipe implements PipeTransform {

    public transform(value: number): any {
        switch(value) {
            case ShipmentSubType.Regular: return 'Regular';
            case ShipmentSubType.Return: return 'Return';
            default: return 'Unknown';
        }
    }
}
