import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NotificationService} from '../../../core/services/notification.service';
import {InvoiceManagerType} from 'src/app/shared/enums/invoice-manager-type.enum';
import {SaleOrderInvoiceType} from '../../../shared/enums/sale-order-invoice-type.enum';
import {ConfirmPopupDialog} from '../../../shared/popups/confirm-popup/confirm-popup.component';
import {InvoiceFormComponent} from '../components/invoice-forms/invoice-form.component';
import {IHistoryClose, InvoiceHistoryComponent} from '../components/invoice-history/invoice-history.component';
import {NewSpecificTemplateComponent, NewTemplateNameDialog} from '../components/new-specific-template/new-specific-template.component';
import {TemplateEditorComponent} from '../components/template-editor/template-editor.component';
import {INVOICE_TYPE_LIST, InvoiceManagementService} from '../invoice-management.service';

export enum EAssignType {
    B2C,
    B2B,
    Shipment,
    ConsolShimpent
}

export interface IActionInvoice {
    action: 'view' | 'view-specific' | 'edit' | 'edit-specific' | 'history' | 'delete' | 'duplicate' | 'assign';
    item: any;
    isSpecific: boolean;
    assignType?: EAssignType;
}

@Component({
    selector: 'eci-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

    showLoader: boolean = false;
    searchTemplateStr: FormControl = new FormControl('');
    displayedColumns: string[] = [
        'templateName',
        'templateType',
        'actions',
    ];

    invoiceTypeList = [];
    invoiceSpecificTemplatesList = [];
    invoiceSpecificTemplatesListForGeneration = [];
    selectedTab: number = 0;
    invoiceManagerType = InvoiceManagerType;

    invoiceFilter = (list: any[], type: InvoiceManagerType, q?: string) => {
        return list.filter(val => {
            return q ?
                (
                    val.type === type &&
                    val.name.toLowerCase().includes(q.replace(/^\s+/g, '').toLowerCase())
                ) :
                (val.type === type);
        });
    };

    constructor(
        public invoiceManagementService: InvoiceManagementService,
        private dialog: MatDialog,
        private notify: NotificationService,
        private router: Router
    ) {
    }

    get SaleOrder_type() {
        return this.invoiceFilter(this.invoiceTypeList, InvoiceManagerType.SaleOrder, this.searchTemplateStr.value)
            .concat(this.invoiceFilter(this.invoiceSpecificTemplatesListForGeneration, InvoiceManagerType.SaleOrder, this.searchTemplateStr.value))
            .sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
    }

    get Shipment_type() {
        return this.invoiceFilter(this.invoiceTypeList, InvoiceManagerType.Shipment, this.searchTemplateStr.value)
            .concat(this.invoiceFilter(this.invoiceSpecificTemplatesListForGeneration, InvoiceManagerType.Shipment, this.searchTemplateStr.value))
            .sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
    }

    get ConsolShipment_type() {
        return this.invoiceFilter(this.invoiceTypeList, InvoiceManagerType.ConsolShipment, this.searchTemplateStr.value)
            .concat(this.invoiceFilter(this.invoiceSpecificTemplatesListForGeneration, InvoiceManagerType.ConsolShipment, this.searchTemplateStr.value))
            .sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
    }

    get Other_type() {
        return this.invoiceFilter(this.invoiceTypeList, InvoiceManagerType.Other, this.searchTemplateStr.value);
    }

    get Specific_type() {
        return this.invoiceSpecificTemplatesList.filter(val => {
            return val.name.toLowerCase().includes(this.searchTemplateStr.value.replace(/^\s+/g, '').toLowerCase());
        });
    }

    get viewed() {
        switch (this.selectedTab) {
            case 0:
                return this.SaleOrder_type.length;
            case 1:
                return this.Shipment_type.length;
            case 2:
                return this.ConsolShipment_type.length;
            case 3:
                return this.Other_type.length;
            case 4:
                return this.Specific_type.length;
        }
    }

    ngOnInit(): void {
        this.invoiceTypeList = INVOICE_TYPE_LIST.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        this.getSpecificTemplates();
        this.getSpecificTemplatesForGeneration();
    }

    onAction(e: IActionInvoice) {
        switch (e.action) {
            case 'view':
                this.invoiceManagementService.getTemplate(SaleOrderInvoiceType[e.item.id]);
                break;
            case 'view-specific':
                this.invoiceManagementService.getSpecificTemplate(e.item.id);
                break;
            case 'edit':
                this.editTemplate(e.item.id);
                break;
            case 'edit-specific':
                this.editSpecificTemplate(e.item.id);
                break;
            case 'history':
                this.showHistory(e.item.id);
                break;
            case 'delete':
                this.deleteSpecificTemplate(e.item);
                break;
            case 'duplicate':
                this.duplicateSpecificTemplate(e.item.id);
                break;
            case 'assign':
                this.router.navigateByUrl('/assign',
                    {
                        state: {
                            type: e.assignType,
                            templateId: e.item.id,
                            templateName: e.item.name
                        }
                    });
                break;
        }
    }

    editTemplate(id: number, historyId?: string) {
        this.invoiceManagementService.getTemplateData(SaleOrderInvoiceType[id], historyId).subscribe(templateData => {
            const dialogRef = this.dialog.open(InvoiceFormComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });
            dialogRef.componentInstance.type = id;
            dialogRef.componentInstance.invoice = {};
            dialogRef.componentInstance.template = {note: templateData.data?.note, ...templateData.data?.template};
            dialogRef.componentInstance.isInvoice = false;

            dialogRef.afterClosed().subscribe(value => {
                if (!value) {
                    return;
                }
                this.invoiceManagementService.setTemplateData(SaleOrderInvoiceType[id], value, historyId).subscribe(res => {
                    this.notify.showSuccessMessage('Update successfully');
                });
            });
        });
    }

    private getSpecificTemplates() {
        this.invoiceManagementService.getAllNewSpecificTemplate().subscribe(res => {
            this.invoiceSpecificTemplatesList = res;
        });
    }

    private getSpecificTemplatesForGeneration() {
        this.invoiceManagementService.getAllNewSpecificTemplateForGeneration().subscribe(res => {
            this.invoiceSpecificTemplatesListForGeneration = res.map(t => ({...t.template, type: t.type, isSpecific: true}));
        });
    }

    private showHistory(id: number) {
        this.invoiceManagementService.getTemplateHistory(SaleOrderInvoiceType[id]).subscribe(history => {
            const dialogRef = this.dialog.open<InvoiceHistoryComponent, any, IHistoryClose>(InvoiceHistoryComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });

            dialogRef.componentInstance.history = history;
            dialogRef.componentInstance.invoiceName = (INVOICE_TYPE_LIST.find(tl => tl.id === id)).name;
            dialogRef.afterClosed().subscribe(value => {
                this.historyAction(value, id);
            });
        });
    }

    private historyAction(value: IHistoryClose, id: number) {
        if (!value) {
            return;
        }
        switch (value.event) {
            case 'active':
                this.invoiceManagementService.setTemplateHistoryActive(SaleOrderInvoiceType[id], value.data.id)
                    .subscribe(res => {
                        this.notify.showSuccessMessage('Update successfully');
                    });
                break;
            case 'view':
                this.invoiceManagementService.getTemplate(SaleOrderInvoiceType[id], value.data.id);
                break;
            case 'edit':
                this.editTemplate(id, value.data.id);
                break;
        }
    }

    onAddNewSpecificTemplate(type?: InvoiceManagerType) {
        const dialogRef = this.dialog.open<NewSpecificTemplateComponent>(NewSpecificTemplateComponent, {
            maxHeight: '80vh',
            width: '890px',
            panelClass: 'inv-man-dialog'
        });

        type != undefined && (dialogRef.componentInstance.type = type);

        dialogRef.afterClosed().subscribe(value => {
            value?.templateId && value?.name && this.createNewSpecificTemplate(value, type);
        });
    }

    private createNewSpecificTemplate(data, type?: InvoiceManagerType) {
        const body = {
            ...data,
            type
        }
        this.invoiceManagementService.createNewSpecificTemplate(body).subscribe(res => {
            this.notify.showSuccessMessage('New specific template created successfully');
            if (type === undefined) {
                this.getSpecificTemplates();
            } else {
                this.getSpecificTemplatesForGeneration();
            }
        }, error => {
            this.notify.showError(error.error ? error.error.message : 'Something wrong!');
        });
    }

    private deleteSpecificTemplate(item) {
        const dialogRef = this.dialog.open(ConfirmPopupDialog);
        dialogRef.componentInstance.message = 'Are you sure you want to delete';
        dialogRef.componentInstance.rightBtnText = 'Delete';
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                if (item.isSpecific) {
                    this.invoiceManagementService.deleteSpecificGenerationTemplate(item.id).subscribe(res => {
                        this.getSpecificTemplatesForGeneration();
                        this.notify.showSuccessMessage('Specific template was successfully deleted');
                    }, error => {
                        this.notify.showError(error.error ? error.error.message : 'Something wrong!');
                    });
                } else {
                    this.invoiceManagementService.deleteSpecificTemplate(item.id).subscribe(res => {
                        this.getSpecificTemplates();
                        this.notify.showSuccessMessage('Specific template was successfully deleted');
                    }, error => {
                        this.notify.showError(error.error ? error.error.message : 'Something wrong!');
                    });
                }
            }
        })

    }

    private duplicateSpecificTemplate(id) {
        const dialogRef = this.dialog.open(NewTemplateNameDialog);
        dialogRef.afterClosed().subscribe(value => {
            value && this.createNewSpecificTemplate({
                templateId: SaleOrderInvoiceType[id],
                name: value
            });
        });
    }

    private editSpecificTemplate(id) {
        this.invoiceManagementService.getOneNewSpecificTemplate(id).subscribe(res => {
            if (res) {
                if (res.templateId) {
                    this.invoiceManagementService.getTemplateData(res.templateId).subscribe(templateData => {
                        const dialogRef = this.dialog.open(TemplateEditorComponent, {
                            maxWidth: '100vw',
                            width: 'calc(100vw - 40px)',
                            height: 'calc(100vh - 40px)',
                            panelClass: 'inv-man-dialog'
                        });
                        dialogRef.componentInstance.templateId = id;
                        dialogRef.componentInstance.name = res.name;
                        dialogRef.componentInstance.model = this.invoiceManagementService.generateDefaultValues(
                            res.template,
                            {note: templateData.data?.note, ...templateData.data?.template}
                        );
                        dialogRef.afterClosed().subscribe(value => {
                            if (!value) {
                                return;
                            }
                            this.getSpecificTemplates();
                        });
                    });
                } else {
                    const dialogRef = this.dialog.open(TemplateEditorComponent, {
                        maxWidth: '100vw',
                        width: 'calc(100vw - 40px)',
                        height: 'calc(100vh - 40px)',
                        panelClass: 'inv-man-dialog'
                    });
                    dialogRef.componentInstance.templateId = id;
                    dialogRef.componentInstance.name = res.name;
                    dialogRef.componentInstance.model = this.invoiceManagementService.generateDefaultValues(
                        res.template,
                        {}
                    );
                    dialogRef.afterClosed().subscribe(value => {
                        if (!value) {
                            return;
                        }
                        this.getSpecificTemplates();
                    });
                }
            }
        });

    }

    onAddBlankSpecificTemplate() {
        const dialogRef = this.dialog.open<NewTemplateNameDialog>(NewTemplateNameDialog);
        dialogRef.afterClosed().subscribe(name => {
            name && this.createNewSpecificTemplate({name});
        });
    }
}
