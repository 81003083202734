import { Pipe, PipeTransform } from '@angular/core';
import {EPaymentLastAction} from '../payments-type.enum';

@Pipe({
  name: 'paymentAction'
})
export class PaymentActionPipe implements PipeTransform {

  transform(value: EPaymentLastAction, ...args: unknown[]): string {
      switch (value) {
          case EPaymentLastAction.Disabled: return 'Disabled';
          case EPaymentLastAction.EntryCreated: return 'Entry created';
          case EPaymentLastAction.FirstEmail: return '1st email';
          case EPaymentLastAction.SecondEmail: return '2st email';
      }
  }

}
