import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { UserService } from 'src/app/core/services/user.service';
import { IPaginatorProperties } from '../../../../../core/models/paginator.interface';
import { ICarrierItem } from '../../../../shipment-price/models/shipment-price.interface';
import { ShipmentPriceService } from '../../../../shipment-price/services/shipment-price.service';
import { IOrderDetails } from '../../../models/order-details.model';
import { OrderType } from '../../../../../shared/enums/order-type.enum';
import { IAvailableCourierItem } from 'src/app/core/models/available-courier.interface';
import { IAgentInfo } from 'src/app/core/models/agent.interface';
import { AgentService } from 'src/app/core/services/agent.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { IPortItem } from '../../../models/ports.model';
import { PortsService } from '../../../services/ports.service';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { debounceTime } from 'rxjs/operators';
import { ICountry } from 'src/app/core/models/country.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';
import { CustomerManagementService } from 'src/app/modules/customer-management/services/customer-management.service';
import { NotificationService } from 'src/app/core/services/notification.service';

export const consolidateShipmentDestinationCountries = ['NL', 'BE'];

@Component({
    selector: 'eci-consolidate-shipments-shipment-details',
    templateUrl: './consolidate-shipments-shipment-details.component.html',
    styleUrls: ['./consolidate-shipments-shipment-details.component.scss'],
    providers: [DatePipe]
})
export class ConsolidateShipmentsShipmentComponent implements OnInit {

    @Input() summaryPage = false;
    @Input() info: any;
    @Input() isCreateMode: boolean = true;
    @Input() availableTypes: IAvailableTypes;
    @Input() availableCarriers: ICarrierItem[];
    @Input() availableFields: string[];
    @Input() hasError: boolean;
    @Input() actualDate: string;
    @Input() currentOrderType: number;
    @Input() availableCourier: IAvailableCourierItem[];
    @Input() availableAgents: IAgentInfo[];
    @Input() companyId: number;
    @Input() currShipmentType: number;
    @Input() showErrors: boolean;
    @Input() isOnlyUK: boolean;

    @Output() infoChange = new EventEmitter<IOrderDetails>();
    @Output() hasErrorChange = new EventEmitter<boolean>();
    @Output() portDestinationChanged = new EventEmitter<string>();

    @Output() originPortsChange = new EventEmitter<any[]>();
    @Output() countriesChange = new EventEmitter<any[]>();
    @Output() destinationPortsChange = new EventEmitter<any[]>();

    @ViewChild(CdkVirtualScrollViewport, { static: false })
    cdkVirtualScrollViewPort: CdkVirtualScrollViewport;

    form: FormGroup;
    minExpectedDate: Date;
    bookingDateLimit: Date;
    listOfOriginPorts: IPortItem[] = [];
    listOfDestinationPorts: IPortItem[] = [];
    previousePageOrigin: number = 0;
    previousePageDestination: number = 0;
    totalPagesOrigin: number = 1;
    totalPagesDestination: number = 1;
    showPorts: boolean = false;
    searchOrigin: string = '';
    searchDestination: string = '';

    searchCarrier1: FormControl = new FormControl('');
    searchCarrier2: FormControl = new FormControl('');
    searchocarrierServiceType: FormControl = new FormControl('');
    searchagentId: FormControl = new FormControl('');

    originPortFlag = false;
    destinationPortFlag = false;
    carrierListFlag = false;

    carrierSelectedRecord = [];
    destinationPortSelectedRecord = [];
    originPortSelectedRecord = [];

    originSearchEmpty = true;
    destinationSearchEmpty = true;

    allCountries: ICountry[];
    searchOriginCountry = new FormControl('');
    searchDestinationCountry = new FormControl('');
    destinationCountries: ICountry[];
    originCountries: ICountry[];
    shipmentPalletCarton: any;
    searchDestinationPorts = new FormControl('');

    searchsourceWarehouse: FormControl = new FormControl('');
    searchsdestinationWarehouse: FormControl = new FormControl('');
    sourceWarehouses: IWarehouseInfo;
    destWarehouses: IWarehouseInfo;

    clear_source_warehouse = false;
    clear_destination_warehouse = false;
    constructor(
        public userSvc: UserService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private agentSvc: AgentService,
        private portSvc: PortsService,
        private carriersSvc: ShipmentPriceService,
        private constSvc: ConstantService,
        private customerManSvc: CustomerManagementService,
        private notify: NotificationService
    ) {
    }

    get availableCarriersList(): ICarrierItem[] {
        if (!this.availableCarriers || !this.availableCarriers.length) {
            return;
        }
        const carrier = Object.assign([], this.availableCarriers);
        return carrier.filter(el => {
            const type = this.form.get('carrierServiceType').value;
            return el.type === (type === ShipmentType.Inland ? ShipmentType.CourierExpress : type);
        });
    }

    get carrierType(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }

        return this.currentOrderType === OrderType.Domestic ? this.availableTypes.OrderShipmentType.filter(el => el.name !== 'Courier express' && el.name !== 'Ocean') : this.availableTypes.OrderShipmentType;
    }

    get isOcean(): boolean {
        return this.currShipmentType === ShipmentType.Ocean;
    }

    get isAir(): boolean {
        return this.currShipmentType === ShipmentType.Air;
    }

    get show0VatMessage(): boolean {
        if (!this.form) {
            return false;
        }
        return this.form.controls.destinationCountry.value === 'BE' || this.form.controls.destinationCountry.value === 'NL';
    }

    ngOnInit(): void {
        this.form = this.initializeForm(this.info);
        this.loadWarehouses(1, 100, '', this.info.companyId);
        if (!this.isCreateMode) {
            this.getAvailableCarriers('', 1, 1000);
        }

        this.actualDate = this.info?.shipment?.actualTimeOfArrival;
        this.currShipmentType = this.info?.shipment && this.info?.shipment?.trackingType ? this.info.shipment.trackingType : undefined;
        if (!this.isCreateMode) {
            this.getListOfDestinationPorts(1, this.info?.shipment?.destinationPort, 1000, this.info.type);
            this.getListOfOriginPorts(1, this.info?.shipment?.originPort, 1000, this.info.type);
        } else {
            this.getPorts();
        }

        this.constSvc.getAvailableCountries(1, 1000).subscribe(data => {
            if (data) {
                this.allCountries = data;
                this.filterOriginDestinationCountries();

                // if (!this.isOnlyUK) {
                //     this.destinationCountries = data.filter(el => !!consolidateShipmentDestinationCountries.includes(el.code));
                // } else {
                //     this.destinationCountries = data.filter(el => el.code === 'GB');
                // }
                this.countriesChange.emit(data);
            }
        });

        this.form.controls.originCountry.valueChanges.subscribe(data => {
            this.getListOfOriginPorts(1, this.searchOrigin, 20, this.currentOrderType, data);
            this.form.controls.originPort.enable();
        });

        this.form.controls.destinationCountry.valueChanges.subscribe(data => {
            this.getListOfDestinationPorts(1, this.searchDestination, 20, this.currentOrderType, data);
            this.form.controls.destinationPort.enable();
        });

        this.form.get('carrierServiceType').valueChanges.subscribe(value => {
            this.currShipmentType = value;
            this.previousCarriersPage = 0;
            this.getAvailableCarriers();
            if (this.currShipmentType === ShipmentType.Ocean || this.currShipmentType === ShipmentType.Air) {
                this.previousePageOrigin = 0;
                this.previousePageDestination = 0;
                this.form.get('originPort').setValidators([Validators.required]);
                this.form.get('destinationPort').setValidators([Validators.required]);
                this.currentOrderType = this.info?.type;
            }
            this.getPorts();

            if (this.isShowCouriers) {
                this.form.controls.carrierServiceProvider.setValidators(null);
                this.form.controls.courierName.setValidators(Validators.required);
            } else {
                this.form.controls.courierName.setValidators(null);
                this.form.controls.carrierServiceProvider.setValidators(Validators.required);
            }
        });

        if (this.isCreateMode) {
            this.form.get('carrierServiceType').setValue(ShipmentType.Air);
        }

        this.form.get('searchOrigin').valueChanges.pipe(debounceTime(500)).subscribe(value => {
            if (value !== '') {
                this.searchOrigin = value;
                this.previousePageOrigin = 0;
                this.originSearchEmpty = false;
                this.getListOfOriginPorts();
            } else {
                this.originSearchEmpty = true;
                this.getListOfOriginPorts();
            }
        });

        this.form.get('searchDestination').valueChanges.pipe(debounceTime(500)).subscribe(value => {
            if (value !== '') {
                this.searchDestination = value;
                this.previousePageDestination = 0;
                this.destinationSearchEmpty = true;
                this.getListOfDestinationPorts();
            } else {
                this.destinationSearchEmpty = true;
                this.getListOfDestinationPorts();
            }
        });

        this.form.get('originPort').valueChanges.subscribe(value => {
            const countryCode = this.listOfOriginPorts.find(el => el.IATA === value || el.code === value);
            this.portDestinationChanged.emit(countryCode?.countryCode);
        });

        if (this.info?.shipment?.originPort) {
            setTimeout(() => {
                const countryCode = this.listOfOriginPorts.find(el => el.IATA === this.info?.shipment?.originPort || el.code === this.info?.shipment?.originPort);
                this.portDestinationChanged.emit(countryCode?.countryCode);
                console.log(countryCode?.countryCode);
            }, 2000);
        }

        if (!this.isCreateMode) {
            this.getAvailableAgents(this.info.companyId);

            if (this.info.shipment.trackingType === ShipmentType.Ocean || this.info.shipment.trackingType === ShipmentType.Air) {
                this.showPorts = true;
            }
        }

        this.searchCarrier1.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.previousCarriersPage = 0;
            this.getAvailableCarriers();
        });

        this.form.valueChanges.subscribe(_ => {
            this.info = this.model;
            this.getFormValidationErrors();
            this.infoChange.emit(this.info);
            this.hasErrorChange.emit(!this.form.valid);
        });

        if (this.info) {
            this.hasErrorChange.emit(!this.form.valid);
            this.shipmentPalletCarton = this.info.shipment?.shipmentConsolPalletCarton;
        }

        this.userSvc.changeCustomerInConsolShipment.subscribe((value) => {
            if (value != null) {
                this.loadWarehouses(1, 100, '', value);
            }
        });

        if (this.info?.sourceWarehouseId) {
            this.clear_source_warehouse = true;
        }
        if (this.info?.destinationWarehouseId) {
            this.clear_destination_warehouse = true;
        }
        this.subscribeOnWMSChanged();
    }

    filterOriginDestinationCountries() {
        if (this.currentOrderType === OrderType.Domestic) {
            this.originCountries = this.allCountries?.filter(el => el.isEurope);
            this.destinationCountries = this.allCountries?.filter(el => el.isEurope);
        } else {
            this.originCountries = this.allCountries;
            this.destinationCountries = this.allCountries;
        }
    }

    subscribeOnWMSChanged(): void {
        this.form.get('sourceWarehouse').valueChanges.subscribe(id => {
            this.clear_source_warehouse = true;
            const tmpSourceId = this.form.get('sourceWarehouse').value;
            const tmpDestinationId = this.form.get('destinationWarehouse').value;
            if (tmpSourceId && tmpSourceId === tmpDestinationId) {
                this.notify.showError("Source and Destination warehouse should not be the same")
                this.form.get('sourceWarehouse').setValue('');
            }
        });

        this.form.get('destinationWarehouse').valueChanges.subscribe(id => {
            this.clear_destination_warehouse = true;
            const tmpSourceId = this.form.get('sourceWarehouse').value;
            const tmpDestinationId = this.form.get('destinationWarehouse').value;
            if (tmpDestinationId && tmpSourceId === tmpDestinationId) {
                this.notify.showError("Destination and Source warehouse should not be the same");
                this.form.get('destinationWarehouse').setValue('');
                this.clear_destination_warehouse = false;
            }
        });

    }

    eventClearSourceSelection(): void {
        this.form.get('sourceWarehouse').setValue('');
        this.clear_source_warehouse = false;
    }

    eventClearDestinationSelection(): void {
        this.form.get('destinationWarehouse').setValue('');
        this.clear_destination_warehouse = false;
    }

    getFormValidationErrors() {
        /*Object.keys(this.form.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.form.get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
             console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
          }
        });*/
    }

    private getPorts(): void {
        this.getListOfDestinationPorts();
        this.getListOfOriginPorts();
        this.showPorts = true;
    }

    ngOnChanges(changes: SimpleChange) {
        if (changes['actualDate'] && !!this.actualDate && this.form) {
            this.form.get('actualTimeOfArrival').setValue(new Date(this.actualDate) || '');
        }

        if (changes['companyId']) {
            this.companyId = changes['companyId'].currentValue;
            this.getAvailableAgents(this.companyId || this.userSvc.userStorage.company.id);
        }

        if (changes['currentOrderType']) {
            this.currentOrderType = Number(changes['currentOrderType'].currentValue);
            this.filterOriginDestinationCountries();
        }

        if (changes['showErrors']) {
            if (this.showErrors) {
                this.form?.markAllAsTouched();
            }
        }

        this.form?.get('originPort')?.valueChanges.subscribe(data => {
            this.listOfOriginPorts.map(item => {
                if (item.IATA === data || item.code === data) {
                    sessionStorage.setItem('city', item.name);
                }
            });
        });

    }

    loadWarehouses(
        page: number = 1,
        limit: number = 1000,
        q: string = '',
        companyId: number = this.info.companyId,
        orderField: string = '',
        orderDir: string = ''
    ): void {
        this.customerManSvc.getWareHouses(page, limit, q, companyId, orderField, orderDir).subscribe(data => {
            if (!data) return;
            this.sourceWarehouses = data as IWarehouseInfo;
            this.destWarehouses = data as IWarehouseInfo;
        });
    }

    getAvailableAgents(companyId: number) {
        this.agentSvc.getAllAgents(1, 1000, '', companyId).subscribe(data => {
            this.availableAgents = data.items;
        });
    }

    initializeForm(info: any): FormGroup {
        return this.formBuilder.group({
            shipmentTerms: [''],
            courierName: [{ disabled: false, value: info?.shipment?.slug }],
            carrierServiceType: [
                {
                    disabled: !this.isCreateMode,
                    value: info?.shipment?.carrier?.type || info?.shipment?.trackingType
                }, [Validators.required]],
            carrierServiceProvider: [
                {
                    disabled: !this.isCreateMode,
                    value: info?.shipment?.carrierId
                }],
            agent: [{ disabled: !this.isCreateMode, value: info?.shipment?.agent }],
            trackingNumber: [{ disabled: false, value: info?.shipment?.trackingNumber }],
            expectedTimeOfArrival: [{
                disabled: !this.isCreateMode,
                value: new Date(info?.shipment?.expectedTimeOfArrival) || ''
            }, [Validators.required]],
            actualTimeOfArrival: [{ disabled: !this.isCreateMode, value: new Date(info?.shipment?.actualTimeOfArrival) || '' }],
            bookingDate: [{
                disabled: !this.isCreateMode,
                value: info?.shipment?.bookingDate ? new Date(info?.shipment?.bookingDate) : ''
            }, [Validators.required]],
            expectedTimeOfDeparture: [{
                disabled: !this.isCreateMode,
                value: new Date(info?.shipment?.expectedTimeOfDeparture) || ''
            }, [Validators.required]],
            originPort: [{ disabled: false, value: info?.shipment?.originPort || null },],
            freightCost: [{
                disabled: false,
                value: info?.shipment?.freightCost || null
            }, [Validators.pattern("^[0-9]+(.[0-9]{0,2})?$")]],
            destinationPort: [{ disabled: false, value: info?.shipment?.destinationPort || null },],
            originCountry: [{ disabled: !this.isCreateMode, value: info?.shipment?.originCountryCode }, [Validators.required]],
            destinationCountry: [{ disabled: !this.isCreateMode, value: info?.shipment?.destinationCountryCode }, [Validators.required]],
            searchOrigin: [''],
            searchDestination: [''],
            searchCourier: [''],
            sourceWarehouse: [info?.sourceWarehouseId],
            destinationWarehouse: [info?.destinationWarehouseId]
        });
    }

    get model(): any {
        return {
            ...this.info,
            sourceWarehouseId: parseInt(this.form.get('sourceWarehouse').value),
            destinationWarehouseId: parseInt(this.form.get('destinationWarehouse').value),
            shipment: {
                expectedTimeOfArrival: this.form.get('expectedTimeOfArrival').value != 'Invalid Date' ? this.datePipe.transform(this.form.get('expectedTimeOfArrival').value, 'yyyy-MM-dd') : '',
                bookingDate: this.form.get('bookingDate').value != 'Invalid Date' ? this.datePipe.transform(this.form.get('bookingDate').value, 'yyyy-MM-dd') : '',
                expectedTimeOfDeparture: this.form.get('expectedTimeOfDeparture').value != 'Invalid Date' ? this.datePipe.transform(this.form.get('expectedTimeOfDeparture').value, 'yyyy-MM-dd') : '',
                carrierId: this.form.get('carrierServiceProvider').value,
                carrier: this.carrierSelectedRecord != null && this.carrierSelectedRecord.length > 0 ? this.carrierSelectedRecord[0] : undefined,
                trackingType: this.form.get('carrierServiceType').value,
                agent: this.form.get('agent').value || null,
                originPort: this.form.get('originPort').value || null,
                freightCost: this.form.get('freightCost').value || null,
                destinationPort: this.form.get('destinationPort').value || null,
                originCountryCode: this.form.get('originCountry').value || null,
                destinationCountryCode: this.form.get('destinationCountry').value || null,
                trackingNumber: this.form.get('trackingNumber').value || null,
                shipmentConsolPalletCarton: this.shipmentPalletCarton,
                id: this.info?.shipment?.id || null,
            }
        };
    }

    isDatePickerValid(item: string): boolean {
        if (!item) {
            return;
        }
        const el = this.form.get(item);
        return el.value !== null;
    }

    onSelectOriginPort(value) {
        this.listOfOriginPorts.forEach((val: any) => {
            if ((val?.IATA && val.IATA == value) || (val?.code && val.code == value)) {
                this.originPortSelectedRecord = [val];
            }
        });
    }

    onSelectDetinationPort(value) {
        this.listOfDestinationPorts.forEach((val: any) => {
            if ((val?.IATA && val.IATA == value) || (val?.code && val.code == value)) {
                this.destinationPortSelectedRecord = [val];
            }
        });
    }

    onSelectCarrier(value) {
        this.availableCarriers.forEach((val: any) => {
            if (val.id == value) {
                this.carrierSelectedRecord = [val];
            }
        });
    }

    /*onOriginPorts(value): void {
      this.searchOrigin = value;
      this.previousePageOrigin = 0;
      this.getListOfOriginPorts();
    }*/

    /*onDetinationPorts(value): void {
      this.searchDestination = value;
      this.previousePageDestination = 0
      this.getListOfDestinationPorts();
    }*/

    getSlugById(id: any): string {
        if (!this.availableCarriersList) {
            return '';
        }
        const item = this.availableCarriersList.find(el => el.id === id);
        return item ? item.slug : '';
    }

    openChange($event: boolean) {
        if ($event) {
            this.cdkVirtualScrollViewPort.scrollToIndex(0);
            this.cdkVirtualScrollViewPort.checkViewportSize();
        } else {
        }
    }

    getListOfOriginPorts(
        page: number = this.previousePageOrigin + 1,
        q: string = !this.originSearchEmpty ? this.searchOrigin : '',
        limit: number = 20,
        orderType: number = OrderType.Import,
        countryCode: string = this.form.controls.originCountry.value
    ): void {
        switch (this.currShipmentType) {
            case ShipmentType.Ocean: {
                if (!countryCode) {
                    break;
                }
                this.portSvc.getOriginPorts(page, limit, q, orderType, countryCode).subscribe(data => {
                    if (!data) {
                        return;
                    }
                    this.setOriginPorts(data);
                });
            }
                ;
                break;
            case ShipmentType.Air: {
                if (!countryCode) {
                    break;
                }
                this.portSvc.getOriginAirports(page, limit, q, orderType, countryCode).subscribe(data => {
                    if (!data) {
                        return;
                    }
                    this.setOriginPorts(data);
                });
            }
                ;
                break;
        }
    }

    setOriginPorts(data): void {
        if (this.form.get('originCountry').value !== '') {
            if (data.meta.currentPage > 1) {
                this.listOfOriginPorts = [...this.listOfOriginPorts, ...data.items];
            } else {
                if (data.meta.itemCount >= 1) {
                    if (this.info?.shipment?.originPort && !this.originPortFlag) {
                        this.onEditGetOriginPorts(data.items);
                        this.originPortFlag = true;
                    } else {
                        this.listOfOriginPorts = [...this.originPortSelectedRecord, ...data.items];
                    }
                }
                else {
                    this.listOfOriginPorts = data.items;
                }
            }
            this.originPortsChange.emit(this.listOfOriginPorts);
            if (data.meta.itemCount >= 20) {
                this.previousePageOrigin = data.meta.currentPage;
            }
            this.totalPagesOrigin = data.meta.totalPages;
        }
    }


    onEditGetOriginPorts(selectedData) {
        switch (this.info.shipment.trackingType) {
            case ShipmentType.Ocean: {
                this.portSvc.getOriginPorts(1, 20, '', this.currShipmentType, this.form.controls.originCountry.value).subscribe(data => {
                    this.listOfOriginPorts = [...selectedData, ...data.items];
                });
            }
                ;
                break;
            case ShipmentType.Air: {
                this.portSvc.getOriginAirports(1, 20, '', this.currShipmentType).subscribe(data => {
                    this.listOfOriginPorts = [...selectedData, ...data.items];
                });
            }
                ;
                break;
        }
    }

    getListOfDestinationPorts(
        page: number = this.previousePageDestination + 1,
        q: string = !this.destinationSearchEmpty ? this.searchDestination : '',
        limit: number = 20,
        orderType: number = OrderType.Import,
        countryCode: string = this.form.controls.destinationCountry.value
    ): void {
        switch (this.currShipmentType) {
            case ShipmentType.Ocean: {
                if (!countryCode) {
                    break;
                }
                this.portSvc.getDestinationPorts(page, limit, q, orderType, null, false, countryCode).subscribe(data => {
                    if (!data) {
                        return;
                    }
                    this.setDestinationPorts(data);
                });
            }
                ;
                break;
            case ShipmentType.Air: {
                if (!countryCode) {
                    break;
                }
                this.portSvc.getDestinationAirports(page, limit, q, orderType, countryCode).subscribe(data => {
                    if (!data) {
                        return;
                    }
                    this.setDestinationPorts(data);
                });
            }
                ;
                break;
        }
    }

    setDestinationPorts(data): void {
        if (this.form.get('destinationCountry').value !== '') {
            if (data.meta.currentPage > 1) {
                this.listOfDestinationPorts = [...this.listOfDestinationPorts, ...data.items];
            } else {
                if (data.meta.itemCount >= 1) {
                    if (this.info?.shipment?.destinationPort && !this.destinationPortFlag) {
                        this.onEditGetDestinationPorts(data.items);
                        this.destinationPortFlag = true;
                    } else {
                        this.listOfDestinationPorts = [...this.destinationPortSelectedRecord, ...data.items];
                    }
                }
                else {
                    this.listOfDestinationPorts = data.items;
                }
            }
            this.destinationPortsChange.emit(this.listOfDestinationPorts);
            if (data.meta.itemCount >= 20) {
                this.previousePageDestination = data.meta.currentPage;
            }
            this.totalPagesDestination = data.meta.totalPages;
        }
    }

    onEditGetDestinationPorts(selectedData) {
        switch (this.currShipmentType) {
            case ShipmentType.Ocean: {
                this.portSvc.getDestinationPorts(1, 20, '', this.currShipmentType, null, false, this.form.controls.destinationCountry.value).subscribe(data => {
                    this.listOfDestinationPorts = [...selectedData, ...data.items];
                });
            }
                ;
                break;
            case ShipmentType.Air: {
                this.portSvc.getDestinationAirports(1, 20, '', this.currShipmentType).subscribe(data => {
                    if (!data) {
                        return;
                    }
                    this.listOfDestinationPorts = [...selectedData, ...data.items];
                });
            }
                ;
                break;
        }
    }

    get isShowCouriers(): boolean {
        return this.currShipmentType === ShipmentType.CourierExpress || this.currShipmentType === ShipmentType.Inland;
    }

    previousCarriersPage: number = 0;
    totalCarriersPage: number = 0;

    getAvailableCarriers(
        q: string = this.searchCarrier1.value,
        page: number = this.previousCarriersPage + 1,
        limit: number = 20,
    ) {
        // this.carriersSvc.getCarriersList(page, limit, q, type).subscribe(data => {
        this.carriersSvc.get<IPaginatorProperties<ICarrierItem>>('carriers', page, limit, q).subscribe(data => {
            if (!data) {
                return;
            }

            if (data.meta.currentPage > 1) {
                this.availableCarriers = [...this.availableCarriers, ...data.items];
            } else {
                if (data.meta.itemCount >= 1) {
                    if (this.info?.shipment?.carrier?.name && !this.carrierListFlag) {
                        this.onEditGetCarriersListPorts(data.items);
                        this.carrierListFlag = true;
                    } else {
                        this.availableCarriers = [...this.carrierSelectedRecord, ...data.items];
                    }
                }
            }
            this.previousCarriersPage = data.meta.currentPage;
            this.totalCarriersPage = data.meta.totalPages;
        });
    }

    onEditGetCarriersListPorts(selectedData) {
        // const t: any = this.currShipmentType == 4 ? 3 : this.currShipmentType;
        this.carriersSvc.get<IPaginatorProperties<ICarrierItem>>('carriers', 1, 20).subscribe(data => {
            this.availableCarriers = [...selectedData, ...data.items];
        });
    }

    closedOriginPorts(): void {
        if (!this.form?.controls?.originPort?.value) {
            this.form?.controls?.searchOrigin?.setValue('');
        } else {
            this.form?.controls?.searchOrigin?.setValue(this.form?.controls?.originPort.value);
        }
    }

    closedDestinationPorts(): void {
        /*if (!this.form?.controls?.destinationPort?.value) {
          this.form?.controls?.searchDestination?.setValue('');
        } else {
          this.form?.controls?.searchDestination?.setValue(this.form?.controls?.destinationPort.value);
        }*/
    }

    closedCarriers(): void {
        if (!this.form?.controls?.carrierServiceProvider?.value) {
            this.searchCarrier1?.setValue('');
        } else {
            const name = this.availableCarriers.find(el => el.id === this.form?.controls?.carrierServiceProvider.value)?.name;
            this.searchCarrier1?.setValue(name);
        }
    }

    getFlagClass(code: any) {
        if (!code) return '';
        const c = this.allCountries?.find(c => c.id === code)?.code;
        return `iti__${c?.toLowerCase()}`;
    }
}
