import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from '../../core/services/auth-guard.service';
import {DetailsComponent} from './components/details/details.component';
import {HistoryComponent} from './components/history/history.component';
import {PayComponent} from './components/pay/pay.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'history',
        pathMatch: 'full'
    },
    {
        path: 'history',
        canActivate: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT']},
        component: HistoryComponent
    },
    {
        path: 'details/:id',
        canActivate: [AuthGuard],
        data: {roles: ['ADMIN', 'CUSTOMER', 'DELICATE_ADMIN', 'DELICATE_CUSTOMER', 'OFFLINE_SHOP_OWNER', 'IND_VAT_ACCOUNT']},
        component: DetailsComponent
    },
    {
        path: 'pay',
        component: PayComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule { }
