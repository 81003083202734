import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IImage } from '../../../core/models/image.interface';
import { UploadFileService } from '../../../core/services/upload-file.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
    selector: 'eci-image-loader',
    templateUrl: './image-loader.component.html',
    styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnInit {
    private Images: IImage[] = [];

    @Input()
    public set images(val: IImage[]) {
        this.Images = val ? val : [];
    }

    public get images(): IImage[] {
        return this.Images;
    }

    @Output() public imagesChange = new EventEmitter();
    @Input() public maximumSize: number = 10; // Size in MB
    @Input() public maximumCount: number = 1;
    @Input() public comingName: string = 'product';

    public btnTitle = '';
    public isImageLength = false;
    public isImageSize = false;
    public isFormat = false;

    constructor(
        public uploadFileSvc: UploadFileService,
        private notify: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.btnTitle = `Maximun ${this.maximumCount} images of the ${this.comingName} can be uploaded.
            Image size should not exceed ${this.maximumSize} MB ${this.maximumCount > 1 ? 'each' : ''}.
            Image should in JPEG or PNG format.`;

        this.images.sort((img1, img2) => Number(img1.priority - img2.priority));
        this.images.forEach(el => {
            if (el.priority === undefined) {
                el.priority = this.images.findIndex(val => val.id === el.id) + 1;
            }
        });
    }

    /**
     * Load image
     */
    public onFileUpload(files: FileList): void {
        if (this.isInvalid(files)) {
            return;
        }
        this.readMultiFiles(files);
    }

    /**
     * Remove image
     */
    public deleteImage(image: IImage): void {
        const index = this.images.findIndex((im) => im.id ? im.id === image.id : im.priority === image.priority);
        if (image.id) {
            this.images[index].isDelete = true;
            this.images[index].file = undefined;
            this.images[index].priority = undefined;
        } else {
            this.images.splice(index, 1);
        }
       this.getActiveImages.map((im, i) => im.priority = i);
    }

    private readMultiFiles(files): void {
        const reader = new FileReader();
        const readFile = (index, images) => {
            if (index >= files.length) {
                return;
            }
            const file = files[index];
            reader.onload = (e) => {
                images.push({ file: e.target.result, priority: images.length + 1 });
                readFile(index + 1, images);
            };
            reader.readAsDataURL(file);
        };

        readFile(0, this.images);
        this.imagesChange.emit(this.images);
    }

    private isInvalid(files: FileList): boolean {
        if (!files) {
            return true;
        }
        this.isImageLength = false;
        const imgCount = this.images.filter((e) => !e.isDelete);
        if (files.length > this.maximumCount || (imgCount.length + files.length) > this.maximumCount) {
            this.isImageLength = true;
            this.notify.showError(`Maximum ${this.maximumCount} images of the ${this.comingName} can be uploaded.`);
            return true;
        }

        this.isImageSize = false;
        this.isFormat = false;
        for (let i = 0; i < files.length; i++) {
            if ((files[i].size / 1024 / 1024) > this.maximumSize) {
                this.notify.showError(`Image size should not exceed ${this.maximumSize} MB ${this.maximumCount > 1 ? 'each' : ''}`);
                this.isImageSize = true;
                break;
            }
            if (!this.uploadFileSvc.fileFormats.includes(files[i].type)) {
                this.notify.showError(`Image should in JPEG or PNG format.`);
                this.isFormat = true;
                break;
            }
        }
        return this.isFormat || this.isImageSize;
    }

    public drop(event: CdkDragDrop<any>): void {
        this.images[event.previousContainer.data.index] = event.container.data.item;
        this.images[event.container.data.index] = event.previousContainer.data.item;
        this.getActiveImages.map((im, i) => im.priority = i);
        this.imagesChange.emit(this.images);
    }

    public get getActiveImages(): IImage[] {
        return this.images.filter((el) => !el.isDelete);
    }
}
