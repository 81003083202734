export const formConstant = {
  textFieldMaxLength: 50,
  textFieldMaxLength120: 120,
  textFieldMaxLength200: 200,
  textFieldMaxLength30: 30,
  passwordMinLength: 8,
  mincontactableIdLength: 9,
  maxcontactableIdLength: 9,
  //  @todo Change after business requirement will be clear
  // Size in KB
  maxLogoSize: 100 * 1024,
  minZipCodeLength: 4,
  minTextLength: 2,
  IBANLength: 15,
  SWIFTLength: 8,
  VATMinLength: 8,
  VATMaxMinLength: 15,
  currencyLength: 3,
  pageSize: 100,
};
