import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ICountry} from '../models/country.interface';
import {ApiService} from './api.service';
import {LocalStorageService} from './local-storage.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ConstantService extends ApiService {

    public availableCountries: BehaviorSubject<ICountry[]> = new BehaviorSubject<ICountry[]>(null);
    // public availablePhoneCodes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    // public availableCourier$: BehaviorSubject<IAvailableCourierItem[]> = new BehaviorSubject<IAvailableCourierItem[]>(null);
    // private availablePhoneCodesCache = null;

    private isLoadingTypes = false;

    constructor(
        public http: HttpClient,
        public localStorage: LocalStorageService,
    ) {
        super(http, localStorage);
        this.setAvailableCountries();
    }

    setAvailableCountries(page: number = 1, limit: number = 1000, q: string = ''): void {
        this.requestMain({
            path: `countries`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        }).subscribe(data => {
            if (!data) {
                return;
            }
            this.availableCountries.next(data.items);
        });
    }

    getAvailableCountries(page: number = 1, limit: number = 1000, isEurope: boolean = false, withTaxRate: boolean = false, q: string = '', isFBA: boolean = false): Observable<any> {
        return this.requestMain({
            path: `countries`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                isEurope: isEurope,
                withTaxRate: withTaxRate,
                isFBA: isFBA,
            }
        }).pipe(map(el => el.items));
    }

    getAvailableStatesById(id: number | string, page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.requestMain({
            path: `countries/${id}/states`,
            method: 'GET',
            query: {
                code: id,
                page: page,
                limit: limit,
                q: q
            }
        }).pipe(map(el => el.items));
    }

    getAllByCountryId(countryId: number | string): Observable<any> {
        return this.requestMain({
            method: 'GET',
            path: `cities/${countryId}`,
        });
    }

    getAvailablePhoneCodes(page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.requestMain({
            path: `countries`,
            method: 'GET',
            query: {
                phoneCodes: true,
                page: page,
                limit: limit,
                q: q
            }
        });
    }

}
