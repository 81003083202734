import {Injectable} from '@angular/core';
import { IBanner } from '../models/image.interface';
import {ApiService, IApiParams} from './api.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CompanyMarketplaceService extends ApiService {

  getListOfMarketplaces(page: number = 1, limit: number = 20, companyId: number | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/${companyId}`,
      method: 'GET',
      query: {
        page: page,
        limit: limit,
        companyId
      }
    });
  }

  getListOfWHSMarketplaces(page: number = 1, limit: number = 20, companyId: number | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/warehouse-shop/${companyId}`,
      method: 'GET',
      query: {
        page: page,
        limit: limit,
        companyId
      }
    });
  }

  getSkuVaultChannelId(skuVaultChannelId: any | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/skuVaultChannelId/${skuVaultChannelId}`,
      method: 'GET'
    });
  }

  getskuVaultShopId(getskuVaultShopId: any | string): Observable<any> {
    return this.request({
      path: `company-marketplaces/skuVaultShopId/${getskuVaultShopId}`,
      method: 'GET'
    });
  }

  getListOfShops(companyId: number | string, q?: string): Observable<any> {
    let data: IApiParams = {
      path: `company-marketplaces/shops/${companyId}`,
      method: 'GET',
    }
    if (q) {
      data['query'] = {q};
    }
    return this.request(data);
  }

  delete(id): Observable<any> {
    return this.request({
      path: `company-marketplaces/${id}`,
      method: 'DELETE',
    });
  }

  deletePaymentDetails(id): Observable<any> {
    return this.request({
      path: `company-marketplace-payment/${id}`,
      method: 'DELETE',
    });
  }

    public addBanner(body): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `banner`,
            body: body
        });
    }

    public getBanner(shopId: string): Observable<IBanner> {
        return this.requestEcommerce({
            method: 'GET',
            path: `banner/${shopId}`,
        });
    }

    addWarehouseShop(body): Observable<any> {
      return this.request({
          path: `warehouses/add-warehouse-shop`,
          method: 'POST',
          body: body
      });
    }

    viewWarehouseShop(warehouseId, companyId): Observable<any> {
      return this.request({
          path: `warehouses/view-warehouse-shop/${warehouseId}/${companyId}`,
          method: 'GET'         
      });
    }

    deleteWarehouseShop(id, companyId): Observable<any> {
      return this.request({
          path: `warehouses/delete-warehouse-shop/${id}/${companyId}`,
          method: 'DELETE'         
      });
    }

    changeWMSStatus(id:number ,status:any) {
      return this.request({
          path: `warehouses/updateStatus/${id}`,
          method: 'PUT',
          body: status  
      })
  }
}
