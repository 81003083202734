import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IProductCard } from '../../core/models/product.interface';
import { EmpactShopService } from '../../core/services/empact-shop.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CartService } from '../../core/services/cart.service';

@Component({
    selector: 'eci-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

    @Input() product: IProductCard;

    constructor(
        public router: Router,
        private shopService: EmpactShopService,
        private cartSvc: CartService,
        private notifySvc: NotificationService,
    ) {
    }

    ngOnInit(): void {
    }

    /**
     * Open products page filtered by category
     */
    public onCardClick(product: IProductCard, event): void {
        event.preventDefault();
        this.router.navigate([`/ecommerce/${this.shopService.shopId}/details/${product.id}`]);
    }

    /**
     * Add product to cart
     */
    public addToCart(product: IProductCard, e: MouseEvent): void {
        e.stopPropagation();
        this.cartSvc.addProduct2Cart({ productId: Number(this.product.id), quantity: 1 }, this.shopService.shopId)
            .subscribe(() => {
                this.notifySvc.showSuccessMessage('Product added to the Cart Successfully');
            });
    }

    public salePriceAfterDiscount(): number {
        return (this.product.discount && this.product.discount > 0) ?
            (this.product.salePrice - (this.product.salePrice * this.product.discount / 100)) :
            this.product.salePrice;
    }
}
