import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { find } from 'lodash';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { OrderType } from 'src/app/shared/enums/order-type.enum';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { NewPickupInfoPopupDialog } from 'src/app/shared/popups/new-pickup-info-popup/new-pickup-info-popup.component';
import { IOrderDetails, IPickupInfo } from '../../../models/order-details.model';

@Component({
  selector: 'eci-pickup-info-list',
  templateUrl: './pickup-info-list.component.html',
  styleUrls: ['./pickup-info-list.component.scss']
})
export class PickupInfoListComponent implements OnInit {

  pageParams: PageParams = new PageParams();
  @Input() summaryPage = false;
  @Input() orderId: number = 0;
  @Input() info: IOrderDetails;
  @Input() isCreateMode: boolean = true;
  @Input() availableTypes: IAvailableTypes;
  @Input() availableFields: string[];
  @Input() hasError: boolean;
  @Input() currentOrderType: number;
  @Input() destinationPortCountryCode: string;

  pickupInfoList: IPickupInfo[];
  currentSort: Sort;

  @Output() infoChange = new EventEmitter<IOrderDetails>();
  @Output() hasErrorChange = new EventEmitter<boolean>();

  pageChanged(e: PageParams): void {
    this.pageParams.pageIndex = e.pageIndex;
    this.pageParams.pageSize = e.pageSize;
    // this.getList(e.pageIndex + 1, e.pageSize);
    this.paginate(this.pickupInfoList, e.pageIndex + 1, e.pageSize);
  }

  constructor(
    private dialog: MatDialog,
    public userSvc: UserService,
    private notify: NotificationService
  ) { }

  displayedColumns: string[] = [
    'addressField',
    'name',
    'telNo',
    'agentContactDetails',
    'dueDate',
    'pickupCode',
    // 'note',
  ];
  dataSource;

  ngOnInit(): void {
    this.pickupInfoList = this.info.pickupInfos === undefined ? [] : this.info.pickupInfos;
    this.dataSource = this.pickupInfoList;
    // this.getList(this.orderId, 1, 100, '');
    this.paginate(this.pickupInfoList, 1, 100);
  }

  openDialog(): void {
    if (!this.destinationPortCountryCode) {
      this.notify.showWarn('Choose port of origin');
      return;
    }

    const dialogRef = this.dialog.open(NewPickupInfoPopupDialog, {
      maxHeight: '600px',
      maxWidth: '800px',
      data: {
        isCreateMode: this.isCreateMode,
        countryId: this.destinationPortCountryCode,
        showAlert: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (this.isCreateMode) {
        this.pickupInfoList.push(result);

        this.dataSource = [...this.pickupInfoList];
        this.info = this.model;
        this.paginate(this.pickupInfoList, 1, 100);
        this.infoChange.emit(this.info);
      } else {
        this.updatePickup(result);
      }
    });
  }

  paginate(array, page_number, page_size) {
    const newArr = array.slice((page_number - 1) * page_size, page_number * page_size);
    this.dataSource = newArr;
    this.pageParams.pageSize = page_size;
    this.pageParams.pageIndex = page_number - 1;
    this.pageParams.length = this.pickupInfoList.length;
    return newArr
  }

  openEditDialog(id): void {
    if (this.isCreateMode) {
      this.openDialogLocal(this.pickupInfoList[id], id);
    } else {
      this.openDialogLocal(this.pickupInfoList?.find(el => el.id === id), id);
    }
  }

  private openDialogLocal(data, id): void {
    const dialogRef = this.dialog.open(NewPickupInfoPopupDialog, {
      maxHeight: '600px',
      maxWidth: '800px',
      data: {
        isCreateMode: this.isCreateMode,
        countryId: this.destinationPortCountryCode,
        showAlert: this.info?.type !== OrderType.Domestic && (this.info?.shipment?.trackingType === ShipmentType.Ocean || this.info?.shipment?.trackingType === ShipmentType.Air)
      }
    });

    dialogRef.componentInstance.info = data;

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      if (!this.isCreateMode) {
        this.updatePickup(result);
        // this.getList(this.info.id);
      } else {
        this.pickupInfoList[id] = result;
        this.dataSource = [...this.pickupInfoList];
      }
    });
  }

  get model(): IOrderDetails {
    return {
      ...this.info,
      pickupInfos: this.pickupInfoList ? this.pickupInfoList : []
    }
  }

  updatePickup(value): void {
    // this.pickupInfoSvc.updateOrderPickupInfo(this.info.id, value.id, value).subscribe(() => {
    //   this.getList(this.info.id);
    // });
  }

  sortData(sort: Sort): void {
    const data = this.pickupInfoList.slice();
    if (!sort.active || sort.direction === '') {
      this.pickupInfoList = data;
      this.dataSource = new MatTableDataSource<IPickupInfo>(this.pickupInfoList);
      return;
    }

    if (this.isCreateMode) {
      this.localSort(sort, data);
      return;
    }

    this.currentSort = sort;
    // this.getList(this.info.id);
  }

  localSort(sort: Sort, data: any): void {
    this.pickupInfoList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'dueDate': return compare(a.dueDate, b.dueDate, isAsc);
        default: return 0;
      }
    });

    this.dataSource = new MatTableDataSource<IPickupInfo>(this.pickupInfoList);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
