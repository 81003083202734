import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NotificationService} from '../../../core/services/notification.service';
import {OrdersService} from '../../../modules/customers-order-management/services/orders.service';

@Component({
    selector: 'add-new-status',
    templateUrl: './add-new-status.component.html',
    styleUrls: ['./add-new-status.component.scss'],
})
export class AddNewStatusComponent {
    status: FormControl;
    showLoader = false;

    constructor(
        private ordersService: OrdersService,
        public dialogRef: MatDialogRef<AddNewStatusComponent>,
        private notify: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data?: any
    ) {
    }

    ngOnInit(): void {
        this.status = new FormControl(
            '',
            [Validators.required]
        );
    }

    addNewStatus() {
        this.showLoader = true;
        this.ordersService.addNewStatus(this.status.value).subscribe(res => {
            this.showLoader = false;
            this.dialogRef.close(res);
        }, error => {
            this.showLoader = false;
            this.notify.showError(error.error ? error.error?.message : 'Something wrong');
        })
    }
}
