import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { formConstant } from '../../../../config';
import { ConstantService } from 'src/app/core/services/constant.service';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';
import { CustomerManagementService } from 'src/app/modules/customer-management/services/customer-management.service';
import { RegistrationService } from 'src/app/modules/customer-registration/services/registration.service';
import { UserService } from 'src/app/core/services/user.service';
import { cellPhoneValidator, cellPhoneValidatorNew, landlinePhoneValidator } from 'src/app/core/helpers/helpers.function';
import { CityService } from 'src/app/core/services/city.service';
import { FilterPipe } from '../../pipes/filter.pipe';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WmsService } from 'src/app/modules/wms/services/wms-service';
import { IWarehouseProviders } from 'src/app/core/models/warehouse-providers';

@Component({
    selector: 'app-warehouse-popup',
    templateUrl: './warehouse-popup.component.html',
    styleUrls: ['./warehouse-popup.component.scss'],
    providers: [
        FilterPipe
    ]
})
export class WarehousePopupDialog implements OnInit {

    wareHouseForm: FormGroup;
    textFieldMaxLength = formConstant.textFieldMaxLength;

    formConstant = formConstant;
    isThirdPartyIdExist = false;
    phoneCode: any[];
    warehouseProviders: IWarehouseProviders[];
    // locales = LOCATION_TYPES;
    // wareHouseTypes = WAREHOUS_TYPES;
    // counties = COUNTRIES;

    availableTypes: IAvailableTypes;
    ALlcountries: ICountry[];
    Allstates: IState[] = [];
    countries: ICountry[];
    states: IState[] = [];
    cities: any[] = [];
    searchState: FormControl = new FormControl('');
    searchCountry: FormControl = new FormControl('');
    searchCity: FormControl = new FormControl('');
    @Input() info: IWarehouseInfo;
    lastPhoneCode: String = '';
    isProviderRequired = false;
    /** Label of the search placeholder */
    @Input() placeholderLabel = 'Search...';
    @Input() noEntriesFoundLabel = 'No results';

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<WarehousePopupDialog>,
        private constSvc: ConstantService,
        public userSvc: UserService,
        private citySvc: CityService,
        public filterPipe: FilterPipe,
        public wmsService: WmsService
    ) {
    }

    get warehouseTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.WarehouseType;
    }

    get locales(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.WarehouseLocation;
    }

    getFlagClass(code: string) {
        return `iti__${code?.toLowerCase()}`;
    }

    ngOnInit(): void {
        this.wareHouseForm = this.initializeForm();
        this.subcribeOnAvailableTypes();
        this.getWarehouseProviders();
        this.wareHouseForm.get('country').setValue(this.info?.address?.state?.country?.code);
        this.constSvc.availablePhoneCodes.subscribe(data => {
            if (!data) {
                return;
            }
            this.phoneCode = data;
        });

        if (this.info?.id) {
            if (this.info.wmsStatus) {
                this.isProviderRequired = true;
                this.wareHouseForm.controls.providerId.setValidators([Validators.required]);
            } else {
                this.isProviderRequired = false;
                this.wareHouseForm.controls.providerId.setValidators([]);
            }
        }

        this.wareHouseForm.get("wmsStatus").valueChanges.subscribe((result) => {
            if (result === 'true') {
                this.isProviderRequired = true;
                this.wareHouseForm.controls.providerId.setValidators([Validators.required]);
                this.wareHouseForm.get('providerId').setValue('');
            } else {
                this.isProviderRequired = false;
                this.wareHouseForm.controls.providerId.setValidators([]);
                this.wareHouseForm.get('providerId').setValue('');
            }
        })

        this.searchCountry.valueChanges.subscribe(value => {
            let searchText = value.toLowerCase();
            this.countries = this.ALlcountries.filter(it => {
                return it.name.toLowerCase().substring(0, searchText.length) == searchText;
            });
        });

        this.searchState.valueChanges.subscribe(value => {
            let searchText = value.toLowerCase();
            this.states = this.Allstates.filter(it => {
                return it.name.toLowerCase().substring(0, searchText.length) == searchText;
            });
        });


        this.wareHouseForm.get('thirdPartyId').valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe(data => {
                if (this.info?.id) {
                    if (data && this.info?.thirdPartyId !== data) {
                        this.checkThirdPartyIdExist(data);
                    } else {
                        this.isThirdPartyIdExist = false;
                    }
                }
                else {
                    this.checkThirdPartyIdExist(data);
                }
            });
    }

    getWarehouseProviders() {
        this.wmsService.getWarehouseProviders().subscribe(result => {
            this.warehouseProviders = result;
            console.log(this.warehouseProviders);
        });
    }

    checkThirdPartyIdExist(data) {
        this.wmsService.checkThirdPartyIdTaken(data).subscribe((result) => {
            if (result) {
                this.isThirdPartyIdExist = true;
            } else {
                this.isThirdPartyIdExist = false;
            }
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.wareHouseForm.invalid) {
            return;
        }

        this.dialogRef.close(this.model);
    }

    initializeForm(): FormGroup {
        this.getCitiesByCountryId(this.info?.address?.state?.country?.id);
        return this.formBuilder.group({
            name: [this.info?.name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
            address: [this.info?.address?.name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
            zipCode: [this.info?.address?.zipCode || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength), Validators.minLength(formConstant.minZipCodeLength)]],
            city: [this.info?.address?.city || null, [Validators.required]],
            country: [this.info?.address?.state?.country?.code || null, [Validators.required]],
            state: [this.info?.address?.stateId || null, [Validators.required]],
            contact1Name: [this.info?.contact1Name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength)]],
            contact1CellNo: [this.info?.contact1CelNo || '', [cellPhoneValidatorNew()]],
            wmsStatus: [this.info?.id ? (this.info?.wmsStatus ? "true" : "false") : null, [Validators.required]],
            thirdPartyId: [this.info?.thirdPartyId || null],
            providerId: [this.info?.providerId || ''],
        });
    }

    subcribeOnAvailableTypes(): void {
        this.constSvc.availableTypes.subscribe(data => {
            if (!data) {
                return;
            }
            this.availableTypes = data;
        });

        this.constSvc.availableCountries.subscribe(data => {
            if (data) {
                this.countries = data;
                this.ALlcountries = data;
            }
        });

        this.wareHouseForm.get('country').valueChanges.subscribe(value => {
            // this.wareHouseForm.get('phoneCode').setValue(this.getPhoneCode(value));
            if (this.countries) {
                const selectedCountry = this.countries.find(item => item.code == value);
                let phoneNumWithoutCode = (this.wareHouseForm.get('contact1CellNo').value.split(this.lastPhoneCode || this.info?.address?.state?.country?.phoneCode).join('').split('+').join('') || '');
                if (this.info?.id) {
                    this.wareHouseForm.get('contact1CellNo').setValue(this.info?.contact1CelNo);
                }
                else {
                    this.wareHouseForm.get('contact1CellNo').setValue((selectedCountry?.phoneCode || '') + phoneNumWithoutCode);
                }
                this.getCitiesByCountryId(selectedCountry?.id);
                this.lastPhoneCode = selectedCountry?.phoneCode
            }

            if (value && (value !== 'null' || value !== 'undefined')) {
                this.constSvc.getAvailableStatesById(value).subscribe(states => {
                    if (!states) {
                        return;
                    }
                    this.states = states;
                    this.Allstates = states;

                    let st = this.states.find(el => el.id == this.info?.address?.stateId);
                    this.wareHouseForm.get('state').setValue(st ? st.id : states.length > 0 ? states[0].id : null);
                });
            }
        });
    }

    getPhoneCode(country): string {
        if (!this.countries) {
            return '';
        }
        const countryInfo = this.countries.find(el => el.code == country);
        return countryInfo ? countryInfo?.phoneCode : '';
    }

    get model(): IWarehouseInfo {
        return {
            // companyId: this.regSvc.currentcontactableId,
            // location: this.wareHouseForm.get('locale').value,
            name: this.wareHouseForm.get('name').value,
            // warehouseType: this.wareHouseForm.get('wms').value,
            // wms: this.wareHouseForm.get('wms').value,
            wmsStatus: this.wareHouseForm.get('wmsStatus').value === "true" ? true : false,
            thirdPartyId: this.wareHouseForm.get('thirdPartyId').value,
            providerId: Number(this.wareHouseForm.get('providerId').value),
            address: {
                name: this.wareHouseForm.get('address').value,
                city: this.wareHouseForm.get('city').value,
                // countryId: this.wareHouseForm.get('country').value,
                zipCode: this.wareHouseForm.get('zipCode').value,
                stateId: this.wareHouseForm.get('state').value,
            },
            contacts: [
                {
                    name: this.wareHouseForm.get('contact1Name').value,
                    cellNumber: this.wareHouseForm.get('contact1CellNo').value,
                    position: 'mock'
                }
            ]
        };
    }

    compareWithFn(listOfItems, selectedItem) {
        return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
    }

    getCitiesByCountryId(countryID: string | number): void {
        if (countryID) {
            this.citySvc.getAllByCountryId(countryID).subscribe(cities => {
                if (!cities) {
                    return;
                }
                this.cities = cities;
                if (!cities) {
                    this.wareHouseForm.get('city').setValue(null);
                    return;
                }
                let st = this.cities.find(el => el.city === this.info?.address?.city);
                this.wareHouseForm.get('city').setValue(st ? st.city : cities[0].city || null);
            });
        }
    }
}
