import {SelectionModel} from '@angular/cdk/collections';
import {DatePipe} from '@angular/common';
import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute} from '@angular/router';
import {UserService} from 'src/app/core/services/user.service';
import {RegistrationService} from 'src/app/modules/customer-registration/services/registration.service';

import {ICategoryMarketplace} from '../../../../core/models/shop-category.interface';
import {CategoriesService} from '../../../../core/services/categories.service';
import {NotificationService} from '../../../../core/services/notification.service';
import { ApplyHsCodeComponent } from '../../../../shared/components/apply-hs-code/apply-hs-code.component';
import {ConfirmPopupDialog} from '../../../../shared/popups/confirm-popup/confirm-popup.component';
import {ShopCategoriesService} from '../../../customer-management/services/shop-categories.service';
import {
    IEmpactProductDetails,
    IEmpactProductResponse,
    IProductProperties, IShopBase,
} from '../../models/empact-product-details.interface';
import {OfflineShopService} from '../../services/offline-shop.service';

@Component({
    selector: 'ecommarce-product-details',
    templateUrl: './offline-product.component.html',
    styleUrls: ['./offline-product.component.scss'],
    providers: [DatePipe],
})
export class OfflineProductComponent {
    private selection = new SelectionModel<any>(true, []);

    public header = 'Product Details';
    public marginTop = '80px';
    public createMode = false;
    public info: IEmpactProductDetails;
    public listOfShop: IShopBase[] = [];
    public properties: IProductProperties[] = [];
    public form: FormGroup;
    public isDialog = true;
    public companyCode: number;
    public shopsId: string[] = [];

    images: any = [];
    submitted = false;
    statusList = ['Not Available', 'Available'];
    public listOfUsers: any[] = []; // TO DO
    public previousePageUsers = 0;
    public totalPagesUsers = 1;

    public searchCategory: FormControl = new FormControl('');

    public marketplaceCategory: ICategoryMarketplace[] = [];
    public marketplaceSubCategory: ICategoryMarketplace[] = [];
    public marketplaceCategoryAll: ICategoryMarketplace[] = [];

    isName: boolean = false;
    isSKU: boolean = false;

    public constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<OfflineProductComponent>,
        private productsSvc: OfflineShopService,
        private categoriesSvc: CategoriesService,
        private notify: NotificationService,
        public userSvc: UserService,
        private regSvc: RegistrationService,
        private dialog: MatDialog,
        private categoriesService: ShopCategoriesService,
    ) {
    }

    public ngOnInit(): void {
        this.getInfo();
        this.productsSvc.listOfShop$.subscribe((shops) => {
            this.listOfShop = shops;
        });
    }

    public getInfo(): void {
        this.route.data.subscribe((data) => {
            if (data.product) {
                this.isDialog = false;
            }
            this.info = data.product;

            if (data.product?.properties) {
                if (Array.isArray(data.product.properties)) {
                    this.info.properties = data.product.properties;
                } else {
                    this.info.properties = JSON.parse(data.product.properties);
                }
            }
            if (!this.info) {
                this.createMode = true;
                this.info = {};
            }
            this.form = this.initializeForm(this.info);
            this.properties = (this.info?.properties && this.info?.properties.length > 0) ? this.info.properties : [];
            if (!this.companyCode && this.userSvc.isOfflineCustomer) {
                this.companyCode = this.userSvc.userStorage?.company?.id;
            }
            this.getShopCategory();
        });
    }

    private getShopCategory(): void {
        this.categoriesService.getAllCategories()
            .subscribe((value: ICategoryMarketplace[]) => {
                if (value && value.length > 0) {
                    this.marketplaceCategoryAll = value;
                    this.marketplaceCategory = value.filter((category: ICategoryMarketplace) => category.parentId === null);
                    // Todo: marketplaceCategory only for ecommerce ms1. need to remove
                    const selectedCategory = this.marketplaceCategory.find((el) => el.name == 'Electronics (Accessories)');
                    this.form.get('shopCategory').setValue(selectedCategory);
                    this.updateMarketplaceSubCategoryes(selectedCategory.id);
                    this.setCategoryInForm();
                }
            });
    }

    public onShopCategoryChange(event): void {
        this.form?.get('shopSubCategory').setValue(undefined);
        if (!event.value) {
            return;
        }
        this.form.get('shopCategory').setValue(event.value);
        this.updateMarketplaceSubCategoryes(event.value.id);
        if (this.marketplaceSubCategory.length === 1) {
            const category = [...this.marketplaceSubCategory].pop();
            this.form.get('shopSubCategory').setValue(category);
            this.setShopCategory(category);
        } else {
            this.setShopCategory(event.value);
        }
    }

    private setShopCategory(shopCategoryValue: ICategoryMarketplace) {
        this.info.shopCategory = shopCategoryValue;
        this.info.shopCategoryName = shopCategoryValue.name;
    }

    public initializeForm(info: IEmpactProductDetails): FormGroup {
        if (info.name && info.sku) {
            this.isName = true;
            this.isSKU = true;
        } else {
            this.isName = false;
            this.isSKU = false;
        }

        return this.formBuilder.group({
            name: [{disabled: false, value: info?.name}, [Validators.required]],
            status: [{disabled: !this.hideUser, value: info?.status || 1}],
            sku: [{disabled: !this.createMode, value: info?.sku}, [Validators.required]],
            secondarySku: [{disabled: !this.hideUser, value: info?.secondarySku}],
            weight: [{disabled: !this.hideUser, value: info?.weight || 0}, [Validators.min(0)]],
            volume: [{disabled: !this.hideUser, value: info?.volume || 0}, [Validators.min(0)]],
            salePrice: [{disabled: !this.hideUser, value: info?.salePrice || 0}, [Validators.required, Validators.min(1)]],
            eanUpc: [{disabled: !this.hideUser, value: info?.eanUpc || ''}],
            costPriceUnit: [{disabled: false, value: info?.costPriceUnit || 0}, [Validators.min(1), Validators.required]],
            // Todo: marketplaceCategory only for ecommerce ms1. need to remove
            shopCategory: new FormControl({
                disabled: true,
                value: this.marketplaceCategory.find((el) => el.name == 'Electronics (Accessories)')
            }),
            shopSubCategory: [{disabled: false}, [Validators.required]],
            shopId: [{disabled: false, value: info?.shopId || this.shopsId?.[0]}, [Validators.required]],
            leadTime: [{disabled: false, value: info?.leadTime || 0}, [Validators.min(0)]],
            availableQuantity: [{disabled: false, value: info?.availableQuantity || 0}, [Validators.min(0)]],
            brand: [{disabled: false, value: info?.brand}],
            discount: [{disabled: false, value: info?.discount || 0}],
            size: [{disabled: false, value: info?.size}],
            color: [{disabled: false, value: info?.color}],
            colorCode: [{disabled: false, value: info?.colorCode}],
            hsCode: [{disabled: false, value: info?.hsCode}],
            hsCodeId: [{disabled: false, value: info?.hsCodeId}],
            currency: [{disabled: false, value: info?.currency || 'EUR'}],
        });
    }

    public setCategoryInForm() {
        if (!this.info.shopCategoryName || this.marketplaceCategoryAll.length === 0) {
            return;
        }
        const shopCategory = this.marketplaceCategoryAll.find((category) => category.name === this.info.shopCategoryName);
        if (!shopCategory) {
            return;
        }
        if (shopCategory.parentId) {
            this.form.get('shopCategory').setValue(this.marketplaceCategoryAll.find((category) => category.id === shopCategory.parentId));
            this.updateMarketplaceSubCategoryes(shopCategory.parentId);
            this.form.get('shopSubCategory').setValue(shopCategory);
        } else {
            this.form.get('shopCategory').setValue(shopCategory);
            this.updateMarketplaceSubCategoryes(shopCategory.id);
        }
    }

    private updateMarketplaceSubCategoryes(categoryId: number): void {
        this.marketplaceSubCategory = this.marketplaceCategoryAll
            .filter((category: ICategoryMarketplace) => category.parentId === categoryId);
    }

    public save(): void {
        this.submitted = true;
        if (!this.isValid()) {
            this.form.markAllAsTouched();
            return;
        }
        if (this.form.get('availableQuantity').value === 0) {
            this.confirmDialog();
        } else {
            (this.createMode) ? this.createProduct() : this.updateProduct();
        }
    }

    public confirmDialog(): void {
        const dialogRef = this.dialog.open(ConfirmPopupDialog);
        dialogRef.componentInstance.message = `Products will appear as Out of Stock in the shop, if added with '0' available quantity`;
        dialogRef.componentInstance.rightBtnText = 'Yes';
        dialogRef.componentInstance.leftBtnText = 'No';
        dialogRef.componentInstance.header = 'Warning';
        dialogRef.afterClosed()
            .subscribe((value) => {
                if (value) {
                    (this.createMode) ? this.createProduct() : this.updateProduct();
                }
            });
    }

    private updateProduct(): void {
        this.productsSvc.updateProduct(this.info?.id, this.prepareBody).subscribe((value) => {
            this.notify.showSuccessMessage('Updated successfully');
            this.empactProductMessage(value.empactStatus);
            this.exit();
        }, (error) => {
            this.notify.showValidationError(error);
        });
    }

    private createProduct(): void {
        this.productsSvc.createProduct(this.prepareBody).subscribe((value) => {
            this.notify.showSuccessMessage('Created successfully');
            this.empactProductMessage(value.empactStatus);
            this.exit();
        }, (error) => {
            this.notify.showValidationError(error);
        });
    }

    private empactProductMessage(value): void {
        if (value?.success == 1) {
            this.notify.showSuccessMessage('Empact Product Created successfully');
        } else {
            if (value.failedReason[0]) {
                this.notify.showError(value.failedReason[0].reason);
            } else {
                this.notify.showError('Failed to create product');
            }
        }
    }

    private isValid(): boolean {
        if (this.form.invalid) {
            this.notify.showError('Not all fields are filled');
            this.form.markAllAsTouched();
            return false;
        }
        const duplicate = this.properties.map((v) => v.name)
            .filter((v, i, vNames) => vNames.indexOf(v) !== i);
        if (duplicate.length > 0) {
            this.notify.showError(`Please remove all duplicate characteristics ${duplicate.join()}`);

            return false;
        }
        if (!this.form.get('shopSubCategory').value) {
            this.notify.showError('Please select the shop sub category');

            return false;
        }
        if (this.form.get('costPriceUnit').value < 1 || isNaN(this.form.get('costPriceUnit').value)) {
            this.notify.showError('Cost price should be greater then 0');
            return false;
        }

        if (this.form.get('salePrice').value < 1 || isNaN(this.form.get('salePrice').value)) {
            this.notify.showError('Sale price should be greater then 0');
            return false;
        }

        if (this.form.get('discount').value >= 100) {
            this.notify.showError('Discount should be less then 100%');
            return false;
        }
        return true;
    }

    public exit(): void {
        (!this.isDialog) ? history.back() : this.dialogRef.close();
    }

    private get prepareBody(): IEmpactProductResponse {
        const productDetails = {
            ...this.info,
            ...this.form?.value,
            status: this.form.get('status')?.value,
            properties: this.properties,
            companyId: this.companyCode,
            images: this.info.images,
        };
        delete productDetails.shopSubCategory;
        delete productDetails.shopCategory;
        return productDetails;
    }

    public changePriceUnit(fieldName): void {
        if (!this.form.get(fieldName)?.value || this.form.get(fieldName)?.value === 0) {
            return;
        }
        this.form.get(fieldName).setValue(parseFloat(this.form.get(fieldName)?.value).toFixed(2));
    }

    public get hideUser(): boolean {
        return this.userSvc.isAdmin || this.userSvc.isOfflineCustomer;
    }

    public onShopChange(event: MatSelectChange): void {
        if (!event.value) {
            return;
        }
        const shop = this.listOfShop.find((item: IShopBase) => item.shopId === event.value);
        if (shop?.shopCategoryId) {
            const categoryMarketplace: ICategoryMarketplace = this.marketplaceCategory
                .find((category) => category.id === shop?.shopCategoryId);
            this.onShopCategoryChange({value: categoryMarketplace});
        }
    }

    public addProperties() {
        this.properties.push({name: '', value: '', status: false} as IProductProperties);
    }

    public removeProperties(index: number) {
        this.properties.splice(index, 1);
    }

    public onCangeProperties(prop: IProductProperties) {
        prop.status = !prop.status;
    }

    public isProperties(): boolean {
        return !this.properties || this.properties.length > 0;
    }

    get isAdmin(): boolean {
        return this.userSvc.isAdmin;
    }

    applyHsCode(id?: number): void {
        let ids = !!id ? [id] : this.selection.selected.map(el => el.id);
        const dialogRef = this.dialog.open(ApplyHsCodeComponent, {
            data: ids,
            maxHeight: '90vh',
            height: '90vh',
            width: '90vw'
        });
        const info = ids;
        dialogRef.componentInstance.info = info;
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                if (!this.createMode) {
                    const obj = {
                        productId: id,
                        hsCodeId: response.hscodeId,
                        hsCode: response.applyHSCodeSuccess
                    };
                    this.productsSvc.applyHsCodes(obj).subscribe(data => {
                        if (!data) {
                            this.notify.showError('Something went Wrong !!!');
                            return;
                        }
                        this.info.hsCode = data.hsCode;
                        this.form.get('hsCode').setValue(data.hsCode);
                        this.form.get('hsCodeId').setValue(data.hscodeId);
                        this.notify.showSuccessMessage('Hs Codes Applied Successfull');
                        this.selection.clear();
                        this.productsSvc.updateShopProductList.next('update');
                    });
                } else {
                    this.form.get('hsCode').setValue(response.applyHSCodeSuccess);
                    this.form.get('hsCodeId').setValue(response.hscodeId);
                }
            }
        });
    }

    removeHsCode(id?: number): void {
        const obj = {
            productId: id,
            hsCodeId: '',
        };
        this.productsSvc.applyHsCodes(obj).subscribe(data => {
            if (!data) {
                this.notify.showError('Something went Wrong !!!');
                return;
            }
            this.info.hsCode = '';
            this.form.get('hsCode').setValue('');
            this.notify.showSuccessMessage('Hs Codes Removed Successfull');
            this.selection.clear();
            this.productsSvc.updateShopProductList.next('update');
        });
    }
}
