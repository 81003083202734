export enum OrderDocumentType {
    DeliveryNote,
    AWB,
    MBL,
    T1,
    EX1,
    PackingList,
    CustomDeclaration,
    CMR,
    SignedPod,
    HBL,
    MAWB,
    HAWB,
    //Invoice,
    PricingQuote
}
