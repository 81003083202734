import {Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {IAvailableTypes, IConstantItem} from 'src/app/core/models/available-types.interface';
import {IPaginatorProperties} from '../../../../../core/models/paginator.interface';
import {ProductAllocationNewComponent} from '../../../../../shared/components/product-allocation-new/product-allocation-new.component';
import {IProduct} from '../../../../customers-order-management/models/order-details.model';
import {ProductAllocation} from '../../../../customers-order-management/models/product-allocation.interface';
import {ICarrierItem} from '../../../../shipment-price/models/shipment-price.interface';
import {ShipmentPriceService} from '../../../../shipment-price/services/shipment-price.service';
import {IOrderDetails} from '../../../models/order-details.model';
import {UserService} from 'src/app/core/services/user.service';
import {ShipmentType} from 'src/app/shared/enums/shipment-type.enum';
import {ConsolidateShipmentService} from 'src/app/core/services/consolidate-shipments.service';
import {MatDialog} from '@angular/material/dialog';
import {AddNewStatusComponent} from '../../../../../shared/components/add-new-status/add-new-status.component';
import {NotificationService} from 'src/app/core/services/notification.service';
import {ConfirmPopupDialog} from 'src/app/shared/popups/confirm-popup/confirm-popup.component';
import {ICountry} from 'src/app/core/models/country.interface';
import {ConstantService} from 'src/app/core/services/constant.service';
import {MatExpansionPanel} from '@angular/material/expansion';
import {ChangeDetectorRef} from '@angular/core';

@Component({
    selector: 'eci-consolidated-shipments-last-mile',
    templateUrl: './consolidated-shipments-last-mile.component.html',
    styleUrls: ['./consolidated-shipments-last-mile.component.scss']
})
export class ConsolidatedShipmentsLastMileComponent implements OnInit {

    @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

    lastMileArr = [];

    @Input() info: any;
    @Input() availableTypes: IAvailableTypes;
    @Input() currShipmentType: number;
    @Input() availableCarriers: ICarrierItem[];
    @Input() isCreateMode: boolean;
    @Input() isAdmin: boolean;
    @Input() isEditable: boolean;
    @Input() hasError: boolean;
    @Input() denominators: any;
    @Input() saleOrderProducts: IProduct[];

    @Output() infoChange = new EventEmitter<IOrderDetails>();
    @Output() getOrder = new EventEmitter<number>();
    @Output() hasErrorChange = new EventEmitter<boolean>();

    form: FormGroup;
    items: FormArray;
    searchCarrier: FormControl = new FormControl('');
    searchorderStatus: FormControl = new FormControl('');
    previousCarriersPage: number = 0;
    totalCarriersPage: number = 0;
    carrierSelectedRecord = [];
    isLastMile = 0;
    isBlinking: boolean = false;
    searchCountry = new FormControl('');
    allCountries: ICountry[];
    phoneObj;
    selectedCountry = [];
    myProductsList: IProduct[];
    productAllocation:
        {
            [key: number]: ProductAllocation[]
        } = {};

    constructor(
        private formBuilder: FormBuilder,
        public userSvc: UserService,
        private carriersSvc: ShipmentPriceService,
        private consolShipmentSvc: ConsolidateShipmentService,
        private dialog: MatDialog,
        private notify: NotificationService,
        private constSvc: ConstantService,
        private cdr: ChangeDetectorRef
    ) {
    }

    get isOcean(): boolean {
        return this.currShipmentType === ShipmentType.Ocean;
    }

    get isAir(): boolean {
        return this.currShipmentType === ShipmentType.Air;
    }

    get isCourier(): boolean {
        return this.currShipmentType === ShipmentType.CourierExpress;
    }

    get isInland(): boolean {
        return this.currShipmentType === ShipmentType.Inland;
    }

    get isShowCouriers(): boolean {
        return this.currShipmentType === ShipmentType.CourierExpress || this.currShipmentType === ShipmentType.Inland;
    }

    get itemsFormArray(): FormArray {
        return this.form.get('items') as FormArray;
    }

    ngOnInit(): void {
        this.myProductsList = [];
        this.saleOrderProducts.forEach((prd: any) => {
            this.myProductsList.push({
                id: prd?.id,
                description: prd?.description,
                productId: prd.id,
                itemNumber: prd?.product?.sku,
                units: prd?.units,
                categoryId: prd?.product?.category?.id,
                price: prd?.price,
            } as IProduct);
        });

        this.getAvailableCarriers('', 1, 10000, this.info?.shipment?.trackingType);

        this.searchCarrier.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.previousCarriersPage = 0;
            this.getAvailableCarriers();
        });

        this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
        });

        this.startBlinking();
        this.getAllCountries();

        this.form.valueChanges.subscribe(_ => {
            this.info = this.model;
            this.infoChange.emit(this.info);
            this.hasErrorChange.emit(!this.form.valid);
            if (this.info?.lastMile && this.info?.lastMile?.length > 0) {
                this.stopBlinking();
            }
        });

        if (this.info?.lastMile && this.info?.lastMile?.length > 0) {
            this.lastMileArr = this.info.lastMile;
            this.lastMileData(this.info);
            this.stopBlinking();
        }

        this.subscribeToTrackingChanges();

    }

    private subscribeToTrackingChanges(): void {
        this.itemsFormArray.valueChanges.subscribe((items) => {
            items.forEach((item, index) => {
                const trackingNumberControl = this.itemsFormArray.at(index).get('trackingNumber');

                if (!trackingNumberControl) {
                    return;
                }

                const trackingNumberValue = trackingNumberControl.value;
                if (trackingNumberValue && trackingNumberValue.trim() !== '') {
                    this.checkForDuplicates(trackingNumberValue, index);
                }
            });
        });
    }

    private checkForDuplicates(value: string, currentIndex: number): void {
        const formValues = this.itemsFormArray.value;

        if (formValues && formValues.length > 0) {
            const duplicate = formValues.some((item, index) => {
                return index !== currentIndex && item.trackingNumber === value;
            });

            const trackingNumberControl = this.itemsFormArray.at(currentIndex).get('trackingNumber');
            if (duplicate) {
                trackingNumberControl.setErrors({duplicateTrackingNumber: true});
            } else {
                trackingNumberControl.setErrors(null);
            }
        }
    }

    private uniqueTrackingNumberValidator(control: FormControl): { [key: string]: any } | null {
        if (!control.value || control.value.trim() === '') {
            return null;
        }

        const formValues = this.itemsFormArray.value;
        const currentIndex = this.itemsFormArray.controls.indexOf(control.parent);

        if (formValues && formValues.length > 0) {
            const duplicate = formValues.some((item, index) => {
                return index !== currentIndex && item.trackingNumber === control.value;
            });

            return duplicate ? {duplicateTrackingNumber: true} : null;
        }

        return null;
    }

    startBlinking() {
        this.isBlinking = true;
    }

    stopBlinking() {
        this.isBlinking = false;
    }

    getAllCountries() {
        this.constSvc.getAvailableCountries(1, 1000).subscribe(data => {
            if (data) {
                this.allCountries = data;

                if (this.allCountries && this.info?.lastMile && this.info?.lastMile?.length > 0) {
                    this.items = this.form.get('items') as FormArray;
                    this.info.lastMile?.forEach((lastMileItem: any, index) => {
                        if (lastMileItem.phoneCountryCode !== null) {
                            let countryIso2 = this.getIso2Code(lastMileItem?.phoneCountryCode);
                            this.items?.at(index)?.get('phoneCountryCodeIso2')?.setValue(countryIso2);
                        }
                        if (lastMileItem?.countryCode !== null) {
                            const lastMileCountry = this.allCountries.find(country => country.code === lastMileItem?.countryCode);
                            this.selectedCountry.push(lastMileCountry);
                        }
                    });
                }
            }
        });
    }

    lastMileData(info: IOrderDetails) {
        this.items = this.form.get('items') as FormArray;

        if (this.items.length !== 0) {
            this.items.removeAt(0);
        }

        info.lastMile?.forEach((lastMileItem: any, index: number) => {
            this.items.push(this.formBuilder.group({
                carrierId: [{
                    value: lastMileItem.carrierId,
                    disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)
                }, [Validators.required]],
                trackingNumber: [{
                    value: lastMileItem.trackingNumber || null,
                    disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)
                }],
                address: [{value: lastMileItem.address || null, disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)}],
                country: [{value: lastMileItem.countryCode || null, disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)}],
                contactPerson: [{
                    value: lastMileItem.contactPerson || null,
                    disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)
                }],
                phone: [{value: lastMileItem.phoneNumber || null, disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)}],
                phoneCountryCode: [{
                    value: lastMileItem.phoneCountryCode || '1',
                    disabled: !this.isAdmin || (!this.isCreateMode || this.isEditable)
                }],
                phoneCountryCodeIso2: ['us'],
                lastMileStatus: [{value: lastMileItem.status || null, disabled: !this.isAdmin || (this.isCreateMode || this.isEditable)}]
            }));
            if (lastMileItem.lastMileConsolPackages) {
                this.productAllocation[index] = lastMileItem.lastMileConsolPackages;
            }
        });
    }

    get model(): IOrderDetails {
        const items = this.form.get('items') as FormArray;
        const lastMileData = items.controls.map((item: FormGroup, index: number) => {
            const carrierId = item.get('carrierId')?.value;
            const selectedCarrier = this.availableCarriers ? this.availableCarriers?.find((val: any) => val.id == carrierId) : '';
            const carrierName = selectedCarrier ? selectedCarrier.name : '';

            return {
                carrierId: carrierId,
                carrier: carrierName,
                trackingNumber: item.get('trackingNumber')?.value || null,
                address: item.get('address')?.value,
                status: item.get('lastMileStatus')?.value,
                type: this.isLastMile,
                countryCode: item.get('country')?.value,
                contactPerson: item.get('contactPerson').value,
                phoneNumber: item.get('phone').value,
                phoneCountryCode: item.get('phoneCountryCode').value,
                productAllocation: this.productAllocation[index]
            };
        });

        return {
            ...this.info,
            lastMile: lastMileData
        };
    }

    get weightUnits(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.UnitsOfMeasure.filter(el => el.id === 1);
    }

    initializeItem(): FormGroup {
        return this.formBuilder.group({
            carrierId: new FormControl(null, Validators.required),
            trackingNumber: [null, [Validators.required, this.uniqueTrackingNumberValidator.bind(this)]],
            address: new FormControl(null),
            country: new FormControl(null),
            contactPerson: new FormControl(null),
            phone: new FormControl(null),
            phoneCountryCode: ['1'],
            phoneCountryCodeIso2: ['us'],
            lastMileStatus: new FormControl({value: null, disabled: true})
        });
    }

    public getNumber(e, field, phoneCode, index): void {
        this.items = this.form.get('items') as FormArray;
        const value = e.replace(`+${this.items.at(index).get(phoneCode)?.value}`, '');
        this.items.at(index).get(field)?.setValue(value);
    }

    public onCountryChange(countryPhoneCode, codeField, index): void {
        this.items = this.form.get('items') as FormArray;
        this.items.at(index).get(`${codeField}Iso2`)?.setValue(countryPhoneCode.iso2);
        this.items.at(index).get(codeField)?.setValue(countryPhoneCode.dialCode);
    }

    onCountrySelected(countryCode: any, index): void {
        this.items = this.form.get('items') as FormArray;
        const country = this.allCountries?.find((c) => c.code === countryCode);
        this.selectedCountry[index] = country;
        const selectedPhoneCode = country?.phoneCode.replace(/\+/g, '');
        const selectedCodeIso2 = country?.code.toLowerCase();
        this.items?.at(index)?.get('phoneCountryCode')?.setValue(selectedPhoneCode);
        this.items?.at(index)?.get('phoneCountryCodeIso2')?.setValue(selectedCodeIso2);
        this.phoneObj.setCountry(this.items?.at(index)?.get('phoneCountryCodeIso2')?.value);
    }

    private getIso2Code(phoneCode): string {
        if (this.allCountries?.length === 0 || !phoneCode) {
            return 'us';
        }
        const country = this.allCountries?.find((c) => c.phoneCode === '+' + phoneCode);
        return country?.code.toLowerCase();
    }

    public telInputObject(event, name): void {
        this[name] = event;
    }

    addItem() {
        this.items = this.form.get('items') as FormArray;
        this.items.push(this.initializeItem());
        this.productAllocation[this.items.length - 1] = [];

        setTimeout(() => {
            this.expansionPanels.last?.open();
        }, 0);
    }

    getAvailableCarriers(
        q: string = this.searchCarrier.value,
        page: number = this.previousCarriersPage + 1,
        limit: number = 10000,
        type: any = this.currShipmentType == 4 ? 3 : this.currShipmentType
    ) {
        this.carriersSvc.get<IPaginatorProperties<ICarrierItem>>('carriers', page, limit, q).subscribe(data => {
            if (!data) {
                return;
            }

            if (data.meta.currentPage > 1) {
                this.availableCarriers = [...this.availableCarriers, ...data.items];
            } else {
                if (data.meta.itemCount >= 0) {
                    this.availableCarriers = [...this.carrierSelectedRecord, ...data.items];
                }
            }
            this.previousCarriersPage = data.meta.currentPage;
            this.totalCarriersPage = data.meta.totalPages;
        });
    }

    onSelectCarrier(value) {
        this.availableCarriers.forEach((val: any) => {
            if (val.id == value) {
                this.carrierSelectedRecord = [val];
            }
        });
        this.previousCarriersPage = 0;
        this.carrierSelectedRecord = [];
    }

    selectNewStatus(status, trackingNumber) {
        const payload = {
            status: status,
            trackingNumber: trackingNumber
        };
        this.consolShipmentSvc.updateLastMileStatus(payload).subscribe((data) => {
            if (data) {
                // this.getOrder.emit(this.info.id);
            }
        });
    }

    addNewStatus(trackingNumber, i) {
        const dialog = this.dialog.open(AddNewStatusComponent, {
            hasBackdrop: false
        });
        dialog.afterClosed().subscribe(value => {
            if (value) {
                const newStatus = {
                    id: value.value,
                    name: value.name
                };
                this.availableTypes.ShipmentConsolDeliveryStatus.push(newStatus);
                const items = this.form.get('items') as FormArray;
                items.controls.map((item: FormGroup, index) => {
                    if (index === i) {
                        item?.get('lastMileStatus')?.setValue(newStatus.id);
                    }
                });
                this.selectNewStatus(newStatus.id, trackingNumber);
            }
        });
    }

    deleteTrackingDetails(trackingNumber: any, index) {
        this.items = this.form.get('items') as FormArray;
        const dialogRef = this.dialog.open(ConfirmPopupDialog);
        dialogRef.componentInstance.message = 'Are you sure you want to delete';
        dialogRef.componentInstance.rightBtnText = 'Delete';
        dialogRef.afterClosed().subscribe(value => {
            if (!value) {
                return;
            }

            if (this.lastMileArr && this.lastMileArr.length > 0) {
                const trackingDetail = this.lastMileArr.find((val) => val.trackingNumber === trackingNumber);

                if (trackingDetail) {
                    this.consolShipmentSvc.deleteTrackingDetails(trackingDetail?.id).subscribe((res) => {
                        if (res) {
                            this.items.removeAt(index);
                            this.notify.showSuccessMessage('Deleted Successfully');
                        }
                    }, () => {
                        this.notify.showError('Something went wrong');
                    });
                } else {
                    this.items.removeAt(index);
                }
            } else {
                this.items.removeAt(index);
                if (this.info?.lastMile && this.info?.lastMile?.length > 0) {
                    this.stopBlinking();
                } else {
                    this.startBlinking();
                }
            }
        });
    }

    updateTrackingDetails() {
        this.info.lastMile = this.model.lastMile;
        delete this.info['paletform'];
        delete this.info['cartonform'];
        delete this.info['originWarehouseId'];
        delete this.info['destinationWarehouseId'];
        if (this.info?.lastMile && this.info?.lastMile.length > 0) {
            this.consolShipmentSvc.updateLastMileDetails(this.info).subscribe((res) => [
                this.notify.showSuccessMessage('Updated Successfully')
            ]);
        }
    }

    getFlagClass(code: any) {
        if (!code) {
            return '';
        }
        const c = this.allCountries?.find(c => c.id === code)?.code;
        return `iti__${c?.toLowerCase()}`;
    }

    getStatusName(lastMileStatus: number) {
        if (lastMileStatus && this.availableTypes?.ShipmentConsolDeliveryStatus) {
            const status = this.availableTypes.ShipmentConsolDeliveryStatus.find((status) => status.id === lastMileStatus);
            return status ? status.name : '';
        }
        return '';
    }

    getCarrierName(carrierId: string): string {
        const selectedCarrier = this.availableCarriers ? this.availableCarriers.find((carrier: any) => carrier.id === carrierId) : null;
        return selectedCarrier ? selectedCarrier.name : '';
    }

    reviewProductAllocationNew(index: number) {
        this.allocateProductNew(index, false);
    }

    allocateProductNew(index: number, detailedView = true) {
        const dialogRef = this.dialog.open(ProductAllocationNewComponent, {
            height: '95%',
            width: '95%',
            disableClose: true
        });
        dialogRef.componentInstance.productList = this.myProductsList;
        dialogRef.componentInstance.packageList = this.productAllocation[index] ? this.productAllocation[index] : [];
        dialogRef.componentInstance.isViewing = !detailedView;
        dialogRef.componentInstance.denominator = this.isOcean ? this.denominators.oceanDenominator : this.denominators.airDenominator;
        dialogRef.afterClosed().subscribe(result => {
            if (result.type === 'save') {
                this.productAllocation[index] = result.data.productAllocation;
                this.info = this.model;
            }
        });
    }
}
