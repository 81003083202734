import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IPaginatorProperties } from '../../../core/models/paginator.interface';
import { ApiService } from '../../../core/services/api.service';
import {
    IService,
    IServiceDetails,
    IShipmentPriceItem, IShipmentPriceItemData,
    ISPItemData, ISPItemSizeRestriction,
    IStatus, ISubCarrierPrice, ITypeActivityPrice,
    IVendor
} from '../models/shipment-price.interface';

@Injectable({
    providedIn: 'root',
})
export class ShipmentPriceService extends ApiService {
    public fileLoadedObserver = new Subject<any>();
    public get<T>(path: string, page: number = 1, limit: number = 100,
                  search: string = '',
                  filter: any = {},
                  orderField: string = '',
                  orderDirection: string = ''): Observable<T> {
        return this.request({
            method: 'GET',
            path,
            query: {
                page,
                limit,
                q: search,
                orderField,
                orderDirection,
                ...filter,
            },
        });
    }

    public getPriceItemDetailsById(id: number): Observable<IShipmentPriceItemData> {
        return this.request({
            method: 'GET',
            path: `shipment-price/${id}`,
        });
    }
    // public getLastMilePriceItemDetailsById(id: number): Observable<IShipmentPriceItemData> {
    //     return this.getPriceItemDetailsById(id) as Observable<IShipmentPriceItemData>;
    // }

    public getRestrictionsListByPriceItemId(
        priceItemId: number,
        page: number = 1,
        limit: number = 100,
        search: string = '',
        filter: any = {},
        orderField: string = '',
        orderDirection: string = '',
    ): Observable<IPaginatorProperties<ISPItemSizeRestriction>> {
        return this.get<IPaginatorProperties<ISPItemSizeRestriction>>(
            `shipment-price/${priceItemId}/restrictions`,
            page,
            limit,
            search,
            filter,
            orderField,
            orderDirection,
        );
    }

    public getPricingSubCarriersListByPriceItemId(
        priceItemId: number,
        page: number = 1,
        limit: number = 100,
        search: string = '',
        filter: any = {},
        orderField: string = '',
        orderDirection: string = ''
    ): Observable<IPaginatorProperties<ISubCarrierPrice>> {
        return this.get<IPaginatorProperties<ISubCarrierPrice>>(
            `shipment-price/${priceItemId}/sub-carrier-prices`,
            page,
            limit,
            search,
            filter,
            orderField,
            orderDirection,
        );
    }

    public getTypeActivityPricesListByPriceItemId(
        priceItemId: number,
        page: number = 1,
        limit: number = 100,
        search: string = '',
        filter: any = {},
        orderField: string = '',
        orderDirection: string = ''
    ): Observable<IPaginatorProperties<ITypeActivityPrice>> {
        return this.get<IPaginatorProperties<ITypeActivityPrice>>(
            `shipment-price/${priceItemId}/type-activity-prices`,
            page,
            limit,
            search,
            filter,
            orderField,
            orderDirection,
        );
    }

    public create<T>(path: string, reqObj: T): Observable<T> {
        return this.request({
            method: 'POST',
            path,
            body: {...reqObj},
        });
    }

    public delete(path: string, id: number): Observable<boolean> {
        return this.request({
            path: `${path}/${id}`,
            method: 'DELETE',
        });
    }

    public deleteList(path: string, ids: number[]): Observable<boolean> {
        return this.request({
            path: `${path}`,
            method: 'DELETE',
            body: {ids},
        });
    }
    public update<T>(path: string, id: number, reqObj: T): Observable<T> {
        return this.request({
            method: 'PUT',
            path: `${path}/${id}`,
            body: {...reqObj},
        });
    }

    public updateLastMileData(path: string, id: number, reqObj: ISPItemData): Observable<IShipmentPriceItemData> {
        return this.request({
            method: 'PUT',
            path: `${path}/${id}/last-mile-data`,
            body: {...reqObj},
        });
    }

    public updateFulfillmentData(path: string, id: number, reqObj: ISPItemData): Observable<IShipmentPriceItemData> {
        return this.request({
            method: 'PUT',
            path: `${path}/${id}/fulfillment-data`,
            body: {...reqObj},
        });
    }

    public updateVendorStatus(path: string, statusObj: IStatus): Observable<boolean> {
        return this.request({
            method: 'PUT',
            path: `vendor/${statusObj.id}/${path}`,
            body: {...statusObj},
        });
    }

    public updateStatus<T>(path: string, id: number, status: boolean): Observable<T>{
        return this.request({
            method: 'PUT',
            path: `${path}/${id}/status`,
            body: {status},
        });

    }

    public getServiceById(id: number): Observable<IService> {
        return this.request({
            method: 'GET',
            path: `service/${id}`,
        });
    }

    public getServiceDetailsById(id: number): Observable<IServiceDetails> {
        return this.request({
            method: 'GET',
            path: `service/details/${id}`,
        });
    }

    public updateSubServiceStatus(id: number, status: boolean, serviceId: number): Observable<IService> {
        return this.request({
            method: 'PUT',
            path: `sub-service/${id}/status`,
            body: {
                status,
                serviceId,
            },
        });
    }

    public getCarriersById(id: number): Observable<any> {
        return this.request({
            path: `carriers/${id}`,
            method: 'GET',
        });
    }

    public createFromXLSX(shipmentPrices: IShipmentPriceItem[]): Observable<IShipmentPriceItem[]> {
        return this.request({
            path: `shipment-price/upload`,
            method: 'POST',
            body: shipmentPrices,
        });
    }

    public downloadExcelExample(fileName = 'shipment_price_last_mile_example.xlsx'): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Accept', '*')
            .append('responseType', 'application/octet-stream');

        const fileUrl = `/assets/xlsx/${fileName}`;
        return this.http.get(fileUrl, {headers, responseType: 'blob'});
    }

    public deleteShipmentPrice(ids: number[]): Observable<boolean>  {
        return this.request({
            path: `shipment-price`,
            method: 'DELETE',
            body: ids,
        });
    }

    public assignVendor(serviceId: number, vendor: IVendor[]): Observable<boolean> {
        return this.request({
            path: `service/assign-vendor/${serviceId}`,
            method: 'PUT',
            body: vendor,
        });
    }

    public uploadFile(file: File, fieldName = 'content', serviceName: string = undefined): void {
        console.log('SPUploadFile', file);
        if (file) {
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            if (serviceName) {
                formData.append('serviceName', serviceName);
            }

            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', `${environment.api_url}shipment-price/upload/upload-xlsx-file/`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        this.fileLoadedObserver.next(xhr.responseText);

                    } else {
                        this.fileLoadedObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
        }
    }

    public downloadPriceExcel(documentId: number): Observable<any> {
        return this.request({
            path: `shipment-price-document/${documentId}`,
            method: 'GET',
        });
    }
}
