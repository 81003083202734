import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InvoiceManagerType} from '../../../../shared/enums/invoice-manager-type.enum';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {INVOICE_TYPE_LIST} from '../../invoice-management.service';

@Component({
  selector: 'eci-new-specific-template',
  templateUrl: './new-specific-template.component.html',
  styleUrls: ['./new-specific-template.component.scss']
})
export class NewSpecificTemplateComponent implements OnInit {

    type: InvoiceManagerType = null;
    templates: any[] = [];
    displayedColumns = [
        'name',
        'type',
    ];

    constructor(
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<NewSpecificTemplateComponent>,
    ) {
    }

    ngOnInit(): void {
        this.templates = INVOICE_TYPE_LIST.filter(i => {
            if (this.type === null && i.type !== InvoiceManagerType.Other) {
                return true;
            } else {
                return i.type === this.type;
            }
        });
    }

    getType(element) {
        switch (element.type) {
            case InvoiceManagerType.SaleOrder: return 'B2C SO / B2B SO / Reporting Tool SO';
            case InvoiceManagerType.Shipment: return 'Shipment';
            case InvoiceManagerType.ConsolShipment: return 'Consol Shipment';
            case InvoiceManagerType.Other: return 'Other';
        }
    }

    onSelectTemplate(row) {
        const dialogRef = this.dialog.open(NewTemplateNameDialog);
        dialogRef.afterClosed().subscribe(value => {
            this.dialogRef.close({
                templateId: SaleOrderInvoiceType[row.id],
                name: value
            });
        })
    }
}

@Component({
    selector: 'new-template-name-dialog',
    templateUrl: 'new-template-name-dialog.html',
    styleUrls: ['./new-specific-template.component.scss']
})
export class NewTemplateNameDialog implements OnInit{

    name = '';
    validate = false;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<NewTemplateNameDialog>,
        private formBuilder: FormBuilder
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            name: ['', [Validators.required]]
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onOkClick() {
        this.validate = true;
        if (this.form.valid) {
            this.dialogRef.close(this.form.get('name').value);
        }
    }
}
