import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'eci-product-allocation-confirm',
  templateUrl: './product-allocation-confirm.component.html',
  styleUrls: ['./product-allocation-confirm.component.scss']
})
export class ProductAllocationConfirmComponent implements OnInit {
  isDialog: boolean = true;

  items: any = [];

  constructor(
    public location: Location,
    public router: Router,
    public dialogRef: MatDialogRef<ProductAllocationConfirmComponent>,
    ) {
     }

  ngOnInit(): void {
  }

  cancel(event:boolean){
    this.dialogRef.close(event);
  }

  confirm(event:boolean): void {
    if(event) {
        this.dialogRef.close(event);
    }
  }
}
