import { Component, OnInit } from '@angular/core';
import {  FormControl } from '@angular/forms';
import { ICountry } from '../../../core/models/country.interface';
import { ConstantService } from '../../../core/services/constant.service';
import { PartnerService } from 'src/app/modules/partner-management/services/partner.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'eci-create-batch-popup',
  templateUrl: './create-batch-popup.component.html',
  styleUrls: ['./create-batch-popup.component.scss']
})
export class CreateBatchPopupComponent implements OnInit {

  originSearchCountry = new FormControl('');
  availableOriginCountries : ICountry[];
  fullCountriesList :ICountry[];
  destinationSearchCountry: FormControl = new FormControl('');
  availableDestinationCountries: ICountry[];
  originCountry: FormControl = new FormControl('');
  partnerList:[];
  searchCustomerName:FormControl = new FormControl('');
  partner:FormControl = new FormControl();
  destinationCountry: FormControl = new FormControl('');
  searchorpartner: FormControl  = new FormControl('');

  constructor(
    private constSvc:ConstantService,
    private partnerService: PartnerService,
    private dialogRef: MatDialogRef<CreateBatchPopupComponent>,
  ) { }

  ngOnInit(): void {
    this.getListOfCountries('', 1);
        this.originSearchCountry.valueChanges.subscribe(value => {
            if (value) {
                this.availableOriginCountries = this.fullCountriesList.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
            } else {
                this.availableOriginCountries = this.fullCountriesList;
            }
        });

        this.destinationSearchCountry.valueChanges.subscribe(value => {
            if (value) {
                this.availableDestinationCountries = this.fullCountriesList.filter(c => c.name.toLowerCase().includes(value.toLowerCase()));
            } else {
                this.availableDestinationCountries = this.fullCountriesList;
            }
        });

        this.constSvc.getAvailableCountries().subscribe(data => {
          this.fullCountriesList = data;
      });

      this.getpartnerList();
  }

  getListOfCountries(
    q:string,
    page:number,
    limit:number = 1000,
  ):void {
    this.constSvc.getAvailableCountriesForScroll(page,limit,false,q).subscribe(data =>{
      if(!data){ return };
      this.fullCountriesList = data.items;
      this.availableOriginCountries = data.items;
      this.availableDestinationCountries = data.items;
    });
  }

  //get partner list
  getpartnerList():void{
    this.partnerService.getPartnersList().subscribe(result=>{
      this.partnerList = result;
      console.log(this.partnerList,"this is list of partner");
    })
  }

  onClickClose():void{
    this.dialogRef.close();
  }
}
