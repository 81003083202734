import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {remove} from 'lodash';
import {EElementTypes} from '../../../invoice-management.service';
import {IElement} from './elements-interfaces';

@Component({
    template:''
})
export class BaseElement implements OnInit, OnDestroy, AfterViewInit {

    // @Input() model: any;
    @Input() dataSource = [];
    @Input() item: IElement;
    @Input() index: number;
    @Input() array;
    @Input() fontSize = 14;
    @Input() id: string;
    @Input() errors: string[];
    @Output() errorsChange: EventEmitter<string[]> = new EventEmitter();
    @Output() onSelectElement: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteElement: EventEmitter<any> = new EventEmitter();
    @Output() onDrop: EventEmitter<any> = new EventEmitter<any>();
    properties = [];
    actions = [];
    addressSource = [];

    @ViewChild('actions') templatesAction;
    @ViewChild('properties') templatesProp;
    @Output() onAddTemplates: EventEmitter<any> = new EventEmitter();

    public baseDialog: MatDialog | undefined;

    constructor() {}

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy(): void {

    }

    onSelect(event: MatCheckboxChange) {
        const unselectChild = (item) => {
            item.selected = false;
            if (item.items && item.items.length) {
                item.items.map(unselectChild);
            }
        }
        this.array.map(t => {t.selected = false});
        this.item.selected = event.checked;
        switch (this.item.type) {
            case EElementTypes.MainContainer:
            case EElementTypes.Address:
            case EElementTypes.ItemsBlock:
            case EElementTypes.Customer:
            case EElementTypes.ContainerItem:
            case EElementTypes.Table:
                this.item.drop_list_id = this.id + '_drop_list';
                if (event.checked) {
                    this.item.items.map(unselectChild);
                }
                break;
        }
        this.onSelectElement.emit(this.item);


        if (event.checked) {
            this.onAddTemplates.emit({
                actions: this.templatesAction,
                props: this.templatesProp
            })
        } else {
            this.onAddTemplates.emit({
                actions: null,
                props: null
            })
        }
    }

    onDelete() {
        this.array = this.array.splice(this.index, 1);
        this.onAddTemplates.emit({
            actions: null,
            props: null
        })
        this.onDeleteElement.emit();
    }

    drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer.id === event.container.id) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    clearGlobalError(){
        const index = this.errors.indexOf(this.id);
        if (index > -1) {
            this.errors.splice(index, 1);
            this.errorsChange.emit(this.errors);
        }
    }

    setGlobalError() {
        const index = this.errors.indexOf(this.id);
        if (index == -1) {
            this.errors.push(this.id);
            this.errorsChange.emit(this.errors);
        }
    }
}

