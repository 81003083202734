import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {PageParams} from '../../../../core/models/page-params.model';
import {NotificationService} from '../../../../core/services/notification.service';
import {UserService} from '../../../../core/services/user.service';
import {IPayment, IPaymentActionEmitter} from '../../payments-interfaces';
import {EEmailType, EPaymentActions, EPaymentMode, EPaymentStatus, EPaymentsType} from '../../payments-type.enum';
import {PaymentsService} from '../../payments.service';
import {StripeService} from '../../stripe.service';
import {ChangeStatusComponent, IPaymentStatusChangeDialog} from '../dialogs/change-status/change-status.component';
import {ChargeComponent} from '../dialogs/charge/charge.component';
import {ConfirmComponent} from '../dialogs/confirm/confirm.component';
import {OnHoldComponent} from '../dialogs/on-hold/on-hold.component';
import {RemarksComponent} from '../dialogs/remarks/remarks.component';
import {EPaginateActions, IPaginateAction} from '../paginator/paginator.component';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'eci-history-payments-table',
    templateUrl: './history-payments-table.component.html',
    styleUrls: ['./history-payments-table.component.scss']
})
export class HistoryPaymentsTableComponent implements OnInit {
    @ViewChild('fileInput') fileInput: any;
    sub$: Subscription = new Subscription();
    @Input() set dataSource(val: IPayment[]) {
        this._dataSource = val;
        const total = this._dataSource.reduce((sum, payment) => {
            return sum + payment.totalWithDiscount;
        }, 0)
        this.footerData = {
            total,
            average: this._dataSource.length ? (total / this._dataSource.length) : 0
        };
    };
    @Input() displayedColumns: string[];
    @Input() pageParams: PageParams;
    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() actions: EventEmitter<IPaymentActionEmitter> = new EventEmitter<IPaymentActionEmitter>();

    @ViewChild('table', {static: true, read: ElementRef}) table: ElementRef;

    // timezone = moment().format('Z');
    _dataSource: IPayment[];
    statuses = EPaymentStatus;
    modes = EPaymentMode;
    types = EPaymentsType;
    paymentHistoryActions: IPaginateAction[] = [
        {
            name: 'Export to Excel',
            action: EPaginateActions.exportToExcel,
            visible: true
        },
        {
            name: 'Create Payment',
            action: EPaginateActions.createPayment,
            visible: true
        }
    ];

    footerData: {total: number; average: number } = null;

    get isAdmin(): boolean {
        return this.userService.isAdmin;
    }

    constructor(
        private router: Router,
        private userService: UserService,
        private paymentsService: PaymentsService,
        private dialog: MatDialog,
        private stripeService: StripeService,
        private notify: NotificationService,
        public uploadFileSvc: UploadFileService,
    ) {
    }

    ngOnInit(): void {
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    pageChanged(e): void {
        this.pageChange.emit(e);
        this.table.nativeElement?.scrollIntoView();
    }

    navigateTo(id): void {
        this.router.navigate([`/payments/details/${id}`]);
    }

    getClass(row) {
        if (row.status === EPaymentStatus.Pending) {
            return 'pending';
        } else if (
            row.type === EPaymentsType.Monthly ||
            row.type === EPaymentsType.Registration
        ) {
            return 'monthly'
        } else {
            return ''
        }
    }

    onViewInvoice(id) {
        this.paymentsService.getInvoicePdf(id).subscribe(res => {
            window.open(res, '_blank');
        });
    }

    onEditInvoice(payment: IPayment) {
        this.actions.emit({
            action: EPaymentActions.EditInvoice,
            data: payment
        });
    }

    onToggleHold(e: MatSlideToggleChange, element: IPayment) {
        if (!e.checked) {
            this.openOnHoldDialog(element, (data) => {
                if (data && data.text) {
                    this.paymentsService.onHold(data).subscribe(res => {
                        element.status = EPaymentStatus.OnHold;
                        element.remarks.push({
                            id: res.id,
                            text: res.text,
                            createdAt: res.createdAt,
                            type: res.type,
                            user: res.user
                        })
                        e.source.checked = false;
                        this.notify.showSuccessMessage('Payment switched to On Hold successfully!');
                        this.actions.emit({
                            action: EPaymentActions.ChangeStatus,
                            data: res
                        });
                    }, error => {
                        e.source.checked = true;
                        this.notify.showError(error.message);
                    });
                } else {
                    e.source.checked = true;
                }
            });
        } else {
            this.paymentsService.setStatus({
                paymentId: element.id,
                status: EPaymentStatus.Pending,
                mode: EPaymentMode.Draft
            }).subscribe(res => {
                if (res) {
                    e.source.checked = true;
                    element.status = res.status;
                    this.notify.showSuccessMessage('Payment switched to Pending successfully!');
                    this.actions.emit({
                        action: EPaymentActions.ChangeStatus,
                        data: res
                    });
                }
            }, error => {
                this.notify.showError(error.message);
            });
        }
    }

    onViewRemarks(element: IPayment) {
        this.paymentsService.getRemarks(element.id).subscribe(res => {
            const remarksDialog = this.dialog.open(RemarksComponent);
            remarksDialog.componentInstance.remarks = res;
            remarksDialog.afterClosed().subscribe(data => {

            });
        });
    }

    onAddRemark(element: IPayment) {
        this.openOnHoldDialog(element, (data) => {
            this.paymentsService.addRemark(data).subscribe(res => {
                if (res) {
                    element.remarks = [res].concat(element.remarks);
                }
            });
        });
    }

    onCharge(element: IPayment) {
        const onChargeDialog = this.dialog.open<ChargeComponent>(ChargeComponent);
        onChargeDialog.componentInstance.payment = element;
        onChargeDialog.afterClosed().subscribe(res => {
            if (res) {
                this.actions.emit({
                    action: EPaymentActions.ChargedPayment,
                    data: res
                });
            }
        });
    }

    isShowChargeBtn(element: IPayment) {
        return this.isAdmin &&
            (
                element.status === EPaymentStatus.Pending ||
                element.status === EPaymentStatus.Failed ||
                element.status === EPaymentStatus.Collection
            ) &&
            element.type !== EPaymentsType.Registration &&
            element.mode === EPaymentMode.Approved
    }

    onSendEmail(element: IPayment) {
        this.paymentsService.sendEmail({paymentId: element.id, type: EEmailType.success}).subscribe(res => {
            this.notify.showSuccessMessage('Email was sent successfully')
        }, error => {
            this.notify.showError(error.message)
        })
    }

    isShowApprovedDraft(element): boolean {
        return this.isAdmin &&
            element.type !== EPaymentsType.Registration &&
            element.status !== EPaymentStatus.Paid &&
            element.status !== EPaymentStatus.OnHold;
    }

    onToggleDraft(e: MatSlideToggleChange, element: IPayment) {
        this.paymentsService.setStatus({
            paymentId: element.id,
            status: element.status,
            mode: e.checked ? EPaymentMode.Draft : EPaymentMode.Approved
        }).subscribe(res => {
            if (res) {
                e.source.checked = res.mode === EPaymentMode.Draft ? true : false;
                element.mode = res.mode;
            }
        }, error => {});
    }

    isViewInvoiceDisplayed(element) {
        return this.isAdmin || (!this.isAdmin && element.status === EPaymentStatus.Paid);
    }

    isEditInvoiceDisplayed(element) {
        return this.isAdmin && element.mode === EPaymentMode.Draft;
    }

    onChangeStatus(element) {
        const onChangeStatusDialog = this.dialog.open<ChangeStatusComponent, null, IPaymentStatusChangeDialog>(ChangeStatusComponent);
        onChangeStatusDialog.componentInstance.payment = element;
        onChangeStatusDialog.afterClosed().subscribe(data => {
            if (data) {
                if (data.status === EPaymentStatus.Refund) {
                    this.openConfirmDialog(data.status, () => {
                        this.openOnHoldDialog(element, (data) => {
                            this.paymentsService.setRefund({id: element.id, text: data.text}).subscribe(res => {
                                this.actions.emit({
                                    action: EPaymentActions.Refund,
                                    data: null
                                });
                            });
                        });
                    });
                } else {
                    this.changeStatus(element, data);
                }
            }
        });
    }

    isShowOnHold(element) {
        return this.isAdmin && element.status !== EPaymentStatus.Paid;
    }

    onSendDisableEmail(element) {
        this.paymentsService.sendEmail({paymentId: element.id, type: EEmailType.disable}).subscribe(res => {
            this.notify.showSuccessMessage('Email was sent successfully')
        }, error => {
            this.notify.showError(error.message)
        })
    }

    onTableAction(e: IPaginateAction) {
        switch (e.action) {
            case EPaginateActions.exportToExcel:
                this.actions.emit({
                    action: EPaymentActions.ExportToExcel,
                    data: null
                });
                break;
            case EPaginateActions.createPayment:
                this.actions.emit({
                    action: EPaymentActions.CreatePayment,
                    data: null
                });
                break;
        }
    }

    private changeStatus(element: IPayment, data: IPaymentStatusChangeDialog) {
        this.paymentsService.setStatus({
            paymentId: element.id,
            status: data.status
        }).subscribe(res => {
            this.actions.emit({
                action: EPaymentActions.ChangeStatus,
                data: res
            });
            this.notify.showSuccessMessage('Status updated successfully!')
        }, error => {
            this.notify.showError(error.message);
        })
    }

    private openConfirmDialog(status: EPaymentStatus, callback?: () => void) {
        const onConfirmDialog = this.dialog.open<ConfirmComponent>(ConfirmComponent);
        onConfirmDialog.componentInstance.status = status;
        onConfirmDialog.afterClosed().subscribe(data => {
            if (data && callback) {
                callback();
            }
        });
    }

    private openOnHoldDialog(element: IPayment, callback?: (data: any) => void) {
        const onHoldDialog = this.dialog.open(OnHoldComponent);
        onHoldDialog.componentInstance.payments = element;
        onHoldDialog.afterClosed().subscribe(data => {
            if (callback) {
                callback(data);
            }
        });
    }

    private uploadInvoice(e, element): void {
        if (!e.target.files) {
            return;
        }
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        if (!this.uploadFileSvc.textFileFormats[0].split(', ').find(el => el === files[0].type)) {
            this.fileInput.nativeElement.value = '';
            this.notify.showError('Should contain only PDF files');
            return;
        }

        this.uploadFileSvc.uploadSeveralFile(files, `pay/upload/invoice/${element.id}`);
        this.sub$.add(
            this.uploadFileSvc.allFilesLoaded().subscribe(data => {
                if (!data) {
                    return;
                }
                let file = data[0];
                if (!file) {
                    return;
                }
                file = JSON.parse(file.data);
                this.notify.showSuccessMessage('Uploaded successfully');
                this.fileInput.nativeElement.value = '';
                this.uploadFileSvc.filesLoadedObserver.next(null);
            })
        );
    }
}
