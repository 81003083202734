import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {LocalStorageService} from 'src/app/core/services/local-storage.service';
import {NotificationService} from 'src/app/core/services/notification.service';
import {AuthService} from 'src/app/modules/empact-shop/core/services/auth.service';
import {UserService} from '../../core/services/user.service';
import {AuthType} from './../../core/enums/auth-type.enum';

@Component({
    selector: 'ecommerce-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    remember: FormControl = new FormControl();

    @Output() typeChanged: EventEmitter<AuthType> = new EventEmitter<AuthType>(null);
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(null);

    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private notify: NotificationService,
        private localStorageSvc: LocalStorageService,
        private userSvc: UserService
    ) {
    }

    ngOnInit(): void {
        this.form = this.initializeForm();
    }

    initializeForm(): FormGroup {
        return this.fb.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]],
        });
    }

    createAccount(): void {
        this.typeChanged.emit(AuthType.Registration);
    }

    forgotPassword(): void {
        this.typeChanged.emit(AuthType.ForgotPassword);
    }

    public goToPrivacyPolicy(): void {
        window.location.href = 'https://empact.online/privacy-policy/';
    }

    public goToTermsAndConditions(): void {
        window.location.href = 'https://empact.online/terms-and-conditions/';
    }

    login(): void {
        const {email, password} = this.form.value;

        this.authService.login({username: email.toLowerCase(), password: password}).subscribe((res) => {
            switch (res.message) {
                case 'MESSAGES.USER.INVALID_LOGIN':
                    this.notify.showError('Invalid Email address or Password');
                    break;
                case 'MESSAGES.USER.USER_DISABLED':
                    this.notify.showError('This user is disabled');
                    break;
                case 'MESSAGES.USER.LOGIN_SUCCESS':
                    if (!this.remember.value) {
                        sessionStorage.setItem('empactToken', res.token);
                    } else {
                        this.localStorageSvc.set('empactToken', res.token);
                    }
                    this.notify.showSuccessMessage('You are successfully logged in');
                    this.userSvc.user$.next(res);
                    this.localStorageSvc.set('empactUser', JSON.stringify(this.userSvc.user));
                    this.userSvc.isEmpactShopLoggedIn.next('yes');
                    this.close.emit(res);
                    break;
                default:
                    break;
            }
        });
    }
}
