import { Pipe, PipeTransform } from '@angular/core';
import { BatchConsoleType, BatchConsoleTypeView } from './../enums/batch-console-type';

@Pipe({ name: 'batchConsolType' })
export class BatchConsolType implements PipeTransform {

    public transform(value): any {
        switch(value) {
            case BatchConsoleType.FULL_BATCH_CONSOLE.toString(): return BatchConsoleTypeView.FULL_BATCH_CONSOLE;
            case BatchConsoleType.THRESHOLD_BATCH_CONSOLE.toString(): return BatchConsoleTypeView.THRESHOLD_BATCH_CONSOLE;
            case BatchConsoleType.FIRST_FULL_BATCH_CONSOLE.toString(): return BatchConsoleTypeView.FIRST_FULL_BATCH_CONSOLE;
            case BatchConsoleType.SECOND_FULL_BATCH_CONSOLE.toString(): return BatchConsoleTypeView.SECOND_FULL_BATCH_CONSOLE;
            default: return 'Unknown';
        }
    }
}
