import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/modules/empact-shop/core/services/user.service';
import { formConstant } from '../../../../../config';
import { EmpactShopService } from '../../core/services/empact-shop.service';
import { AuthType } from './../../core/enums/auth-type.enum';

@Component({
  selector: 'ecommerce-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit {

  @Input() token: string;
  @Output() typeChanged: EventEmitter<AuthType> = new EventEmitter<AuthType>(null);
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private notify: NotificationService,
    private router: Router,
    private shopService: EmpactShopService
  ) { }

  ngOnInit(): void {
    this.form = this.initializeForm();
  }

  goToLogin(): void {
    this.typeChanged.emit(AuthType.Login);
  }

    private initializeForm(): FormGroup {
        return new FormGroup({
            password: new FormControl('', [Validators.required,
                Validators.minLength(formConstant.passwordMinLength),
                Validators.pattern(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)]),
            confirmPassword: new FormControl('', [
                Validators.pattern(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)]),
        }, { validators: this.checkPasswords });
    }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  restorePassword(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.userSvc.updatePassword({
      ...this.form.value,
      token: this.token,
    }).subscribe(result => {
      this.notify.showSuccessMessage('Password updated successfully');
      this.router.navigateByUrl(`/ecommerce/${this.shopService.shopId}/products`);
      this.close.emit(true);
    })
  }

    public fieldValidation(name, errorName: string = 'required'): boolean {
        return this.form?.controls?.[name]?.touched && this.form?.controls?.[name]?.hasError(errorName);
    }

}
