import { Pipe, PipeTransform } from '@angular/core';
import { SPItemSizeRestrictionDimensions, SPISizeRestrictionWeightUnits, SPISizeRestrictionVolumeUnits } from '../enums/shipment-price.enum';

@Pipe({ name: 'SPISRestrictionDimensionUnits' })
export class SPISRestrictionDimensionUnitsPipe implements PipeTransform {

    public transform(value: number): any {
        switch(value) {
            case SPItemSizeRestrictionDimensions.cm: return 'cm';
            case SPItemSizeRestrictionDimensions.m: return 'm';
            case SPItemSizeRestrictionDimensions.dm: return 'dm';
            case SPItemSizeRestrictionDimensions.km: return 'km';
            case SPItemSizeRestrictionDimensions.mm: return 'mm';
            default: return 'Unknown';
        }
    }
}

@Pipe({ name: 'SPISRestrictionWeightUnits' })
export class SPISRestrictionWeightUnitsPipe implements PipeTransform {

    public transform(value: number): any {
        switch(value) {
            case SPISizeRestrictionWeightUnits.kg: return 'kg';
            case SPISizeRestrictionWeightUnits.g: return 'g';
            case SPISizeRestrictionWeightUnits.t: return 't';
            default: return 'Unknown';
        }
    }
}

@Pipe({ name: 'SPISRestrictionVolumeUnits' })
export class SPISRestrictionVolumeUnitsPipe implements PipeTransform {

    public transform(value: number): any {
        switch(value) {
            case SPISizeRestrictionVolumeUnits.liters: return 'liters';
            case SPISizeRestrictionVolumeUnits.mm3: return 'mm3';
            case SPISizeRestrictionVolumeUnits.cm3: return 'cm3';
            case SPISizeRestrictionVolumeUnits.m3: return 'm3';
            default: return 'Unknown';
        }
    }
}
