import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';
import { HsCodesService } from 'src/app/modules/hs-codes/services/hs-code-services';
import { Sort } from '@angular/material/sort';
import { PageParams } from 'src/app/core/models/page-params.model';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
@Component({
  selector: 'eci-apply-hs-code',
  templateUrl: './apply-hs-code.component.html',
  styleUrls: ['./apply-hs-code.component.scss'],
  providers: [DatePipe]
})
export class ApplyHsCodeComponent {
  header: string = "Hs Code";
  createMode: boolean = true;
  info: any;
  form: FormGroup;
  isDialog: boolean = true;
  expandedPanelIndex = -1;
  expandedChaptersPanelIndex = -1;
  chapters = [];
  hscodes = [];
  displayedColumns: string[] = [
    'hsCodeId',
    'taricCode',
    'description',
    'dutyExpression',
    'supplementaryUnit'
  ];
  dataSource: any;
  selectedCountry: number = 1;

  pageParams: PageParams = new PageParams();
  availableTypes: IAvailableTypes;

  ordersList: any[];

  showLoader: boolean = false;
  applyHSCodeSuccess: any;
  //#region Search params
  fromData: FormControl = new FormControl('');
  toData: FormControl = new FormControl('');
  searchStr: FormControl = new FormControl('');
  orderType: FormControl = new FormControl(null);
  orderStatus: FormControl = new FormControl(null);
  customerType: FormControl = new FormControl(null);
  currentSort: Sort;
  searchorderType: FormControl = new FormControl('');
  searchorderStatus: FormControl = new FormControl('');
  searchcustomerType: FormControl = new FormControl('');
  hscodeId: any;
  applyHSCode: boolean = false;
  dataItem:any
  private regex: RegExp = new RegExp(/^\d+$/g);

  prevSectionIndex: any;
  prevChapterIndex: any;
  // private checkItem: any = [];
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ApplyHsCodeComponent>,
    private notify: NotificationService,
    private hsCodesService: HsCodesService,
    @Inject(MAT_DIALOG_DATA) public data?: any
  ) {
  }

  ngOnInit(): void {
    this.getInfo();
    this.getList(1, 100, '', '');
  }
  getInfo(): void {
    this.form = this.initializeForm(this.info);
  }

  initializeForm(info: any): FormGroup {
    return this.formBuilder.group({
      hsCodeId: new FormControl({ disabled: false, value: info?.hsCodeId }, [Validators.required]),
      searchStr: new FormControl('')
    });

  }
  pageChanged(e: PageParams): void {
    this.pageParams.pageIndex = e.pageIndex;
    this.pageParams.pageSize = e.pageSize;
    this.getList(e.pageIndex + 1, e.pageSize, this.searchStr.value, '');
  }

  getList(
    page: number = 1,
    limit: number = 100,
    q: string = this.searchStr.value,
    hscodeData: any
  ): void {
    this.showLoader = true;
    let description = '';
    if (q && !q.match(this.regex)) {
        description = q;
        q = '';
    }
    let hscode = hscodeData?.taricCode;
    this.hsCodesService.getList(page, limit, q, description, hscode).subscribe(data => {
      this.showLoader = false;
      if (!data) return;
      if (hscodeData?.hsCodeType === "section") {
        this.chapters = data.items;
      }
      else if (hscodeData?.hsCodeType === "chapter") {
        this.hscodes = data.items;
      }
      else {
        const dataResult = [];
        data.items.map(ele=>{
          if(ele.taricCode == this.dataItem) {
            ele.isSelected = true;
          } else{
            ele.isSelected = false;
          }
          dataResult.push(ele);
        })

        this.dataSource = dataResult;
        this.ordersList = data.items;
      }
      this.pageParams.pageSize = data.meta.itemsPerPage;
      this.pageParams.pageIndex = data.meta.currentPage - 1;
      this.pageParams.length = data.meta.totalItems;
    })
  }

  isAllSelected(item) {
    this.dataItem = item.taricCode;
    this.hscodes.map(val => {
      if (val.taricCode == item.taricCode) {
        val.isSelected = !val.isSelected;
        this.hscodeId = null;
        this.applyHSCode = false;
        this.applyHSCodeSuccess = null;
      }
      else {
        val.isSelected = false;
      }
      if (val.isSelected == true) {
        this.hscodeId = item.taricCode;
        this.applyHSCode = true;
        this.applyHSCodeSuccess = item.taricCode;
      }
    });
  }

  search(): void {
    this.getList(1, 100, this.searchStr.value, '');
  }

  save(): void {
    if (this.hscodeId == null) {
      this.notify.showError('HS Code not selected');
      this.form.markAllAsTouched();
      return;
    } else {
      const object = {
        hscodeId: this.hscodeId,
        applyHSCodeSuccess: this.applyHSCodeSuccess ? this.applyHSCodeSuccess : null
      }
      this.dialogRef.close(object);
    }
  }

  exit(): void {
    if (!this.isDialog) {
      history.back();
    } else this.dialogRef.close();
  }

  expandPanel(section: any, index: number, event) {
    event.stopPropagation();
    this.chapters =  [];
    this.hscodes = [];
    if (this.expandedPanelIndex !== index) {
      this.expandedChaptersPanelIndex = -1;
    }
    this.expandedPanelIndex = index === this.expandedPanelIndex ? -1 : index;
    this.getList(1, 100, this.searchStr.value || '', section);
  }

  expandChaptersPanel(chapter: any, index: number, event) {
    event.stopPropagation();
    this.hscodes = [];
    this.expandedChaptersPanelIndex = index === this.expandedChaptersPanelIndex ? -1 : index;
    this.getList(1, 100, this.searchStr.value || '', chapter);
  }

  identify(index, item){
    return item.taricCode;
 }
}
