import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { IProductCart, IProductCheckout } from '../models/product.interface';
import { EmpactShopService } from './empact-shop.service';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { AddProduct2Cart } from '../models/add-product-cart.model';
import { UpdateProductCart } from '../models/update-product-cart.model';

@Injectable({
    providedIn: 'root',
})
export class CartService extends ApiService {

    $cart: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    private productsInCart = [];

    constructor(
        public http: HttpClient,
        private userSvc: UserService,
        private storageService: LocalStorageService,
    ) {
        super(http, storageService);
    }

    private cartQuery(shopId): object {
        const userId = this.userSvc.userId;
        const cartToken = this.storageService.get(`cartToken-${shopId}`);
        return !userId ? {cartToken} : {};
    }

    public addProduct2Cart(body: AddProduct2Cart, shopId): Observable<any> {
        const userId = this.userSvc.userId;
        const cartToken = this.storageService.get(`cartToken-${shopId}`);
        return this.requestEcommerce({
            method: 'POST',
            path: `cart/add-to-cart`,
            body: {
                userId: !!userId ? userId : null,
                token: !!cartToken ? cartToken : null,
                ...body
            }
        }).pipe(
            map((value, index) => {
                this.productsInCart.push(value);
                this.$cart.next(this.productsInCart);
                return value;
            })
        );
    }

    public updateProductInCart(body: UpdateProductCart[]): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `cart/update-cart-items`,
            body: { products: body },
        });
    }

    // IF USER LOGGED IN - WE ARE USING THE USER ID TO GET DETAILS FOR USER'S CART
    // IF USER IS NOT LOGGED IN - WE ARE USING CART TOKEN TO IDENTIFY UNAUTHORIZED USER
    public getProducts4Cart(shopId: string): Observable<IProductCart[]> {
        return this.requestEcommerce({
            method: 'GET',
            path: `cart`,
            query: this.cartQuery(shopId),
        }).pipe(
            map((value) => {
                this.productsInCart = value;
                this.$cart.next(this.productsInCart);
                return value;
            })
        );
    }

    public getCheckoutSummary(shopId: string): Observable<IProductCheckout> {
        return this.requestEcommerce({
            method: 'GET',
            path: `cart/checkout`,
            query: this.cartQuery(shopId)
        });
    }

    public deleteProducts4Cart(id: number): Observable<IProductCart> {
        return this.requestEcommerce({
            method: 'DELETE',
            path: `cart`,
            body: { cartId: id },
        });
    }

    public clearCart(shopId): Observable<IProductCart> {
        return this.requestEcommerce({
            method: 'DELETE',
            path: `cart/clear-cart`,
            body: { cartToken: this.storageService.get(`cartToken-${shopId}`) },
        });
    }

    public checkIndividualCartToken(shopId: string): void {
        const cartToken = this.storageService.get(`cartToken-${shopId}`);
        if (!cartToken) {
            this.storageService.set(`cartToken-${shopId}`, this.cartToken);
        }
    }

    private get cartToken(): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890=-&^%$#!~';
        const lengthOfCode = Math.random() * (40 - 20) + 20;

        for (let i = 0; i < lengthOfCode; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

}
