import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {LocalStorageService} from '../../../../core/services/local-storage.service';
import {UserService} from '../../../../core/services/user.service';
import {IPayment, IPaymentsUpdate} from '../../payments-interfaces';
import {EPaymentStatus, EPaymentsType} from '../../payments-type.enum';
import {PaymentsService} from '../../payments.service';
import {StripeService} from '../../stripe.service';

@Component({
    selector: 'eci-pay',
    templateUrl: './pay.component.html',
    styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit, AfterViewInit {

    @ViewChild('cardInfo') cardInfo: ElementRef;

    form: FormGroup;
    payment: IPayment;
    cardError: any;
    payDisable = true;
    isLoad = false;


    constructor(
        private userService: UserService,
        private paymentsService: PaymentsService,
        private formBuilder: FormBuilder,
        public stripeService: StripeService,
        private router: Router,
        private localStorageSvc: LocalStorageService,
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        if(this.userService.user) {
            this.paymentsService.getUserPaymentToPay().subscribe(res => {
                if (res) {
                    this.localStorageSvc.remove('redirectTo');
                    this.payment = res;
                    this.form.get('amount').setValue(`€ ${res.total}`);
                    this.form.get('period').setValue(moment(res.period).format('MMM-yy'));
                } else {
                    this.localStorageSvc.set('redirectTo', 'pay');
                    this.router.navigate(['/auth/login']);
                }
            });
        } else {
            this.localStorageSvc.set('redirectTo', 'pay');
            this.router.navigate(['/auth/login']);
        }
    }

    ngAfterViewInit(): void {
        this.initStripe();
    }

    initForm() {
        this.form = this.formBuilder.group({
            amount: '€ 0',
            period: '',
        })
    }

    onPay() {
        this.isLoad = true;
        this.paymentsService.createPayment({
            type: this.payment.type,
            timezone: moment().format('Z'),
            companyId: this.payment.company.id,
            paymentId: this.payment.id,
            comment: '',
            withNewCard: true
        }).subscribe(cpRes => {
            this.stripeService.clientSecret = cpRes.client_secret;
            this.stripeService.payWithCard({
                name: `Payment for ${this.payment.id} payment`
            }).then(pwcRes => {
                const data: IPaymentsUpdate = {
                    paymentId: this.payment.id,
                    status: null
                }
                if (pwcRes.error) {
                    data.status = EPaymentStatus.Failed;
                    data.message = pwcRes.error.message;
                } else {
                    data.status = EPaymentStatus.Paid;
                    data.method = pwcRes.paymentIntent?.payment_method;
                }
                this.paymentsService.updatePayment(data).subscribe(upRes => {
                    this.isLoad = false;
                    this.router.navigate(['/auth/login']);
                }, error => {
                    this.isLoad = false;
                });
            });
        });
    }

    getPaymentType() {
        switch (this.payment?.type) {
            case EPaymentsType.Registration: return 'Registration';
            case EPaymentsType.Monthly: return `Monthly subscriptions for ${this.userService.user.packages.packageName}`;
            case EPaymentsType.VAT_COM: return `Commission`;
            default: return '';
        }
    }

    private initStripe() {
        this.stripeService.createCard(
            this.cardInfo.nativeElement,
            (info) => {
                if (info.error) {
                    this.cardError = info.error.message;
                } else {
                    this.cardError = null;
                }

                if (!this.cardError && info.complete && !info.empty) {
                    this.payDisable = false;
                }
            }
        );
    }
}
