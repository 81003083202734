import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { HsCodesService } from 'src/app/modules/hs-codes/services/hs-code-services';
import { HSCodeStatus } from 'src/app/shared/enums/hs-code-status.enum';

@Component({
  selector: 'eci-hscode-confirm',
  templateUrl: './hscode-confirm.component.html',
  styleUrls: ['./hscode-confirm.component.scss']
})
export class HSCodeConfirmComponent implements OnInit {
  isDialog: boolean = true;
  products: any;
  active: number = 0;
  inactive: number = 0;
  constructor(
    public location: Location,
    public router: Router,
    public userSvc: UserService,
    private hsCodesService: HsCodesService,
    public dialogRef: MatDialogRef<HSCodeConfirmComponent>,
    ) {
     }

  ngOnInit(): void {

  }

  cancel(event:boolean){
    this.dialogRef.close(event);
  }

  confirm(event:boolean): void {
    if(event) {
      this.hsCodesService.updateHsCodes(this.userSvc.userId, HSCodeStatus.Active.toString()).subscribe((result: any) => {
        this.dialogRef.close(event);
      });
    }
  }
}
