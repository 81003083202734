import { Pipe, PipeTransform } from '@angular/core';
import { ConsolShipmentInvoiceType } from '../enums/consol-shipment-invoice-type.enum';

@Pipe({ name: 'consolShipmentInvoiceType' })
export class ConsolShipmentInvoiceTypePipe implements PipeTransform {
    transform(value: number, isSpecific?: boolean, specificName?: string) {
        if (isSpecific) {
            return specificName;
        }
        switch (Number(value)) {
            case ConsolShipmentInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE: return 'VAT Invoice';
            case ConsolShipmentInvoiceType.SERVICE_INVOICE: return 'Service Invoice';
            case ConsolShipmentInvoiceType.SHIPPING_INVOICE: return 'Commercial Invoice';
            case ConsolShipmentInvoiceType.PACKING_LIST: return 'Packing List';
            case ConsolShipmentInvoiceType.SPECIFIC: return 'Specific';
            default: return 'Undefined';
        }
    }
}
