import {Component, OnInit} from '@angular/core';
import {InvoiceTemplateBase} from '../invoce-template-base';

@Component({
    selector: 'eci-consolidate-shipment-vat-invoice-form',
    templateUrl: './consolidate-shipment-vat-invoice-form.component.html',
    styleUrls: ['./consolidate-shipment-vat-invoice-form.component.scss']
})
export class ConsolidateShipmentVatInvoiceFormComponent extends InvoiceTemplateBase implements OnInit {

    ngOnInit(): void {
        this.model = {
            ...this.template,
            ...this.invoice,
            customerCompany: {
                ...this.template.customerCompany,
                ...this.invoice.customerCompany
            }
        };

        if (!this.model.labels.billToVat) {
            this.model.labels.billToVat = 'VAT:';
        }
        if (!this.model.labels.billToCocNr) {
            this.model.labels.billToCocNr = 'COC NR:';
        }
        if (!this.model.labels.billToTel) {
            this.model.labels.billToTel = 'TEL:';
        }
    }

}
