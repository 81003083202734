import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Sort} from '@angular/material/sort';
import * as moment from 'moment';
import {Moment} from 'moment';
import {forkJoin} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {PageParams} from '../../../../core/models/page-params.model';
import {NotificationService} from '../../../../core/services/notification.service';
import {UserService} from '../../../../core/services/user.service';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {RegistrationService} from '../../../customer-registration/services/registration.service';
import {InvoiceFormComponent} from '../../../invoice-manager/components/invoice-forms/invoice-form.component';
import {InvoiceManagementService} from '../../../invoice-manager/invoice-management.service';
import {
    DISPLAYED_COLUMNS,
    DISPLAYED_COLUMNS_CUSTOMER, PAYMENT_CUSTOMERS_TYPE,
    PAYMENT_STATUS_LIST,
    PAYMENT_STATUS_LIST_CUSTOMER,
    PAYMENT_TYPES_LIST
} from '../../payments-constants';
import {IPaymentActionEmitter, IPaymentPagination, IPaymentSummary, IPaymentsUpdate} from '../../payments-interfaces';
import {EPaymentActions, EPaymentStatus} from '../../payments-type.enum';
import {PaymentsService} from '../../payments.service';
import { CreatePaymentPopupComponent } from '../create-payment-popup/create-payment-popup.component';

@Component({
    selector: 'eci-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {

    formFilters: FormGroup;
    showLoader: boolean = false;
    searchCustomerName: FormControl = new FormControl('');
    pageParams: PageParams = new PageParams();

    companyList = [];
    paymentTypesList = PAYMENT_TYPES_LIST;
    summaryData: IPaymentSummary;
    paymentsData: Partial<IPaymentPagination> = {
        items: []
    };

    constructor(
        private formBuilder: FormBuilder,
        public regSvc: RegistrationService,
        private paymentsService: PaymentsService,
        private notificationService: NotificationService,
        private invoiceManagementService: InvoiceManagementService,
        private dialog: MatDialog,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.initFormFilters();
        this.getListCompanies();
        this.getPaymentsData(this.prepareSearchParams());
        this.isAdmin && this.getSummaryData();
        this.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.clearPage();
            this.getListCompanies();
        });
    }

    get isAdmin(): boolean {
        return this.userService.isAdmin;
    }

    get statusList(): any[] {
        if (this.isAdmin) {
            return PAYMENT_STATUS_LIST;
        } else {
            return PAYMENT_STATUS_LIST_CUSTOMER;
        }
    }

    get customerTypes(): any[] {
        return PAYMENT_CUSTOMERS_TYPE;
    }

    get displayedColumns(): any[] {
        if (this.isAdmin) {
            return DISPLAYED_COLUMNS;
        } else {
            return DISPLAYED_COLUMNS_CUSTOMER;
        }
    }

    initFormFilters() {
        this.formFilters = this.formBuilder.group({
            search: '',
            period: [],
            invoicePeriodFrom: [''],
            invoicePeriodTo: [''],
            lastActionFrom: [''],
            lastActionTo: [''],
            customerName: [''],
            paymentType: [''],
            paymentStatus: '',
            customerType: [''],
        })
    }

    selectMonth(date: Moment) {
        this.formFilters.get('period').setValue(date);
    }

    getListCompanies(q: string = this.searchCustomerName.value): void {
        q = this.formFilters.get('customerName').value ? this.searchCustomerName.value : q;
        this.regSvc.getCompanyListForSales(q).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }

    clearSelection() {
        this.formFilters.get('customerName').reset();
        this.getListCompanies(this.searchCustomerName.value);
    }

    clearPage() {
        this.pageParams.pageIndex = 0;
    }

    clearSearchStatus(searchInput:  AbstractControl) {
        searchInput.reset();
    }

    prepareSearchParams(sort?: Sort) {
        let params: any = {
            page: this.pageParams.pageIndex + 1,
            limit: this.pageParams.pageSize
        };

        if (sort) {
            params.sort = sort.active;
            params.direction = sort.direction;
        }

        if (this.formFilters.get('search').value) {
            params.search = this.formFilters.get('search').value;
        }

        if (this.formFilters.get('period').value) {
            params.period = moment(this.formFilters.get('period').value).format('MMM-yy');
        }

        if (this.formFilters.get('invoicePeriodFrom').value && this.formFilters.get('invoicePeriodTo').value) {
            params.invoicePeriodFrom = moment(this.formFilters.get('invoicePeriodFrom').value).format('YYYY-MM-DD');
            params.invoicePeriodTo = moment(this.formFilters.get('invoicePeriodTo').value).format('YYYY-MM-DD');
        }

        if (this.formFilters.get('lastActionFrom').value && this.formFilters.get('lastActionTo').value) {
            params.lastActionFrom = moment(this.formFilters.get('lastActionFrom').value).format('YYYY-MM-DD');
            params.lastActionTo = moment(this.formFilters.get('lastActionTo').value).format('YYYY-MM-DD');
        }

        if (this.formFilters.get('customerName').value) {
            params.customerName = this.formFilters.get('customerName').value;
        }

        if (this.formFilters.get('paymentType').value) {
            params.paymentType = this.formFilters.get('paymentType').value;
        }

        if (this.formFilters.get('paymentStatus').value) {
            params.paymentStatus = this.formFilters.get('paymentStatus').value;
        }

        if (this.formFilters.get('customerType').value) {
            params.customerTypes = this.formFilters.get('customerType').value;
        }
        return params;
    }

    onSelectSummaryFilter(e: string | EPaymentStatus) {
        this.clearFilters();
        const val = e === 'total' ? '' : e;
        this.formFilters.get('paymentStatus').setValue(val);
        this.formFilters.get('paymentStatus').updateValueAndValidity();
        this.getPaymentsData(this.prepareSearchParams());
        this.getSummaryData();
    }

    getPaymentsData(params?) {
        this.showLoader = true;
        this.paymentsService.getPaymentsData(params).subscribe(res => {
            this.showLoader = false;
            this.paymentsData = res;
            this.pageParams.pageSize = res.meta.itemsPerPage;
            this.pageParams.pageIndex = res.meta.currentPage - 1;
            this.pageParams.length = res.meta.totalItems;
        }, error => {
            this.showLoader = false;
            this.notificationService.showError(error.message);
        });
    }

    pageChanged(e: PageParams): void {
        this.pageParams.pageIndex = e.pageIndex;
        this.pageParams.pageSize = e.pageSize;
        this.getPaymentsData(this.prepareSearchParams());
    }

    sortPayments(e: Sort) {
        console.log(e);
        this.getPaymentsData(this.prepareSearchParams(e));
    }

    private getSummaryData() {
        this.paymentsService.getSummaryData().subscribe(res => {
            this.summaryData = res;
            console.log(this.summaryData);
        });
    }

    onActions(e: IPaymentActionEmitter) {
        this.showLoader = true;
        switch (e.action) {
            case EPaymentActions.EditInvoice:
                this.onAction_EditInvoice(e);
                break;
            case EPaymentActions.ChargedPayment:
                this.onAction_ChargedPayment();
                break;
            case EPaymentActions.ChangeStatus:
            case EPaymentActions.Refund:
                this.onAction_ChangeStatus();
                break;
            case EPaymentActions.ExportToExcel:
                this.onAction_ExportToExcel();
                break;
            case EPaymentActions.CreatePayment:
                this.onAction_CreatePayment();
                break;
        }
    }

    private onAction_EditInvoice(e: IPaymentActionEmitter) {
        this.showLoader = false;
        forkJoin([
            this.paymentsService.getInvoice(e.data.invoice.id),
            this.invoiceManagementService.getTemplateData(SaleOrderInvoiceType[e.data.invoice.type])
        ]).subscribe(res => {
            let dialogRef = this.dialog.open(InvoiceFormComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });
            dialogRef.componentInstance.type = e.data.invoice.type;
            dialogRef.componentInstance.tabType = 0;
            dialogRef.componentInstance.invoice = res[0];
            dialogRef.componentInstance.template = res[1].data?.template;
            dialogRef.componentInstance.isInvoice = true;
            dialogRef.componentInstance.isPaymentEdit = true;
            dialogRef.afterClosed().subscribe(value => {
                if (!value) return;
                this.showLoader = true;
                const data: IPaymentsUpdate = {
                    status: e.data.status,
                    paymentId: e.data.id,
                    discount: parseFloat(value.discount),
                    totalFee: parseFloat(value.totalFee),
                    fee: value.fee ? parseFloat(value.fee) : null,
                    commissionFee: value.commissionFee ? parseFloat(value.commissionFee) : null,
                };
                this.paymentsService.updatePayment(data).subscribe(() => {
                    this.getPaymentsData(this.prepareSearchParams());
                    this.showLoader = false;
                    this.notificationService.showSuccessMessage('Updated successfully');
                }, error => {
                    this.showLoader = false;
                    this.notificationService.showError(error.message);
                });
            });
        }, error => {
            this.notificationService.showError('Invoice is not available due to some error');
            this.showLoader = false;
        });
    }

    private onAction_ChargedPayment() {
        this.getSummaryData();
        this.getPaymentsData(this.prepareSearchParams());
    }

    private onAction_ChangeStatus() {
        this.getSummaryData();
        this.getPaymentsData(this.prepareSearchParams());
    }

    private onAction_ExportToExcel() {
        this.showLoader = true;
        this.paymentsService.exportToExcel(this.prepareSearchParams()).subscribe(res => {
            this.showLoader = false;
        }, error => {
            this.showLoader = false;
        })
    }

    clearFilters() {
        this.formFilters.reset();
        console.log(this.formFilters);
    }

    private onAction_CreatePayment() {
        const dialogRef = this.dialog.open(CreatePaymentPopupComponent, {maxHeight: '80vh',width: '500px'});
        dialogRef.componentInstance.companyList = this.companyList;
        dialogRef.afterClosed().subscribe((data) => {
            if (!data) {
                this.showLoader = false;
                return;
            }
            this.paymentsService.createPaymentManually(data).subscribe(res => {
                this.showLoader = false;
                this.notificationService.showSuccessMessage('Payment entry created successfully');
            }, error => {
                console.log('********error')
                console.log(error)
                this.showLoader = false;
                this.notificationService.showError('Payment entry failed, please try again.');
            })
        });
    }
}
