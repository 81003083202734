import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

const SERVER_URL = environment.api_url;
const ECOMMERCE_SERVER_URL = environment.api_ecommerce_url;
// const IMAGE_URL = environment.img_url;
const IS_LIVE_MODE = environment.is_live_mode;

export interface IApiParams {
    path: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    query?: any;
    body?: any;
    noAuth?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private cancelRequest$: Subject<void> = new Subject<void>();
    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService
    ) {
    }
    public request(params: IApiParams): Observable<any> {

        // Cancel the previous request, if any
        this.cancelRequest$.next();

        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');

        if (this.storageBrowser.get('mode') === null) {
            this.storageBrowser.set('mode', IS_LIVE_MODE);
        }

        let is_liveMOde = (this.storageBrowser.get('mode') === null) ? IS_LIVE_MODE : this.storageBrowser.get('mode');

        this.storageBrowser.get('user');

        // if (this.storageBrowser.get('user') === null ||
        // (this.storageBrowser.get('user').role !== Roles.Admin) ) {
        //     is_liveMOde = IS_LIVE_MODE;
        //     this.storageBrowser.set('mode', IS_LIVE_MODE);
        // }

        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'islive': `${is_liveMOde}`
        });

        return this.http.request(method, `${SERVER_URL}${path}`, {body, params: query, headers}).pipe(
          catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse) {
        const val = localStorage.getItem('accessDenied') || '';
        if(error?.error?.name === 'AuthorizationRequiredError' || (error?.error?.name === 'AccessDeniedError' && val === '')){
            localStorage.setItem('accessDenied','yes');
            window.location.href='/auth/login';
        }
        return throwError(error);
      }

    public requestPdf(params: IApiParams, fileType?: string): Observable<any> {
        let is_liveMOde = (this.storageBrowser.get('mode') === null) ? IS_LIVE_MODE : this.storageBrowser.get('mode');
        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
            'islive': `${is_liveMOde}`
        });

        return this.http.request(method, `${SERVER_URL}${path}`, {body, params: query, headers, responseType: 'blob'}).pipe(map((res) => {
            return new Blob([res], {type: 'application/pdf'});
        }));
    }

    public requestDoc(params: IApiParams, fileType?: string): Observable<any> {
        let is_liveMOde = (this.storageBrowser.get('mode') === null) ? IS_LIVE_MODE : this.storageBrowser.get('mode');
        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
            'islive': `${is_liveMOde}`
        });

        return this.http.request(method, `${SERVER_URL}${path}`, {body, params: query, headers, responseType: 'blob'}).pipe(map((res) => {
            return new Blob([res], {type: 'application/pdf'});
        }));
    }

    public requestFile(params: IApiParams, fileType: string = 'text/json; charset=utf-8'): Observable<any> {
        let is_liveMOde = (this.storageBrowser.get('mode') === null) ? IS_LIVE_MODE : this.storageBrowser.get('mode');
        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');
        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: fileType,
            'Content-Type': 'application/json',
            'islive': `${is_liveMOde}`
        });

        return this.http.request(method, `${SERVER_URL}${path}`, {body, params: query, headers, responseType: 'blob'}).pipe(map((res) => {
            return new Blob([res], {type: fileType});
        }));
    }

    public requestPDFFile(path) {
        window.open(`${path}`, '_blank');
    }

    public getFileById(id: any): Observable<any> {
        return this.request({
            path: `uploads/getById/${id}`,
            method: 'GET',
        }).pipe(map((res) => {
            return res.file;
        }));
    }

    public requestFormData(params: IApiParams): Observable<any> {
        const {path, method, body, query} = params;

        return this.http.request(method, `${SERVER_URL}${path}`, {body, params: query});
    }

    public requestEcommerce(params: IApiParams): Observable<any> {
        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');

        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });

        return this.http.request(method, `${ECOMMERCE_SERVER_URL}${path}`, {body, params: query, headers});
    }

    public requestEcommerceFile(params: IApiParams, fileType: string = 'text/json; charset=utf-8', contentType: string = 'application/json'): Observable<any> {
        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');

        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: fileType,
        });

        if (!!contentType) {
            headers.append('Content-Type', contentType);
        }

        return this.http.request(method, `${ECOMMERCE_SERVER_URL}${path}`, {body, params: query, headers, responseType: 'blob'}).pipe(map((res) => {
            return new Blob([res], {type: fileType});
        }));
    }

    public requestEcommerceFileWithResult(params: IApiParams, fileType: string = 'text/json; charset=utf-8', contentType: string = 'application/json'): Observable<any> {
        const {path, method, body, query} = params;
        const token = this.storageBrowser.get('token');

        const headers = new HttpHeaders({
            'Authorization': `Bearer ${token}`,
            Accept: fileType,
        });

        if (!!contentType) {
            headers.append('Content-Type', contentType);
        }

        return this.http.request(method, `${ECOMMERCE_SERVER_URL}${path}`, {body, params: query, headers});
    }
}
