import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

declare let Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    private card: any;
    private stripe: any;
    private _clientSecret: any;

    constructor() {
    }

    get clientSecret(): any {
        return this._clientSecret;
    }

    set clientSecret(value: any) {
        this._clientSecret = value;
    }

    public createCard(el: HTMLElement, callback: (info) => void) {
        this.initStripe();
        if (this.card) {
            this.card.destroy();
        }
        this.card = this.stripe.elements().create('card', {
            style: {
                base: {
                    color: "#32325d",
                }
            }
        });
        this.card.mount(el);
        this.card.addEventListener('change', (info) => {
            if (callback) {
                callback(info)
            }
        });
        console.log(this.card);
    }

    public destroyCard() {
        if (this.card) {
            this.card.destroy();
            this.card = null;
        }
    }

    public payWithCard(billingDetails?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this._clientSecret) {
                reject('The client secret was not set');
            }
            if (!this.card) {
                reject('The card was not set');
            }
            console.log(this.card);
            this.stripe.confirmCardPayment(this._clientSecret, {
                payment_method: {
                    card: this.card,
                    billing_details: billingDetails
                }
            }).then(res => {
                resolve(res);
            });
        });
    }


    private initStripe() {
        if (!this.stripe) {
            this.stripe = Stripe(environment.pk_stripe, {
                locale: 'en'
            });
        }
    }
}
