import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {Moment} from 'moment';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {debounceTime, finalize} from 'rxjs/operators';
import {IAvailableTypes, IConstantItem} from '../../../core/models/available-types.interface';
import {PageParams} from '../../../core/models/page-params.model';
import {IShopFilter} from '../../../core/models/shop.interface';
import {ConstantService} from '../../../core/services/constant.service';
import {MarketplacesService} from '../../../core/services/marketplaces.service';
import {NotificationService} from '../../../core/services/notification.service';
import {UserService} from '../../../core/services/user.service';
import {ShipmentType} from '../../../shared/enums/shipment-type.enum';
import {RegistrationService} from '../../customer-registration/services/registration.service';
import {SaleOrderService} from '../../sales-order-management/services/sale-order.service';
import {ConfirmAssignComponent} from '../components/confirm-assign/confirm-assign.component';
import {EAssignType} from '../editor/editor.component';
import {IAnyPaginate, IGetSaleOrdersParams, InvoiceManagementService} from '../invoice-management.service';


export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM/YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM/YYYY',
    },
};

@Component({
    selector: 'eci-assign-template',
    templateUrl: './assign-template.component.html',
    styleUrls: ['./assign-template.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class AssignTemplateComponent implements OnInit {

    showLoader: boolean = false;
    type: EAssignType;
    eAssignType = EAssignType;
    templateId: number;
    templateName: string;

    searchStr = new FormControl('');

    shopIdByfilter: FormControl = new FormControl('');
    searchShopIdDropDown = new FormControl('');
    getAllShops: any = [];

    customerName: FormControl = new FormControl('');
    searchCustomerName: FormControl = new FormControl('');
    companyList: any[];

    orderType: FormControl = new FormControl('');
    orderSubType: FormControl = new FormControl('');

    orderMode: FormControl = new FormControl('');
    orderModes = [
        { value: ShipmentType.Air, name: 'Air' },
        { value: ShipmentType.Ocean, name: 'Ocean' },
        { value: ShipmentType.CourierExpress, name: 'Courier Express' },
        { value: ShipmentType.Inland, name: 'Inland' },
    ]

    fromDate: FormControl = new FormControl('');
    toDate: FormControl = new FormControl('');
    dateError = false;

    pageParams: PageParams = new PageParams();
    currentSort: Sort;
    dataSource: any[];
    displayedColumnsSO: string[] = [
        'checkboxes',
        'orderID',
        'shopName',
        'customerName',
        'soDate',
    ];
    displayedColumnsShipment: string[] = [
        'checkboxes',
        'shipmentID',
        'type',
        'subtype',
        'mode',
        'customer',
        'shipmentDate',
    ];
    displayedColumnsConShipment: string[] = [
        'checkboxes',
        'conShipmentID',
        'type',
        'subtype',
        'mode',
        'customer',
        'shipmentDate',
    ];
    displayedColumns: string[] = [];
    selectedItems: any[] = [];
    availableTypes: IAvailableTypes;
    subTypes = [
        {name: 'Regular', id:0},
        {name: 'Return', id:1},
    ]


    constructor(
        private router: Router,
        private regSvc: RegistrationService,
        public userSvc: UserService,
        private invoiceManagementService: InvoiceManagementService,
        private saleOrderSvc: SaleOrderService,
        private dialog: MatDialog,
        private notify: NotificationService,
        private constSvc: ConstantService,
    ) {
        const navigation = this.router.getCurrentNavigation();
        if (!navigation) {
            this.router.navigate(['editor']);
        }
        const state = navigation?.extras?.state;
        if (state) {
            this.type = Number(state.type);
            this.templateId = state.templateId;
            this.templateName = state.templateName;
            if (this.type != undefined && this.templateId != undefined){
                this.getList(this.type).subscribe(res => {
                    this.dataSource = res.items;
                    this.pageParams.pageSize = res.meta.itemsPerPage;
                    this.pageParams.pageIndex = res.meta.currentPage - 1;
                    this.pageParams.length = res.meta.totalItems;
                });
            }
        }
    }

    get orderTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.OrderType;
    }

    ngOnInit(): void {
        this.searchShopIdDropDown.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.getAllShopsData();
        });

        this.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.getListCompanies();
        });
        this.searchStr.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.search();
        });
        this.getAllShopsData();
        this.getListCompanies();

        this.constSvc.availableTypes.subscribe(data => {
            this.availableTypes = data;
        });
        this.constSvc.getAvailableTypes();

        this.fromDate.valueChanges.subscribe(date => {
            this.dateError = this.fromDate.value && (this.fromDate.value as Moment).isAfter(this.toDate.value);
        });

        this.toDate.valueChanges.subscribe(date => {
            this.dateError = this.fromDate.value && (this.fromDate.value as Moment).isAfter(this.toDate.value);
        });
    }

    clearSelection() {
        this.customerName.reset();
        this.getListCompanies(this.searchCustomerName.value);
    }

    getAllShopsData(q: string = this.searchShopIdDropDown.value || ''): void {
        this.saleOrderSvc.getAllShops(q).subscribe(res => {
            if (res.length > 0) {
                this.getAllShops = res;
            } else {
                this.getAllShops = [];
            }
        })
    }

    getListCompanies(q: string = this.searchCustomerName.value): void {
        q = this.customerName.value ? this.searchCustomerName.value : q;
        this.regSvc.getCompanyListForSales(q).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }

    search(): void {
        this.pageParams.pageIndex = 0;
        this.getList(this.type, {
            query: this.searchStr.value,
            page: this.pageParams.pageIndex + 1,
            limit: this.pageParams.pageSize,
        }).subscribe(res => {
            this.dataSource = res.items;
            this.pageParams.pageSize = res.meta.itemsPerPage;
            this.pageParams.pageIndex = res.meta.currentPage - 1;
            this.pageParams.length = res.meta.totalItems;
        });
    }

    getList(type: EAssignType, params?: Partial<IGetSaleOrdersParams>): Observable<IAnyPaginate> {
        this.showLoader = true;
        params ??= {};
        if (this.fromDate.value) {
            params.dateFrom = (this.fromDate.value as Moment)?.format('YYYY-MM-DD');
        }
        if (this.toDate.value) {
            params.dateTo = (this.toDate.value as Moment)?.format('YYYY-MM-DD');
        }

        switch (type) {
            case EAssignType.B2C:
                this.displayedColumns = this.displayedColumnsSO;
                params.companyId = this.customerName.value;
                params.shopId = this.shopIdByfilter.value;
                params.isB2B = 0;
                return this.invoiceManagementService.getSaleOrders(params).pipe(
                    finalize(() => { this.showLoader = false; })
                );
            case EAssignType.B2B:
                this.displayedColumns = this.displayedColumnsSO;
                params.companyId = this.customerName.value;
                params.shopId = this.shopIdByfilter.value;
                params.isB2B = 1;
                return this.invoiceManagementService.getSaleOrders(params).pipe(
                    finalize(() => { this.showLoader = false; })
                );
            case EAssignType.Shipment:
                this.displayedColumns = this.displayedColumnsShipment;
                params.mode = this.orderMode.value;
                params.companyId = this.customerName.value;
                params.type = this.orderType.value;
                params.subtype = this.orderSubType.value;
                return this.invoiceManagementService.getShipments(params).pipe(
                    finalize(() => { this.showLoader = false; })
                )
            case EAssignType.ConsolShimpent:
                this.displayedColumns = this.displayedColumnsConShipment;
                params.mode = this.orderMode.value;
                params.companyId = this.customerName.value;
                params.type = this.orderType.value;
                params.subtype = this.orderSubType.value;
                return this.invoiceManagementService.getConShipments(params).pipe(
                    finalize(() => { this.showLoader = false; })
                )
        }
    }

    clearFilters(): void {
        this.searchStr.setValue('');
        this.fromDate.setValue(null);
        this.toDate.setValue(null);
        this.customerName.setValue('');
        this.shopIdByfilter.setValue('');
        this.orderType.setValue(null);
        this.orderSubType.setValue(null);
        this.orderMode.setValue(null);
        this.searchShopIdDropDown.setValue('');
    }

    onAssignOrder() {
        const dialogRef = this.dialog.open<ConfirmAssignComponent>(ConfirmAssignComponent);
        dialogRef.componentInstance.type = this.type;
        dialogRef.componentInstance.templateName = this.templateName;
        dialogRef.componentInstance.selectedOrders = this.selectedItems;
        dialogRef.afterClosed().subscribe(value => {
            if (value) {
                console.log(this.selectedItems);
                console.log(this.templateId);
                console.log(this.type);
                this.showLoader = true;
                this.invoiceManagementService.assignTemplates(this.selectedItems.map(e => e.id), [this.templateId], this.type)
                    .pipe(finalize(() => {
                        this.showLoader = false;
                    }))
                    .subscribe(res => {
                        this.selectedItems = [];
                        this.notify.showSuccessMessage('Assigning complete successfully')
                    }, error => {
                        this.notify.showError(error?.error?.message ? error.error.message : 'Something wrong!')
                    })
            }
        })
    }

    pageChanged(e: PageParams): void {
        this.pageParams.pageIndex = e.pageIndex;
        this.pageParams.pageSize = e.pageSize;
        this.getList(this.type, {
            query: this.searchStr.value,
            page: e.pageIndex + 1,
            limit: e.pageSize,
        }).subscribe(res => {
            this.dataSource = res.items;
            this.pageParams.pageSize = res.meta.itemsPerPage;
            this.pageParams.pageIndex = res.meta.currentPage - 1;
            this.pageParams.length = res.meta.totalItems;
        });
    }
}
