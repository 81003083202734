import {Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges, OnChanges} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { ICategory } from 'src/app/core/models/category.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { UserService } from 'src/app/core/services/user.service';
import { ReportType } from 'src/app/shared/enums/report-type.enum';
import { OrderType } from 'src/app/shared/enums/order-type.enum';
import * as XLSX from 'xlsx';
import { ConstantService } from 'src/app/core/services/constant.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

const Field_List_Value: any =
    {
        orderType: false,
        vatId: false,
        customerType: false,
        stripeAmount: false,
        stripeRefundAmount: false,
        mpInvoiceVATAmount: false,
        marketplaceVATRate: false,
        orderId: false
    };

@Component({
    selector: 'eci-rt-table-tax-report',
    templateUrl: './table-tax-report.component.html',
    styleUrls: ['./table-tax-report.component.scss']
})
export class RTTableTaxReportComponent implements OnInit, OnChanges {
    isSaleOrder(orderId: string): boolean {
        return !!orderId.includes('SO');
    }

    isDomesticOrder(orderId: string): boolean {
        return !orderId.includes('SO');
    }

    constructor(
        private router: Router,
        public userSvc: UserService,
        public uploadFileSvc: UploadFileService,
        private constSvc: ConstantService,
        private localStorageSvc: LocalStorageService,
    ) { }

    @Input() dataSource;
    @Input() displayedColumns;
    @Input() availableCategories: ICategory[];
    @Input() availableTypes: IAvailableTypes;
    @Input() sumOfNumericFields: any;
    @Input() currentReportType: any;
    @Input() sumOfNumericFieldsIncludingCancellation: any;
    @Input() pageParams: PageParams = new PageParams();
    @Input() showDownloadBtn: boolean = false;
    @Input() reportType: string = null;
    @Input() updateHeader;

    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() downInvoice: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() downloadSalesInvoice: EventEmitter<any> = new EventEmitter<any>(null);
    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() downloadPdf: EventEmitter<any> = new EventEmitter<any>(null);
    @Output() downloadXl: EventEmitter<any> = new EventEmitter<any>(null);
    hiddenFieldList: any = Field_List_Value;
    showLoader: boolean = false;
    ReportType: number = ReportType.UK;

    pageChanged(e): void {
        this.pageChange.emit(e);
    }

    @ViewChild(MatSort) sort: MatSort;

    @ViewChild('table', { static: true }) table: any;

    ngOnInit(): void {
        this.showLoader = false;
        this.hiddenFieldList = [];
        if (this.userSvc.isAdmin) {
            this.getShowHideFields();
        }
    }

    findCategoryName(id): string {
        if (!this.availableCategories) return;
        const category = this.availableCategories.find(el => el.id == id);
        return category ? category.name : '';
    }

    viewOrderType(orderType: string): string {
        return OrderType[orderType] + ' Order';
    }

    downloadInvoice(id): void {
        this.downInvoice.emit(id);
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    get hideCol(): boolean {
        return this.userSvc.isDelicated;
    }
    get fieldList(): any {
        return this.hiddenFieldList;
    }
    totalPay(item): number {
        if (!item) return 0;
        const invoice = item.invoices?.find(el => el.isRequired);
        if (!invoice) return;
        return invoice.total;
    }

    totalVat(item): number {
        const totalProductVat: number = this.getTotalVat(item.products);
        const total = item.shipment?.vatRate + totalProductVat
        return total > 0 ? total : '0';
    }

    getMarketPlaceVAT(element): any {
        const marketplaceName = element?.marketplace?.saleOrderMarketplace?.marketplaceInfo?.name || '';
        const vatCharges = Number(element?.marketplace?.vatCharges || 0);
        return vatCharges == 0.1 && marketplaceName === 'wix' ? 'Not Available' : vatCharges;
    }
    public getTotalVat(products: any): number {
        return products?.reduce((sum, p) => sum + (p.vatRate * p.units), 0);
    }

    getAddress(item): string {
        return `${item?.state?.country?.name},
            ${item?.state?.name},
            ${item?.city},
            ${item?.name},
            ${item?.zipCode}`
    }

    exportToExcel(sheetName: string = 'SheetJS') {
        let ws: XLSX.WorkSheet;
        ws = XLSX.utils.table_to_sheet(this.table.nativeElement, { cellStyles: true, });

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, sheetName + '.xlsx', { cellStyles: true, bookSST: true });
    }

    public exportToPdf(): void {
        this.downloadPdf.emit();
    }
    public exportToXl(): void {
        this.downloadXl.emit();
    }

    redirectToDetails(element): void {
        if (element.orderType === 'SaleOrder') {
            this.router.navigateByUrl(`/sales-order-management/order-details/${element?.referenceId}`);
        } else {
            this.router.navigateByUrl(`/customers-order/order-details/${element?.referenceId}`);
        }
    }

    toFixed(value: any, place: number = 2): any {
        if (!value) return (0.00).toFixed(place);
        return Number(value)?.toFixed(place);
    }

    downloadSalesInvoices(): void {
        const myObj = this.dataSource;
        const selectedIds = myObj.map(({ referenceId }) => referenceId);
        this.downloadSalesInvoice.emit();
    }

    getShowHideFields(){
        // this.constSvc.getSettingsDetails('TAX_REPORT_VISIBLITY_FIELD_RULE').subscribe(data => {
        //     if (data.value) {
        //         let fields = JSON.parse(data.value);
        //         fields.map(field => {
        //             this.hiddenFieldList[field.key] = field.value;
        //         });
        //     }
        // });
        const fields = this.localStorageSvc.get('TAX_REPORT_VISIBLITY_FIELD_RULE');
        fields.map(field => {
            this.hiddenFieldList[field.key] = field.value;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes?.updateHeader?.currentValue === true)
        {
           this.getShowHideFields();
        }
    }
}
