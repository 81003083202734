import {EPaymentStatus} from './payments-type.enum';

export const PAYMENT_STATUS_LIST = [
    {name: 'Pending', value: EPaymentStatus.Pending},
    {name: 'Paid', value: EPaymentStatus.Paid},
    {name: 'Failed', value: EPaymentStatus.Failed},
    {name: 'On Hold', value: EPaymentStatus.OnHold},
    {name: 'Collection', value: EPaymentStatus.Collection},
    {name: 'Refund', value: EPaymentStatus.Refund},
    {name: 'Aborted', value: EPaymentStatus.Aborted},
];

export const PAYMENT_STATUS_LIST_CUSTOMER = [
    {name: 'Paid', value: EPaymentStatus.Paid},
    {name: 'Failed', value: EPaymentStatus.Failed},
];

export const PAYMENT_TYPES_LIST = [
    {name: 'Registration', value: 0},
    {name: 'Monthly', value: 1},
    {name: 'VAT & Com', value: 2},
];

export const PAYMENT_CUSTOMERS_TYPE = [
    'B2B offline',
    'B2B',
    'B2C',
    'Reporting Tool',
];

export const DISPLAYED_COLUMNS = [
    'period',
    'customerName',
    'customerId',
    'customerType',
    'type',
    'total',
    'actions',
    'status',
    'invoiceDate',
    'lastAction',
    'lastActionDate',
    'failedDescription',
    'chargedBy',
    'remark',
];
export const DISPLAYED_COLUMNS_CUSTOMER = [
    'period',
    'customerName',
    'customerId',
    'type',
    'total',
    'status',
    'invoiceDate',
    //'lastAction',
    //'lastActionDate',
    'failedDescription',
];
