import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CoreModule} from '../../core/core.module';
import {LoaderModule} from '../../shared/directives/loader/loader.module';
import {MaterialModule} from '../material.module';
import {ChangeStatusComponent} from './components/dialogs/change-status/change-status.component';
import {ConfirmComponent} from './components/dialogs/confirm/confirm.component';
import {PaginatorComponent} from './components/paginator/paginator.component';
import {PaymentsRoutingModule} from './payments-routing.module';
import {PaymentsComponent} from './payments.component';
import { HistoryComponent } from './components/history/history.component';
import { HistoryPaymentsTableComponent } from './components/history-payments-table/history-payments-table.component';
import { HistoryPaymentsSummaryTableComponent } from './components/history-payments-summary-table/history-payments-summary-table.component';
import { MonthDatepickerComponent } from './components/month-datepicker/month-datepicker.component';
import { PaymentTypePipe } from './pipes/payment-type.pipe';
import { PaymentStatusPipe } from './pipes/payment-status.pipe';
import { PaymentActionPipe } from './pipes/payment-action.pipe';
import { DetailsComponent } from './components/details/details.component';
import { PaymentInvoiceTypePipe } from './pipes/payment-invoice-type.pipe';
import { OnHoldComponent } from './components/dialogs/on-hold/on-hold.component';
import { RemarkTypePipe } from './pipes/remark-type.pipe';
import { RemarksComponent } from './components/dialogs/remarks/remarks.component';
import { ChargeComponent } from './components/dialogs/charge/charge.component';
import { PayComponent } from './components/pay/pay.component';

@NgModule({
    declarations: [
        PaymentsComponent,
        HistoryComponent,
        HistoryPaymentsTableComponent,
        HistoryPaymentsSummaryTableComponent,
        MonthDatepickerComponent,
        PaymentTypePipe,
        PaymentStatusPipe,
        PaymentActionPipe,
        DetailsComponent,
        PaymentInvoiceTypePipe,
        OnHoldComponent,
        RemarkTypePipe,
        RemarksComponent,
        ChargeComponent,
        PayComponent,
        ChangeStatusComponent,
        PaginatorComponent,
        ConfirmComponent,
    ],
    imports: [
        PaymentsRoutingModule,
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        LoaderModule,
        NgxMatSelectSearchModule,
        MatSelectInfiniteScrollModule,
        CoreModule,
    ]
})
export class PaymentsModule {
}
