import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiService } from '../../../core/services/api.service';
import { IProductFilter } from '../components/models/product-filter.interface';
import { IEmpactProductResponse, IShopBase } from '../models/empact-product-details.interface';

@Injectable({
    providedIn: 'root',
})

/**
 * Service for offline shop
 * requestEcommerce - call Ecommerce API
 * request - call Empact API
 */

export class OfflineShopService extends ApiService {

    public filters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public updateShopProductList = new BehaviorSubject<any>(null);
    public amountProductsWithoutHSCode$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public listOfShop$: BehaviorSubject<IShopBase[]> = new BehaviorSubject<IShopBase[]>([]);

    getProducts(page: number = 1, limit: number = 100, filter: IProductFilter , orderField: string = '',
                orderDirection: string = '',  shopsId: string[]): Observable<any> {
        return this.requestEcommerce({
            method: 'GET',
            path: `shop/products`,
            query: {
                ...filter,
                limit,
                page,
                orderField,
                orderDirection,
                shopsId,
            },
        })
            .pipe(map((value) => {
                const withoutHsCode = value?.items?.filter((el) => !el?.hsCode)?.length || 0;
                this.amountProductsWithoutHSCode$.next(withoutHsCode);
                return value;
            }));
    }

    getProductsCount(shopsId: string[]): Observable<any> {
        return this.requestEcommerce({
            method: 'GET',
            path: `shop/products/count`,
            query: {
                shopsId: shopsId,
            },
        });
    }

    changeStatus(id, status): Observable<any> {
        return this.requestEcommerce({
            path: `shop/update-status/${id}`,
            method: 'PUT',
            body: { status },
        });
    }

    getProductById(id: number): Observable<IEmpactProductResponse> {
        return this.requestEcommerce({ path: `shop/product/${id}`, method: 'GET' });
    }

    createProduct(body: IEmpactProductResponse): Observable<any> {
        return this.requestEcommerce({ path: `shop/product`, method: 'POST', body });
    }

    createProductFromXLSX(body: IEmpactProductResponse[]): Observable<any> {
        return this.requestEcommerce({ path: `shop/xlsx-products`, method: 'POST', body });
    }

    public getProductsExcel(page: number = 1, limit: number = 100, filter: IProductFilter , orderField: string = '',
                            orderDirection: string = '',  shopsId: string[]): Observable<any> {
        return this.requestEcommerceFile({
            path: `shop/download-xlsx-products`,
            method: 'POST',
            query: {
                ...filter,
                page,
                limit,
                orderField,
                orderDirection,
                shopsId,
            },
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `Product-${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    updateProduct(id, body): Observable<any> {
        return this.requestEcommerce({path: `shop/product/${id}`, method: 'PUT', body });
    }

    getDownloadStatus(id): Observable<any> {
        return this.requestEcommerce({ path: `shop/product/getDownloadStatus/${id}`, method: 'GET' });
    }

    updateDownloadStatus(id): Observable<any> {
        return this.requestEcommerce({ path: `shop/product/downloadUpdate/${id}`, method: 'GET' });
    }

    public getOfflineShop(companyId: number|string): Observable<IShopBase[]> {
        return this.request({
            path: `company-marketplaces/eshops/${companyId}`,
            method: 'GET',
        }).pipe(map(value => {
            this.listOfShop$.next(value);
            return value;
        }));
    }

    public downloadEcommarceExcelExample(): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Accept', '*')
            .append('responseType', 'application/octet-stream');

        const fileUrl = `/assets/xlsx/ecommerce_product_example.xlsx`;

        return this.http.get(fileUrl, { headers: headers, responseType: 'blob' });
    }

    public applyHsCodes(body:any):Observable<any> {
        return this.requestEcommerce({
            path:'shop/product/apply-hs-code',
            method:'POST',
            body:body
        })
    }

    public downloadProductImageExcelTemplate(): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Accept', '*')
            .append('responseType', 'application/octet-stream');

        const fileUrl = `/assets/xlsx/product_images_example.xlsx`;

        return this.http.get(fileUrl, { headers: headers, responseType: 'blob' });
    }

    public uploadImages4Products(file: any, companyId: number): Observable<any> {
        return this.requestEcommerceFileWithResult({
            path: `shop/product/upload-images/${companyId}`,
            method: 'POST',
            body: file
        }, 'application/json', undefined);
    }

    private amount(query?): Observable<number> {
        return this.requestEcommerce({
            path: `shop/product/amount-without-hs-code`,
            method: 'GET',
            query,
        })
    }

    public updateAmountProducts(query?): void {
        this.amount(query)
            .subscribe((value) => {
                const amount = isNaN(+value) ? 0 : value;
                this.amountProductsWithoutHSCode$.next(amount);
            });
    }

    public updateShopsList(marketplaces: IShopBase[]): void {
        this.listOfShop$.next(marketplaces);
    }
}
