import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'add-remark-popup',
  templateUrl: './add-remark-popup.component.html',
  styleUrls: ['./add-remark-popup.component.scss'],
})
export class AddRemarkPopup implements OnInit {

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddRemarkPopup>,
  ) {
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.controls.remark.value);
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      remark: [null, [Validators.required]],
    });
  }
}
