import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from './core/services/local-storage.service';
import { UserService } from './core/services/user.service';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {PaymentsService} from './modules/payments/payments.service';
import {ProductAllocationNewComponent} from './shared/components/product-allocation-new/product-allocation-new.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ECI';
  isFedex = environment.isFedex;

  public previousUrl: string = null;
  public currentUrl: string = null;

  constructor(
    public router: Router,
    private localStorageSvc: LocalStorageService,
    private userService: UserService,
    private Location: Location,

    private dialog: MatDialog,
  ) {
    this.userService.user$.next(this.localStorageSvc.get('user'));
    if (this.localStorageSvc.get('user')) {
        this.localStorageSvc.set('mode', this.localStorageSvc.get('user').workingMode);
    }
    const path = this.Location.path().split('/');
    const path2 = this.Location.path().split('?');

    let monthly_commission = '';
    if(path[1] && path2[0]){
      const newpath = path[1]?.split('?');
       monthly_commission = newpath[0];
    }
    switch (path2?.[1]) {
      case 'redirectTo=payment-methods': this.localStorageSvc.set('redirectTo', 'payment-methods'); break;
      case 'redirectTo=tax-numbers': this.localStorageSvc.set('redirectTo', 'tax-numbers'); break;
      case 'redirectTo=online-shop': this.localStorageSvc.set('redirectTo', 'online-shop'); break;
      case 'redirectTo=empact-shop': this.localStorageSvc.set('redirectTo', 'empact-shop'); break;
      case 'redirectTo=fedex': this.localStorageSvc.set('redirectTo', 'fedex'); break;
    }

    if (path2[0] === '/products') {
      this.localStorageSvc.set('redirectTo', 'products');
    }

    if (path[1] !== 'payment-plugin' &&
        path[1] !== 'ec-payment' &&
        path[1] !== `registration` &&
        (path[1] !== `sso` && path2[0] !== `/sso`) &&
        (path[1] !== `stripe-plugin` && path2[0] !== `/stripe-plugin`) &&
        (path[1] !== `thankyou` && path2[0] !== `/thankyou`) &&
        (path[1] !== `stripe-plugin` && path2[0] !== `/stripe-plugin`) &&
        path[1] !== 'ecommerce' &&
        monthly_commission !== 'monthly-payment' &&
        monthly_commission !== 'commission-payment' &&
        (path[1] !== `payments` && path2[0] !== `/payments/pay`) &&
        !this.userService.user
    ) {
      this.router.navigateByUrl('/auth/login');
    }
    if (this.isFedex) {
      sessionStorage.setItem("HSCodeStatus", 'Active');
    }

    if (this.userService.isAdmin) {
        sessionStorage.setItem('mode', this.userService.user.workingMode);
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (this.currentUrl.split('/')[1] === 'payment-plugin') {
          this.localStorageSvc.set('previousUrl', this.previousUrl);
        }

        if (
            event.id === 1 &&
            event.url === event.urlAfterRedirects &&
            event.url.includes('registration')
          ) {
            this.userService.isTestUserRegistration$.next(false);
        }
    });
  }

    ngOnInit(): void {
        /*const dialogRef = this.dialog.open(ProductAllocationNewComponent, {
            height: '95%',
            width: '95%',
            maxWidth: '100vw',
            disableClose: true
        });
        dialogRef.componentInstance.denominator = 6000;
        dialogRef.componentInstance.productList = [
            {
                hsCode: "620463000080",
                simplyDutyTaxRate: 0,
                simplyDutyTaxAmount: 0,
                discount: 0.00,
                weight: 0.01,
                volume: 0.01,
                units: 100,
                productId: "67613",
                itemNumber: "12301100012",
                description: "Bandeau Tube Top",
                price: 29,
                saleUnitPrice: 29.00,
                costUnitPrice: 29,
                costPriceUnit: 29,
                categoryId: "305",
                sku: 'sku1',
                status: 1
            },
            {
                hsCode: "620463000081",
                simplyDutyTaxRate: 0,
                simplyDutyTaxAmount: 0,
                discount: 0.00,
                weight: 0.01,
                volume: 0.01,
                units: 100,
                productId: "67614",
                itemNumber: "12301100013",
                description: "Bandeau Tube Top 2",
                price: 29,
                saleUnitPrice: 29.00,
                costUnitPrice: 29,
                costPriceUnit: 29,
                categoryId: "305",
                sku: 'sku2',
                status: 1
            }
        ];*/
    }
}
