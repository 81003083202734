import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {NotificationService} from '../../../../../core/services/notification.service';
import {IPayment, IPaymentsUpdate} from '../../../payments-interfaces';
import {EPaymentStatus, EPaymentsType} from '../../../payments-type.enum';
import {PaymentsService} from '../../../payments.service';
import {StripeService} from '../../../stripe.service';

@Component({
    selector: 'eci-charge',
    templateUrl: './charge.component.html',
    styleUrls: ['./charge.component.scss']
})
export class ChargeComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('cardInfo') cardInfo: ElementRef;

    complete = false;
    empty = true;
    payment: IPayment;
    amountCtrl: FormControl;
    periodCtrl: FormControl;
    commentCtrl: FormControl;
    cardError: any;
    init = false;
    isLoad = false;
    types = EPaymentsType;

    constructor(
        public dialogRef: MatDialogRef<ChargeComponent>,
        public stripeService: StripeService,
        private paymentsService: PaymentsService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        let periodStart = moment();
        let periodEnd = moment();
        if (this.payment.type === EPaymentsType.VAT_COM) {
            periodStart = periodStart.subtract(1, 'month');
            periodEnd = periodEnd.subtract(1, 'month');
        }
        periodStart = periodStart.startOf('month');
        periodEnd = periodEnd.endOf('month');
        this.amountCtrl = new FormControl(this.payment.totalWithDiscount);
        this.periodCtrl = new FormControl(`From ${periodStart.format('Do of MMMM')} till ${periodEnd.format('Do of MMMM')}`);
        this.commentCtrl = new FormControl(``);
    }

    onCharge() {
        if (this.empty) {
            this.isLoad = true;
            this.paymentsService.createPayment({
                type: this.payment.type,
                timezone: moment().format('Z'),
                companyId: this.payment.company.id,
                paymentId: this.payment.id,
                comment: this.commentCtrl.value,
                withNewCard: false
            }).subscribe(res => {
                this.isLoad = false;
                this.dialogRef.close(true);
                if (res.status === EPaymentStatus.Failed) {
                    this.notificationService.showError(res.failedDescription)
                } else {
                    this.notificationService.showSuccessMessage('Payment was charged successfully!')
                }
            }, error => {
                this.isLoad = false;
                this.notificationService.showError(error.message);
            });
        } else {
            if (this.complete) {
                this.isLoad = true;
                this.paymentsService.createPayment({
                    type: this.payment.type,
                    timezone: moment().format('Z'),
                    companyId: this.payment.company.id,
                    paymentId: this.payment.id,
                    comment: this.commentCtrl.value,
                    withNewCard: true
                }).subscribe(cpRes => {
                    this.stripeService.clientSecret = cpRes.client_secret;
                    this.stripeService.payWithCard({
                        name: `Payment for ${this.payment.id} payment`
                    }).then(pwcRes => {
                        const data: IPaymentsUpdate = {
                            paymentId: this.payment.id,
                            status: null
                        }
                        if (pwcRes.error) {
                            data.status = EPaymentStatus.Failed;
                            data.message = pwcRes.error.message;
                        } else {
                            data.status = EPaymentStatus.Paid;
                            data.method = pwcRes.paymentIntent?.payment_method;
                        }
                        this.paymentsService.updatePayment(data).subscribe(upRes => {
                            this.isLoad = false;
                            this.dialogRef.close(true);
                            if (upRes.status === EPaymentStatus.Failed) {
                                this.notificationService.showError(upRes.failedDescription)
                            } else {
                                this.notificationService.showSuccessMessage('Payment was charged successfully!')
                            }
                        }, error => {
                            this.isLoad = false;
                            this.notificationService.showError(error.message);
                        });
                    });
                });
            }
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.init = true;
        }, 1000);
        this.initStripe();
    }

    private initStripe() {
        this.stripeService.createCard(
            this.cardInfo.nativeElement,
            (info) => {
                this.complete = info.complete;
                this.empty = info.empty;
                if (info.error) {
                    this.cardError = info.error.message;
                } else {
                    this.cardError = null;
                }
            }
        );
    }

    ngOnDestroy(): void {
        this.stripeService.destroyCard();
    }
}
