import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {InvoiceManagerModule} from './modules/invoice-manager/invoice-manager.module';
import {NavMenuComponent} from './modules/nav-menu/nav-menu.component';
import {PaymentAlertModule} from './modules/payments/payment-alert.module';
import {PaymentsModule} from './modules/payments/payments.module';
import {ReportingToolModule} from './modules/reporting-tool/reporting-tool.module';
import {SharedModule} from './shared/shared.module';
import {MaterialModule} from './modules/material.module';
import {LoginComponent} from './modules/auth/components/login.component';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import {AuthService} from './modules/auth/services/auth.service';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './core/services/auth-guard.service';
import {CoreModule} from './core/core.module';
import {AuthModule} from './modules/auth/auth.module';
import {HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {VbanAlertsService} from './modules/vban-alerts/services/vban-alerts.service';
import {ConsolidateShipmentModule} from './modules/consolidate-shipments/consolidate-shipments.module';
import {SaleProductService} from './modules/sales-order-management/services/sale-product.service';
import {AuthModule as Auth} from './modules/empact-shop/auth/auth.module';
import {CoreModule as Core} from './modules/empact-shop/core/core.module';
import {HsCodesService} from 'src/app/modules/hs-codes/services/hs-code-services';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { PartnerService } from './modules/partner-management/services/partner.service';
import { CreatePaymentPopupComponent } from './modules/payments/components/create-payment-popup/create-payment-popup.component';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        LoginComponent,
        NavMenuComponent,
        CreatePaymentPopupComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        AuthModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        CoreModule,
        HttpClientModule,
        ToastrModule.forRoot(),
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        MatDialogModule,
        ConsolidateShipmentModule,
        Auth,
        Core,
        ReportingToolModule,
        InvoiceManagerModule,
        PaymentsModule,
        PaymentAlertModule,
    ],
    providers: [
        AuthService,
        SaleProductService,
        PartnerService,
        AuthGuard,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        VbanAlertsService,
        HsCodesService,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
        // OrdersService
    ],
    bootstrap: [
        AppComponent
    ],
    exports: [
        MatDialogModule
    ]
})
export class AppModule {
}
