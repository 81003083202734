import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Injectable({
    providedIn: 'root'
})
export class OrdersService extends ApiService {

    public fileLoadedObserver = new Subject<any>();
    public paletUpdateRecord = new BehaviorSubject<any>(null);

    public selectReturnSOProducts$ = new BehaviorSubject<any[]>([]);

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private notify: NotificationService
    ) {
        super(http, storageBrowser);
    }
    getOrders(page: number = 1, limit: number = 100, q: string = '', companyId?: number, searchParams?: any, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders`,
            query: {
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                orderTypes: searchParams?.orderTypes,
                companyTypes: searchParams?.companyTypes,
                orderStatuses: searchParams?.orderStatuses,
                handleStatus: searchParams?.handleStatus,
                lastMile: searchParams?.handleLastMile,
                lastMileStatus: searchParams?.lastMileStatus,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                deliveryDateTo: searchParams?.deliveryDateTo,
                deliveryDateFrom: searchParams?.deliveryDateFrom,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getOrderById(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/${id}`,
        });
    }

    getOrderTracking(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/${id}/tracking`,
        });
    }

    getUneditedOrderCount(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/get-unedited-shipment-count/${id}`,
        });
    }

    deleteOrder(id: number): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `orders/${id}`,
        });
    }

    addOrder(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders`,
            body: body
        });
    }

    addOrderNew(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders-new`,
            body: body
        });
    }

    updateOrder(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `orders/${id}`,
            body: body
        });
    }

    updateTrackNum(id: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/update/tracking-number/${id}`,
            body: body
        });
    }

    public updateRemark(id: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/update/remark/${id}`,
            body: body
        });
    }

    updateFreightCost(id: number, body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/update/freight-cost/${id}`,
            body: body
        });
    }

    // downloadOrder(id: number, body): Observable<any> {
    //     return this.request({
    //         method: 'PUT',
    //         path: `orders/${id}`,
    //         body: body
    //     });
    // }
    public downloadFile(orderId, id): any {
        this.request({
            path: `orders/${orderId}/documents/${id}`,
            method: 'GET',
        }).subscribe(res => {
            window.open(`${res.file}`, '_blank');
        });
    }

    public downloadInvoice(orderId): any {
        this.request({
            path: `orders/download`,
            method: 'POST',
            body: {
                invoiceId: orderId,
                type: 'PDF'
            }
        }).subscribe(res => {
            window.open(`${res.file}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    public updateOrderDoc(orderId, id, body): Observable<any> {
        return this.request({
            path: `orders/${orderId}/documents/${id}`,
            method: 'PUT',
            body: body
        })
    }

    public deleteOrderDoc(orderId, id): Observable<any> {
        return this.request({
            path: `orders/${orderId}/documents/${id}`,
            method: 'DELETE',
        })
    }

    uploadFile(files: any, orderId, docId, fieldName = 'content') {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', `${environment.api_url}orders/${orderId}/documents/${docId}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        this.fileLoadedObserver.next(xhr.responseText);

                    } else {
                        this.fileLoadedObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
        }
    }

    getOrderDocs(orderId): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/${orderId}/documents`,
        });
    }

    getOrderTabs(type: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/tabs`,
            body: {
                type: type,
            }
        });
    }

    getAvailableOrderStates(orderId: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/${orderId}/states`,
        });
    }

    getHistoryById(orderId: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/${orderId}/history`,
        });
    }

    regenrateOrdersInvoice(id: number): Observable<any> {
        return this.request({
            path: `orders/regenerate-shipment-invoice`,
            method: 'POST',
            body: { id: id }
        });
    }

    uploadOrdersInvoice(files: any, docId, orderId, fieldName = 'content') {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `${environment.api_url}orders/upload-shipment-invoice/${docId}/${orderId}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        this.fileLoadedObserver.next(xhr.responseText);

                    } else {
                        this.fileLoadedObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                console.error(xhr.statusText);
            };
            xhr.send(formData);
        }
    }

    switchHandled(ids: number[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/switch-handled`,
            body: ids
        });
    }

    delete(ids: number[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/delete`,
            body: ids
        });
    }

    getB2BShipmentNotEditedOnce(orderId: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/${orderId}/history`,
        });
    }

    getTotalNonEditedOrders(): Observable<any> {
        return this.request({
            path: `orders/b2b-orders/get-total-non-edited-orders`,
            method: 'GET'
        })
    }

    getOrderCheckpoints(orderId: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `orders/aftership-checkpoints/${orderId}`,
        });
    }
    syncWithAfterShip(soIds: any[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/sync-aftership`,
            body: soIds
        });
    }

    addNewStatus(status: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/add-new-status`,
            body: {
                name: status
            }
        });
    }

    deleteNewStatus(id: any): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `orders/delete-new-status/${id}`,
        });
    }

    getNewOrderStatuses() {
        return this.request({
            method: 'GET',
            path: `orders/get-new-status`,
        });
    }

    updateLastMileStatus(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/update-last-mile-status`,
            body: body
        });
    }

    deleteTrackingDetails(id: number): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `tracking-numbers-shipments/${id}`,
        });
    }

    updateLastMileDetails(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `orders/update-last-mile-details`,
            body: body
        });
    }

    getOrdersInvoice(id: number): Observable<any> {
        return this.request({
            path: `orders/get-invoice/${id}`,
            method: 'GET',
        });
    }

    getSuppliers(page: number = 1, limit: number = 100, q: string = '', companyId?: number,): Observable<any> {
        return this.request({
            method: 'GET',
            path: `supplier`,
            query: {
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : ''
            }
        });
    }

    getSupplierById(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `supplier/${id}`,
        });
    }

    createSupplier(body): Observable<any> {
        return this.request({
            method: 'POST',
            path: `supplier`,
            body: body
        });
    }

    deleteSupplier(id: any): Observable<any> {
        return this.request({
            method: 'DELETE',
            path: `supplier/${id}`
        });
    }
}
