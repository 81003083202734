import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService } from '../../../core/services/api.service';
import { IContactInfo } from '../models/contact-info';

@Injectable()
export class PartnerService extends ApiService {

    getPartners(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        dateFrom?: string,
        dateTo?: string,
        partnerIds = ''
    ): Observable<any> {
        return this.request({
            path: `partner`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                dateFrom,
                dateTo,
                partnerIds
            }
        });
    }

    getPartnersList(): Observable<any> {
        return this.request({
            path: `partner/list/all`,
            method: 'GET'
        });
    }

    getCustomListByPartner(limit: number = 1000, page: number = 1, q: string = '', partnerId = ''): Observable<any> {
        return this.request({
            path: `company/partner-customer-list-filter`,
            method: 'POST',
            query: {
                limit,
                page,
                q,
                partnerId
            }
        });
    }

    getMarketplaceList(): Observable<any> {
        return this.request({
            path: `marketplaces`,
            method: 'GET'
        });
    }

    createPartnerAccount(body): Observable<any> {
        return this.request({
            path: 'partner',
            method: 'POST',
            body: body
        }).pipe(map(el => el.items));
    }

    deletePartnerAccount(id): Observable<any> {
        return this.request({
            path: `partner/${id}`,
            method: 'DELETE',
        });
    }

    updatePartnerAccount(id, body): Observable<any> {
        return this.request({
            path: `partner/${id}`,
            method: 'PUT',
            body: body
        });
    }

    resetPartnerPassword(id, body): Observable<any> {
        return this.request({
            path: `partner/reset/password/${id}`,
            method: 'PUT',
            body: body
        });
    }

    getAllContacts(page: number = 1, limit: number = 100, q: string = '', contactableId?, orderField: string = '', orderDirection: string = ''): Observable<any> {
        if (!contactableId) contactableId = '';
        return this.request({
            path: `contacts`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                contactableId: contactableId,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getContactById(id: number): Observable<IContactInfo> {
        return this.request({
            path: `contacts/${id}`,
            method: 'GET',
        });
    }

    addContact(body): Observable<any> {
        return this.request({
            path: `contacts`,
            method: 'POST',
            body: body
        });
    }

    updateContact(id: number, body): Observable<any> {
        return this.request({
            path: `contacts/${id}`,
            method: 'PUT',
            body: body
        });
    }

    deleteContact(id: number): Observable<any> {
        return this.request({
            path: `contacts/${id}`,
            method: 'DELETE',
        });
    }

    getPartnerById(id: number): Observable<IContactInfo> {
        return this.request({
            path: `partner/${id}`,
            method: 'GET',
        });
    }

    changeIntegrationFeeStatus(id:number ,status:any) {
        return this.request({
            path: `partner/integrationFee/status/${id}`,
            method: 'PUT',
            body: status
        })
    }

    getDelegatedUser(page: number = 1, limit: number = 100, parentId: number, orderField: string = '', orderDirection = ''): Observable<any> {
        return this.request({
            path: 'partner/delegate/list',
            method: 'GET',
            query: {
                parentId: parentId,
                page: page,
                limit: limit,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    addDelegatedUser(body): Observable<any> {
        return this.request({
            path: 'partner/delegate/add',
            method: 'POST',
            body: body
        }).pipe(map(el => el.items));
    }

    deleteDelegatedUser(id): Observable<any> {
        return this.request({
            path: `partner/delegate/${id}`,
            method: 'DELETE',
        });
    }

    changeDelegateStatus(id:number ,status:any) {
        return this.request({
            path: `partner/delegate/updateStatus/${id}`,
            method: 'PUT',
            body: status
        })
    }

    updateDelegatedUser(id, body): Observable<any> {
        return this.request({
            path: `partner/delegate/update/${id}`,
            method: 'PUT',
            body: body
        });
    }

    getDelegatedUserById(id: number): Observable<IContactInfo> {
        return this.request({
            path: `partner/delegate/get/${id}`,
            method: 'GET',
        });
    }

    getWareHouses(page: number = 1, limit: number = 100, q: string = '', parentId?: number, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            path: `warehouses/partner/list`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                parentId: parentId ? parentId : '',
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getCustomersList(page: number = 1, limit: number = 100, q: string, customerId?: number, partnerId?: number, showIncompleted?: boolean, dateFrom?: string, dateTo?: string, packageType?: string, companyIds = '', marketplaceId: string = ''): Observable<any> {
        return this.request({
            path: `company/partner/customers-list`,
            method: 'GET',
            query: {
                q: q,
                page: page,
                limit: limit,
                customerId: customerId ? customerId : '',
                partnerId: partnerId,
                showIncompleted,
                dateFrom,
                dateTo,
                packageType,
                companyIds,
                marketplaceId
            }
        });
    }

    getParnterMarketplaceList(q: string, partnerId: number, marketplaceIds = ''): Observable<any> {
        return this.request({
            path: `company-marketplaces/partner/marketplaces/list`,
            method: 'GET',
            query: {
                q: q,
                partnerId: partnerId,
                marketplaceIds
            }
        });
    }

    getCustomerWareHouses(page: number = 1, limit: number = 100, q: string = '', companyId?: number, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            path: `warehouses`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                companyId: companyId ? companyId : '',
                orderField: orderField,
                orderDirection: orderDirection
            }
        }).pipe(map(el => el.items));
    }

    getPartnerPackage(id: number): Observable<IContactInfo> {
        return this.request({
            path: `partner/package/${id}`,
            method: 'GET',
        });
    }

    emailCheck(email: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/check-registration-email/${email}`
        });
    }

    nameCheck(name: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `users/check-partner-registration-name/${name}`
        });
    }


    emailCheckContact(contactableId: number,email: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `contacts/check-email/${contactableId}/${email}`
        });
    }

    getCompanyListByPartner(limit: number = 1000, page: number = 1, q: string = '', partnerId = ''): Observable<any> {
        return this.request({
            path: `company/partner-customer-list`,
            method: 'POST',
            query: {
                limit,
                page,
                q,
                partnerId
            }
        });
    }
}
