import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WmsService extends ApiService {

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService
    ) {
        super(http, storageBrowser);
    }

    getWarehouses(page: number = 1, limit: number = 100, q: string = '', warehouseId: number[] = [], warehouseName: number[] = [], warehouseCountry: number[] = [], customers: number[] = [], partnerId: string = '', orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_all_warehouses`,
            query: {
                q: q,
                warehouseId: warehouseId,
                warehouseName: warehouseName,
                warehouseCountry: warehouseCountry,
                companyId: customers,
                limit: limit,
                page: page,
                partnerId: partnerId,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getWarehouseIds(id: string = '', name: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_all_warehouse_ids`,
            query: {
                id: id,
                name: name,
            }
        });
    }

    getSkus(page: number = 1, limit: number = 100, q: string = '', skuId: number[] = [], companyId: number[] = [], partnerId: string = '', orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_all_skus`,
            query: {
                q: q,
                skuId: skuId,
                companyId: companyId,
                partnerId: partnerId,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getSkuIds(q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_all_sku_ids`,
            query: {
                q: q,
            }
        });
    }

    getWarehouseDetails(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_warehouse_details/${id}`
        });
    }

    getWarehouseSkus(id: number, page: number = 1, limit: number = 100, q: string = '', skuId: number[] = [], orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_warehouse_skus/${id}`,
            query: {
                q: q,
                skuId: skuId,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getWarehouseSkuIds(id: number, q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_warehouse_sku_ids/${id}`,
            query: {
                q: q,
            }
        });
    }

    getSkuDetails(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_sku_details/${id}`
        });
    }

    getSkuWarehouses(id: number, page: number = 1, limit: number = 100, q: string = '', warehouseId: number[] = [], warehouseName: number[] = [], warehouseCountry: number[] = [], region: boolean, orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_sku_warehouses/${id}`,
            query: {
                q: q,
                warehouseId: warehouseId,
                warehouseName: warehouseName,
                warehouseCountry: warehouseCountry,
                region: region,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getSkuMovements(
        id: number,
        page: number = 1,
        limit: number = 100,
        q: string = '',
        originalWarehouseId: number[] = [],
        destinationWarehouseId: number[] = [],
        originCountryCode: number[] = [],
        destinationCountryCode: number[] = [],
        action: string = '',
        orderType: string = '',
        movementStatus: string = '',
        dateFrom?: string,
        dateTo?: string,
        orderField: string = '',
        orderDirection: string = ''
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_sku_movements/${id}`,
            query: {
                q: q,
                originalWarehouseId: originalWarehouseId,
                destinationWarehouseId: destinationWarehouseId,
                originCountryCode,
                destinationCountryCode,
                action: action,
                orderType: orderType,
                movementStatus: movementStatus,
                dateFrom,
                dateTo,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getWarehouseMovements(
        id: number,
        page: number = 1,
        limit: number = 100,
        q: string = '',
        originalWarehouseId: number[] = [],
        destinationWarehouseId: number[] = [],
        originCountryCode: number[] = [],
        destinationCountryCode: number[] = [],
        skuId: number[] = [],
        action: string = '',
        orderType: string = '',
        movementType: string = '',
        movementStatus: string = '',
        dateFrom?: string,
        dateTo?: string,
        orderField: string = '',
        orderDirection: string = ''
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_warehouse_movements/${id}`,
            query: {
                q: q,
                originalWarehouseId: originalWarehouseId,
                destinationWarehouseId: destinationWarehouseId,
                originCountryCode,
                destinationCountryCode,
                skuId: skuId,
                action: action,
                orderType: orderType,
                movementType: movementType,
                movementStatus: movementStatus,
                limit: limit,
                page: page,
                dateFrom,
                dateTo,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    getAllMovements(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        originalWarehouseId: number[] = [],
        destinationWarehouseId: number[] = [],
        originCountryCode: number[] = [],
        destinationCountryCode: number[] = [],
        skuId: number[] = [],
        action: string = '',
        orderType: string = '',
        movementStatus: string = '',
        dateFrom?: string,
        dateTo?: string,
        orderField: string = '',
        orderDirection: string = '',
        thirdPartyId: string = '',
        warehouseName: number[] = [],
        warehouseId: number[] = [],
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/get_all_movements`,
            query: {
                q: q,
                originalWarehouseId: originalWarehouseId,
                destinationWarehouseId: destinationWarehouseId,
                originCountryCode,
                destinationCountryCode,
                skuId: skuId,
                action: action,
                orderType: orderType,
                movementStatus: movementStatus,
                limit: limit,
                page: page,
                dateFrom,
                dateTo,
                orderField: orderField,
                orderDirection: orderDirection,
                thirdPartyId: thirdPartyId,
                warehouseName: warehouseName,
                warehouseId: warehouseId
            }
        });
    }

    getInTransitMovements(
        id: number,
        page: number = 1,
        limit: number = 100,
        q: string = '',
        originalWarehouseId: number[] = [],
        destinationWarehouseId: number[] = [],
        originCountryCode: number[] = [],
        destinationCountryCode: number[] = [],
        skuId: number[] = [],
        action: string = '',
        orderType: string = '',
        dateFrom?: string,
        dateTo?: string,
        orderField: string = '',
        orderDirection: string = '',
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/in_transit_movements/${id}`,
            query: {
                q: q,
                originalWarehouseId: originalWarehouseId,
                destinationWarehouseId: destinationWarehouseId,
                originCountryCode,
                destinationCountryCode,
                skuId: skuId,
                action: action,
                orderType: orderType,
                limit: limit,
                page: page,
                dateFrom,
                dateTo,
                orderField: orderField,
                orderDirection: orderDirection,
            }
        });
    }

    getInTransitSkuMovements(
        id: number,
        sku: string,
        page: number = 1,
        limit: number = 100,
        q: string = '',
        originalWarehouseId: number[] = [],
        destinationWarehouseId: number[] = [],
        originCountryCode: number[] = [],
        destinationCountryCode: number[] = [],
        action: string = '',
        orderType: string = '',
        dateFrom?: string,
        dateTo?: string,
        orderField: string = '',
        orderDirection: string = '',
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `wms/in_transit_sku_movements/${id}/${sku}`,
            query: {
                q: q,
                originalWarehouseId: originalWarehouseId,
                destinationWarehouseId: destinationWarehouseId,
                originCountryCode,
                destinationCountryCode,
                action: action,
                orderType: orderType,
                limit: limit,
                page: page,
                dateFrom,
                dateTo,
                orderField: orderField,
                orderDirection: orderDirection,
            }
        });
    }


    public downloadWarehouses(q: string = '', warehouseId: number[] = [], warehouseName: number[] = [], warehouseCountry: number[] = [], orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.requestFile({
            path: `wms/download-warehouses`,
            method: 'POST',
            query: {
                q: q,
                warehouseId: warehouseId,
                warehouseName: warehouseName,
                warehouseCountry: warehouseCountry,
                orderField: orderField,
                orderDirection: orderDirection
            }
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `warehouses${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    public downloadSkus(q: string = '', skuId: number[] = [], companyId: number[] = [], orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.requestFile({
            path: `wms/download-skus`,
            method: 'POST',
            query: {
                q: q,
                skuId: skuId,
                companyId: companyId,
                orderField: orderField,
                orderDirection: orderDirection
            }
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `skus${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    checkThirdPartyIdTaken(thirdPartyId: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `warehouses/third-party-id-taken`,
            body: {
                thirdPartyId: thirdPartyId
            }
        });
    }

    getAllWarehouses(filterName, value): Observable<any> {
        return this.request({
            method: 'GET',
            path: `warehouses`,
            query: {
                filterName: filterName,
                value: value
            }
        });
    }

    getWarehouseProviders(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `warehouses/providers/list`
        });
    }

    updateWMSSkuBalance(body: any): Observable<any> {
        return this.request({
            method: 'POST',
            path: `wms/wms_sku_balance_update`,
            body
        });
    }
}
