import { DatePipe } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ProductStatus } from 'src/app/shared/enums/product-status.enum';
import { JdName } from 'src/app/shared/enums/jdName.enum';
import { formConstant } from '../../../../config';
import { IProductDetails } from '../../../modules/products/models/product-details.interface';
import { CreationType } from 'src/app/shared/enums/creation-type.enum';
import { UserService } from 'src/app/core/services/user.service';
import { RegistrationService } from 'src/app/modules/customer-registration/services/registration.service';
import { debounceTime } from 'rxjs/operators';
import { ProductStatusPipe } from '../../pipes/product-status.pipe';
import { environment } from 'src/environments/environment';
import { FilterPipe } from 'src/app/shared/pipes/filter.pipe';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';

import { ProductsService } from 'src/app/modules/products/services/products.service';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ApplyHsCodeComponent } from 'src/app/shared/components/apply-hs-code/apply-hs-code.component';
import { ShopCategoriesService } from 'src/app/modules/customer-management/services/shop-categories.service';
import { HsCodesService } from 'src/app/modules/hs-codes/services/hs-code-services';
import { CompanyMarketplaceService } from 'src/app/core/services/company-marketplaces.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import * as _ from 'lodash';

@Component({
    selector: 'eci-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
    providers: [DatePipe, ProductStatusPipe],
})
export class ProductDetailsComponent {
    crateProductbtn: boolean = false;
    header: string = 'Product Details';
    marginTop: string = '80px';
    createMode: boolean = false;
    submitted = false;
    info: any;
    availableTypes: IAvailableTypes;
    idInArray: any[] = [];
    form: FormGroup;
    isDialog: boolean = true;

    countryCode: string;
    companyCode: number;
    thisCompanyId: number;
    companyInfo: any[] = [];
    listOfCategories: any[] = []; // TO DO
    previousePageCategories: number = 0;
    totalPagesCategories: number = 1;
    searchCategory: FormControl = new FormControl('');
    searchkeysStatus: FormControl = new FormControl('');
    searchShop: FormControl = new FormControl('');

    listOfUsers: any[] = []; // TO DO
    previousePageUsers: number = 0;
    totalPagesUsers: number = 1;
    searchUser: FormControl = new FormControl('');
    isFedex = environment.isFedex;
    hsCodeData: any;
    isRemoveHsCode: boolean = false;
    applyHSCodeSuccess: any;
    isName: boolean = false;
    isSKU: boolean = false;
    catAdmin = true;
    catSystem = false;
    isCategoryChangeByAdmin = false;
    isJdPartner: boolean = false;
    listOfShops: any[] = [];
    @Input() allowAdd4Customer: boolean = false;
    @Input() isSaleOrder: boolean = false;
    @Input() isShipment: boolean = false;
    @Output() reloadData = new EventEmitter<any>();

    isMarketplaceSO = false;

    filterPipe = new FilterPipe();
    get keysStatus(): Array<string> {
        var keys = Object.keys(ProductStatus);
        return keys.slice(keys.length / 2);
    }

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ProductDetailsComponent>,
        private productsSvc: ProductsService,
        private categoriesSvc: CategoriesService,
        private notify: NotificationService,
        public userSvc: UserService,
        private regSvc: RegistrationService,
        public productStatusPipe: ProductStatusPipe,
        private categoriesService: ShopCategoriesService,
        private hsCodesService: HsCodesService,
        private dialog: MatDialog,
        private companyMarketplaceSvc: CompanyMarketplaceService,
        private localStorageSvc: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.getInfo();
        this.getList();
        this.getListOfShops();
    }
    getList(): void {
        this.hsCodesService.getListHsCode().subscribe((data) => {
            if (!data) return;
            if (this.info.hsCodeId) {
                this.isRemoveHsCode = true;
            } else {
                this.isRemoveHsCode = false;
            }
            this.hsCodeData = data;
        });
    }

    searchHsCode(): void {
        this.hsCodesService
            .getListHsCode(this.form.get('searchStr').value)
            .subscribe((data) => {
                if (!data) return;
                this.hsCodeData = data;
            });
    }
    getInfo(): void {
        this.route.data.subscribe((data) => {
            if (data['data']) {
                this.isDialog = false;
            }
            this.info = data['data'];
            if (!this.info) {
                this.createMode = true;
                this.info = {};
            }
            this.form = this.initializeForm(this.info);
            if (this.info?.hsCodes?.taricCode) {
                this.isRemoveHsCode = true;
            }

            this.getJDStatus();
            this.form.get('salePrice')?.valueChanges.subscribe((value) => {
                if (value < 0) {
                    this.form.get('salePrice').setValue(0);
                }
            });
        });

        this.getListOfCategories();

        this.searchCategory.valueChanges
            .pipe(debounceTime(500))
            .subscribe((value) => {
                this.previousePageCategories = 0;
                this.getListOfCategories();
                value = '';
            });
    }

    initializeForm(info: IProductDetails): FormGroup {
        if (info.name && info.sku) {
            this.isName = true;
            this.isSKU = true;
        } else {
            this.isName = false;
            this.isSKU = false;
        }
        //,Validators.pattern('^[A-Za-z][A-Za-z0-9_ ]{0,500}$')
        return this.formBuilder.group({
            status: [
                { disabled: !this.isAdmin, value: ProductStatus[info?.status] },
            ],
            // name: [{ disabled: false, value: info?.name || '' }, this.isMarketplaceSO ? [Validators.required] : []],
            name: [
                { disabled: false, value: info?.name || '' },
                [Validators.required],
            ],
            categoryId: [{ disabled: !this.isAdmin, value: info?.categoryId }],
            sku: [
                {
                    disabled: !this.hideUser && !this.allowAdd4Customer,
                    value: info?.sku,
                },
                [Validators.required],
            ],
            weight: [
                {
                    disabled: !this.hideUser && !this.allowAdd4Customer,
                    value: info?.weight,
                },
            ],
            volume: [
                {
                    disabled: !this.hideUser && !this.allowAdd4Customer,
                    value: info?.volume,
                },
            ],
            eanUpc: [
                {
                    disabled: !this.hideUser && !this.allowAdd4Customer,
                    value: info?.eanUpc || '',
                },
            ],
            // costPriceUnit: [{ disabled: false, value: info?.costPriceUnit }, this.isMarketplaceSO ? [Validators.required] : [ Validators.pattern("^[0-9]+\.?[0-9]*$|^$")]],
            costPriceUnit: [
                { disabled: false, value: info?.costPriceUnit },
                [
                    Validators.required,
                    Validators.pattern('^[0-9]+.?[0-9]*$|^$'),
                ],
            ],
            // salePrice: [{ disabled: false, value: info?.salePrice }, this.isMarketplaceSO ? [Validators.required] : []],
            salePrice: [
                { disabled: false, value: info?.salePrice },
                [
                    Validators.required,
                    Validators.pattern('^[0-9]+.?[0-9]*$|^$'),
                ],
            ],
            shopCategory: [{ disabled: false }],
            shopSubCategory: [{ disabled: false }],
            companyId: [
                {
                    disabled: false,
                    value: info?.companyId
                        ? info?.companyId
                        : this.userSvc.userStorage?.company?.id ||
                          this.companyCode,
                },
                [Validators.required],
            ],
            hsCodeId: [
                { disabled: !this.isFedex, value: info?.hsCodes?.taricCode },
            ],
            searchStr: [{ disabled: !this.isFedex, value: '' }],
            // shopId: [{ disabled: false, value: info?.companyMarketplace?.shopId }, this.isMarketplaceSO ? [Validators.required] : []],
            shopId: [
                { disabled: false, value: info?.companyMarketplace?.shopId },
                this.isShipment === false ? [Validators.required] : [],
            ],
            currency: [
                { disabled: false, value: info?.currency },
                [
                    Validators.minLength(formConstant.currencyLength),
                    Validators.maxLength(formConstant.currencyLength),
                    Validators.pattern(/[A-Za-z]/),
                ],
            ],
            createdBy: [{ disabled: false, value: info?.createdBy }],
            updatedBy: [{ disabled: false, value: info?.updatedBy }],
        });
    }

    get f(): { [key: string]: AbstractControl } {
        return this.form.controls;
    }

    save(): void {
        this.submitted = true;
        this.crateProductbtn = true;
        if (this.form.invalid) {
            this.notify.showError('Not all fields are filled');
            this.form.markAllAsTouched();
            this.crateProductbtn = false;
            return;
        }

        const data = {
            ...this.prepareBody,
            costPriceUnit:
                this.prepareBody.costPriceUnit != null
                    ? Number(this.prepareBody.costPriceUnit)
                    : null,
            salePrice: Number(this.prepareBody.salePrice),
            isCategoryChangeByAdmin: this.isCategoryChangeByAdmin,
            categoryBy: this.form.get('categoryId').value
                ? this.catAdmin
                : this.catSystem,
            type: CreationType.Web,
            userRole: this.localStorageSvc.get('user').role,
            hsCodeId: this.form.get('hsCodeId').value,
            isSaleOrder: this.isSaleOrder,
        };
        if (this.createMode) {
            this.productsSvc.createProduct([data]).subscribe(
                (value) => {
                    if (value?.success === 1) {
                        this.notify.showSuccessMessage('Created successfully');
                        this.exit();
                    } else {
                        if (value.failedReason[0]) {
                            this.notify.showError(value.failedReason[0].reason);
                        } else {
                            this.notify.showError('Failed to create product');
                        }
                    }
                },
                (err) => {
                    if (err.error.message === 'PRODUCT.ERRORS.EXIST') {
                        this.notify.showError('Product already exist');
                    }
                }
            );
        } else {
            this.productsSvc.updateProduct(this.info?.id, data).subscribe(
                (value) => {
                    if (value[0] !== undefined) {
                        this.notify.showError(value[0]);
                    } else {
                        this.notify.showSuccessMessage('Updated successfully');
                        this.exit();
                    }
                },
                (err) => {
                    if (
                        err.error.message === 'PRODUCT.ERRORS.EAN/UPC_NOT_FOUND'
                    ) {
                        this.notify.showError('EAN/UPC is Required');
                    }
                }
            );
        }
    }

    getListOfCategories(
        page: number = this.previousePageCategories + 1,
        q: string = this.searchCategory?.value || '',
        limit: number = 400
    ): void {
        this.categoriesSvc
            .getCategories(page, limit, '', q, '', '', '', false)
            .subscribe((data) => {
                if (!data) return;

                data.items = _.filter(data.items, (item) => {
                    if (item.originalName !== null) return item;
                });
                if (data.meta.currentPage > 1)
                    this.listOfCategories = [
                        ...this.listOfCategories,
                        ...data.items,
                    ];
                else this.listOfCategories = data.items;

                this.previousePageCategories = data.meta.currentPage;
                this.totalPagesCategories = data.meta.totalPages;
            });
    }

    closeListOfCategories(): void {
        this.searchCategory.setValue('');
    }

    getListOfUsers(
        page: number = this.previousePageUsers + 1,
        q: string = this.searchUser?.value || '',
        limit: number = 20
    ): void {
        this.regSvc.getCompany(page, limit, q).subscribe((data) => {
            if (!data) return;

            if (data.meta.currentPage > 1)
                this.listOfUsers = [...this.listOfUsers, ...data.items];
            else this.listOfUsers = data.items;

            this.previousePageUsers = data.meta.currentPage;
            this.totalPagesUsers = data.meta.totalPages;
        });
    }

    exit(): void {
        if (!this.isDialog) {
            history.back();
        } else this.dialogRef.close();
    }
    applyHsCode(): void {
        const ids = this.info.id;
        const dialogRef = this.dialog.open(ApplyHsCodeComponent, {
            data: ids,
            maxHeight: '90vh',
            height: '90vh',
            width: '90vw',
        });
        const info = ids;
        dialogRef.componentInstance.info = info;
        dialogRef.afterClosed().subscribe((response) => {
            if (!ids) {
                this.form.get('hsCodeId').setValue(response.applyHSCodeSuccess);
                return;
            }
            if (response) {
                this.idInArray.push(ids);
                const obj = {
                    productId: this.idInArray,
                    hsCodeId: response.hscodeId,
                    userRole: this.localStorageSvc.get('user').role,
                };
                this.form.get('hsCodeId').setValue(response.applyHSCodeSuccess);
                this.isRemoveHsCode = true;
                this.hsCodesService.applyHsCodes(obj).subscribe((data) => {
                    if (!data) {
                        this.notify.showError('Something went wrong !!!');
                        return;
                    }
                    if (data) {
                        let status = ProductStatus.Active;
                        this.productsSvc
                            .changeStatus(ids, status)
                            .subscribe((response) => {
                                if (response) {
                                    this.notify.showSuccessMessage(
                                        'Hscode applied successfully'
                                    );
                                    this.reloadData.emit(data);
                                }
                            });
                    } else {
                        this.notify.showSuccessMessage('Something went wrong');
                    }
                });
            }
        });
    }

    removeHsCode(): void {
        this.form.get('hsCodeId').setValue('');
        this.idInArray.push(this.info.id);
        const obj = {
            productId: this.idInArray,
            hsCodeId: null,
            status: false,
            userRole: this.localStorageSvc.get('user').role,
        };
        this.hsCodesService.applyHsCodes(obj).subscribe((data) => {
            if (!data) {
                this.notify.showError('Something went wrong !!!');
                return;
            }
            this.notify.showSuccessMessage('Hs Codes Removed Successfully');
            this.reloadData.emit(data);
        });
    }

    get prepareBody(): IProductDetails {
        return {
            ...this.info,
            ...this.form?.value,
            costPriceUnit:
                this.form.controls.costPriceUnit.value !== null
                    ? Number(this.form.controls.costPriceUnit.value)
                    : null,
            salePrice: Number(this.form.controls.salePrice.value || 0),
            description: this.form.controls.name.value,
            companyId: this.companyCode,
            status: !!this.form.controls.categoryId.value
                ? ProductStatus[this.form.get('status')?.value]
                : ProductStatus.NotActive,
        };
    }

    changeCostPriceUnit(fieldName): void {
        if (this.form.get(fieldName)?.value !== '')
            this.form
                .get(fieldName)
                .setValue(
                    parseFloat(this.form.get(fieldName)?.value || 0).toFixed(2)
                );
    }

    getListOfShops(): void {
        this.companyMarketplaceSvc
            .getListOfShops(
                !this.createMode ? this.info?.companyId : this.companyCode
            )
            .subscribe((res) => {
                this.listOfShops = res ? res : [];
            });
    }

    getJDStatus(): boolean {
        // console.log('infooooooooooooo');
        // console.log(this.info?.companyId);
        // console.log(this.userSvc.userStorage?.company?.id);
        // console.log(this.companyCode);
        this.thisCompanyId = this?.companyCode
            ? this.companyCode
            : this.info?.companyId
            ? this.info?.companyId
            : this.userSvc.userStorage?.company?.id;
        this.regSvc.getCompanyById(this.thisCompanyId).subscribe((data) => {
            // console.log(data);
            this.companyInfo = data;
            if (data?.users.length) {
                this.isJdPartner = data.users[0].partner === JdName.NAME;
            }
            this.updateEanUpcValidation();
            return this.isJdPartner;
        });
        this.updateEanUpcValidation();
        return this.isJdPartner;
    }

    updateEanUpcValidation(): void {
        const eanUpcControl = this.form.get('eanUpc');
        if (eanUpcControl) {
            if (this.isJdPartner) {
                eanUpcControl.setValidators([Validators.required]);
            } else {
                eanUpcControl.clearValidators();
            }
            eanUpcControl.updateValueAndValidity();
        }
    }

    get hideUser(): boolean {
        return this.isAdmin;
    }

    get isAdmin(): boolean {
        return this.userSvc.isAdmin;
    }

    get listFilteredShops(): any {
        if (!this.listOfShops) {
            return [];
        }
        return this.filterPipe.transform(
            this.listOfShops,
            this.searchShop.value,
            true
        );
    }

    public validateKey(event: KeyboardEvent): boolean {
        return !!event.key.match(/[a-zA-Z]/);
    }
}

