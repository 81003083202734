import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includeFilter',
    pure: false
})
export class IncludeFilterPipe implements PipeTransform {

    public transform(items: any[], searchText: string, fieldName?: string): any {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        return items.filter((item) => {
            if (fieldName) {
                return item?.[fieldName]?.toLowerCase().includes(searchText?.toLowerCase());
            }
            if (item.companyName) {
                return item.companyName?.toLowerCase().includes(searchText?.toLowerCase());
            } else if (item.name) {
                return item.name?.toLowerCase().includes(searchText?.toLowerCase());
            } else if (item.description) {
                return item.description?.toLowerCase().includes(searchText?.toLowerCase());
            } else if (item.product?.name) {
                return item.product?.name?.toLowerCase().includes(searchText?.toLowerCase());
            } else if (item.city) {
                return item.city?.toLowerCase().includes(searchText?.toLowerCase());
            } else if (item.unitOfMeasurement) {
                return item.unitOfMeasurement?.toLowerCase().includes(searchText?.toLowerCase());
            }
            return false;
        });
    }
}
