import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as moment from 'moment';

import {Moment} from 'moment';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'eci-month-datepicker',
    templateUrl: './month-datepicker.component.html',
    styleUrls: ['./month-datepicker.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    encapsulation: ViewEncapsulation.None,
})
export class MonthDatepickerComponent implements OnInit, OnChanges {

    @Input() date: Moment = null;
    @Output() onDateChange = new EventEmitter(true);
    data: FormControl;

    constructor() {
    }

    ngOnInit(): void {
        this.data = new FormControl(this.date);
        this.data.valueChanges.subscribe(d => {
            console.log(d);
            this.onDateChange.emit(d);
        });
    }

    selectMonth(normalizedMonthAndYear: any, datepicker: MatDatepicker<any>) {
        const ctrlValue = this.data.value ? this.data.value : moment();
        ctrlValue.month(normalizedMonthAndYear.month());
        ctrlValue.year(normalizedMonthAndYear.year());
        this.data.setValue(ctrlValue);
        datepicker.close();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        console.log(this.date);
        if (changes.date) {
            this.data?.setValue(changes.date.currentValue);
        }
    }

}
