import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { EmpactShopService } from './empact-shop.service';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class UserService extends ApiService {
    user$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public isEmpactShopLoggedIn = new BehaviorSubject<any>(null);
    get userId(): number {
        const user = this.user$.getValue();
        return user && user.id;
    }

    constructor(
        public http: HttpClient,
        private router: Router,
        private storageService: LocalStorageService,
        private shopService: EmpactShopService,
        private localStorageSvc: LocalStorageService,
    ) {
        super(http, storageService);
    }

    get user(): any {
        return this.user$.getValue();
    }

    logOut(): void { //Promise<any> {
        this.resetAfterLogout();
    }

    resetAfterLogout(): void {
        this.storageService.clear();
        sessionStorage.clear();
        this.user$.next(null);
        this.router.navigate([`/ecommerce/${this.shopService.shopId}/products`]);
    }

    updateUser(id: number, body): Observable<any> {
        return this.requestEcommerce({
            method: 'PUT',
            path: `users/${id}`,
            body: body
        });
    }

    public getUser(id: number): Observable<any> {
        return this.requestEcommerce({
            method: 'GET',
            path: `users/${id}`,
        }).pipe(map((data) => {
            this.user$.next(data);
            this.localStorageSvc.set('empactUser', JSON.stringify(data));
            return data;
        }));
    }

    updateUserSelf(body): Observable<any> {
        return this.requestEcommerce({
            method: 'PUT',
            path: `users`,
            body: body
        });
    }

    isPasswordValid(body): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `users/password/valid`,
            body: body
        });
    }

    getAll(page: number = 1, limit: number = 100, q: string = '', orderField: string = '', orderDirection: string = ''): Observable<any> {
        return this.requestEcommerce({
            method: 'GET',
            path: `users`,
            query: {
                q: q,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection
            }
        });
    }

    public register(body: any): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `users/registration`,
            body: {...body, companyId : this.shopService.companyId},
        });
    }

    updatePassword(body: any): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `users/update-password`,
            body
        });
    }

    emailCheck(email: string): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `users/check-registration-email/${email}`
        });
    }

    resendRegistrationLink(id: any): Observable<any> {
        return this.requestEcommerce({
            method: 'POST',
            path: `users/resend-registration-email/${id}`,
        });
    }

    forgotPassword(passwordData): Observable<any> {
        return this.requestEcommerce({
            path: `users/send-reset-password-link`,
            method: 'POST',
            body: { ...passwordData, shopId: this.shopService.shopId },
        });
    }
}
