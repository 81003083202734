import {Pipe, PipeTransform} from '@angular/core';
import {EPaymentsType} from '../payments-type.enum';

@Pipe({
    name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {

    transform(value: EPaymentsType, args: any): string {
        // Access additional arguments from the 'args' parameter
        const { description } = args;
        switch (value) {
            case EPaymentsType.Registration: return 'Registration';
            // case EPaymentsType.RegistrationPartner: return 'Registration partner';
            case EPaymentsType.Monthly: return 'Monthly';
            case EPaymentsType.VAT_COM: return 'VAT & Com';
            case EPaymentsType.Other: return description;
            case EPaymentsType.Legacy: return 'Legacy';
        }
    }

}
