import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { PalletService } from '../../../../services/pallet.service';
import { IPalette } from '../../../../models/palette.interface';
import { ProductAllocation, ProductAllocationCollapsed } from '../../../../models/product-allocation.interface';

@Component({
  selector: 'eci-pallet-details-table',
  templateUrl: './pallet-details-table.component.html',
  styleUrls: ['./pallet-details-table.component.scss']
})
export class PalletDetailsTableComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private palletSvc: PalletService
  ) { }

  @Input() summaryPage = false;
  @Input() data: ProductAllocation[];
  @Input() palettes: IPalette[];
  @Input() palletsArray: any;
  @Input() cartonsArray: any;
  @Input() weightUnits: IConstantItem[];
  @Input() currentWeightType: string;
  @Input() currentMeasuremenrType: string;
  @Input() isOcean: boolean = false;
  @Input() packingType;
  @Input() isCreateMode: boolean;
  @Input() info: any;
  @Input() isEditable: boolean;

  @Input() denominators: {
    airDenominator,
    oceanDenominator
  } = {
    airDenominator: 5000,
    oceanDenominator: 1000000
  }

  @Output() dataChange: EventEmitter<IPalette[]> = new EventEmitter<IPalette[]>();

  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'pallets': this.rows });

  dataSource;
  displayedColumns: string[] = [
    'packingTypeText',
    'packingQuantity',
    'length',
    'width',
    'height',
    'units',
    'unitsOfMeasure',
    'grossWeight',
    'volume'
  ];

  ngOnInit(): void {
    if (this.info?.packageDetails) {
      this.packingType = this.info.packageDetails?.packingType;
      this.cartonsArray = this.cartonsArray ? this.cartonsArray : this.info.packageDetails?.cartons?.cartons;
      this.palletsArray = this.info.packageDetails?.palets?.palets;
      this.palettes = this.info.packageDetails?.pallets;
    }
    this.form.valueChanges.subscribe(data => {
      this.dataChange.emit(data['pallets']);
    })
    this.setData();

    this.palletSvc.paletColUpdateRecord.subscribe((value: any)=>{
      if(value !== null){
        this.updateRecords(value.productAllocation, value.pallets);
      }
    });
  }

  public updateRecords(items, _palettes) {
    this.palettes = _palettes as IPalette[];
    this.clearFormArray(this.form.get('pallets') as FormArray);
    this.data = items;
    this.setData();
  }
  updateData(obj){
    this.palettes = obj.palets as IPalette[];
    this.clearFormArray(this.form.get('pallets') as FormArray);
    this.data = obj.ProductAllocation;;
    this.packingType=obj.packingType;
    this.palletsArray= obj.palletsArray;
    this.cartonsArray= obj.cartonsArray;
    this.setData();
  }
  setData() {
    if (this.packingType == 'Pallets') {
     this.setPalletsData();
    } else if (this.packingType == 'SingleCarton'){
     this.setSingleCartonData();
    }
    else{
      this.setPalletsData();
      this.setSingleCartonData();
    }
  }

  setPalletsData(){
    for (var i = 0; i < this.palletsArray?.length; i++) {
      var total_qty = 0;
      this.data.filter(x=>x.pallets!=null).forEach((element: ProductAllocation) => {
        if (element.pallets == i) {
          total_qty += Number(element.allocated);
        }
      });
      const palet = this.palettes.filter((data) => (Number(data.palletCartonIndex) == i));
      if (palet[0]) {
        this.addRowForm(total_qty, i, 'Pallets',palet[0]);
      }
      else {
        this.addRowForm(total_qty, i,'Pallets');
      }
    }
  }

setSingleCartonData(){
  for (var i = 0; i < this.cartonsArray?.length; i++) {
    var total_qty = 0;
    this.data.filter(x=>x.pallets==null).forEach((element: ProductAllocation) => {
      if (element.cartons == i) {
        total_qty += Number(element.allocated);
      }
    });
    const crton = this.palettes.filter((data) => data.palletCartonIndex == i);
    if (crton[0]) {
      this.addRowForm(total_qty, i, 'SingleCarton',crton[0]);
    } else {
      this.addRowForm(total_qty, i,'SingleCarton');
    }
  }
}
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  addRowForm(total_qty, i, packingType='',palette: IPalette = {}) {
    const row = this.fb.group({
      'packingType': [{disabled: !this.isCreateMode, value: packingType == 'SingleCarton' ? 1 : 2}, [Validators.required]],
      'packingTypeText': [{disabled: !this.isCreateMode, value: packingType === 'SingleCarton' ? `Single Carton ${i + 1}` : `Pallets ${i + 1}`}, [Validators.required]],
      'palletCartonIndex': [{disabled: !this.isCreateMode, value: i}, [Validators.required]],
      'packingQuantity': [{disabled: !this.isCreateMode, value: total_qty}, [Validators.required]],
      'length': [{disabled: !this.isCreateMode, value: palette.length ?? 0}, [Validators.required]],
      'width': [{disabled: !this.isCreateMode, value: palette.width ?? 0}, [Validators.required]],
      'height': [{disabled: !this.isCreateMode, value: palette.height ?? 0}, [Validators.required]],
      'units': [{disabled: !this.isCreateMode, value: packingType == 'SingleCarton' ? 1 : this.palletsArray[i]?.cartonNumbers?.length}, [Validators.required]],
      'unitsOfMeasure': [{disabled: !this.isCreateMode, value: palette.unitsOfMeasure ?? this.weightUnits[0].id}, [Validators.required]],
      'grossWeight': [{disabled: !this.isCreateMode, value: palette.grossWeight ?? 0}, [Validators.required]],
      'volume': [{disabled: !this.isCreateMode, value: palette.volume ?? 0}, [Validators.required]],
    });
    this.rows.push(row);
    this.updateView();
  }

  updateView() {
    this.dataSource = [...this.rows.controls];
  }

  changeVolume(el, index): void {
    if (!el) return;
    const amountOfUnits = ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get('units');
    this.rows.value[index].volume = amountOfUnits ? ((this.rows.value[index].height * this.rows.value[index].width * this.rows.value[index].length) / (this.isOcean ? this.denominators.oceanDenominator : this.denominators.airDenominator)) *  amountOfUnits.value : 0;
    ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get('volume').setValue(parseFloat(this.rows.value[index].volume.toFixed(3)||0));
    el.volume = parseFloat((amountOfUnits ? ((el.height * el.width * el.length) / (this.isOcean ? this.denominators.oceanDenominator : this.denominators.airDenominator)) * amountOfUnits.value : 0).toFixed(3))||0;
  }

  changeTo0(index, field: string): void {
    const el = ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get(field).value;
    el < 0 ? ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get(field).setValue(0) : '';
  }
}
