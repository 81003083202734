import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatColumnDef, MatTable } from '@angular/material/table';

@Component({
    selector: 'eci-table-checkbox',
    templateUrl: './table-checkbox.component.html',
    styleUrls: ['./table-checkbox.component.scss'],
})
export class TableCheckboxComponent implements OnInit {
    @Input() public dataSource = [];
    @Input() public selection = new SelectionModel<any>(true, []);
    @Output() public selectionChange: EventEmitter<SelectionModel<any>> =
        new EventEmitter<SelectionModel<any>>(null);

    @ViewChildren(MatColumnDef)
    public cols: QueryList<MatColumnDef>;

    constructor(private table: MatTable<any>, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.cdr.detectChanges();
        this.cols.forEach((col: MatColumnDef) => {
            this.table.addColumnDef(col);
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected(): boolean {
    
        const selectedItems = this.selection.selected;

        const numRows = selectedItems.filter((obj, index) => {
            return this.dataSource.findIndex((o) => obj.id == o.id) !== -1;
        })?.length;
       
        return this.dataSource?.length === numRows;
    }

    isItemsChecked(element: any) {
        const selectedItems = this.selection.selected;
        return selectedItems.filter((obj, index) => obj.id==element.id)?.length>0;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle(event: MatCheckboxChange): void {
        if (event.checked) {
            const items = [...this.selection.selected, ...this.dataSource];
            const selectedItem = items.filter((obj, index) => {
                return index === items.findIndex((o) => obj.id == o.id);
            });
            this.selection.select(...selectedItem);
            this.selectionChange.emit(this.selection);
        } else {
            
            const items = this.selection.selected;
            const selectedItem = items.filter((obj) => {
                return this.dataSource.findIndex((o) => obj.id == o.id) == -1;
            });
            this.selection.clear();
            this.selection.select(...selectedItem);
            this.selectionChange.emit(this.selection);
        }
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(row?): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
            row.position + 1
        }`;
    }
}



