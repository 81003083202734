import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductSearchType } from 'src/app/shared/enums/product-search-type.enum';
import { ApiService } from '../../../core/services/api.service';
import { ProductStatus } from 'src/app/shared/enums/product-status.enum';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { UploadFileService } from 'src/app/core/services/upload-file.service';

@Injectable({
    providedIn: 'root',
})
export class ProductsService extends ApiService {
    public checkUrlForPopup: boolean = false;
    public filters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public amountProductsWithoutCategory$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public totalProductCategory = 0;

    constructor(
        private userSvc: UserService,
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private uploadFileSvc: UploadFileService
    ) {
        super(http, storageBrowser);
    }

    getAllShopIds(q: string = '', companyId: any = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: 'products/get-shop-id',
            query: {
                q: q,
                companyId: companyId
            }
        });
    }

    getProducts(page: number = 1, limit: number = 100, q: string = '', orderField: string = '', orderDirection: string = '', companyId: number, shopId: string, type: ProductSearchType, status?: ProductStatus): Observable<any> {
        return this.request({
            method: 'GET',
            path: `products`,
            query: {
                q: q,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection,
                companyId: companyId ? companyId : '',
                type,
                status: status ? status : '',
                shopId: !!shopId ? shopId : '',
            }
        });
    }

    filterShopId(page: number = 1, limit: number = 100, q: string = '', orderField: string = '', orderDirection: string = '', companyId: string, type: ProductSearchType, shopId: string): Observable<any> {
        return this.request({
            method: 'GET',
            path: `products/filterShopId`,
            query: {
                q: q,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection,
                companyId: companyId,
                type,
                shopId: shopId ? shopId : ''
            }
        })
    }
    getProductsDataCount(myCount) {
        this.totalProductCategory = myCount;
    }
    getProductById(id: number): Observable<any> {
        return this.request({
            path: `products/${id}`,
            method: 'GET',
        });
    }

    getAvailableCategories(page: number = 1, limit: number = 1000, q: string = ''): Observable<any> {
        return this.request({
            path: `categories`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q
            }
        })
    }

    updateProduct(id, body): Observable<any> {
        return this.request({
            path: `products/${id}`,
            method: 'PUT',
            body: body
        }).pipe(map(value => {
            this.updateAmountProducts();
            return value;
        }));
    }

    changeStatus(id, status, isFedex?: any): Observable<any> {
        return this.request({
            path: `products/update-status/${id}`,
            method: 'PUT',
            body: {
                status,
                isFedex: isFedex
            }
        });
    }

    createProduct(body): Observable<any> {
        return this.request({
            path: `products`,
            method: 'POST',
            body: body
        }).pipe(map(value => {
            this.updateAmountProducts();
            return value;
        }));
    }

    productsUpdateCategory(body): Observable<any> {
        return this.request({
            path: `products/update-category`,
            method: 'POST',
            body: body
        }).pipe(map(value => {
            this.updateAmountProducts();
            return value;
        }));
    }

    productsUpdateCategory4All(body): Observable<any> {
        return this.request({
            path: `products/update-category-all`,
            method: 'POST',
            body: body
        }).pipe(map(value => {
            this.updateAmountProducts();
            return value;
        }));
    }

    productsUpdateCompany(body): Observable<any> {
        return this.request({
            path: `products/update-company`,
            method: 'POST',
            body: body
        }).pipe(map(value => {
            this.updateAmountProducts();
            return value;
        }));
    }

    productsUpdateCompany4All(body): Observable<any> {
        return this.request({
            path: `products/update-company-all`,
            method: 'POST',
            body: body
        }).pipe(map(value => {
            return value;
        }));
    }

    amount(query?): Observable<any> {
        return this.request({
            path: `products/amount`,
            method: 'GET',
            query,
        })
    }

    productsUpdateStatus(body): Observable<any> {
        return this.request({
            path: `products/update-status`,
            method: 'POST',
            body: body
        })
    }

    productsUpdateStatus4Filtered(body): Observable<any> {
        return this.request({
            path: `products/update-status-all`,
            method: 'POST',
            body: body
        })
    }

    delete(ids): Observable<any> {
        return this.request({
            path: `products`,
            method: 'DELETE',
            body: {
                ids
            }
        }).pipe(map(value => {
            this.updateAmountProducts();
            return value;
        }));
    }

    downloadExcelExample(): Observable<any> {
        const headers = new HttpHeaders();
        headers.append('Accept', '*')
            .append('responseType', 'application/octet-stream');

        const fileUrl = this.userSvc.isAdmin ? `/assets/xlsx/product_example_admin.xlsx` : `/assets/xlsx/product_example_customer.xlsx`;

        return this.http.get(fileUrl, { headers: headers, responseType: 'blob' });
    }

    public downloadProduct(page: number = 1, limit: number = 100, q: string = '', orderField: string = '', orderDirection: string = '', companyId: number, shopId: string, type: ProductSearchType, status?: ProductStatus): Observable<any> {
        return this.requestFile({
            path: `products/download-products`,
            method: 'POST',
            query: {
                q: q,
                limit: limit,
                page: page,
                orderField: orderField,
                orderDirection: orderDirection,
                companyId: companyId ? companyId : '',
                type,
                status: status ? status : '',
                shopId: !!shopId ? shopId : ''
            }
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `product${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    private updateAmountProducts(): void {
        this.amount().subscribe(value => {
            this.amountProductsWithoutCategory$.next(value.withoutCategories);
        });
    }
}
