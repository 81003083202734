import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {PageParams} from 'src/app/core/models/page-params.model';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from 'src/app/core/services/user.service';
import {FormControl} from '@angular/forms';
import {IAvailableTypes, IConstantItem} from 'src/app/core/models/available-types.interface';
import {ConstantService} from 'src/app/core/services/constant.service';
import {DatePipe} from '@angular/common';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NotificationService} from '../../../core/services/notification.service';
import {ConfirmPopupDialog} from '../../../shared/popups/confirm-popup/confirm-popup.component';
import {ConfirmAssignComponent} from '../../invoice-manager/components/confirm-assign/confirm-assign.component';
import {
    SpecificInvoiceListDialogComponent
} from '../../invoice-manager/components/specific-invoice-list-dialog/specific-invoice-list-dialog.component';
import {EAssignType} from '../../invoice-manager/editor/editor.component';
import {InvoiceManagementService} from '../../invoice-manager/invoice-management.service';
import {IOrderListItem} from '../../sales-order-management/models/order-list-item';
import {debounceTime, finalize} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ConsolidateShipmentService} from '../../../core/services/consolidate-shipments.service';
import { BehaviorSubject } from 'rxjs';
import { RegistrationService } from '../../customer-registration/services/registration.service';
import { SOTypeConsolSelectPopupPopupComponent } from './so-type-consol-select-popup/so-type-consol-select-popup.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'eci-consolidate-shipments-management',
    templateUrl: './consolidate-shipments-management.component.html',
    styleUrls: ['./consolidate-shipments-management.component.scss'],
    providers: [DatePipe]
})
export class ConsolidateShipmentsManagementComponent implements OnInit {

    displayedColumns: string[] = [
        'lastMile',
        'dateCreated',
        'consolId',
        'type',
        'subtype',
        'batch',
        'consoleType',
        'shippingDate',
        'destination',
        'shipped',
        'delivered',
        'consolidateWeight',
        'mawb',
        'ETAdate',
        'deliveryDate',
        'status',
        'lastMileCarrierName',
        'lastMileTrackingNumber',
        'lastMileStatus',
        'deliveredLastMiles',
        'Action'
    ];
    displayedColumnsAdmin: string[] = [
        'select',
        'lastMile',
        'dateCreated',
        'handled',
        'type',
        'subtype',
        'batch',
        'consoleType',
        'consolId',
        'customer',
        'shippingDate',
        'destination',
        'shipped',
        'delivered',
        'consolidateWeight',
        'mawb',
        'ETAdate',
        'deliveryDate',
        'status',
        'lastMileCarrierName',
        'lastMileTrackingNumber',
        'lastMileStatus',
        'deliveredLastMiles',
        'Action'
    ];
    dataSource;
    selectedCountry: number = 1;
    public amountNotHandledConsolShipment$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    pageParams: PageParams = new PageParams();
    availableTypes: IAvailableTypes;

    ordersList: IOrderListItem[];

    showLoader: boolean = false;

    //#region Search params

    fromData: FormControl = new FormControl('');
    toData: FormControl = new FormControl('');
    searchStr: FormControl = new FormControl('');
    orderType: FormControl = new FormControl(null);
    orderStatus: FormControl = new FormControl(null);
    handleStatus: FormControl = new FormControl('All');
    //customerType: FormControl = new FormControl(null);
    searchCustomerName: FormControl = new FormControl('');
    customerName: FormControl = new FormControl('');
    currentSort: Sort;
    searchorderType: FormControl = new FormControl('');
    searchorderStatus: FormControl = new FormControl('');
    //searchcustomerType: FormControl = new FormControl('');
    searchStatus: FormControl = new FormControl('');
    deliveryFromDate: FormControl = new FormControl('');
    deliveryToDate: FormControl = new FormControl('');
    handleLastMile: FormControl = new FormControl('All');
    lastMileStatus: FormControl = new FormControl(null);

    listOfSelectedItems: any[] = [];
    companyList: any[];
    previousePageCustomer: number = 0;
    inValidDeliveryDate = false;
    inValidDate = false;
    //#endregion
    batchId: FormControl = new FormControl(null);
    searchBatchId: FormControl = new FormControl('');
    batchIdList = [];

    pageChanged(e: PageParams): void {
        this.pageParams.pageIndex = e.pageIndex;
        this.pageParams.pageSize = e.pageSize;
        this.userSvc.isAdmin || this.userSvc.isShipmentsAdmin || this.userSvc.isDelegatedAdmin ? this.getList(e.pageIndex + 1, e.pageSize, this.searchStr.value) : this.getList(e.pageIndex + 1, e.pageSize, this.searchStr.value, this.userSvc.company.id);

        // this.getList(e.pageIndex + 1, this.userSvc.company.id);
    }

    constructor(
        private dialog: MatDialog,
        public userSvc: UserService,
        private constSvc: ConstantService,
        private datePipe: DatePipe,
        private router: Router,
        private consolShipmentSvc: ConsolidateShipmentService,
        private notify: NotificationService,
        private regSvc: RegistrationService,
        private invoiceManagementService: InvoiceManagementService,
    ) {
    }
    @Output() syncWithAfterShip: EventEmitter<any> = new EventEmitter<any>(null);
    get orderTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.OrderType;
    }

    get orderStatuses(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.OrderStatus;
    }

    get companyType(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.CompanyType;
    }

    ngOnInit(): void {
        this.handleStatus.setValue(null);
        this.handleLastMile.setValue(null);
        if (this.userSvc.isAdmin || this.userSvc.isShipmentsAdmin || this.userSvc.isDelegatedAdmin) {
            this.getList(1, 100, '');
        } else {
            this.getList(1, 100, '', this.userSvc.company.id);
        }
        this.constSvc.availableTypes.subscribe(data => {
            this.availableTypes = data;
        });
        // this.constSvc.getAvailableTypes();

        this.searchStr.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.search();
        });

        this.getListCompanies();
        this.getBatchIdList();
        this.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.previousePageCustomer = 0;
            this.getListCompanies();
        });
    }
    getListCompanies(q: string = this.searchCustomerName.value): void {
        q = this.customerName.value ? this.searchCustomerName.value : q;
        this.regSvc.getCompanyListForSales(q).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }
    clearSelection() {
        this.customerName.reset();
        this.getListCompanies(this.searchCustomerName.value);
    }
    getList(
        page: number = 1,
        limit: number = 100,
        q: string = this.searchStr.value,
        companyId: number = this.customerName.value,
        orderField: string = this.currentSort?.active || '',
        orderDir: string = this.currentSort?.direction.toUpperCase() || '',
        batchIds: string = this.batchId.value || ''
    ): void {
        this.showLoader = true;
        this.consolShipmentSvc.getShipments(page, limit, q, companyId, this.prepareSearchParams, batchIds, orderField, orderDir).subscribe(data => {
            this.showLoader = false;
            if (!data) {
                return;
            }

            this.dataSource = data.items;
            this.ordersList = data.items;
            this.pageParams.pageSize = data.meta.itemsPerPage;
            this.pageParams.pageIndex = data.meta.currentPage - 1;
            this.pageParams.length = data.meta.totalItems;
            this.consolShipmentSvc.getAmountConsolHandled(false).subscribe(count => { sessionStorage.setItem('consol_count', count); });
            // sessionStorage.setItem('consol_count', data.meta.totalItems);
        });
    }

    getBatchIdList(partnerId = ''): void {
        this.userSvc.getBatchIdList(partnerId).subscribe(result => {
          this.batchIdList = result;
        });
      }
    

    navigateToSaleOrders(): void {
        const dialogRef = this.dialog.open(SOTypeConsolSelectPopupPopupComponent);
        dialogRef.componentInstance.selectedItems = this.listOfSelectedItems;
        // this.userSvc.isSaleOrderConsolPopupShowed = false;
        // if (this.userSvc.isIndVat) {
        //     this.router.navigateByUrl('/sales-order-management/indvat-sales-order-management');
        // } else {
        //     this.router.navigateByUrl('/sales-order-management');
        // }
    }

    search(): void {
        this.getList(1, 100, this.searchStr.value, this.userSvc.isAdmin || this.userSvc.isShipmentsAdmin || this.userSvc.isDelegatedAdmin ?  this.customerName.value : this.userSvc.company.id);
    }

    isDataNotCorrect(item: FormControl): boolean {
        return item.value === null;
    }

    get prepareSearchParams(): any {
        return {
            orderTypes: this.orderType.value ? this.orderType.value : '',
            //companyTypes: this.customerType.value ? this.customerType.value : '',
            orderStatuses: this.orderStatus.value ? this.orderStatus.value : '',
            handleStatus: (this.handleStatus.value !== null && this.handleStatus.value !== 'All') ? !!this.handleStatus.value : '',
            dateTo: this.toData.value && this.toData.value !== null ? this.datePipe.transform(this.toData.value, 'yyyy-MM-dd') : '',
            dateFrom: this.toData.value && this.fromData.value !== null ? this.datePipe.transform(this.fromData.value, 'yyyy-MM-dd') : '',
            deliveryDateTo: this.deliveryToDate.value && this.deliveryToDate.value !== null ? this.datePipe.transform(this.deliveryToDate.value, 'yyyy-MM-dd') : '',
            deliveryDateFrom: this.deliveryFromDate.value && this.deliveryFromDate.value !== null ? this.datePipe.transform(this.deliveryFromDate.value, 'yyyy-MM-dd') : '',
            handleLastMile: (this.handleLastMile.value !== null && this.handleLastMile.value !== 'All') ? !!this.handleLastMile.value : '',
            lastMileStatus: this.lastMileStatus.value? this.lastMileStatus.value : '',
        };
    }

    sortData(sort: Sort): void {
        const data = this.ordersList.slice();
        if (!sort.active || sort.direction === '') {
            this.ordersList = data;
            this.dataSource = new MatTableDataSource<IOrderListItem>(this.ordersList);
            return;
        }

        this.currentSort = sort;
        this.getList(this.pageParams?.pageIndex + 1, this.pageParams?.pageSize,
            this.searchStr.value || '', this.userSvc.isAdmin  || this.userSvc.isShipmentsAdmin || this.userSvc.isDelegatedAdmin ? '' : this.userSvc.company.id);
    }

    isAdmin() {
        return this.userSvc.isAdmin;
    }

    isShipmentsAdmin() {
        return this.userSvc.isShipmentsAdmin;
    }

    handleSwitch() {
        const ids = this.listOfSelectedItems.map(o => o.id);
        this.showLoader = true;
        this.consolShipmentSvc.switchHandled(ids).subscribe(data => {
            this.showLoader = false;
            this.listOfSelectedItems = [];
            this.notify.showSuccessMessage("Handled updated.");
            this.getList();
            // this.consolShipmentSvc.getAmountConsolHandled(false).subscribe(count => { this.amountNotHandledConsolShipment$.next(count); });
        });
    }

    handleSync(event) {
        this.showLoader = true;
        const ids = this.listOfSelectedItems.map(o => o.id);
        this.consolShipmentSvc.syncWithAfterShip(ids).subscribe(res => {
            this.getList();
            this.listOfSelectedItems = [];
            this.notify.showSuccessMessage('Synchronization with AfterShip complete successfully.');
        }, error => {
            this.showLoader = false;
        });
    }

    handleDelete() {
        const ids = this.listOfSelectedItems.map(o => o.id);
        if (ids && ids?.length > 0) {
            const dialogRef = this.dialog.open(ConfirmPopupDialog);
            dialogRef.componentInstance.message = 'Please confirm deleting';
            dialogRef.componentInstance.rightBtnText = 'Delete';
            dialogRef.afterClosed().subscribe(value => {
                if (!value) return;
                this.consolShipmentSvc.delete(ids).subscribe(() => {
                    this.notify.showSuccessMessage("Shipments deleted successfully");
                    this.listOfSelectedItems = [];
                    this.getList(1, 100, '');
                }, () => {
                    this.notify.showError("Something went wrong");
                })
            });
        } else {
            this.notify.showError("Please select any product!");
        }
    }

    createSpecificInvoice() {
        const dialogRef = this.dialog.open<SpecificInvoiceListDialogComponent>(SpecificInvoiceListDialogComponent, {
            maxHeight: '80vh',
            width: '890px',
            panelClass: 'inv-man-dialog'
        });
        dialogRef.afterClosed().subscribe(value => {
            if (!value) {
                return;
            }

            const selectedTemplates = value;

            console.log(this.listOfSelectedItems);

            const dialogRef = this.dialog.open<ConfirmAssignComponent>(ConfirmAssignComponent);
            dialogRef.componentInstance.type = EAssignType.ConsolShimpent;
            dialogRef.componentInstance.templateName = selectedTemplates.map(v => v.name)?.join(', ');
            dialogRef.componentInstance.selectedOrders = this.listOfSelectedItems;

            dialogRef.afterClosed().subscribe(value => {
                if (value) {
                    this.showLoader = true;
                    this.invoiceManagementService.assignTemplates(this.listOfSelectedItems.map(o => o.id), selectedTemplates.map(v => v.id), EAssignType.ConsolShimpent)
                        .pipe(finalize(() => {
                            this.showLoader = false;
                        }))
                        .subscribe(res => {
                            this.listOfSelectedItems = [];
                            this.notify.showSuccessMessage('Assigning complete successfully');
                        }, error => {
                            this.notify.showError(error?.error?.message ? error.error.message : 'Something wrong!');
                        });
                }
            })
        })
    }

    addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
        if (event.value === null) {
            this.inValidDeliveryDate = true;
        } else {
            this.inValidDeliveryDate = false;
        }
    }

    addEventDate(type: string, event: MatDatepickerInputEvent<Date>) {
        if (event.value === null) {
            this.inValidDate = true;
        } else {
            this.inValidDate = false;
        }
    }
}
