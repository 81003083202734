import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConsolidateShipmentService } from '../../core/services/consolidate-shipments.service';
import { OrderShipmentService } from '../../core/services/order.service';
import { IShopBase } from '../offline-shop-admin/models/empact-product-details.interface';
import { OfflineShopService } from '../offline-shop-admin/services/offline-shop.service';
import {PaymentsService} from '../payments/payments.service';
import {ProductsService} from '../products/services/products.service';
import {Subscription} from 'rxjs';
import {VbanAlertsService} from '../vban-alerts/services/vban-alerts.service';
import {OrdersService} from '../customers-order-management/services/orders.service';
import {HsCodesService} from '../hs-codes/services/hs-code-services';
import {Router} from '@angular/router';
import {HSCodeStatus} from 'src/app/shared/enums/hs-code-status.enum';
import {HSCodeConfirmComponent} from '../../shared/components/hscode-confirm/hscode-confirm.component';
import {TypeBusinesses} from 'src/app/shared/enums/type-business';
import {LocalStorageService} from 'src/app/core/services/local-storage.service';
import {NotificationService} from 'src/app/core/services/notification.service';
import {AuthService} from 'src/app/modules/auth/services/auth.service';
import { SaleOrderService } from '../sales-order-management/services/sale-order.service';
import {NavMenuService} from './nav-menu.service';


@Component({
    selector: 'eci-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit, OnDestroy {
    isFedex = environment.isFedex;
    clickedItem: string;
    timerSubscription: Subscription;
    badgeHandleShipmentSubs: Subscription;
    badgeHandleConsolShipmentSubs: Subscription;
    listOfShop: IShopBase[] = [];
    showPaymentAlertSubs: Subscription;

    get isAdmin(): boolean {
        if (!this.userSvc.user) return;
        return this.userSvc.user.role == Roles.Admin || this.userSvc.user.role == Roles.DelicateAdmin;
    }

    get isShipmentsAdmin(): boolean {
        if (!this.userSvc.user) return;
        return this.userSvc.user.role === Roles.ShipmentsAdmin;
    }

    get isAdminAuth(): boolean {
        if (!this.userSvc.user) return;
        return !!this.localStorageSvc.get('main_auth_token') ? true : false;
    }

    get shipmentCount(): any {
        return sessionStorage.getItem('shipment_count');
    }

    get consoleCount(): any {
        return sessionStorage.getItem('consol_count');
    }

    get isCustomer(): boolean {
        if (!this.userSvc.user) {
            return;
        }
        return this.userSvc.user.role !== Roles.Admin && this.userSvc.user.role !== Roles.DelicateAdmin && this.userSvc.user.role !== Roles.ShipmentsAdmin;
    }

    get isPartnerCustomer(): boolean {
        if (!this.userSvc.user) {
            return;
        }
        return (this.userSvc.user.role === Roles.Customer || this.userSvc.user.role === Roles.OfflineShopOwner
            || this.userSvc.user.role === Roles.IndVatAccount) && this.userSvc.user.parentId !== null;
    }

    get isOfflineShopOwner(): boolean {
        if (!this.userSvc.user) {
            return;
        }
        return this.userSvc.user.role === Roles.OfflineShopOwner || this.userSvc.user.role === Roles.DelegateOfflineShopOwner;
    }

    get isIndVat(): boolean {
        if (!this.userSvc.user) {
            return;
        }
        return this.userSvc.company.type === TypeBusinesses['Reporting Tool'];
        // return this.userSvc.user.role === Roles.IndVatAccount || this.userSvc.user.role === UserRoles.IND_VAT_ACCOUNT;
    }

    get companyId(): number {
        return this.userSvc.company.id;
    }

    get userId(): number {
        return this.userSvc.userId;
    }

    get userIdOrPartner(): number {
        return this.userSvc.user.role == Roles.DelegatePartnerAccount ? this.userSvc.user.parentId : this.userId;
    }

    get isDelegatePartnerAccount(): boolean {
        return this.userSvc.user.role == Roles.DelegatePartnerAccount ? true : false;
    }

    /*  get getWithoutCategoryCount(): number {
        return this.totalProductWithoutCategory;
      }*/

    get getNotDismissedCount(): number {
        return this.totalNotDismissedCount;
    }

    subscription: Subscription = new Subscription();
    showWarningProductLabel: boolean = false;
    totalProductWithoutCategory: number = 0;
    totalNotDismissedCount: number = 0;
    // unEditedShipmentCount: number = 0;
    // badgeHandleShipment: number = 0;
    // badgeHandleShipmentConsol: number = 0;
    customerId: number;

    // subscriptionAlert: Subscription = new Subscription();
    checkRoutItem = true;
    showPaymentAlert = false;
    isShopLegacyPayments = false;

    constructor(
        public userSvc: UserService,
        public dialogRef: MatDialogRef<NavMenuComponent>,
        public vbanAlertsService: VbanAlertsService,
        public productsSvc: ProductsService,
        public orderSvc: OrdersService,
        public consolidateShipmentService: ConsolidateShipmentService,
        public orderShipmentService: OrderShipmentService,
        public router: Router,
        private hsCodesService: HsCodesService,
        private dialog: MatDialog,
        private localStorageSvc: LocalStorageService,
        private authSvc: AuthService,
        private paymentsService: PaymentsService,
        private saleOrderSvc: SaleOrderService,
        public offlineShopService: OfflineShopService,
        private navMenuService: NavMenuService,
        private notify: NotificationService,
    ) {
    }

    // @Output() exitCustomerView: EventEmitter<any> = new EventEmitter<any>();

    isMenuVisible: boolean = true;
    version: string = environment.appVersion;

    admin: boolean = this.userSvc?.user?.role === Roles.Admin;

    getCounts(): void {
        this.consolidateShipmentService.getAmountConsolHandled(false).subscribe(count => { sessionStorage.setItem('consol_count', count); });
        this.orderShipmentService.getAmountHandled(false).subscribe(count => { sessionStorage.setItem('shipment_count', count); });
    }

    ngOnInit(): void {
        this.getCounts()
        this.hsCodePopup();
        this.getLegacyPayments();

        this.subscription.add(
            this.productsSvc.amount().subscribe(value => {
                this.showWarningProductLabel = value.withoutCategories > 0;
                this.totalProductWithoutCategory = value.withoutCategories;
            })
        );
        if (this.isAdmin) {
            this.showPaymentAlertSubs = this.paymentsService.showPaymentAlert$.subscribe(val => {
                this.showPaymentAlert = val;
            });
            this.paymentsService.getSummaryData().subscribe(res => {});
            this.subscription.add(
                this.vbanAlertsService.amountAlertsWithoutDismissed$.subscribe((value) => {
                    this.totalNotDismissedCount = value;
                })
            );
            this.vbanAlertsService.getWithoutDismissed();
        }

        // Not the best solution, but we don't have the websocket which could automatically update data
        if (this.isAdmin || this.isOfflineShopOwner) {
            this.saleOrderSvc.countNewSO().subscribe(res => {
                this.userSvc.newMarketplaceSOCount = res;
            });
            this.updateAmountWithoutHSCode();
        }
        this.getShopLink();
        this.subscription.add(this.offlineShopService.listOfShop$.subscribe((shops: IShopBase[]) => {
            this.listOfShop = shops;
        }));
    }

    private updateAmountWithoutHSCode(): void {
        // Not good solution, need add companyId to ecommerce product
        if (this.userSvc.isAdmin) {
            this.offlineShopService.updateAmountProducts();
        } else {
            const query = {};
            this.offlineShopService.getOfflineShop(this.userSvc.userStorage?.company?.id)
                .subscribe((shops) => {
                    if (shops && shops.length > 0) {
                        query['shopsId'] = shops.map((shop) => shop.shopId);
                    }
                }, () => {
                }, () => {
                    this.offlineShopService.updateAmountProducts(query);
                });
        }
    }

    toggle(): void {
        this.isMenuVisible = !this.isMenuVisible;
    }

    onClick(item: string): void {
        if (this.checkRoutItem || this.hsCodesService.checkRoutItem) {
            this.checkRoutItem = false;
            this.hsCodesService.checkRoutItem = false;
            this.hsCodePopup();
        }
        this.clickedItem = item;

    }

    hsCodePopup(): void {
        if (this.isFedex && this.isCustomer) {
            this.hsCodesService.hsCodeStatus(this.userSvc.userId).subscribe((result: any) => {
                if (result.isHSCodeConfirm == 0) {
                    this.checkRoutItem = true;
                }
                var str = this.router.url;
                var strArr = str.split('/');
                var url = strArr.slice(1, -1);
                if (('products/product-details' === url[0] + '/' + url[1])) {
                    this.productsSvc.checkUrlForPopup = true;
                }
                if (result.isHSCodeConfirm === HSCodeStatus.Inactive && !this.productsSvc.checkUrlForPopup && this.checkRoutItem) {
                    this.isHSCodeConfirm(result.products);
                }
            });
        }
        this.productsSvc.checkUrlForPopup = false;
    }

    public isHSCodeConfirm(_products) {
        const dialogRef = this.dialog.open(HSCodeConfirmComponent, {
            disableClose: true,
            maxHeight: '600px',
        });
        dialogRef.componentInstance.products = _products;
        dialogRef.afterClosed().subscribe(_result => {
            if (!_result) {
                this.checkRoutItem = true;
                this.router.navigateByUrl('/products');
            }
        });
    }

    setRedirectParams() {
        this.clickedItem = 'empact-shop'
    }

    ngOnDestroy(): void {
        this.badgeHandleShipmentSubs && this.badgeHandleShipmentSubs.unsubscribe();
        this.badgeHandleConsolShipmentSubs && this.badgeHandleConsolShipmentSubs.unsubscribe();
        this.showPaymentAlertSubs && this.showPaymentAlertSubs.unsubscribe();
    }

    getHeaderName() {
        return "Hello, "+ this.localStorageSvc.get('user').company.name;
        // if (this.localStorageSvc.get('user').role === Roles.PartnerAccount || this.localStorageSvc.get('user').role === Roles.DelegatePartnerAccount) {
        //     return 'EMPACT PARTNER TOOL';
        // } else {
        //     return this.isIndVat ? 'EMPACT Reporting Tool' : 'EMPACT SYSTEM';
        // }
    }

    clickExitCustomerView(): any {
        const admin_auth_token = this.localStorageSvc.get('main_auth_token');
        this.localStorageSvc.set('token', admin_auth_token);
        this.authSvc.getUserDetails().subscribe((res) => {
            this.userSvc.user$.next(res);
            this.localStorageSvc.set('user', JSON.stringify(this.userSvc.user));
            localStorage.removeItem('main_auth_token');
            localStorage.removeItem('partnerName');
            this.router.navigateByUrl('customers-management/search');
        });
    }

    get isPartnerAccount(): boolean {
        if (!this.userSvc.user) return;
        return this.userSvc.user.role == Roles.PartnerAccount || this.userSvc.user.role == Roles.DelegatePartnerAccount;
    }

    get isPartnerCustomerAccount(): boolean {
        if (!this.userSvc.user) return;
        return this.userSvc.user.parentId !== null;
    }

    getShopLink() {
        if (this.isOfflineShopOwner) {
            this.offlineShopService.getOfflineShop(this.companyId)
                .subscribe((shops) => {
                    if (!shops) {
                        return;
                    }
                    this.listOfShop = shops;
                });
        }
    }

    public getLink(shop): string {
        return `/ecommerce/${shop.shopId}/products`;
    }

    getLegacyPayments() {
        this.navMenuService.getPaymentHistory().subscribe(data => {
            this.isShopLegacyPayments = !!data.items.length;
        });
    }
}
