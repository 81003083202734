import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
    selector: 'eci-items-block',
    templateUrl: './items-block.component.html',
    styleUrls: ['./items-block.component.scss']
})
export class ItemsBlockComponent extends BaseElement implements OnChanges{

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 12;
        }
    }
}
