import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ICountry } from 'src/app/core/models/country.interface';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FilterPipe } from 'src/app/modules/reporting-tool/tax-reports/components/add-vat/add-vat.component';
import { SaleOrderService } from 'src/app/modules/sales-order-management/services/sale-order.service';

@Component({
    selector: 'eci-origin-country-popup',
    templateUrl: './origin-country-popup.component.html',
    styleUrls: ['./origin-country-popup.component.scss'],
})
export class OriginCountryPopupDialog {
    header: string = "Select changes";
    countries: ICountry[];
    country: string = undefined;
    deliveryDate: Date;
    orderIds: number[];

    searchOriginCountry: FormControl = new FormControl('');

    get allCountries(): ICountry[] {
        if (!this.searchOriginCountry.value) {
            return this.countries;
        }
       // return this.countries.filter(el => el.code === this.searchOriginCountry.value);
        return this.countries.filter(el => el.name.toLowerCase().startsWith(this.searchOriginCountry.value.toLowerCase()));
    }

    constructor(
        public dialogRef: MatDialogRef<OriginCountryPopupDialog>,
        private saleOrderService: SaleOrderService,
        private notify: NotificationService
    ) {

    }
    ngOnInit(): void {
        this.searchOriginCountry.valueChanges.subscribe(value => {
            this.allCountries;
        });
    }

    apply(): void {
        if (!this.country && !this.deliveryDate) {
            this.notify.showError('Any value is not selected');
            return;
        }

        this.saleOrderService.applyChanges({
            ids: this.orderIds,
            deliveryDate: this.deliveryDate,
            originCountry: this.country
        }).subscribe(() => {
            this.notify.showInfo('Saved successfully');
            this.dialogRef.close();
        })
    }
}
