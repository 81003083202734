import {DatePipe} from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Sort} from '@angular/material/sort';
import {IAvailableTypes} from '../../../../core/models/available-types.interface';
import {PageParams} from '../../../../core/models/page-params.model';

@Component({
    selector: 'eci-orders-list',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {


    @Input()
    set dataSource(data) {
        this._dataSource = data;
        if (this.isSelectedAll) {
            this._dataSource.map((e) => {
                e.isChecked = true;
                if (!this.selectedItems.find(si => si.id === e.id)){
                    this.selectedItems.push(e);
                    this.selectedItemsChange.emit(this.selectedItems);
                }
            })
        } else {
            if (this.selectedItems.length > 0) {
                this._dataSource.map((e) => {
                    if (this.selectedItems.find(si => si.id === e.id)){
                        e.isChecked = true;
                    }
                });
            }
        }
    };

    _dataSource;

    @Input() displayedColumns;
    @Input() availableTypes: IAvailableTypes;
    @Input() pageParams: PageParams = new PageParams();
    @Input() selectedItems: any[] = [];

    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() selectedItemsChange: EventEmitter<number[]> = new EventEmitter<number[]>(null);
    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);

    @ViewChild('table', { static: true, read: ElementRef }) table: ElementRef;
    isSelectedAll = false;

    constructor(
        private datePipe: DatePipe,
    ) {
    }

    get isAllSelected(): boolean {
        return this._dataSource?.filter(e => e.isChecked).length === this._dataSource?.length;
    }

    isSelected(element): boolean {
        return !!this.selectedItems.find(e => e.id === element.id);
    }

    ngOnInit(): void {
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    selectAllChanged(event: MatCheckboxChange): void {
        if (event.checked) {
            this._dataSource.map((e) => {
                e.isChecked = true;
                if (!this.selectedItems.find(si => si.id === e.id)){
                    this.selectedItems.push(e);
                }
            })
            this.selectedItemsChange.emit(this.selectedItems);
            this.isSelectedAll = true;
        } else {
            this._dataSource.map((e) => {e.isChecked = false;})
            this.selectedItems = [];
            this.selectedItemsChange.emit(this.selectedItems);
            this.isSelectedAll = false;
        }
    }

    selectionChanged(event: MatCheckboxChange, item): void {
        item.isChecked = event.checked;
        if (item.isChecked) {
            if (!this.selectedItems.find(si => si.id === item.id)){
                this.selectedItems.push(item);
                this.selectedItemsChange.emit(this.selectedItems);
            }
        } else {
            const index = this.selectedItems.findIndex(e => e.id === item.id);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
                this.selectedItemsChange.emit(this.selectedItems);
                this.isSelectedAll = false;
            }
        }
    }

    pageChanged(e): void {
        this.pageChange.emit(e);
        this.table.nativeElement.scrollIntoView();
    }

    dateConvert(date: any): string {
        if (!date) return null;
        return this.datePipe.transform(new Date(date), 'dd/MM/yyyy');
    }

}
