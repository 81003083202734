import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageParams } from 'src/app/core/models/page-params.model';

export enum EPaginateActions {
    exportToExcel,
    createPayment
}

export interface IPaginateAction {
    name: string,
    action: EPaginateActions;
    visible: boolean;
}

@Component({
    selector: 'pay-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent{

    constructor() { }

    @Input() pageParams: PageParams = new PageParams();
    @Input() withActions: boolean = false;
    @Input() actions: IPaginateAction[] = [];

    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() onAction: EventEmitter<IPaginateAction> = new EventEmitter<IPaginateAction>(null);

    pageChanged(e): void {
        this.pageParams.pageSize = e.pageSize;
        this.pageParams.pageIndex = e.pageIndex
        this.pageChange.emit(this.pageParams);
    }

    onActionClick(action: IPaginateAction) {
        this.onAction.emit(action);
    }
}
