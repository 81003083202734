import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BaseElement} from '../base-element';
import {IElement} from '../elements-interfaces';

@Component({
    selector: 'eci-items-container',
    templateUrl: './items-container.component.html',
    styleUrls: ['./items-container.component.scss']
})
export class ItemsContainerComponent extends BaseElement {

    widthChange = false;
    errorsItems = [];
    constructor() {
        super();
    }

    ngOnInit(): void {
        this.item.items.map(i => {
            this.errorsItems.push('')
        })
    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }

    onChangeWidth(e: any, index: number) {
        if (e !== null) {
            if (e > 100) {
                setTimeout(() => {
                    this.item.items[index].width = 100;
                }, 0);
            }
            if (e < 1) {
                setTimeout(() => {
                    this.item.items[index].width = null;
                }, 0);
            }
        }

        if (!this.widthChange){
            this.item.items.map((i, _index) => {
                if (index != _index) {
                    i.width = null;
                    this.setError('Width should not be empty', _index);
                }
            })
            this.widthChange = true;
        } else {
            this.checkWidth(index);
        }
    }

    onDeleteItems() {
        this.recalculateWidth();
        this.widthChange = false;
    }

    onAddItems(e: CdkDragDrop<IElement[], any>) {
        this.onDrop.emit(e);
        this.widthChange = false;
        this.recalculateErrors();
    }

    recalculateErrors() {
        this.errorsItems = Array(this.item.items.length);
        this.clearError();
    }

    recalculateWidth() {
        this.item.items.map(i => {
            i.width = 100 / this.item.items.length
        });
    }

    checkWidth(index: number): boolean {
        const totalWidth = this.item.items.reduce((sum, i, _index) => {
            return sum + i.width;
        }, 0);

        if (this.item.items[index].width == null){
            this.setError('Width should not be empty', index);
            return false;
        }

        if (totalWidth > 100) {
            this.errorsItems.map(eI => {
               if (eI === 'Summary width should not be more then 100') {
                   return  '';
               }
            });
            this.setError('Summary width should not be more then 100', index);
            return false;
        } else {
            this.errorsItems.map((eI, i) => {
                if (eI === 'Summary width should not be more then 100') {
                    this.clearError(i);
                }
            });
        }

        this.clearError(index);
        return false;
    }

    setError(text: string, index: number) {
        this.errorsItems[index] = text;
        this.setGlobalError();
    }

    clearError(index?: number) {
        if (index != undefined && index > -1) {
            this.errorsItems[index] = '';
            if (!this.errorsItems.find(e => !!e)) {
                this.clearGlobalError();
            }
        } else {
            this.errorsItems.fill('');
            this.clearGlobalError();
        }
    }
}
