import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'eci-peyment-alert',
    templateUrl: 'payment-alert.component.html',
    styleUrls: ['payment-alert.component.scss']
})
export class PaymentAlertComponent implements OnInit {
    constructor(
        private router: Router
    ) {
    }
    ngOnInit(): void {

    }
    goToPayments() {
        this.router.navigateByUrl('/payments/history');
    }
}
