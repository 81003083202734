import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formConstant} from '../../../../config';
import {ConstantService} from 'src/app/core/services/constant.service';
import {IAvailableTypes, IConstantItem} from 'src/app/core/models/available-types.interface';
import {IContactInfo} from 'src/app/modules/customer-management/models/contact-info';
import {UserService} from 'src/app/core/services/user.service';
import {cellPhoneValidator} from 'src/app/core/helpers/helpers.function';
import {RegistrationService} from 'src/app/modules/customer-registration/services/registration.service';
import {CustomerManagementService} from 'src/app/modules/customer-management/services/customer-management.service';
import {NotificationService} from 'src/app/core/services/notification.service';
import {LocalStorageService} from 'src/app/core/services/local-storage.service';
import {Roles} from 'src/app/shared/enums/role-types.enum';
import {DatePipe} from '@angular/common';
import {ICountry} from 'src/app/core/models/country.interface';
import {CityService} from 'src/app/core/services/city.service';
import {ICompany} from '../../../modules/customer-registration/models/company.model';
import {FilterPipe} from '../../pipes/filter.pipe';
import {emailValidator} from 'src/app/core/helpers/helpers.function';

const CONTACT_TYPES = ['Agent', 'Source Warehouse', 'Destination Warehouse'];

@Component({
    selector: 'app-contact-popup',
    templateUrl: './contact-popup.component.html',
    styleUrls: ['./contact-popup.component.scss'],
    providers: [
        DatePipe,
        FilterPipe
    ]
})
export class ContactPopupDialog implements OnInit {

    contactForm: FormGroup;
    textFieldMaxLength = formConstant.textFieldMaxLength;

    header: string = 'Customer Registration -  Business Representative ';

    contactPersonTypes = CONTACT_TYPES;
    availableTypes: IAvailableTypes;

    isForUpdate: boolean = false;

    contactableId: number;
    countries: ICountry[];
    states: any[];
    cities: any[];
    info: IContactInfo;
    customerInfo: ICompany;
    phoneCode: any[];
    currentCustomerCountry;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ContactPopupDialog>,
        private constSvc: ConstantService,
        public userSvc: UserService,
        private regSvc: RegistrationService,
        private localStorageSvc: LocalStorageService,
        private customerManSvc: CustomerManagementService,
        private notify: NotificationService,
        private datePipe: DatePipe,
        private citySvc: CityService,
        public filterPipe: FilterPipe
    ) {

    }

    get types(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.ContactType;
    }

    ngOnInit(): void {
        this.subscribeOnAvailableTypes();

        if (this.contactableId) {
            this.getData();
        } else if (this.isCustomer) {
            this.currentCustomerCountry = this.localStorageSvc.get('user').company && this.localStorageSvc.get('user').company.address &&
            this.localStorageSvc.get('user').company.address.state && this.localStorageSvc.get('user').company.address.state.country ? this.localStorageSvc.get('user').company.address.state.country : null;
        } else {
            this.currentCustomerCountry = this.customerInfo?.address?.state?.country ? this.customerInfo.address.state.country : null;
            this.contactForm.controls.cellNumber.setValue(this.contactForm.get('cellNumber').value ? this.contactForm.get('cellNumber').value : this.currentCustomerCountry?.phoneCode);
        }
        // this.contactForm.get('contactRole').setValue(this.findRole(this.info?.contactRole));
        this.constSvc.availablePhoneCodes.subscribe(data => {
            this.phoneCode = data;
        });

        // this.getCountriesList();
        this.constSvc.availableCountries.subscribe(countries => {
            if (countries) {
                this.countries = countries;
                this.contactForm?.controls?.countryId.valueChanges.subscribe(value => {
                    const selectedCountry = this.countries?.find(el => el.code === value);
                    if (value && (value !== 'null' || value !== 'undefined')) {
                        this.constSvc.getAvailableStatesById(value).subscribe(states => {
                            if (!states) {
                                return;
                            }
                            this.states = states;
                            if (!states) {
                                this.contactForm?.controls?.stateId.setValue(null);
                                return;
                            }
                            this.contactForm?.controls?.stateId.setValue(states[0]?.id || null);
                        });
                    }
                    this.getCitiesByCountryId(selectedCountry?.id);
                });
                if (this.info?.address?.state?.country?.code) {
                    this.contactForm?.controls?.countryId?.setValue(this.info?.address?.state?.country?.code);
                }
            }
        });

        /*this.contactForm?.controls?.countryId?.valueChanges.subscribe(value => {
            const selectedCountry = this.countries?.find(el => el.code === value);
            if (value && (value !== 'null' || value !== 'undefined')) {
            this.constSvc.getAvailableStatesById(value).subscribe(states => {
                if (!states) {
                    return;
                }
                this.states = states;
                if (!states) {
                    this.contactForm?.controls?.stateId.setValue(null);
                    return;
                }
                this.contactForm?.controls?.stateId.setValue(states[0]?.id || null);
            });
            }
            this.getCitiesByCountryId(selectedCountry?.id);
        });*/

    }

    getData() {
        if (this.contactableId) {
            this.regSvc.getCompanyById(this.contactableId).subscribe(data => {
                const currentCustomerData = data;
                const country = currentCustomerData.address.state.country;
                this.contactForm.controls.cellNumber.setValue(this.contactForm.get('cellNumber').value ? this.contactForm.get('cellNumber').value : country?.phoneCode);
            });
        }
    }

    get isCustomer(): boolean {
        return this.localStorageSvc.get('user').role == Roles.Customer || this.localStorageSvc.get('user').role == Roles.DelicateCustomer;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.contactForm.invalid) {
            return;
        }

        if (this.isForUpdate) {
            this.dialogRef.close(this.model);
        } else {
            this.addNewContact(this.model);
        }
    }

    addNewContact(model): void {
        model['contactableId'] = parseInt(this.contactableId.toString());
        model['type'] = 'CompanyContact';
        this.customerManSvc.addCompanyContact(model).subscribe((data) => {
            if (data) {
                this.customerInfo.contacts.push(data);
            }
            this.dialogRef.close(model);
        }, (error => {
            if (!error || !error.error) {
                return;
            }
            if (error.error.message) {
                this.notify.showError('The email is already present in the system please enter a different email.');
            } else {
                this.notify.showError(error.message);
            }
        }));
    }

    cellPhoneValidator = cellPhoneValidator;

    initializeForm(): FormGroup {
        return this.formBuilder.group({
            name: [this.info?.name || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength), Validators.minLength(2)]],
            mailAddress: [this.info?.mailAddress || '', [Validators.required, Validators.email, emailValidator()]],
            dob: [new Date(this.info?.dob), [Validators.required]],
            cellNumber: [this.info?.cellNumber || '', [cellPhoneValidator()]],
            countryId: [this.info?.address?.state?.country?.code || '', [Validators.required]],
            stateId: [this.info?.address?.stateId || '', [Validators.required]],
            cityName: [this.info?.address?.city || '', [Validators.required]],
            address: [this.info?.address?.name || '', [Validators.required]],
            zipCode: [this.info?.address?.zipCode || '', [Validators.required]],

            searchCountry: [''],
            searchCity: [''],
            searchState: [''],
            // position: [this.info?.position || '', [Validators.required, Validators.maxLength(this.textFieldMaxLength), Validators.minLength(2)]],
            // contactRole: [this.info?.contactRole, [Validators.required]],
            // phoneCode: [this.info?.countryPhoneCode || '']
        });
    }

    findRole(roleName): number {
        if (!roleName || !this.types) {
            return null;
        }
        return this.types.find(el => el.name == roleName).id;
    }

    subscribeOnAvailableTypes(): void {
        this.contactForm = this.initializeForm();
        this.constSvc.availableTypes.subscribe(data => {
            if (!data) {
                return;
            }
            this.availableTypes = data;
            //this.contactForm = this.initializeForm();
            // this.contactForm.get('contactRole').setValue(this.findRole(this.info?.contactRole));
        });

    }

    compareWithFn(listOfItems, selectedItem) {
        return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
    }

    get model(): IContactInfo {
        return {
            name: this.contactForm.get('name').value,
            cellNumber: this.contactForm.get('cellNumber').value,
            mailAddress: this.contactForm.get('mailAddress').value,
            dob: this.contactForm?.controls?.dob?.value != 'Invalid Date' ? this.datePipe.transform(this.contactForm?.controls?.dob?.value, 'yyyy-MM-dd') : '',
            address: {
                name: this.contactForm?.controls?.address?.value,
                city: this.contactForm?.controls?.cityName?.value,
                stateId: this.contactForm?.controls?.stateId?.value,
                zipCode: this.contactForm?.controls?.zipCode?.value,
            },
        };
    }

    /*getCountriesList(page: number = 1, limit: number = 1000): void {
        this.constSvc.getAvailableCountries(page, limit).subscribe(data => {
            if (!data) {
                return;
            }
            this.countries = data;

            this.contactForm?.controls?.countryId?.valueChanges.subscribe(value => {
                const selectedCountry = this.countries?.find(el => el.code === value);
                if (value && (value !== 'null' || value !== 'undefined')) {
                this.constSvc.getAvailableStatesById(value).subscribe(states => {
                    if (!states) {
                        return;
                    }
                    this.states = states;
                    if (!states) {
                        this.contactForm?.controls?.stateId.setValue(null);
                        return;
                    }
                    this.contactForm?.controls?.stateId.setValue(states[0]?.id || null);
                });
                }
                this.getCitiesByCountryId(selectedCountry?.id);
            });

            this.contactForm?.controls?.countryId?.setValue(this.info?.address?.state?.country?.code);
        });
    }*/

    getCitiesByCountryId(countryID: string | number): void {
        if (!countryID) {
            return;
        }
        this.citySvc.getAllByCountryId(countryID).subscribe(cities => {
            if (!cities) {
                return;
            }
            this.cities = cities;
            if (!cities) {
                this.contactForm.controls.cityName.setValue(null);
                return;
            }
            this.contactForm.controls.cityName.setValue(cities[0].city || null);
        });
    }
}
