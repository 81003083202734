import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EElementTypes} from '../../../../invoice-management.service';
import {IElement} from '../elements-interfaces';

@Component({
    selector: 'eci-all-elements',
    templateUrl: './all-elements.component.html',
    styleUrls: ['./all-elements.component.scss']
})
export class AllElementsComponent implements OnInit {

    elementTypes = EElementTypes;

    @Input() items: IElement[] = [];
    @Input() name;
    @Input() zoom;
    @Input() fontSize;
    @Input() dataSource;
    @Input() id_prefix: string = '';
    @Input() align: 'horizontal' | 'vertical' = 'vertical';
    @Input() errors: string[] = [];
    @Output() errorsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
    @Output() onAddTemplates: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSelectElement: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDeleteElement: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDrop: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }

    ngOnInit(): void {
    }

    getWidth(item: IElement) {
        return item.width && this.align === 'horizontal' ? item.width : 100;
    }
}
