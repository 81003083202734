import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {  FormControl,FormGroup,FormBuilder,Validators } from '@angular/forms';
import {RegistrationService} from '../../../customer-registration/services/registration.service';
import {debounceTime} from 'rxjs/operators';
//import {EPaymentsType} from '../../payments-type.enum';

@Component({
  selector: 'eci-create-payment-popup',
  templateUrl: './create-payment-popup.component.html',
  styleUrls: ['./create-payment-popup.component.scss']
})
export class CreatePaymentPopupComponent implements OnInit {

    companyId:FormControl = new FormControl();
    paymentDescription:FormControl = new FormControl();
    paymentAmount:FormControl = new FormControl();
    searchCustomerName: FormControl = new FormControl('');
    companyList: any[];
    form: FormGroup;
    //paymentTypes: any;

  constructor(
    private dialogRef: MatDialogRef<CreatePaymentPopupComponent>,
    private formBuilder: FormBuilder,
    public regSvc: RegistrationService
    ) {
        //this.paymentTypes = EPaymentsType;

     }

  ngOnInit(): void {
    this.initForm();
    this.searchCustomerName.valueChanges.pipe(debounceTime(500)).subscribe(() => {
        this.getListCompanies();
    });
  }
    getListCompanies(q: string = this.searchCustomerName.value): void {
        q = this.form.get('companyId').value ? this.searchCustomerName.value : q;
        this.regSvc.getCompanyListForSales(q).subscribe(data => {
            if (!data) {
                return;
            }
            this.companyList = data;
        });
    }
    clearSelection() {
        this.form.get('companyId').reset();
        this.getListCompanies(this.searchCustomerName.value);
    }
    initForm() {
        this.form = this.formBuilder.group({
            companyId: [
                { disabled: false, value: '' },
                [
                    Validators.required
                ],
            ],
            paymentDescription: [
                { disabled: false, value: '' },
                [
                    Validators.required
                ],
            ],
            paymentAmount: [
                { disabled: false, value: '' },
                [
                    Validators.required,
                    Validators.pattern('^[0-9]+.?[0-9]*$|^$'),
                ],
            ]
        })
    }

    onClickClose():void{
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
        const formData = {
            companyId: this.form.get('companyId').value,
            paymentDescription: this.form.get('paymentDescription').value,
            paymentAmount: this.form.get('paymentAmount').value
        };
        this.dialogRef.close(formData);
    }

}
