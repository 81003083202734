import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { emailValidator } from '../../../../core/helpers/helpers.function';
import {AuthType} from '../../core/enums/auth-type.enum';
import { UserService } from '../../core/services/user.service';

@Component({
  selector: 'ecommerce-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @Output() typeChanged: EventEmitter<AuthType> = new EventEmitter<AuthType>(null);
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userSvc: UserService,
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = this.initializeForm();
  }

  initializeForm(): FormGroup {
    return this.fb.group({
        email: ['', [Validators.required, Validators.email, emailValidator()]],
    });
  }

  goToLogin(): void {
    this.typeChanged.emit(AuthType.Login);
  }

  sendCode(): void {
    if (this.form.invalid) {
      this.notify.showError('Please pass the email');
      return;
    }

    this.userSvc.forgotPassword(this.form.value).subscribe(result => {
        this.notify.showSuccessMessage(`Email with reset password link has been sent to ${this.form.controls.email.value}`);
    }, (error) => {
        switch (error.error.message) {
            case 'USER.ERRORS.NOT_FOUND': this.notify.showError('User does not exist'); return;
            default: this.notify.showError('User does not exist'); return;
        }
    });
  }

    public fieldValidation(name, errorName: string = 'required'): boolean {
        return this.form?.controls?.[name]?.touched && this.form?.controls?.[name]?.hasError(errorName);
    }

}
