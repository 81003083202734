import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
    selector: 'eci-customer-company',
    templateUrl: './customer-company.component.html',
    styleUrls: ['./customer-company.component.scss']
})
export class CustomerCompanyComponent extends BaseElement implements OnChanges{
    customerCompanyKey = 'name_eu';

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (!this.item.model || !('customerCompany' in this.item.model)) {
            this.item.model.customerCompany = this.item.defaultValues;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && changes.item.currentValue) {
            if (!changes.item.currentValue.model) {
                this.item.model = {
                    customerCompany: {
                        name_eu: 'E-Comm-In BV',
                        name_uk: 'Empact Online LTD',
                        shipper: 'Shipper',
                        shipment: 'Shipment'
                    }
                } as any;
                this.item.defaultValues = {
                    name_eu: 'E-Comm-In BV',
                    name_uk: 'Empact Online LTD',
                    shipper: 'Shipper',
                    shipment: 'Shipment'
                }
            }
        }
        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 14;
        }
    }
}
