import {moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {EElementTypes} from '../../../../invoice-management.service';
import {BaseElement} from '../base-element';

@Component({
    selector: 'eci-table-template',
    templateUrl: './table-template.component.html',
    styleUrls: ['./table-template.component.scss']
})
export class TableTemplateComponent extends BaseElement implements OnChanges{

    elementTypes = EElementTypes;
    $rowDropped: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    searchSource: FormControl = new FormControl('');

    constructor(
        private dialog: MatDialog,
    ) {
        super();
    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }


    ngOnInit(): void {

    }

    onRemoveColumn() {
        const dialogRef = this.dialog.open(ManipulateTableDialog);
        dialogRef.componentInstance.title = 'Hide / Show columns';
        dialogRef.componentInstance.cols = this.item.model.cols;
        dialogRef.afterClosed().subscribe(res => {
            const removeValue = (value, index, arr) => {
                if (value.selected) {
                    arr.splice(index, 1);
                    return false;
                }
                return true;
            }
            this.item.model.cols.filter(removeValue);
        })
    }

    onAddColumn() {
        this.item.model.cols = [...this.item.model.cols, {
            width: 100,
            label: 'Title',
            selected: false,
            key: '',
            value: '',
            replace: false,
        }];
        console.log(this.item);
    }

    onHeaderChange(event: any) {
        moveItemInArray(this.item.model.cols, event.previousIndex, event.currentIndex);
        this.$rowDropped.next(event);
        // moveItemInArray(this.item.model.dynamicRow, event.previousIndex, event.currentIndex);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && changes.item.currentValue) {
            if (!changes.item.currentValue.model) {
                this.item.model = {
                    cols: [],
                    source: '',
                } as any;
            }
        }
    }

    isArray(key) {
        return Array.isArray(key);
    }

    onAddField(col: any) {
        if (Array.isArray(col.key)) {
            col.key.push('');
        } else {
            col.key = [col.key];
            col.key.push('');
        }
    }

    removeSourceItem(col, index: number) {
        col.key.splice(index, 1);
        if (col.key.length === 1) {
            col.key = col.key[0];
        }
    }

    get sources() {
        const dataSource =  (Array.from(this.dataSource) as any).filter(ds => {
            if (this.searchSource.value) {
                const values = ds.values.filter(v => {
                    return v.name.toLowerCase().includes(this.searchSource.value.toLowerCase());
                });
                ds.displayedValues = values;
                return values.length;
            } else {
                ds.displayedValues = ds.values;
                return true;
            }
        });
        return dataSource;
    }
}

@Component({
    selector: 'manipulate-table-dialog',
    templateUrl: 'manipulate-table-dialog.html',
    styleUrls: ['./table-template.component.scss']
})
export class ManipulateTableDialog {
    title = '';
    cols: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<ManipulateTableDialog>,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onAccept() {
        this.dialogRef.close();
    }
}
