import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Sort} from '@angular/material/sort';
import {EAssignType, IActionInvoice} from '../../editor/editor.component';

@Component({
    selector: 'eci-inv-man-table-templates',
    templateUrl: './inv-man-table-templates.component.html',
    styleUrls: ['./inv-man-table-templates.component.scss']
})
export class InvManTableTemplatesComponent implements OnInit {

    assignType = EAssignType;

    @Input() dataSource;
    @Input() isSpecific = false;
    @Input() displayedColumns;
    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() action: EventEmitter<IActionInvoice> = new EventEmitter<IActionInvoice>(null);

    constructor() {
    }

    ngOnInit(): void {
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    getTemplateName(element) {
        return element.name;
    }

    getTemplateType(element) {
        return element.isSpecific ? 'Specific' : 'Default'
    }
}
