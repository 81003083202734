import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class PortsService extends ApiService {

    getOriginPorts(page: number = 1, limit: number = 100, q: string = '', orderType?: number, countryCode: string = ''): Observable<any> {
        return this.request({
            method: 'POST',
            path: `ports/getOriginPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType,
                countryCode: countryCode ? countryCode : ''
            }
        });
    }

    getDestinationPorts(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        orderType?: number,
        companyId?: number,
        isEU: boolean = false,
        countryCode?: string,
    ): Observable<any> {
        return this.request({
            method: 'POST',
            path: `ports/getDestinationPortsByCountries`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType,
                companyId: companyId ? Number(companyId) : null,
                isEU,
                countryCode: countryCode ? countryCode : ''
            }
        });
    }

    getOriginAirports(page: number = 1, limit: number = 100, q: string = '', orderType?: number, countryCode: string = ''): Observable<any> {
        return this.request({
            method: 'POST',
            path: `air-ports/getOriginPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType,
                countryCode: countryCode ? countryCode : ''
            }
        });
    }

    getDestinationAirports(page: number = 1, limit: number = 100, q: string = '', orderType?: number, countryCode: string = ''): Observable<any> {
        return this.request({
            method: 'POST',
            path: `air-ports/getDestinationPorts`,
            body: {
                portName: q,
                limit: limit,
                page: page,
                orderType: orderType,
                countryCode: countryCode ? countryCode : ''
            }
        });
    }
}
