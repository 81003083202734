export enum ExpirationPolicy {
    '5 min' = 300,
    '1 day' = 86400,
    '3 days' = 259200,
    '7 days' = 604800,
    '14 days' = 1209600,
    '30 days' = 2592000,
    '60 days' = 5184000,
    '120 days' = 10368000,
    'forever' = undefined,
}
