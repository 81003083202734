import {Component, OnInit, Input, ViewChild, EventEmitter, Output, ElementRef} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Router, ActivatedRoute, NavigationExtras} from '@angular/router';
import {MatSort, Sort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import {PageParams} from 'src/app/core/models/page-params.model';
import {ICategory} from 'src/app/core/models/category.interface';
import {UserService} from 'src/app/core/services/user.service';
import {UploadFileService} from 'src/app/core/services/upload-file.service';
import {FileStatus} from '../../../../shared/enums/file-status.enum';
import {IAvailableTypes} from 'src/app/core/models/available-types.interface';
import {ShipmentType} from 'src/app/shared/enums/shipment-type.enum';
import {OrderDocumentType} from '../../../../shared/enums/order-document-type.enum';
import {OrderDocumentStatus} from '../../../../shared/enums/order-document-status.enum';
import {SaleOrderStatus} from 'src/app/shared/enums/sale-order-status.enum';
import {ISaleOrder} from '../../../sales-order-management/models/sale-order.interface';
import { ConsolidateShipmentsDetailsComponent } from '../../../consolidate-shipments/components/consolidate-shipments-details/consolidate-shipments-details.component';
import * as _ from 'lodash';
import { ConsolidateShipmentService } from '../../../partner-management/services/consolidate-shipments.service';
import { ShipmentSubType } from 'src/app/shared/enums/shipment-subtype.enum';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'eci-table-consolidate-shipments',
    templateUrl: './table-consolidate-shipments.component.html',
    styleUrls: ['./table-consolidate-shipments.component.scss'],
})
export class TableConsolidateShipmentsComponent implements OnInit {
    selection = new SelectionModel<any>(true, []);

    isVatInvoice(item): boolean {
        if (item.invoiceNumber != undefined) {
            return true;
        }
        return false;
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public userSvc: UserService,
        public uploadFileSvc: UploadFileService,
        private dialog: MatDialog,
        private consolidateShipmentService: ConsolidateShipmentService
    ) {
    }

    @Input() dataSource;
    @Input() displayedColumns;
    @Input() availableCategories: ICategory[];
    @Input() availableTypes: IAvailableTypes;
    @Input() isLink: boolean = false;
    @Input() pageParams: PageParams = new PageParams();
    @Input() shipmentType: number;
    @Input() listOfSelectedItems: any[] = [];
    @Input() assignSpecificInvoice = false;
    @Input() showFooter: boolean = false;

    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() editPickupInfos: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() editProducts: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() documentSave: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() downInvoice: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() downVatInvoice: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() documentUpload: EventEmitter<{ id: number, e: any, file: any }> = new EventEmitter<{ id: number, e: any, file: any }>(null);
    @Output() documentDelete: EventEmitter<number> = new EventEmitter<number>(null);
    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() listOfSelectedItemsChange: EventEmitter<any[]> = new EventEmitter<any[]>(null);
    @Output() getList: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() createSpecificInvoice: EventEmitter<any> = new EventEmitter<any>(null);

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('table', {static: true, read: ElementRef}) table: ElementRef;

    ShipmentSubtype = ShipmentSubType;

    pageChanged(e): void {
        this.pageChange.emit(e);
        this.table.nativeElement?.scrollIntoView();
    }

    ngOnInit(): void {
        this.selection.changed.subscribe((selectionChange: SelectionChange<any>) => {
            this.listOfSelectedItemsChange.emit(this.selection.selected);
        });
    }

    navigateTo(id): void {
        this.router.navigate([`${this.router.url}/consol-shipment-details/${id}`]);
    }

    // navigateToDetails(id): void {
    //   this.router.navigateByUrl(`/customers-management/details?id=${id}`);
    // }

    navigateTolastMile(id): void {
        const lastMileFlag = true;
        const navigationExtras: NavigationExtras = {
          queryParams: { islastMile: lastMileFlag },
        };
        this.router.navigate([`${this.router.url}/consol-shipment-details/${id}`], navigationExtras);
    }

    editPickupInfo(id: number): void {
        this.editPickupInfos.emit(id);
    }

    editProductItem(id: number): void {
        this.editProducts.emit(id);
    }

    findCategoryName(id): string {
        if (!this.availableCategories) {
            return;
        }
        const category = this.availableCategories.find(el => el.id == id);
        return category ? category.name : '';
    }

    uploadShow(status: number): boolean {
        return status == FileStatus.PENDING;
    }

    downloadShow(status: number): boolean {
        return status == FileStatus.UPLOADED;
    }

    uploadFile(e, file, id): void {
        this.documentUpload.emit({id: id, e: e, file: file});
    }

    downloadFile(id): void {
        this.documentSave.emit(id);
    }

    deleteDoc(id): void {
        this.documentDelete.emit(id);
    }

    downloadInvoice(id): void {
        this.downInvoice.emit(id);
    }

    downloadVatInvoice(id): void {
        this.downVatInvoice.emit(id);
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    get hideCol(): boolean {
        return this.userSvc.isDelicated;
    }

    getDeliveredItems(consolShipment): number {
        const statuses = consolShipment.saleOrders.map(el => el.status);

        const delivered = statuses.filter(el => el === SaleOrderStatus.DELIVERED);
        return delivered?.length;
    }

    isRequired(element): boolean {
        if (element.type === OrderDocumentType.SignedPod) {
            return true;
        }
        if (this.shipmentType === ShipmentType.Air) {
            // if (element.type === OrderDocumentType.SignedPod) return true;
            if (element.type === OrderDocumentType.AWB && !this.dataSource.find(el => el.type === OrderDocumentType.AWB && el.status === OrderDocumentStatus.Uploaded)) {
                return true;
            }
            if (element.type === OrderDocumentType.MAWB && (!this.dataSource.find(el => el.type === OrderDocumentType.AWB && el.status === OrderDocumentStatus.Uploaded) && (!this.dataSource.find(el => el.type === OrderDocumentType.HAWB && el.status === OrderDocumentStatus.Uploaded)))) {
                return true;
            }
            if (element.type === OrderDocumentType.HAWB && (this.dataSource.find(el => el.type === OrderDocumentType.MAWB && el.status === OrderDocumentStatus.Pending))) {
                return true;
            }
        }

        // if (this.shipmentType === ShipmentType.Ocean) {
        //   if (element.type === OrderDocumentType.SignedPod) return true;
        // }

        return element.isRequired;
    }

    get isAllSelected(): boolean {
        return this.listOfSelectedItems?.length > 0 &&
            this.listOfSelectedItems?.length === this.dataSource?.length;
    }

    selectAllChanged(event: MatCheckboxChange): void {
        if (event.checked) {
            this.listOfSelectedItems = this.dataSource;
            this.listOfSelectedItemsChange.emit(this.listOfSelectedItems);
        } else {
            this.listOfSelectedItems = [];
            this.listOfSelectedItemsChange.emit(this.listOfSelectedItems);
        }
    }

    isChecked(id: number): boolean {
        return !!this.listOfSelectedItems.find(el => el.id === id);
    }

    selectionChanged(event: MatCheckboxChange, item): void {
        if (event.checked) {
            if (!this.isChecked(item.id)) {
                this.listOfSelectedItems.push(item);
            }
        } else {
            if (this.isChecked(item.id)) {
                this.listOfSelectedItems.splice(this.listOfSelectedItems.findIndex(el => el.id === item.id), 1);
            }
        }
        this.listOfSelectedItemsChange.emit(this.listOfSelectedItems);
    }

    get isAdmin(): boolean {
        return this.userSvc.isAdmin;
    }

    editConsolShipment(data) {
        this.consolidateShipmentService.getShipmentById(data?.id).subscribe((res) => {
            if (res) {
                const dialogRef = this.dialog.open(ConsolidateShipmentsDetailsComponent, {
                    width: '1000px',
                    height: '600px'
                });
                dialogRef.componentInstance.summaryPage = false;
                dialogRef.componentInstance.info = res;
                dialogRef.componentInstance.createMode = true;
                dialogRef.componentInstance.isEditable = true;
                dialogRef.componentInstance.saleOrders = _.cloneDeep(res.saleOrders);
                dialogRef.afterClosed().subscribe((result: any) => {
                    this.getList.emit();
                });
            }
        });
    }

    getDeliveredCount(data: any) {
        if (data?.lastMile && data?.lastMile.length > 0) {
            return data?.lastMile.filter(item => item.status === 4).length;
        }
    }

    calculateTotalUnits() {
        let totalUnits = 0;
        if (this.dataSource) {
            for (let product of this.dataSource) {
                totalUnits += product.units;
            }
        }
        return totalUnits;
    }

    calculateTotalProducts() {
        let totalProducts = 0;
        if (this.dataSource) {
            totalProducts += this.dataSource?.length
        }
        return totalProducts;
    }
    clearSelection(){
        this.selection.clear();
    }
}
