import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {EPaymentStatus} from '../../../payments-type.enum';

@Component({
    selector: 'eci-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

    status: EPaymentStatus = null;
    statuses = EPaymentStatus;

    constructor(
        public dialogRef: MatDialogRef<ConfirmComponent>,
    ) {
    }

    ngOnInit(): void {
    }

    onYes() {
        this.dialogRef.close(true);
    }

    onCancel() {
        this.dialogRef.close();
    }
}
