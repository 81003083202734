import {moveItemInArray} from '@angular/cdk/drag-drop';
import {EventEmitter, Output} from '@angular/core';
import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BaseElement} from '../base-element';
import {IColumn, IRowField} from '../elements-interfaces';

@Component({
    selector: 'eci-grid-item-dynamic',
    templateUrl: './grid-item-dynamic.component.html',
    styleUrls: ['./grid-item-dynamic.component.scss']
})
export class GridItemDynamicComponent extends BaseElement implements OnDestroy, OnChanges {

    @Input() columns: IColumn[] = [];
    @Input() disabled: boolean;
    @Input() rowDroppedSubject: BehaviorSubject<any>;
    @Input() model: IRowField[] = [];
    @Output() modelChange: EventEmitter<any> = new EventEmitter<any>();

    rowDroppedSubscription: Subscription;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.rowDroppedSubscription = this.rowDroppedSubject.subscribe(res => {
            if (res) {
                moveItemInArray(this.item.model, res.previousIndex, res.currentIndex);
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.rowDroppedSubscription && this.rowDroppedSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.columns) {
            if (changes.columns.currentValue.length && this.model.length === 0) {
                for (const col of changes.columns.currentValue) {
                    this.model.push({
                        background: '#FAFAFA',
                        key: '',
                        replace: false,
                        value: ''
                    });
                }
                this.updateModel();
            } else if (changes.columns.currentValue.length > this.model.length) {
                this.model = [...this.model, {
                    background: '#FAFAFA',
                    key: '',
                    replace: false,
                    value: ''
                }];
                this.updateModel();
            }
        }
        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 10;
        }
    }

    isArray(key) {
        return Array.isArray(key);
    }

    private updateModel() {
        setTimeout(() => {
            this.modelChange.emit(this.model);
        },0)
    }
}
