import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'eci-select-source-field',
    templateUrl: './select-source-field.component.html',
    styleUrls: ['./select-source-field.component.scss']
})
export class SelectSourceFieldComponent implements OnInit, OnChanges {

    @Input() dataSource;
    @Input() model;
    searchSource: FormControl = new FormControl('');
    constructor() {
    }

    get sources() {
        const dataSource =  (Array.from(this.dataSource) as any).filter(ds => {
            if (this.searchSource.value) {
                const values = ds.values.filter(v => {
                    return v.name.toLowerCase().includes(this.searchSource.value.toLowerCase());
                });
                ds.displayedValues = values;
                return values.length;
            } else {
                ds.displayedValues = ds.values;
                return true;
            }
        });
        return dataSource;
    }

    get isEmptySelect(): boolean {
        return (Array.isArray(this.model.key) && this.model.key.filter(k => k === '').length > 0) || this.model.key === '';
    }

    ngOnInit(): void {
    }

    get separators() {
        return this.dataSource.find(r => r.name === 'Symbols')?.values;
    };

    isArray(key) {
        return Array.isArray(key);
    }

    onAddField(col: any) {
        if (Array.isArray(col.key)) {
            col.key.push('');
        } else {
            col.key = [col.key];
            col.key.push('');
        }
    }

    removeSourceItem(col, index: number) {
        col.key.splice(index, 1);
        if (col.key.length === 1) {
            col.key = col.key[0];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
    }
}
