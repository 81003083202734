import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatExpansionPanel} from '@angular/material/expansion';
import {
    IPackage_ProductAllocation,
    IProduct_ProductAllocation,
    PackingTypeNew,
    UnitsOfMeasure
} from '../../product-allocation-new.component';
import {moreThen} from '../pack-items/pack-items.component';

@Component({
    selector: 'eci-pallet-item',
    templateUrl: './pallet-item.component.html',
    styleUrls: ['./pallet-item.component.scss']
})
export class PalletItemComponent implements OnInit {

    @Input() package: IPackage_ProductAllocation;
    @Output() packageChange: EventEmitter<IPackage_ProductAllocation> = new EventEmitter<IPackage_ProductAllocation>();

    @Input() isSimply: boolean = false;
    @Input() notAllocated: number = 0;
    @Input() denominator: number = 1;
    @Input() canCancel: boolean = false;
    @Input() products: IProduct_ProductAllocation[] = [];
    @Input() isViewing: boolean = false;
    @Output() onCancelEvent: EventEmitter<IPackage_ProductAllocation> = new EventEmitter<IPackage_ProductAllocation>();
    @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatExpansionPanel) matExpansionPanel: MatExpansionPanel;

    palletForm: FormGroup;
    validate = false;
    lastCartonIndex = 0;

    constructor(
        private formBuilder: FormBuilder,
    ) {
    }
    get productsInPackage() {
        return this.package.cartons.reduce((sum, value) => {
            return sum + value.products.length;
        }, 0) | 0;
    }

    get productQuantity() {
        return this.package.cartons.reduce((sum, value) => {
            const cartonQuantity = value.products.reduce((pSum, pValue) => {
                return pSum + pValue.allocated;
            }, 0);
            return sum + cartonQuantity;
        }, 0) | 0;
    }

    ngOnInit(): void {
        this.palletForm = this.formBuilder.group({
            length: [{value: this.package.length, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            width: [{value: this.package.width, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            height: [{value: this.package.height, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            grossWeight: [{value: this.package.grossWeight, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            volumetricWeight: [this.package.grossWeight],
        });

        this.palletForm.get('length').valueChanges.subscribe(values => this.onChangeInfo());
        this.palletForm.get('width').valueChanges.subscribe(values => this.onChangeInfo());
        this.palletForm.get('height').valueChanges.subscribe(values => this.onChangeInfo());

        if (this.package.cartons.length === 0) {
            this.addChild();
        }
    }

    addChild() {
        this.package.cartons.push(
            {
                packingType: PackingTypeNew.carton,
                productsQuantity: 0,
                length: 0,
                width: 0,
                height: 0,
                cartons_qty: 0,
                unitsOfMeasure: UnitsOfMeasure.Kg,
                grossWeight: 0,
                volumetricWeight: 0,
                expand: true,
                products: [
                    {item: {}, allocated: 0}
                ],
                index: ++this.lastCartonIndex,
                isNew: true,
            }
        )
        this.onChangeInfo();
    }

    onSave() {
        if (this.validatePackage()) {
            this.package = {
                ...this.package,
                ...this.palletForm.value,
                quantity: this.productQuantity,
                expand: false,
                isNew: false
            };
            this.packageChange.emit(this.package);
            this.matExpansionPanel.close();
        }
    }

    onCancel() {
        this.onCancelEvent.emit(this.package);
    }

    private validatePackage() {
        this.validate = true;
        return this.palletForm.valid;
    }

    onChangeInfo() {
        this.palletForm.get('volumetricWeight').setValue(this.calculateVolumetricWeight());
    }

    calculateVolumetricWeight() {
        const volumetricWeight =
            this.palletForm.get('height').value *
            this.palletForm.get('width').value *
            this.palletForm.get('length').value *
            this.package.cartons.length / this.denominator;
        return parseFloat(volumetricWeight.toFixed(3));
    }

    onCancelPack(event: IPackage_ProductAllocation, index: number) {
        this.package.cartons.splice(index, 1);
        this.lastCartonIndex = 0;

        this.package.cartons.map(pl => {
            pl.index = ++this.lastCartonIndex;
        })
        this.packageChange.emit(this.package);
    }

    onDuplicate(e: any) {
        this.package.cartons.push(
            {
                ...e,
                expand: true,
                index: ++this.lastCartonIndex,
                isNew: true,
            }
        )
        this.onChangeInfo();
    }

    getUnit(unitsOfMeasure: number) {
        return UnitsOfMeasure[unitsOfMeasure];
    }

    updatePackage(data: IPackage_ProductAllocation, index: number) {
        for (const key in this.package.cartons[index]) {
            this.package.cartons[index][key] = data[key];
        }
        this.package.productsQuantity = this.productQuantity;
    }

    updateQuantity(e: any, index: number) {
        if (this.isSimply) {
            this.package.cartons[index].products[0].allocated = e.value;
        } else {
            for (const v of e.value) {
                const productId = Number(Object.keys(v)[0]);
                const product = this.package.cartons[index].products.find(p => Number(p?.item?.productId) === productId);
                if (product) {
                    product.allocated = v[productId];
                } else {
                    this.package.cartons[index].products.push({
                        item:{
                            productId
                        },
                        allocated: v[productId]
                    })
                }
            }
        }
        this.package.productsQuantity = this.productQuantity;
        this.onQuantityChange.emit(e);
    }
}
