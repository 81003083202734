import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { IConstantItem } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { IContainer } from 'src/app/modules/customers-order-management/models/containers.interface';
import { IOrderDetails } from 'src/app/modules/customers-order-management/models/order-details.model';

@Component({
  selector: 'eci-container-table',
  templateUrl: './container-table.component.html',
  styleUrls: ['./container-table.component.scss']
})
export class ContainerTableComponent implements OnInit {

  constructor(
    private fb: FormBuilder
  ) { }

  @Input() dataSource;
  @Input() data: IOrderDetails;
  @Input() displayedColumns;
  @Input() availablePackType: IConstantItem[];
  @Input() isLink: boolean = false;
  @Input() weightUnits: IConstantItem[];
  @Input() currentWeightType: string;
  @Input() currentMeasuremenrType: string;
  @Input() isCreateMode: boolean;
  @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
  @Output() editPickupInfos: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() editProducts: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() add: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() delete: EventEmitter<number> = new EventEmitter<number>(null);
  @Output() dataChange: EventEmitter<IContainer[]> = new EventEmitter<IContainer[]>();

  rows: FormArray = this.fb.array([]);
  form: FormGroup = this.fb.group({ 'pallets': this.rows });

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.form = this.initializeForm(this.data);

    this.form.valueChanges.subscribe(() => {

    })
  }

  deleteRow(id): void {
    this.rows.removeAt(id);
    this.delete.emit(id);
    this.updateView();
  }


  initializeForm(data?: IOrderDetails): FormGroup {
    return this.fb.group({
      dvSmall: [{ disabled: !this.isCreateMode, value: data?.packageDetails?.oceanPackage?.dvSmall || 0 }],
      dvBig: [{ disabled: !this.isCreateMode, value: data?.packageDetails?.oceanPackage?.dvBig || 0 }],
      hsSmall: [{ disabled: !this.isCreateMode, value: data?.packageDetails?.oceanPackage?.hsSmall || 0 }],
      hsBig: [{ disabled: !this.isCreateMode, value: data?.packageDetails?.oceanPackage?.hsBig || 0 }],
    });
  }

  get model(): IOrderDetails {
    return {
      ...this.data,
      packageDetails: {
        //palettes: [],
        oceanPackage: {
          dvSmall: 0,
          dvBig: 0,
          hsBig: 0,
          hsSmall: 0
        }
      }
    }
  }

  // changeVolume(el, index): void {
  //   if (!el) return;
  //   const amountOfUnits = ((this.form.get('containers') as FormArray).controls[index] as FormGroup).get('units');
  //   this.rows.value[index].volume = amountOfUnits ? (this.rows.value[index].height * this.rows.value[index].width * this.rows.value[index].length / ConstantValues.VolumaticWeightDivider) * amountOfUnits.value : 0;
  //   ((this.form.get('containers') as FormArray).controls[index] as FormGroup).get('volume').setValue(this.rows.value[index].volume.toFixed(3));
  //   el.volume = (amountOfUnits ? (el.height * el.width * el.length / ConstantValues.VolumaticWeightDivider) * amountOfUnits.value : 0).toFixed(3);
  // }

  updateView() {
    this.dataSource = [...this.rows.controls];
  }

  changeTo0(index, field: string): void {
    const el = ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get(field).value;
    el < 0 ? ((this.form.get('pallets') as FormArray).controls[index] as FormGroup).get(field).setValue(0) : '';
  }
}
