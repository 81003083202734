import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';

import {PaymentAlertComponent} from './payment-alert/payment-alert.component';

@NgModule({
    imports: [
        MatButtonModule,
    ],
    declarations:[
        PaymentAlertComponent
    ],
    exports: [
        PaymentAlertComponent
    ]
})
export class PaymentAlertModule {
}
