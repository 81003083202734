import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { SOType } from 'src/app/shared/enums/so-type.enum';
import { ConfirmPopupDialog } from '../../../../shared/popups/confirm-popup/confirm-popup.component';

@Component({
    selector: 'so-type-consol-select-popup',
    templateUrl: './so-type-consol-select-popup.component.html',
    styleUrls: ['./so-type-consol-select-popup.component.scss']
})
export class SOTypeConsolSelectPopupPopupComponent implements OnInit {

    @Input() selectedItems: any;

    listOfAvailableSOTypes: any[] = [
        { id: SOType.B2B, name: 'B2B SO' },
        { id: SOType.B2C, name: 'B2C SO' },
        { id: SOType.Empact, name: 'Marketplace SO' },
        { id: SOType.ReportingTool, name: 'Reporting Tool SO' },
    ];

    listOfSOTypes2Show: any[] = [];
    selectedType: SOType = undefined;

    listOfAvailableShipmentTypes: any[] = [];

    get isOfflineShopOwner(): boolean {
        if (!this.userSvc.user) {
            return;
        }
        return this.userSvc.user.role === Roles.OfflineShopOwner;
    }

    constructor(
        private dialog: MatDialogRef<SOTypeConsolSelectPopupPopupComponent>,
        private userSvc: UserService,
        private notify: NotificationService,
        private matDialog: MatDialog,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.listOfSOTypes2Show = this.getList2Show(this.userSvc.user.role);

        if (this.listOfSOTypes2Show.length === 1 && this.listOfAvailableShipmentTypes?.length === 1) {
            this.selectedType = this.listOfSOTypes2Show[0].id;
            this.proceed();
        }
    }

    getList2Show(role: Roles): any[] {
        switch (role) {
            case Roles.OfflineShopOwner: return this.listOfAvailableSOTypes.filter(el => el.id === SOType.Empact);
            case Roles.DelegateOfflineShopOwner: return this.listOfAvailableSOTypes.filter(el => el.id === SOType.Empact);
            case Roles.Admin: return this.listOfAvailableSOTypes;
            case Roles.Customer: return this.listOfAvailableSOTypes.filter(el => el.id !== SOType.Empact && el.id !== SOType.ReportingTool);
            case Roles.IndVatAccount: return this.listOfAvailableSOTypes.filter(el => el.id === SOType.ReportingTool);
        }
    }

    proceed(): void {
        if (this.selectedType === undefined) {
            this.notify.showError('Select Sale Order Type');
            return;
        }

        const saleOrderType = this.listOfAvailableSOTypes.find((el) => el.id === this.selectedType).name;
        const dialogRef = this.matDialog.open(ConfirmPopupDialog);
        dialogRef.componentInstance.message = `To create Consolidated Shipment (Consol), please select the Sale orders to be included,
             then press the "Consolidate Shipment" button should get appear for ${saleOrderType}.`;
        dialogRef.componentInstance.header = 'Information message';
        dialogRef.componentInstance.leftBtnText = 'Close';
        dialogRef.componentInstance.showRightBtn = false;

        let navigateUrl = '/sales-order-management';
        switch (this.selectedType) {
            case SOType.ReportingTool: navigateUrl += '/indvat-sales-order-management'; break;
            case SOType.B2C: navigateUrl += ''; break;
            case SOType.B2B: navigateUrl += '/b2b-sales-order-management'; break;
            case SOType.Empact: navigateUrl += '/marketplace-sales-management'; break;
            default: break;
        }

        if (this.selectedItems && this.selectedItems?.length > 0) {
            const id = this.selectedItems[0].id;
            const flag = true;
            this.router.navigateByUrl(`${navigateUrl}?consolShipment=1&id=${id}&addSO=${flag}`);
        }
        else {
            this.router.navigateByUrl(`${navigateUrl}?consolShipment=1`);
        }
        this.dialog.close();
    }
}
