import { Pipe, PipeTransform } from '@angular/core';
import { SaleOrderStatus } from '../enums/sale-order-status.enum';

@Pipe({ name: 'soShipmentOrderId' })
export class SoShipmentOrderIdPipe implements PipeTransform {
    transform(value: any) {
        if (!value || (!value?.shipmentId && !value?.shipment?.id)) {
            return '';
        } else {
            if (value?.status === SaleOrderStatus.CANCELED || value?.status === SaleOrderStatus.REFUNDED) {
                return `<=${!!value?.shipmentOrderId ? value?.shipmentOrderId : value?.shipment?.orderId}`;
            } else {
                return !!value?.shipmentOrderId ? value?.shipmentOrderId : value?.shipment?.orderId;
            }
        }
    }
}
