import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MaterialModule } from '../modules/material.module';
import {AddNewStatusComponent} from './components/add-new-status/add-new-status.component';
import { ApplyHsCodeComponent } from './components/apply-hs-code/apply-hs-code.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import {ChangeOrderStatusPopup} from './popups/change-order-status-popup/change-order-status-popup';
import { ProductDetailsComponent } from './popups/product-details/product-details.component';
import { AddVatModule } from '../modules/reporting-tool/tax-reports/components/add-vat/add-vat.module';
import { ImageLoaderComponent } from './components/image-loader/image-loader.component';
import { ContactPopupDialog } from './popups/contact-popup/contact-popup.component';
import { AgentPopupDialog } from './popups/agent-popup/agent-popup.component';
import { WarehousePopupDialog } from './popups/warehouse-popup/warehouse-popup.component';
import { NewOrderPopupDialog } from './popups/new-order-popup/new-order-popup.component';
import { NewPickupInfoPopupDialog } from './popups/new-pickup-info-popup/new-pickup-info-popup.component';
import { ProductPopupDialog } from './popups/product-popup/product-popup.component';
import { ImgViewerComponent } from './popups/img-viewer/img-viewer.component';
import { TaxReportGeneratorPopup } from './popups/tax-report-generator-popup/tax-report-generator-popup.component';
import { LoaderModule } from './directives/loader/loader.module';
import { TwoDigitDecimalModule } from './directives/twoDigitDecimalNumber/2Digit.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MarketplaceDetailsComponent } from './popups/marketplace-details/marketplace-details.component';
import { PayoneerPayoutComponent } from './popups/payoneer-payout/payoneer-payout.component';
import { ConfirmPopupDialog } from './popups/confirm-popup/confirm-popup.component';
import { PaymentsGatewayComponent } from './popups/payments-gateway/payments-gateway.component';
import { CustomerPopupDialog } from './popups/customer-popup/customer-popup.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ReorderPopupDialog } from './popups/reorder-popup/reorder-popup.component';
import { ModeSelectPopupComponent } from './popups/mode-select-popup/mode-select-popup.component';
import { SaleOrderInvoiceEditForm } from './popups/sale-order-invoice-edit-form/sale-order-invoice-edit-form.component';
import { SaleOrderVatInvoiceEditForm } from './popups/sale-order-vat-invoice-edit-form/sale-order-vat-invoice-edit-form.component';
import { FinalPurchaseInvoiceEditForm } from './popups/final-purchase-invoice-edit-form/final-purchase-invoice-edit-form.component';
import { StripePayoutComponent } from './popups/stripe-payout/stripe-payout.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { VbanPopupDialog } from './popups/vban-popup/vban-popup.component';
import { HideShowFieldsComponent } from './popups/hideshow-fields/hideshowfields.component';
import { RestorePayonerAccountComponent } from './popups/restore-payoneer-account/restorepayoneraccount.component';
import { ApplyCouponDialog } from './popups/coupon-apply-popup/coupon-apply-popup.component';
import { BusinessChoosePopupDialog } from './popups/business-choose-popup/business-choose-popup.component';
import { AddNewPortPopupDialog } from './popups/add-new-port-popup/add-new-port-popup.component';
import { HscodeExcelInfoPopupComponent } from './popups/hscode-excel-info-popup/hscode-excel-info-popup.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AuthGuard } from '../core/services/auth-guard.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { ExcelInfoPopupDialog } from './popups/excel-info-popup/excel-info-popup.component';
import { OriginCountryPopupDialog } from './popups/origin-country/origin-country-popup.component';
import { AddCityPopup } from './popups/add-city-popup/add-city-popup.component';
import { AddRemarkPopup } from './popups/add-remark-popup/add-remark-popup.component';
import { TestUserPasswordDialog } from './popups/test-user-password/test-user-password.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProductImgUpload } from './popups/product-img-upload/product-img-upload.component';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { HscodeUpdatePopupComponent } from './popups/hscode-update-popup/hscode-update-popup.component';
import { AddWarehouseShopPopupComponent } from './popups/add-warehouse-shop-popup/add-warehouse-shop-popup.component';
import { ViewWarehouseShopPopupComponent } from './popups/view-warehouse-shop-popup/view-warehouse-shop-popup.component';
import { TableCheckboxComponent } from './components/table-select/table-checkbox.component';
import { ReasonPopupComponent } from './popups/reason-popup/reason-popup.component';
import { AssignVendorPopupComponent } from './popups/assign-vendor-popup/assign-vendor-popup.component';
import { ShipmentSubtypeSelectPopupComponent } from './popups/shipment-subtype-select-popup/shipment-subtype-select-popup.component';
import { ProductPopupTableComponent } from './popups/product-popup-table/product-popup-table.component';
import { SupplierPopupComponent } from './popups/supplier-popup/supplier-popup.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ProductAllocationNewComponent } from './components/product-allocation-new/product-allocation-new.component';
import { PalletItemComponent } from './components/product-allocation-new/components/pallet-item/pallet-item.component';
import { CartonItemComponent } from './components/product-allocation-new/components/carton-item/carton-item.component';
import { PackItemsComponent } from './components/product-allocation-new/components/pack-items/pack-items.component';
import { AddToExistingBatchComponent } from './components/add-to-existing-batch/add-to-existing-batch.component';
import { CreateBatchPopupComponent } from './components/create-batch-popup/create-batch-popup.component';
import { BatchService } from '../modules/batch/service/batch.service';
@NgModule({
    imports: [
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatIconModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        LoaderModule,
        TwoDigitDecimalModule,
        NgxMatSelectSearchModule,
        MatSelectInfiniteScrollModule,
        MatDialogModule,
        AddVatModule,
        DragDropModule,
        MatPaginatorModule,
        Ng2TelInputModule
    ],
    declarations: [
        ContactPopupDialog,
        WarehousePopupDialog,
        NewOrderPopupDialog,
        NewPickupInfoPopupDialog,
        ProductPopupDialog,
        ProductDetailsComponent,
        ImgViewerComponent,
        AgentPopupDialog,
        TaxReportGeneratorPopup,
        MarketplaceDetailsComponent,
        PayoneerPayoutComponent,
        ConfirmPopupDialog,
        ExcelInfoPopupDialog,
        PaymentsGatewayComponent,
        CustomerPopupDialog,
        ReorderPopupDialog,
        SaleOrderInvoiceEditForm,
        SaleOrderVatInvoiceEditForm,
        FinalPurchaseInvoiceEditForm,
        StripePayoutComponent,
        ModeSelectPopupComponent,
        ProgressBarComponent,
        VbanPopupDialog,
        HideShowFieldsComponent,
        ApplyCouponDialog,
        RestorePayonerAccountComponent,
        BusinessChoosePopupDialog,
        AddNewPortPopupDialog,
        HscodeExcelInfoPopupComponent,
        OriginCountryPopupDialog,
        AddCityPopup,
        AddRemarkPopup,
        TestUserPasswordDialog,
        ImageLoaderComponent,
        ApplyHsCodeComponent,
        ProductImgUpload,
        SpinnerLoaderComponent,
        HscodeUpdatePopupComponent,
        PaginatorComponent,
        AddWarehouseShopPopupComponent,
        ViewWarehouseShopPopupComponent,
        ChangeOrderStatusPopup,
        TableCheckboxComponent,
        AddNewStatusComponent,
        ReasonPopupComponent,
        AssignVendorPopupComponent,
        ShipmentSubtypeSelectPopupComponent,
        ProductPopupTableComponent,
        SupplierPopupComponent,
        ProductAllocationNewComponent,
        PalletItemComponent,
        CartonItemComponent,
        PackItemsComponent,
        CreateBatchPopupComponent,
        AddToExistingBatchComponent
    ],
    providers: [
        DatePipe,
        AuthGuard,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        BatchService
    ],
    exports: [
        LoaderModule,
        TwoDigitDecimalModule,
        ProgressBarComponent,
        MatDialogModule,
        ImageLoaderComponent,
        ApplyHsCodeComponent,
        SpinnerLoaderComponent,
        PaginatorComponent,
        TableCheckboxComponent,
    ]
})
export class SharedModule {
}
