import { ExpirationPolicyTypePipe } from './expiration-policy-type.pipe';
import { IncludeFilterPipe } from './include-filter.pipe';
import { OrderTypePipe } from './order-type.pipe';
import { CustomerTypePipe } from './customer-type.pipe';
import { PaymentStatusPipe } from './payment-status.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { DocTypePipe } from './doc-type.pipe';
import { DocStatusPipe } from './doc-status.pipe';
import { CarrierTypePipe } from './carrier-type.pipe';
import { InvoiceTypePipe } from './invoice-type.pipe';
import { OrderStatePipe } from './order-state.pipe ';
import { ProductSearchTypePipe } from './product-search-type.pipe';
import { ProductStatusPipe } from './product-status.pipe';
import { AuditLogTypePipe } from './audit-log-type.pipe';
import { CarrierTypeTablePipe } from './carrier-type-table.pipe';
import { ActivityRangePipe } from './activity-range.pipe';
import { ActivityRangeSumSubscriptionPipe } from './activity-range-sum-subscription.pipe';
import { ActivityRangeLabelPipe } from './activity-range-label.pipe';
import { ActivityRangeTooltipPipe } from './activity-range-tooltip.pipe';
import { RegistrationStatusPipe } from './registration-status.pipe';
import { RegistrationStatusIndVatPipe } from './registration-status-ind-vat.pipe';
import { RegistrationStatusOfflinePipe } from './registration-status-offline.pipe';
import { FilterPipe } from './filter.pipe';
import { PayoneerPayoutStatusPipe } from './payoneer-payout-status-pipe';
import { PayoutTypePipe } from './payout-type.pipe';
import { PayoneerPayinTypePipe } from './payoneer-payin-type-pipe';
import { PayinStatusPipe } from './payinstatus-type.pipe';
import { ShipmentConsoleTypePipe } from './shipment-conso-type.pipe';
import { SPISRestrictionDimensionUnitsPipe, SPISRestrictionWeightUnitsPipe, SPISRestrictionVolumeUnitsPipe } from './shipment-price.pipe';
import { VatOptionModePipe } from './vat-option-mode.pipe';
import { ConsolShipmentInvoiceTypePipe } from './consol-shipment-invoice-type.pipe';
import { PlaceStatusPipe } from './place-status.pipe';
import { SaleOrderErrorTypePipe } from './sale-order-error-type.pipe';
import { CountryNamePipe } from './country-name.pipe';
import { CronJobTypePipe } from './cron-job-type.pipe';
import { ShipmentSubtypePipe } from './shipment-subtype.pipe';
import { SoShipmentOrderIdPipe } from './so-shipment-orderId';
import { BatchStatePipe } from './batch-state.pipe';
import { BatchConsolType } from './batch-consol-type.pipe';
export const SHARED_PIPES = [
    OrderTypePipe,
    CustomerTypePipe,
    PaymentStatusPipe,
    OrderStatusPipe,
    DocTypePipe,
    DocStatusPipe,
    CarrierTypePipe,
    InvoiceTypePipe,
    OrderStatePipe,
    ProductSearchTypePipe,
    ProductStatusPipe,
    AuditLogTypePipe,
    CarrierTypeTablePipe,
    ActivityRangePipe,
    ActivityRangeSumSubscriptionPipe,
    ActivityRangeLabelPipe,
    ActivityRangeTooltipPipe,
    RegistrationStatusPipe,
    RegistrationStatusIndVatPipe,
    RegistrationStatusOfflinePipe,
    FilterPipe,
    IncludeFilterPipe,
    PayoneerPayoutStatusPipe,
    PayoutTypePipe,
    PayoneerPayinTypePipe,
    PayinStatusPipe,
    ShipmentConsoleTypePipe,
    VatOptionModePipe,
    ConsolShipmentInvoiceTypePipe,
    PlaceStatusPipe,
    SaleOrderErrorTypePipe,
    CountryNamePipe,
    CronJobTypePipe,
    SPISRestrictionDimensionUnitsPipe,
    SPISRestrictionWeightUnitsPipe,
    SPISRestrictionVolumeUnitsPipe,
    ShipmentSubtypePipe,
    SoShipmentOrderIdPipe,
    ExpirationPolicyTypePipe,
    BatchStatePipe,
    BatchConsolType
]
