import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableExporterModule} from 'mat-table-exporter';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {ColorPickerModule} from 'ngx-color-picker';

import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CoreModule} from '../../core/core.module';
import {LoaderModule} from '../../shared/directives/loader/loader.module';
import {MaterialModule} from '../material.module';
import {AllElementsComponent} from './components/invoice-forms/constructor/all-elements/all-elements.component';
import {BaseElement} from './components/invoice-forms/constructor/base-element';
import {InvoiceFormComponent} from './components/invoice-forms/invoice-form.component';
import {PackingListFormComponent} from './components/invoice-forms/packing-list-form/packing-list-form.component';
import {InvoiceManagerRoutingModule} from './invoice-manager-routing.module';
import {InvoicesComponent} from './invoices/invoices.component';
import {EditorComponent} from './editor/editor.component';
import {InvManTableSaleOrderComponent} from './components/inv-man-table-sale-order/inv-man-table-sale-order.component';
import { SaleOrderTabComponent } from './components/sale-order-tab/sale-order-tab.component';
import { ShipmentTabComponent } from './components/shipment-tab/shipment-tab.component';
import { ConsolShipmentTabComponent } from './components/consol-shipment-tab/consol-shipment-tab.component';
import { InvManTableTemplatesComponent } from './components/inv-man-table-templates/inv-man-table-templates.component';
import { SaleInvoiceFormComponent } from './components/invoice-forms/sale-invoice-form/sale-invoice-form.component';
import { ImportInvoiceFormComponent } from './components/invoice-forms/import-invoice-form/import-invoice-form.component';
import { DomesticInvoiceFormComponent } from './components/invoice-forms/domestic-invoice-form/domestic-invoice-form.component';
import { ExportInvoiceFormComponent } from './components/invoice-forms/export-invoice-form/export-invoice-form.component';
import { CommercialInvoiceFormComponent } from './components/invoice-forms/commercial-invoice-form/commercial-invoice-form.component';
import { VatInvoiceFormComponent } from './components/invoice-forms/vat-invoice-form/vat-invoice-form.component';
import { ProformaInvoiceFormComponent } from './components/invoice-forms/proforma-invoice-form/proforma-invoice-form.component';
import { ImportAirInvoiceFormComponent } from './components/invoice-forms/import-air-invoice-form/import-air-invoice-form.component';
import { ExportAirInvoiceFormComponent } from './components/invoice-forms/export-air-invoice-form/export-air-invoice-form.component';
import { DomesticAirInvoiceFormComponent } from './components/invoice-forms/domestic-air-invoice-form/domestic-air-invoice-form.component';
import { MonthlySubscriptionPaymentInvoiceFormComponent } from './components/invoice-forms/monthly-subscription-payment-invoice-form/monthly-subscription-payment-invoice-form.component';
import { RegistrationFeesInvoiceFormComponent } from './components/invoice-forms/registration-fees-invoice-form/registration-fees-invoice-form.component';
import { CancelInvoiceFormComponent } from './components/invoice-forms/cancel-invoice-form/cancel-invoice-form.component';
import { FinalPurchaseInvoiceFormComponent } from './components/invoice-forms/final-purchase-invoice-form/final-purchase-invoice-form.component';
import { ShippingInvoiceFormComponent } from './components/invoice-forms/shipping-invoice-form/shipping-invoice-form.component';
import { ServiceInvoiceFormComponent } from './components/invoice-forms/service-invoice-form/service-invoice-form.component';
import { ConsolidateShipmentVatInvoiceFormComponent } from './components/invoice-forms/consolidate-shipment-vat-invoice-form/consolidate-shipment-vat-invoice-form.component';
import { InvoiceHistoryComponent } from './components/invoice-history/invoice-history.component';
import { CustomerCompanyBlockComponent } from './components/invoice-forms/sub-forms/customer-company-block/customer-company-block.component';
import { PartnerBlockComponent } from './components/invoice-forms/sub-forms/partner-block/partner-block.component';
import { CommissionMonthlyFormComponent } from './components/invoice-forms/commission-monthly-form/commission-monthly-form.component';
import {NewSpecificTemplateComponent, NewTemplateNameDialog} from './components/new-specific-template/new-specific-template.component';
import { AssignTemplateComponent } from './assign-template/assign-template.component';
import { OrdersListComponent } from './components/orders-list/orders-list.component';
import { ConfirmAssignComponent } from './components/confirm-assign/confirm-assign.component';
import { SpecificInvoiceListDialogComponent } from './components/specific-invoice-list-dialog/specific-invoice-list-dialog.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { HeaderComponent } from './components/invoice-forms/constructor/header/header.component';
import { CustomerCompanyComponent } from './components/invoice-forms/constructor/customer-company/customer-company.component';
import { AddressComponent } from './components/invoice-forms/constructor/address/address.component';
import { MainContainerComponent } from './components/invoice-forms/constructor/main-container/main-container.component';
import {ItemsBlockComponent} from './components/invoice-forms/constructor/items-block/items-block.component';
import { CustomerComponent } from './components/invoice-forms/constructor/customer/customer.component';
import {
    ManipulateTableDialog,
    TableTemplateComponent
} from './components/invoice-forms/constructor/table-template/table-template.component';
import { ItemsContainerComponent } from './components/invoice-forms/constructor/items-container/items-container.component';
import { ItemComponent } from './components/invoice-forms/constructor/item/item.component';
import {
    GridItemStaticComponent,
    ManipulateStaticColsDialog
} from './components/invoice-forms/constructor/grid-item-static/grid-item-static.component';
import { GridItemDynamicComponent } from './components/invoice-forms/constructor/grid-item-dynamic/grid-item-dynamic.component';
import { GridItemHeaderComponent } from './components/invoice-forms/constructor/grid-item-header/grid-item-header.component';
import { PaymentTermsComponent } from './components/invoice-forms/constructor/payment-terms/payment-terms.component';
import { SpaceComponent } from './components/invoice-forms/constructor/space/space.component';
import { FreeTextComponent } from './components/invoice-forms/constructor/free-text/free-text.component';
import { SelectSourceFieldComponent } from './components/invoice-forms/constructor/select-source-field/select-source-field.component';
import { DisplayValueComponent } from './components/invoice-forms/constructor/display-value/display-value.component';


@NgModule({
    declarations: [
        InvoicesComponent,
        EditorComponent,
        InvManTableSaleOrderComponent,
        SaleOrderTabComponent,
        ShipmentTabComponent,
        ConsolShipmentTabComponent,
        InvoiceFormComponent,
        InvManTableTemplatesComponent,
        SaleInvoiceFormComponent,
        ImportInvoiceFormComponent,
        DomesticInvoiceFormComponent,
        ExportInvoiceFormComponent,
        CommercialInvoiceFormComponent,
        VatInvoiceFormComponent,
        ProformaInvoiceFormComponent,
        ImportAirInvoiceFormComponent,
        ExportAirInvoiceFormComponent,
        DomesticAirInvoiceFormComponent,
        MonthlySubscriptionPaymentInvoiceFormComponent,
        RegistrationFeesInvoiceFormComponent,
        CancelInvoiceFormComponent,
        FinalPurchaseInvoiceFormComponent,
        ShippingInvoiceFormComponent,
        ServiceInvoiceFormComponent,
        ConsolidateShipmentVatInvoiceFormComponent,
        InvoiceHistoryComponent,
        CustomerCompanyBlockComponent,
        PartnerBlockComponent,
        CommissionMonthlyFormComponent,
        NewSpecificTemplateComponent,
        NewTemplateNameDialog,
        AssignTemplateComponent,
        OrdersListComponent,
        ConfirmAssignComponent,
        SpecificInvoiceListDialogComponent,
        TemplateEditorComponent,
        HeaderComponent,
        CustomerCompanyComponent,
        AddressComponent,
        MainContainerComponent,
        BaseElement,
        ItemsBlockComponent,
        CustomerComponent,
        TableTemplateComponent,
        ManipulateTableDialog,
        AllElementsComponent,
        ItemsContainerComponent,
        ItemComponent,
        GridItemStaticComponent,
        GridItemDynamicComponent,
        GridItemHeaderComponent,
        PaymentTermsComponent,
        SpaceComponent,
        FreeTextComponent,
        ManipulateStaticColsDialog,
        SelectSourceFieldComponent,
        DisplayValueComponent,
        PackingListFormComponent,
    ],
    imports: [
        CommonModule,
        InvoiceManagerRoutingModule,
        LoaderModule,
        MaterialModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        CoreModule,
        NgxMatSelectSearchModule,
        MatTableExporterModule,
        MatTabsModule,
        MatSelectInfiniteScrollModule,
        ColorPickerModule,
    ]
})
export class InvoiceManagerModule {
}
