import { Pipe, PipeTransform } from '@angular/core';
import { IConstantItem } from './../../core/models/available-types.interface';
import * as _ from 'lodash';
import { SaleOrderInvoiceType } from '../enums/sale-order-invoice-type.enum';

@Pipe({ name: 'invoiceType' })
export class InvoiceTypePipe implements PipeTransform {
    transform(value: any, array: IConstantItem[], isSpecific?: boolean, specificName?: string) {
        if (isSpecific) {
            return specificName;
        }
        if (!array) return '';
        const elem = array.find(el => el.id === value)
        if(!elem) return '';
        if(elem.id == SaleOrderInvoiceType.IMPORT_INVOICE_AIR){
            return 'Service Invoice (IMP)';
        } if(elem.id == SaleOrderInvoiceType.COMMERCIAL_INVOICE) {
            // For Video presentation
            return 'Commercial invoice (Shipping)';
        }
        else if(elem.id == SaleOrderInvoiceType.ReturnGoodsOrder){
            return 'Return Goods Order';
        }
        else {
            return _.startCase(_.toLower(elem.name.split('_').join(' ')));
        }
    }
}
