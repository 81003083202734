export enum Roles {
    Admin = 'ADMIN',
    Customer = 'CUSTOMER',
    DelicateAdmin = 'DELICATE_ADMIN',
    DelicateCustomer = 'DELICATE_CUSTOMER',
    Marketplace = 'MARKETPLACE',
    OfflineShopOwner = 'OFFLINE_SHOP_OWNER',
    IndVatAccount = 'IND_VAT_ACCOUNT',
    PartnerAccount = 'PARTNER_ACCOUNT',
    DelegatePartnerAccount = 'DELICATE_PARTNER_ACCOUNT',
    DelegateOfflineShopOwner = 'DELEGATE_OFFLINE_SHOP_OWNER',
    ShipmentsAdmin = 'SHIPMENTS_ADMIN',
}

export enum UserRoles {
    ADMIN = 1,
    DELICATE_ADMIN = 2,
    CUSTOMER = 3,
    DELICATE_CUSTOMER = 4,
    MARKETPLACE = 5,
    OFFLINE_SHOP_OWNER = 6,
    IND_VAT_ACCOUNT = 7,
    PARTNER_ACCOUNT = 8,
    DELICATE_PARTNER_ACCOUNT = 9,
    DELEGATE_OFFLINE_SHOP_OWNER = 10,
    SHIPMENTS_ADMIN = 11,
    ECOMMIN_EXTERNAL = 12,
    THIRD_PARTY_RESTRICTED_FULLY = 13,
    THIRD_PARTY_GENERAL_TBD = 14, // TODO: TBD for now don't have any API for this role
}
