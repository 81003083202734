import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ICountry } from 'src/app/core/models/country.interface';
import { ConsolidateShipmentService } from 'src/app/core/services/consolidate-shipments.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';
import { ShipmentStatus } from 'src/app/shared/enums/shipment-status.enum';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import {PackingTypeNew, UnitsOfMeasure} from '../../../../shared/components/product-allocation-new/product-allocation-new.component';
import { ShipmentConsolStatus } from '../../../../shared/enums/shipment-consol-status.enum';
import { ShipmentSubType } from 'src/app/shared/enums/shipment-subtype.enum';

@Component({
    selector: 'eci-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class ShipmentSummaryComponent implements OnInit {
    displayedColumnsProducts: string[] = [
        'productName',
        'sku',
        'numberOfUnits',
    ];

    displayedColumnsPickupInfo: string[] = [
        'name',
        'telNo',
        'agentContactDetails',
        'dueDate',
        'pickupCode',
    ];

    displayedColumnsPacking: string[] = [
        'packingType',
        'packingQuantity',
        'length',
        'width',
        'height',
        'units',
        'unitsOfMeasure',
        'grossWeight',
        'volume',
    ];

    displayedColumnsLastMileInfo: string[] = [
        'carrier',
        'trackingNumber',
        'address'
    ];
    @Input() info: any;

    showLoader: boolean = false;

    dataSourcePackingInfo;
    dataSourcePickupInfo;
    dataSourceProducts;
    dataSourceLastMileInfo;

    companyList: any[] = [];
    availableTypes: any;
    countries: ICountry[];
    originPorts: any[];
    destinationPorts: any[];

    saleOrders: any[] = [];

    saleOrderProducts: any[] = [];
    allWarehouses: IWarehouseInfo[];

    public ShipmentSubtype = ShipmentSubType;

    constructor(
        public uploadFileSvc: UploadFileService,
        public dialogRef: MatDialogRef<ShipmentSummaryComponent>,
        private router: Router,
        private consolShipmentSvc: ConsolidateShipmentService,
        private notify: NotificationService
    ) {
    }

    get availableFileTypes(): string[] {
        return [...this.uploadFileSvc.textFileFormats, ...this.uploadFileSvc.fileFormats];
    }

    ngOnInit(): void {
        /*let products = [];
        this.saleOrders.forEach(val => {
            products = [...products, ...val.products];
        });*/
        this.dataSourceProducts = new MatTableDataSource<any>(this.saleOrderProducts);
        this.dataSourcePickupInfo = new MatTableDataSource<any>(this.info.pickupInfos);
        this.dataSourcePackingInfo = new MatTableDataSource<any>(
            this.info.packageDetails.isNew ? this.info.packageDetails.productAllocation : this.info.packageDetails.palettes
        );
        this.dataSourceLastMileInfo = new MatTableDataSource<any>(this.info.lastMile);
    }

    get isOcean(): boolean {
        return this.info.shipment.trackingType === ShipmentType.Ocean;
    }

    get isAir(): boolean {
        return this.info.shipment.trackingType === ShipmentType.Air;
    }

    companyName(id: number): string {
        return this.companyList?.find(el => Number(el.id) === id)?.name;
    }

    countryName(code: string): string {
        return this.countries?.find(el => el.code === code)?.name;
    }

    originPortName(code: string): string {
        return this.originPorts?.find(el => el.code === code || el.IATA === code)?.name;
    }

    destinationPortName(code: string): string {
        return this.destinationPorts?.find(el => el.code === code || el.IATA === code)?.name;
    }

    shipmentType(id: number): string {
        return this.availableTypes.OrderShipmentType.find(el => el.id === id)?.name;
    }

    orderType(id: number): string {
        return this.availableTypes.OrderType.find(el => el.id === id)?.name;
    }

    packingType(id: number): string {
        return this.availableTypes.PackingType.find(el => el.id === id)?.name;
    }

    packingTypeNew(element) {
        switch (element.packingType){
            case PackingTypeNew.single_carton: return 'Single carton';
            case PackingTypeNew.pallet: return 'Pallet';
            default: return '';
        }
    }

    getNumberOfCarton(element) {
        if (element.packingType === PackingTypeNew.single_carton) return 1;
        else return element.cartons.length;
    }

    unitsOfMeasure(id: number): string {
        return this.availableTypes.UnitsOfMeasure.find(el => el.id === id)?.name;
    }

    getWarehouseName(id: number): string{
        console.log(id);
        if (!this.allWarehouses) {
            return '';
        }
        return this.allWarehouses.find(el => Number(el.id) === Number(id))?.name;
    }

    get unitOfMeasureCommon(): string {
        return this.availableTypes.UnitsOfMeasure.find(el => el.id === this.info.packageDetails?.pallets[0]?.unitsOfMeasure)?.name;
    }

    editShipment() {
        this.dialogRef.close('edit');
        // this.dialogRef.close({ type: 'createPage', data: this.info, status: 'open' });
    }
    saveDraft() {
        this.info.status = ShipmentConsolStatus.DRAFT;
        this.confirm(false);
    }

    confirm(checkProducts = true) {
        const orderIds = [];
        this.saleOrders.forEach(d => {
            orderIds.push(d.id);
        });
        if (checkProducts) {
            this.info.productAllocation.forEach(prdAlcd => {
                if (Number(prdAlcd.not_allocated) !== 0) {
                    this.info.shipment.shipmentStatus = ShipmentStatus.PENDING;
                }
            });
            this.info.status = ShipmentConsolStatus.PENDING;
        }
       
        // #4790 : make pickup information optional to be consistent the create consolidate shipment with create shipment
        // if (!this.info?.pickupInfos) {
        //     this.notify.showError('Should have at least one pickup information');
        //     return;
        // }

        if (!Array.isArray(this.info?.pickupInfos)) {
            this.info.pickupInfos = [];
        }
        // this.info.saleOrders = this.saleOrders;
        this.info.orderIds = orderIds;
        delete this.info['paletform'];
        delete this.info['cartonform'];
        this.showLoader = true;

        if (this.info.packageDetails.isNew) {
            this.info.packageDetails.packingType = 'NewPackage';
            this.consolShipmentSvc.addShipmentNew(this.info).subscribe(data => {
                if (!data) {
                    this.notify.showError('Consol shipment not created for this user !!!');
                    this.showLoader = false;
                } else {
                    this.showLoader = false;
                    this.dialogRef.close();
                    this.router.navigate([`/consolidate-shipments/consol-shipment-details/${data.id}`]);
                }
            }, err => {
                console.log(err);
                this.notify.showError(err?.error?.errors?.data);
                this.showLoader = false;
            });
        } else {
            this.consolShipmentSvc.addShipment(this.info).subscribe(data => {
                if (!data) {
                    this.notify.showError('Consol shipment not created for this user !!!');
                    this.showLoader = false;
                } else {
                    this.showLoader = false;
                    this.dialogRef.close();
                    this.router.navigate([`/consolidate-shipments/consol-shipment-details/${data.id}`]);
                }
            }, err => {
                console.log(err);
                this.notify.showError(err?.error?.errors?.data);
                this.showLoader = false;
            });
        }
    }

    exit(): void {
        this.dialogRef.close();
    }

    getUnit(unitsOfMeasure: UnitsOfMeasure) {
        return UnitsOfMeasure[unitsOfMeasure];
    }
}
