import { Component, OnInit } from '@angular/core';
import {InvoiceTemplateBase} from '../invoce-template-base';

@Component({
  selector: 'eci-registration-fees-invoice-form',
  templateUrl: './registration-fees-invoice-form.component.html',
  styleUrls: ['./registration-fees-invoice-form.component.scss']
})
export class RegistrationFeesInvoiceFormComponent extends InvoiceTemplateBase implements OnInit {

  ngOnInit(): void {
      this.model = {
          ...this.template,
          ...this.invoice,
          customerCompany: {
              ...this.template.customerCompany,
              ...this.invoice.customerCompany
          }
      };

      if (!this.model.labels.cocNr) {
          this.model.labels['cocNr'] = 'COC NR:'
      }
  }

}
