import {Component, OnInit, SimpleChange} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {IProduct} from '../../../modules/customers-order-management/models/order-details.model';

export enum UnitsOfMeasure {
    'Kg' = 1,
    'Pound' = 2,
}
export enum PackingTypeNew {
    single_carton =1,
    pallet,
    carton,
}
export interface IProduct_ProductAllocation extends IProduct{
    description?: string;
    itemNumber?: string;
    allocated?: number;
    notAllocated?: number;
}
export interface IProductPackage_ProductAllocation{
    item?: IProduct_ProductAllocation;
    allocated?: number;
}
export interface IPackage_ProductAllocation {
    index?: number;
    packingType?: PackingTypeNew;
    expand?: boolean;
    isNew?: boolean;

    productsQuantity?: number;
    length?: number;
    width?: number;
    height?: number;
    cartons_qty?: number;
    unitsOfMeasure?: number;
    grossWeight?: number;
    volumetricWeight?: number;

    cartons?: IPackage_ProductAllocation[];
    products?: IProductPackage_ProductAllocation[];

    packageId?: string;
}

export interface IPackagesEvent {
    event: 'new_pallet' | 'new_carton';
    data?: any;
}
@Component({
    selector: 'eci-product-allocation-new',
    templateUrl: './product-allocation-new.component.html',
    styleUrls: ['./product-allocation-new.component.scss']
})
export class ProductAllocationNewComponent implements OnInit {

    isViewing: boolean = false;
    productList: IProduct_ProductAllocation[] = [];
    packageList: IPackage_ProductAllocation[] | any = [];
    packageListLocal: IPackage_ProductAllocation[] = [];
    isSimply: boolean = true;

    displayedColumnsProduct: string[] = ['description', 'itemNumber', 'not_allocated', 'allocated'];

    denominator = 1;
    packagesEvents = new BehaviorSubject<IPackagesEvent>(null);
    showError = false;

    productAllocation = {};
    constructor(
        private matDialogRef: MatDialogRef<ProductAllocationNewComponent>,
    ) {}

    totalNotAllocated = 0;

    ngOnInit(): void {
        this.productList.map(p => {
            if (!this.packageList.length) {
                p.notAllocated = p.units;
                p.allocated = 0;
            }

                this.totalNotAllocated += p.units;
        })

        this.packageListLocal = [...this.packageList];
        this.checkIfSimple();
    }

    save() {
        const data: any = {
            productAllocation: this.packageList,
        };
        this.matDialogRef.close({type: 'save', data});
        // if (this.validateAllocation()){
        //     const data: any = {
        //         productAllocation: this.packageList,
        //         // palets: this.palets,
        //         // packingType: this.packingType,
        //         // paletform: this.paletform,
        //         // cartonform: this.cartonform
        //     };
        //     this.matDialogRef.close({type: 'save', data});
        // } else {

        // }
    }

    validateAllocation(): boolean {
        let res = true;
        /*** Validate product allocation ***/
        if (this.isSimply) {
            if (this.totalNotAllocated - this.totalAllocated > 0) {
                res = false;
                this.showError = true;
            }
        } else {
            this.productList.map(p => {
                if (p.notAllocated > 0) {
                    res = false;
                    this.showError = true;
                }
            })
        }
        return res;
    }

    exit() {
        this.matDialogRef.close({type: 'close', data: null});
    }

    addPallet() {
        this.packagesEvents.next({
            event: 'new_pallet'
        });
    }

    addSingleCarton() {
        this.packagesEvents.next({
            event: 'new_carton'
        });
    }

    onPackChange(e: {pack: IPackage_ProductAllocation, index: number}) {
        this.updatePackage(e.pack, e.index);
        this.productAllocationFromPackages();
    }

    quantityChange(e: any) {
        if (!this.productAllocation.hasOwnProperty(e.name)) {
            this.productAllocation[e.name] = {};
        }
        this.productAllocation[e.name] = e.value;
        !this.isSimply && this.updateProductAllocation();
    }

    updateProductAllocation() {
        setTimeout(() => {
            this.productList.map(product => {
                product.allocated = 0;
                product.notAllocated = product.units;
                for (const packName in this.productAllocation) {
                    this.productAllocation[packName].map(i => {
                        if (i.hasOwnProperty(product.productId)) {
                            product.allocated += i[product.productId];
                            product.notAllocated -= i[product.productId];
                        }
                    })
                }
            });
            this.productList = [...this.productList];
        }, 0);
    }

    productAllocationFromPackages() {
        this.productAllocation = {};
        this.packageList.map(pack => {
            let packName = '';
            if (pack.packingType === PackingTypeNew.pallet) {
                pack.cartons.map(c => {

                    if (this.isSimply) {
                        this.productAllocation['Pallet ' + pack.index + ' Carton ' + c.index] = 0;
                        this.productAllocation['Pallet ' + pack.index + ' Carton ' + c.index] = c.products[0].allocated;
                    } else {
                        this.productAllocation['Pallet ' + pack.index + ' Carton ' + c.index] = [];
                        c.products.map(p => {
                            this.productAllocation['Pallet ' + pack.index + ' Carton ' + c.index].push({
                                [p.item.productId]: p.allocated
                            })
                        })
                    }
                })
            } else {
                if (this.isSimply) {
                    this.productAllocation['Single carton ' + pack.index] = 0;
                    this.productAllocation['Single carton ' + pack.index] = pack.products[0].allocated
                } else {
                    this.productAllocation['Single carton ' + pack.index] = [];
                    pack.products.map(p => {
                        if (p.item) {
                            this.productAllocation['Single carton ' + pack.index].push({
                                [p.item.productId]: p.allocated
                            })
                        }
                    })
                }
            }
        });
        !this.isSimply && this.updateProductAllocation();
    }

    get totalAllocated() {
        let total = 0;
        for (const packName in this.productAllocation) {
            total += this.productAllocation[packName];
        }
        return total;
    };

    onChangeMode(event: any) {
        this.productAllocation = {};
        this.productList.map(product => {
            product.allocated = 0;
            product.notAllocated = product.units;
        });
    }

    updatePackage(data: IPackage_ProductAllocation, index: number) {
        for (const key in this.packageList[index]) {
            this.packageList[index][key] = data[key];
        }
    }

    checkIfSimple() {
        const checkProd = (pack) => {
            pack.products.map(p => {
                if (p.productId || p.item?.productId) {
                    this.isSimply = false;
                }
            })
        }
        this.packageList.map(pl => {
            if (pl.packingType === PackingTypeNew.single_carton) {
                checkProd(pl);
            } else {
                pl.cartons.map(c => {
                    checkProd(c);
                })
            }
        })
    }
}
