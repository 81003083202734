import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthComponent } from './auth.component';
import { ComponentsModule } from '../components/components.module';
import { RestorePasswordComponent } from './restore-password/restore-password.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    ComponentsModule
  ],
  declarations: [
    AuthComponent,
    RestorePasswordComponent
  ],
  providers: [
  ]
})
export class AuthModule { }
