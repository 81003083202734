import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import {ConstantService} from 'src/app/core/services/constant.service';
import { CityService } from 'src/app/core/services/city.service';
import { OrdersService } from 'src/app/modules/customers-order-management/services/orders.service';
import { FilterPipe } from '../../pipes/filter.pipe';

interface SupplierPayload {
  id?: any; 
  companyId: any;
  firstName: any;
  lastName: any;
  companyName: any;
  vatNumber: any;
  phoneNumber: any;
  phoneCountryCode: any;
  email: any;
  countryCode: any;
  state: any;
  city: any;
  address: any;
  zipCode: any;
  remark: any;
}

@Component({
  selector: 'eci-supplier-popup',
  templateUrl: './supplier-popup.component.html',
  styleUrls: ['./supplier-popup.component.scss'],
  providers: [
    FilterPipe
  ]
})
export class SupplierPopupComponent implements OnInit {

  companyId: any;
  form: FormGroup;
  info: any;
  selectedCountry: any;
  allCountries: ICountry[];
  states: IState[] = [];
  cities: any[] = [];
  phoneObj;

  searchCountry: FormControl = new FormControl('');
  searchState: FormControl = new FormControl('');
  searchCity: FormControl = new FormControl('');

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SupplierPopupComponent>,
    private constSvc: ConstantService,
    private cityService: CityService,
    private ordersService: OrdersService,
    public filterPipe: FilterPipe,
  ) { }

  ngOnInit(): void {
    this.getInfo();
    this.getAllCountries();
  }

  getAllCountries() {
    this.constSvc.getAvailableCountries(1, 1000).subscribe(data => {
      if (data) {
          this.allCountries = data;
          if (this.info) {
            const country = this.allCountries?.find((c) => c.code === this.info?.countryCode);
            this.selectedCountry = country;
            this.onCountrySelected(this.info?.countryCode);
          }
      }
    });
  }

  getInfo() {
    this.form = this.initializeForm(this.info);
  }

  initializeForm(info: any): FormGroup { 
    return this.fb.group({
      firstName: new FormControl({ disabled: false, value: info?.firstName || null }, [Validators.required]),
      lastName: new FormControl ({ disabled: false, value: info?.lastName || null }, [Validators.required]),
      companyName: new FormControl({ disabled: false, value: info?.companyName || null }, [Validators.required]),
      vatNumber: new FormControl({ disabled: false, value: info?.vatNumber || null }, [Validators.required]),
      phone: new FormControl({ disabled: false, value: info?.phoneNumber || null }, [Validators.required]),
      phoneCountryCode: [{disabled: false, value: info?.phoneCountryCode || '1'}],
      phoneCountryCodeIso2: ['us'],
      email: new FormControl({ disabled: false, value: info?.email || null }, [Validators.required]),
      country: new FormControl({ disabled: false, value: info?.countryCode || null }, [Validators.required]),
      state: new FormControl ({ disabled: false, value: info?.state || null }, [Validators.required]),
      city: new FormControl({ disabled: false, value: info?.city || null }, [Validators.required]),
      address: new FormControl({ disabled: false, value: info?.address || null }, [Validators.required]),
      zipCode: new FormControl({ disabled: false, value: info?.zipCode || null }, [Validators.required]),
      remark: new FormControl({ disabled: false, value: info?.remark || null })
    });
  }

  save() {
    let payload: SupplierPayload = {
      companyId: this.companyId,
      firstName: this.form.get("firstName").value,
      lastName: this.form.get("lastName").value,
      companyName: this.form.get("companyName").value,
      vatNumber: this.form.get("vatNumber").value,
      phoneNumber: this.form.get("phone").value,
      phoneCountryCode: this.form.get("phoneCountryCode").value,
      email: this.form.get("email").value,
      countryCode: this.form.get("country").value,
      state: this.form.get("state").value,
      city: this.form.get("city").value,
      address: this.form.get("address").value,
      zipCode: this.form.get("zipCode").value,
      remark: this.form.get("remark").value
    }

    if (this.info && this.info.id) {
      payload.id = this.info.id;
    } else {
      delete payload.id;
    }

    this.ordersService.createSupplier(payload).subscribe((data) => {
      this.dialogRef.close({status: "updated", response: data});
    });
  }

  getNumber(e): void {
    const value = e.replace(`+${this.form?.controls?.phoneCountryCode.value}`, '');
    this.form.controls.phone.setValue(value);
  }

  onCountryChange(countryPhoneCode): void {
    this.form?.controls?.phoneCountryCode.setValue(countryPhoneCode.dialCode);
  }

  public telInputObject(event, name): void {
    this[name] = event;
  }

  onCountrySelected(countryCode: any): void {
    const country = this.allCountries?.find((c) => c.code === countryCode);
    this.selectedCountry = country;
    const selectedPhoneCode = country?.phoneCode.replace(/\+/g, '');
    const selectedCodeIso2 = country?.code.toLowerCase();
    this.form.get('phoneCountryCode')?.setValue(selectedPhoneCode);
    this.form.get('phoneCountryCodeIso2')?.setValue(selectedCodeIso2);
    this.phoneObj.setCountry(this.form?.get('phoneCountryCodeIso2')?.value);

    if (countryCode && country) {
      this.getStates(countryCode);
      this.getCities(country);
    }
  }

  getStates(selectedCountry: any): void {
    if (!selectedCountry) return;

    this.constSvc.getAvailableStatesById(selectedCountry).subscribe(states => {
      if (!states) return;

      this.states = states;
    });
  }

  getCities(selectedCountry: any): void {
    if (!selectedCountry) return;

    this.cityService.getAllByCountryId(selectedCountry?.id).subscribe(cities => {
      if (!cities) {
        this.form.get('city').setValue(null);
        return;
      }
      this.cities = cities;
    });
  }

  getFlagClass(code: any) {
    if (!code) return '';
    const c = this.allCountries?.find(c => c.id === code)?.code;
    return `iti__${c?.toLowerCase()}`;
  }

  exit() {
    this.dialogRef.close();
  }
}
