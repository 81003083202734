import { Pipe, PipeTransform } from '@angular/core';
import {EPaymentStatus} from '../payments-type.enum';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(value: EPaymentStatus, ...args: unknown[]): string {
      switch (value) {
          case EPaymentStatus.Paid: return 'Paid';
          case EPaymentStatus.Failed: return 'Failed';
          case EPaymentStatus.Pending: return 'Pending';
          case EPaymentStatus.OnHold: return 'On Hold';
          case EPaymentStatus.Collection: return 'Collection';
          case EPaymentStatus.Aborted: return 'Aborted';
          case EPaymentStatus.Refund: return 'Refunded';
      }
  }

}
