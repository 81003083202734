import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'add-city-popup',
  templateUrl: './add-city-popup.component.html',
  styleUrls: ['./add-city-popup.component.scss'],
})
export class AddCityPopup implements OnInit {

  form: FormGroup;

  @Input() countryId: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCityPopup>,
  ) {
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      city: [null, [Validators.required]],
      zipCode: [null],
    });
  }
}
