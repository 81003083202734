export enum ShipmentConsolStatus {
    CONSOL_CREATED = 1,
    PAYMENT_FAILED,
    PAID,
    IN_PROGRESS,
    CUSTOM_CLEARANCE_PROCESS,
    CUSTOMS_CLEARED,
    SHIPMENT_DELIVERED,
    CANCELED,
    DELIVERED,
    PENDING,
    NEW,
    DRAFT,
    COMPLETED,
    COLLECTED,
    IN_TRANSIT,
    PENDING_COLLECTION,
    PENDING_CUSTOMER_COLLECTION,
    DELIVERY_ATTEMPT_FAILED,
    DELIVERY_ADDRESS_CHANGED,
    DELIVERY_REFUSED,
    CUSTOM_DELAY,
    SHIPMENT_DELAYED,
    DELAYED_DUE_PAYMENT,
    INCORRECT_ADDRESS,
    SHIPMENT_REJECTED,
    SHIPMENT_RETURNED,
    SHIPMENT_LOST,
    SHIPMENT_ARRIVED_DESTINATION_PORT,
    CUSTOM_CLEARED,
    SHIPMENT_BACK_2_TRANSIT,
    NEW_ADDRESS_RECEIVED,
    DELIVERY_IN_PROCESS,
    SHIPMENT_CANCELED,
    DELAYED,
    PENDING_DELIVERY
}
