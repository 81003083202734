import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'eci-change-order-status-popup',
    templateUrl: './change-order-status-popup.html',
    styleUrls: ['./change-order-status-popup.scss']
})
export class ChangeOrderStatusPopup {

    missingProperties: {
        missingProps: any[];
        missingPropsProducts: {
            name: string;
            sku: string;
            missingProperties: any[]
        }[],
    };

    constructor(
        public dialogRef: MatDialogRef<ChangeOrderStatusPopup>,
    ) {}
}
