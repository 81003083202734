import { Pipe, PipeTransform } from '@angular/core';
import {InvoiceType} from '../../../shared/enums/invoice-type.enum';

@Pipe({
  name: 'paymentInvoiceType'
})
export class PaymentInvoiceTypePipe implements PipeTransform {

  transform(value: InvoiceType, ...args: unknown[]): string {
      switch (value) {
          case InvoiceType.REGISTRATION_FEES: return 'Registration';
          case InvoiceType.MONTHLY_SUBSCRIPTION_PAYMENT: return 'Monthly subscription';
          case InvoiceType.MONTHLY_COMMISSION: return 'Vat & Com';
          default: return '';
      }
  }

}
