import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IAvailableTypes, IConstantItem} from 'src/app/core/models/available-types.interface';
import {UserService} from 'src/app/core/services/user.service';
import {MeasurementType} from 'src/app/shared/enums/measurement-type.enum';
import {WeightType} from 'src/app/shared/enums/weight-type.enum';
import {ProductAllocationNewComponent} from '../../../../../shared/components/product-allocation-new/product-allocation-new.component';
import {IOrderDetails} from '../../../models/order-details.model';
import {IPalette} from '../../../models/palette.interface';
import {CargoType} from 'src/app/shared/enums/cargo-type.enum';
import {ShipmentType} from 'src/app/shared/enums/shipment-type.enum';
import {ICompany} from 'src/app/modules/customer-registration/models/company.model';
import {ProductAllocation} from 'src/app/modules/customers-order-management/models/product-allocation.interface';
import {IProduct} from 'src/app/modules/customers-order-management/models/order-details.model';
import {NotificationService} from 'src/app/core/services/notification.service';
import {MatDialog} from '@angular/material/dialog';

import {ProductAllocationModalComponent} from 'src/app/shared/components/product-allocation-modal/product-allocation-modal.component';
import {ProductAllocationCartonComponent} from 'src/app/shared/components/product-allocation-carton/product-allocation-carton.component';
import {PalletDetailsTableComponent} from './pallet-details-table/pallet-details-table.component';
import {PalletService} from '../../../services/pallet.service';

const packingTypeArray = [
    {name: 'Single Carton', checked: false},
    {name: 'Pallet', checked: false},
];

@Component({
    selector: 'eci-consolidate-shipments-package-details',
    templateUrl: './consolidate-shipments-package-details.component.html',
    styleUrls: ['./consolidate-shipments-package-details.component.scss'],
    providers: [DatePipe]
})
export class ConsolidateShipmentsPackageDetailsComponent implements OnInit {

    @ViewChild(PalletDetailsTableComponent) palletDetailComponent: PalletDetailsTableComponent;

    displayedColumns: string[] = [
        'packingType',
        'width',
        'height',
        'length',
        'units',
        // 'weight',
        // 'chargeableWeight',
        'grossWeight',
        'unitsOfMeasure',
        'volume',
        'delete'
    ];
    dataSource = [];

    @Input() summaryPage = false;
    @Input() info: any;
    @Input() denominators: any;
    @Input() isCreateMode: boolean = true;
    @Input() availableTypes: IAvailableTypes;
    @Input() hasError: boolean;
    @Input() currentOrderType: number;
    @Input() currShipmentType: number;
    @Input() showErrors: boolean;
    @Input() companyInfo: ICompany;
    @Input() saleOrderProducts: IProduct[];
    @Input() isEditable: boolean;

    pallets: IPalette[] = [];
    sub$: Subscription = new Subscription();
    CargoType = CargoType;
    ShipmentType = ShipmentType;

    availableContainerTypes: IConstantItem[];

    @Output() infoChange = new EventEmitter<IOrderDetails>();
    @Output() hasErrorChange = new EventEmitter<boolean>();

    form: FormGroup;

    currentWeightType: string = WeightType.kg;
    currentMeasuremenrType: string = MeasurementType.cm;
    tempValueGrossWeightLCL: any = '';
    tempValueTotalVolumeLCL: any = '';
    tempValueGrossWeightFCL: any = '';
    tempValueTotalVolumeFCL: any = '';

    packingTypeArray = packingTypeArray;
    packingType = '';
    totalpalet: any = 0;
    platnumber: any = 0;
    showpalletform: boolean = false;
    paletform!: FormGroup;
    cartonform!: FormGroup;
    palets = new FormArray([]);
    catoon = new FormArray([]);
    cartonFormArray = new FormArray([]);
    myProductsList: IProduct[];

    productAllocation: ProductAllocation[] = [];
    newProductAllocation = true;

    constructor(
        public userSvc: UserService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private notify: NotificationService,
        private dialog: MatDialog,
        private palletSvc: PalletService
    ) {
    }

    ngOnInit(): void {
        this.myProductsList = [];
        this.saleOrderProducts.forEach((prd: any) => {
            this.myProductsList.push({
                id: prd?.id,
                description: prd?.description,
                productId: prd.id,
                itemNumber: prd?.product?.sku,
                units: prd?.units,
                categoryId: prd?.product?.category?.id,
                price: prd?.price,
            } as IProduct);
        });

        this.packingTypeArray.forEach(el => el.checked = false);

        this.paletform = new FormGroup({
            palets: new FormArray([]),
        });

        this.cartonform = new FormGroup({
            cartons: new FormArray([]),
        });

        this.form = this.initializeForm(this.info);
        if (!this.info || !this.info.packageDetails) {
            this.pallets = [];
        } else {
            this.pallets = this.info.packageDetails.pallets;

            /* new code */
            this.packingType = this.info.packageDetails.packingType;
            // if (this.info.packageDetails.packingType === 'SingleCarton') {
            //   this.changePackingStatus(this.packingTypeArray[0]);
            // } else {
            //   this.changePackingStatus(this.packingTypeArray[1]);
            // }
            if (((this.summaryPage && !this.isCreateMode) || (!this.summaryPage && this.isCreateMode)) && !this.info.status) {
                this.generatePallatCartonForms();
            }
        }
        if ((!this.isCreateMode && this.summaryPage && this.info?.status)) {
            this.generatePallatCartonForms();
        }

        this.dataSource = this.pallets;

        this.form?.valueChanges.subscribe(_ => {
            this.info = this.model;
            this.info.packageDetails.pallets = this.pallets;
            this.info.productAllocation = this.productAllocation;
            this.info.cartonform = this.cartonform;
            this.info.paletform = this.paletform;
            this.infoChange.emit(this.info);
            this.hasErrorChange.emit(this.error);
            this.getFormValidationErrors();
        });
        this.hasErrorChange.emit(this.error);

        if (this.info && this.info.packageDetails?.packingType === 'NewPackage') {
            this.newProductAllocation = true;
            this.productAllocation = this.info.packageDetails.shipmentPackages;
            this.calculateDataNew();
        } else {
            this.newProductAllocation = false;
        }
    }

    getFormValidationErrors() {
        Object.keys(this.form.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    updateInfo() {
        this.info = this.model;
        this.info.packageDetails.pallets = this.pallets;
        this.info.productAllocation = this.productAllocation;
        this.info.cartonform = this.cartonform;
        this.info.paletform = this.paletform;
        this.infoChange.emit(this.info);
    }

    generatePallatCartonForms() {
        if (this.info.packageDetails.packingType === 'SingleCarton') {
            this.cartonform = this.info.cartonform as FormGroup;
        } else if (this.info.packageDetails.packingType === 'Pallets') {
            this.paletform = this.info.paletform as FormGroup;
        } else {
            this.cartonform = this.info.cartonform as FormGroup;
            this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
            this.paletform = this.info.paletform as FormGroup;
            this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
        }
        this.productAllocation = this.info.productAllocation;
    }

    ngAfterViewInit() {

        if ((!this.isCreateMode && !this.summaryPage && this.info?.status) || (this.isCreateMode && !this.summaryPage && this.info?.status)) {

            this.info.shipment?.shipmentConsolPalletCarton.forEach((palletObject, pIndex) => {
                let isSingelCarton = palletObject.noOfCartons == -1;
                palletObject.shipmentConsolPalletCartonDetail.forEach(async (cartonObject, cIndex) => {
                    // tslint:disable-next-line:max-line-length
                    const matchedRecord = this.myProductsList.filter((data: IProduct) => (Number(data.productId) === Number(cartonObject.productId)));
                    const allocatedQty = await this.getNotAllocatedProducts(matchedRecord[0]);
                    if (isSingelCarton) {
                        this.productAllocation.push({
                            'cartons': cartonObject.cartonIndex,
                            'product_name': matchedRecord[0]?.description,
                            'product_id': cartonObject.productId,
                            'sku': matchedRecord[0]?.itemNumber,
                            'total_qty': matchedRecord[0]?.units,
                            'not_allocated': (Number(matchedRecord[0]?.units) - allocatedQty),
                            'allocated': cartonObject.quantity
                        });
                    } else {
                        this.productAllocation.push({
                            'pallets': palletObject.palletCartonIndex,
                            'cartons': cartonObject.cartonIndex,
                            'pallet_carton': ((Number(palletObject.palletCartonIndex) * 10) + cartonObject.cartonIndex),
                            'product_name': matchedRecord[0]?.description,
                            'product_id': cartonObject.productId,
                            'sku': matchedRecord[0]?.itemNumber,
                            'total_qty': matchedRecord[0]?.units,
                            'not_allocated': (Number(matchedRecord[0]?.units) - allocatedQty),
                            'allocated': cartonObject.quantity
                        });
                    }

                });
                if (isSingelCarton) {
                    this.onChangeCartons();
                } else {
                    this.onChangePallet();
                    for (let j = 0; j < Number(palletObject.noOfCartons); j++) {
                        this.changePalletNumber(pIndex);
                    }
                }
            });
            setTimeout(() => {
                this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
            }, 2000);
            this.palletSvc.paletColUpdateRecord.next({productAllocation: this.productAllocation, pallets: this.pallets});
        }
    }

    async getNotAllocatedProducts(product: IProduct) {
        let totalQty = 0;
        this.info.shipment?.shipmentConsolPalletCarton.forEach((palletObject, pIndex) => {
            palletObject.shipmentConsolPalletCartonDetail.forEach((cartonObject, cIndex) => {
                if (Number(cartonObject?.productId) === Number(product?.productId)) {
                    totalQty += Number(cartonObject.quantity);
                }
            });
        });
        return totalQty;
    }

    createPallet(): FormGroup {
        return this.formBuilder.group({
            palletNumber: '',
            palletIndex: '',
            cartonNumbers: new FormArray([]),
        });
    }

    createCarton(): FormGroup {
        return this.formBuilder.group({
            catoon: ''
        });
    }

    palletForm(): FormArray {
        return this.paletform.get('palets') as FormArray;
    }

    addPallet(): void {
        this.palets = this.paletform.get('palets') as FormArray;
        this.palets.push(this.createPallet());
    }

    getcartoons(empIndex: number): FormArray {
        return this.palletForm()
            .at(empIndex)
            .get('cartonNumbers') as FormArray;
    }

    addCarton(empIndex: number) {
        this.getcartoons(empIndex).push(this.createCarton());
    }

    onChangePallet() {
        this.addPallet();
        this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
    }

    clearFormArray = (formArray: FormArray) => {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    };

    removePallet(i) {
        this.palletForm().removeAt(i);
        this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
    }

    removePalletCarton(event, i, j) {
        if (this.productAllocation.length == 0) {
            this.getcartoons(i).removeAt(j);
            this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
        } else {
            let flag = 0;
            this.productAllocation.forEach(element => {
                if (Number(element.pallets) == i && Number(element.cartons) == j) {
                    if (Number(element.allocated) !== 0) {
                        flag++;
                    }
                }
            });
            if (flag === 0) {
                this.getcartoons(i).removeAt(j);
                this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
            } else {
                this.notify.showError('Product is allocated to this Carton');
            }
        }
    }

    removeSingleCarton(event, i) {
        if (this.productAllocation.length == 0) {
            this.cartoonForm().removeAt(i);
            this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
        } else {
            let flag = 0;
            this.productAllocation.forEach(element => {
                if (Number(element.cartons) === i) {
                    if (Number(element.allocated) !== 0) {
                        flag++;
                    }
                }
            });

            if (flag === 0) {
                this.cartoonForm().removeAt(i);
                this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
            } else {
                this.notify.showError('Product is allocated to this Carton');
            }
        }
    }

    changePalletNumber(index: any) {
        this.palletForm().at(index).get('palletIndex').setValue(index);
        this.addCarton(index);
        this.palletForm().at(index).get('palletNumber').setValue((this.getcartoons(index).value.length));
    }

    allocateProduct(palletIndex, cartonIndex, detailedView = true) {
        const dialogRef = this.dialog.open(ProductAllocationModalComponent, {
            height: '95%',
            width: '900px',
            disableClose: true
        });
        dialogRef.componentInstance.paletform = this.paletform;
        dialogRef.componentInstance.cartonform = this.cartonform;
        dialogRef.componentInstance.packingType = this.packingType;
        dialogRef.componentInstance.productList = this.myProductsList;
        dialogRef.componentInstance.palletIndex = -1;
        dialogRef.componentInstance.cartonIndex = -1;
        dialogRef.componentInstance.productAllocation = this.productAllocation;
        dialogRef.componentInstance.detailedView = detailedView;
        dialogRef.componentInstance.isCreateMode = this.isCreateMode;

        dialogRef.afterClosed().subscribe(result => {
            if (result.type === 'save') {
                this.productAllocation = result.data.productAllocation as ProductAllocation[];
                //this.pallets=result.data.palets;
                this.packingType = result.data.packingType;
                this.paletform = result.data.paletform;
                this.cartonform = result.data.cartonform;
                this.updateInfo();
                //  this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
                this.palletSvc.paletColUpdateRecord.next({productAllocation: this.productAllocation, pallets: this.pallets});
            }
        });
    }

    allocateProductNew(detailedView = true) {
        const dialogRef = this.dialog.open(ProductAllocationNewComponent, {
            height: '95%',
            width: '95%',
            disableClose: true
        });
        dialogRef.componentInstance.productList = this.myProductsList;
        dialogRef.componentInstance.packageList = this.productAllocation;
        dialogRef.componentInstance.isViewing = !detailedView;
        dialogRef.componentInstance.denominator = this.isOcean ? this.denominators.oceanDenominator : this.denominators.airDenominator;
        dialogRef.afterClosed().subscribe(result => {
            if (result.type === 'save') {
                this.newProductAllocation = true;
                this.productAllocation = result.data.productAllocation;
                this.calculateDataNew();
            }
        });
    }

    onChangeCartons() {
        //  this.clearFormArray(this.cartoonForm())
        this.catoon = this.cartonform.get('cartons') as FormArray;
        this.catoon.push(this.createCarton());
        this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
    }

    cartoonForm(): FormArray {
        return this.cartonform.get('cartons') as FormArray;
    }

    allocateProductCartons(cartonIndex) {
        const dialogRef = this.dialog.open(ProductAllocationCartonComponent, {
            height: '95%',
            width: '900px',
            disableClose: true
        });
        dialogRef.componentInstance.cartonform = this.cartonform;
        dialogRef.componentInstance.productList = this.myProductsList;
        dialogRef.componentInstance.cartonIndex = cartonIndex;
        dialogRef.componentInstance.productAllocation = this.productAllocation;
        dialogRef.componentInstance.detailedView = true;
        dialogRef.afterClosed().subscribe(result => {
            if (result.type === 'save') {
                this.productAllocation = result.data as ProductAllocation[];
                // this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
                this.palletSvc.paletColUpdateRecord.next({productAllocation: this.productAllocation, pallets: this.pallets});
            }
        });
    }

    get error(): boolean {
        return !this.form?.valid;
    }

    changePackingStatus(packingTypeSingle) {
        this.productAllocation = [];
        this.packingTypeArray.forEach(el => el.checked = false);
        packingTypeSingle.checked = !packingTypeSingle.checked;
        if (packingTypeArray[0].checked) {
            this.packingType = 'SingleCarton';
        } else {
            this.packingType = 'Pallets';
        }
        this.form.get('packType').setValue(this.packingType);
    }

    reviewProductAllocation() {
        if (packingTypeArray[0].checked) {
            this.allocateProductCartons(0);
        } else {
            this.allocateProduct(0, 0, false);
        }
    }

    reviewProductAllocationNew() {
        this.allocateProductNew(false)
    }

    ngOnChanges(changes: SimpleChange) {
        if (changes['info']) {
            if (this.currShipmentType !== this.info?.shipment?.trackingType) {
                this.currShipmentType = this.info?.shipment?.trackingType;
                if (this.currShipmentType !== ShipmentType.Ocean && this.tempValueGrossWeightLCL !== '') {
                    this.changeValues({value: 0});
                }
                if (this.currShipmentType == ShipmentType.Ocean && this.tempValueGrossWeightFCL !== '') {
                    this.changeValues({value: 1});
                }
                if (this.currShipmentType === ShipmentType.Ocean) {
                    this.form?.get('containerType').setValidators(Validators.required);
                }
            }
            if (this.currShipmentType === ShipmentType.Ocean && !this.form?.get('cargoType').value) {
                this.form?.get('cargoType').setValue(0);
            }

            if (
                (
                    this.info?.packageDetails?.pallets?.length <= 0 &&
                    !this.newProductAllocation
                ) ||
                Number(this.info?.packageDetails?.chargeableWeight
                ) <= 0) {
                this.hasErrorChange.emit(true);
            }
        }

        if (changes['showErrors']) {
            if (this.showErrors) {
                this.form?.markAllAsTouched();
            }
        }
    }

    get isOcean(): boolean {
        return this.currShipmentType === ShipmentType.Ocean;
    }

    get packageTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.PackingType;
    }

    get weightUnits(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.UnitsOfMeasure.filter(el => el.id === 1);
    }

    isDatePickerValid(item: string): boolean {
        if (!item) {
            return;
        }
        const el = this.form?.get(item);
        return el.value !== null;
    }

    initializeForm(info: any): FormGroup {
        const form = this.formBuilder.group({
            totalUnits: [{disabled: !this.isCreateMode || this.isEditable, value: info?.packageDetails?.units || 0}, [Validators.required]],
            /*totalPallets: [{
                disabled: !this.isCreateMode || this.isEditable,
                value: info?.packageDetails?.pallets?.length || 0
            }, [Validators.required]],*/
            totalWeight: [{disabled: !this.isCreateMode, value: info?.packageDetails?.weight || 0}, [Validators.required]],
            totalVolume: [{
                disabled: !this.isCreateMode || this.isEditable,
                value: info?.packageDetails?.volume || 0
            }, [Validators.required]],
            totalGrossWeight: [{
                disabled: !this.isCreateMode || this.isEditable,
                value: parseFloat(info?.packageDetails?.grossWeight)?.toFixed(2) || (0).toFixed(2)
            }, [Validators.required]],
            volCBM: [{disabled: !this.isCreateMode, value: info?.packageDetails?.volCBM?.toFixed(2) || (0).toFixed(2)}],
            totalVolWeight: [{disabled: !this.isCreateMode, value: info?.packageDetails?.weight || 0}, [Validators.required]],
            totalChargWeight: [{
                disabled: !this.isCreateMode || this.isEditable,
                value: Number(info?.packageDetails?.chargeableWeight).toFixed(2) || 0
            }, [Validators.required]],
            packingDate: [{disabled: !this.isCreateMode, value: new Date(info?.packageDetails?.packingDate) || ''}, [Validators.required]],
            packType: [{disabled: !this.isCreateMode, value: this.packingType}],
            cargoType: [{
                disabled: !this.isCreateMode,
                value: info?.packageDetails?.cargoType !== undefined || info?.packageDetails?.cargoType !== null ? info?.packageDetails?.cargoType : CargoType.FCL
            }],
            hsSmall: [{disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.hsSmall || 0}],
            hsBig: [{disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.hsBig || 0}],
            dvSmall: [{disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.dvSmall || 0}],
            dvBig: [{disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.dvBig || 0}],
            containerType: [{disabled: !this.isCreateMode, value: info?.packageDetails?.oceanPackage?.containerType || null},],
            palletQty: [],
            singleCartonQty: [],
            totalProducts: [{disabled: !this.isCreateMode || this.isEditable, value: 0}],
        });

        if (!this.newProductAllocation) {
            this.form.addControl('totalPallets', this.formBuilder.control({
                disabled: !this.isCreateMode || this.isEditable,
                value: info?.packageDetails?.pallets?.length || 0
            }, [Validators.required]));
        }
        return form;
    }

    get model(): IOrderDetails {
        return {
            ...this.info,
            packageDetails: {
                units: this.form?.get('totalUnits').value,
                volume: parseFloat(this.form?.get('totalVolume').value) || 0,
                grossWeight: this.form?.get('totalGrossWeight').value,
                volCBM: parseFloat(this.form?.get('volCBM')?.value),
                weight: this.form?.get('totalVolWeight').value,
                insuranceRate: 0,
                chargeableWeight: this.form?.get('totalChargWeight').value,
                packingDate: this.form?.get('packingDate').value != 'Invalid Date' ? this.datePipe.transform(this.form?.get('packingDate').value, 'yyy-MM-dd') : '',
                packingType: this.packingType,
                pallets: this.pallets,
                productAllocation: this.productAllocation,
                palets: this.paletform.value,
                cartons: this.cartonform.value,
                cargoType: this.currShipmentType === ShipmentType.Ocean ? this.form?.get('cargoType').value : null,
                oceanPackage: this.showContainers ? {
                    dvBig: parseInt(this.form?.get('dvBig').value) || 0,
                    dvSmall: parseInt(this.form?.get('dvSmall').value) || 0,
                    hsBig: parseInt(this.form?.get('hsBig').value) || 0,
                    hsSmall: parseInt(this.form?.get('hsSmall').value) || 0,
                    containerType: this.form?.get('containerType').value || null
                } : null,
                totalProducts: this.form?.get('totalProducts').value,
                isNew: this.newProductAllocation
            }
        };
    }

    calculateData(el, chaneDataSource: boolean = false): void {
        this.pallets = el;
        this.form?.get('totalUnits').setValue(this.calculateUnits);
        this.form?.get('totalWeight').setValue(this.calculateWeight);
        this.form?.get('totalPallets').setValue(this.pallets.length);
        this.form?.get('totalVolume').setValue(this.calculateVolume.toFixed(2));
        this.form?.get('totalChargWeight').setValue(this.calculateChargeableWeight.toFixed(2));
        this.form?.get('totalGrossWeight').setValue(this.calculateGrossWeight.toFixed(2));

        let totalProducts = 0;
        this.productAllocation.forEach((prodAllocat: any) => {
            totalProducts += Number(prodAllocat.allocated);
        });

        this.form.get('totalProducts').setValue(totalProducts);

        if (chaneDataSource) {
            this.dataSource = [...this.pallets];
        }
    }

    calculateDataNew(): void {

        let totalProducts = 0;
        let totalGrossWeight = 0;
        let totalVolumetricWeight = 0;

        this.productAllocation.map(p => {
            totalProducts = totalProducts + (p as any).productsQuantity;
            totalGrossWeight = totalGrossWeight + (p as any).grossWeight;
            totalVolumetricWeight = totalVolumetricWeight + (p as any).volumetricWeight;
        });

        this.form.get('totalProducts').setValue(totalProducts);
        this.form?.get('totalUnits').setValue(this.productAllocation.length);
        this.form?.get('totalVolume').setValue(totalVolumetricWeight);
        this.form?.get('totalGrossWeight').setValue(totalGrossWeight);
        this.form?.get('totalChargWeight').setValue(totalGrossWeight > totalVolumetricWeight ? totalGrossWeight : totalVolumetricWeight);

        this.info = this.model;
        this.info.packageDetails.productAllocation = this.productAllocation;
        this.infoChange.emit(this.info);
        this.hasErrorChange.emit(this.error);
    }

    changeValues(event) {
        if (event.value == 0) {
            this.tempValueTotalVolumeFCL = this.form?.value.totalGrossWeight;
            this.tempValueGrossWeightFCL = this.form?.value.totalVolume;
            this.form?.get('totalGrossWeight').setValue(this.tempValueGrossWeightLCL);
            this.form?.get('totalVolume').setValue(this.tempValueTotalVolumeLCL);
        }
        if (event.value == 1) {
            this.tempValueTotalVolumeLCL = this.form?.value.totalGrossWeight;
            this.tempValueGrossWeightLCL = this.form?.value.totalVolume;
            this.form?.get('totalGrossWeight').setValue(this.tempValueGrossWeightFCL);
            this.form?.get('totalVolume').setValue(this.tempValueTotalVolumeFCL);
        }
    }

    ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }

    get calculateUnits(): number {
        return this.pallets.reduce((sum, el) => sum + el.units, 0);
    }

    get calculateVolume(): number {
        return this.pallets.reduce((sum, el) => sum + parseFloat(el.volume?.toString()), 0);
    }

    get calculateGrossWeight(): number {
        return this.pallets.reduce((sum, el) => sum + parseFloat(el.grossWeight?.toString()), 0);
    }

    get calculateWeight(): number {
        return this.pallets.reduce((sum, el) => sum + parseFloat(el.weight?.toString()), 0);
    }

    get calculateChargeableWeight(): number {
        const grossW = this.pallets.reduce((sum, el) => sum + parseFloat(el.grossWeight?.toString()), 0);
        const volumaticW = this.pallets.reduce((sum, el) => sum + parseFloat(el.volume?.toString()), 0);
        return grossW > volumaticW ? grossW : volumaticW;
    }

    changeTo0(field: string): void {
        this.form?.get(field).value < 0 ? this.form?.get(field).setValue(0) : '';
    }

    get showContainers(): boolean {
        if (!this.form) {
            return;
        }
        return parseInt(this.form?.get('cargoType').value) === CargoType.FCL && this.currShipmentType === ShipmentType.Ocean;
    }

    get showVolCBM(): boolean {
        if (!this.form) {
            return;
        }
        return parseInt(this.form?.get('cargoType').value) === CargoType.FCL && this.currShipmentType === ShipmentType.Ocean;
    }

    resetPalletsValidators(): void {
        this.form?.get('totalUnits').setValidators(null);
        this.form?.get('totalPallets').setValidators(null);
        this.form?.get('totalWeight').setValidators(null);
        this.form?.get('totalVolume').setValidators(null);
        this.form?.get('totalGrossWeight').setValidators(null);
        this.form?.get('totalVolWeight').setValidators(null);
        this.form?.get('totalChargWeight').setValidators(null);
    }

    setPalletsValidators(): void {
        this.form?.get('totalUnits').setValidators([Validators.required]);
        this.form?.get('totalPallets').setValidators([Validators.required]);
        this.form?.get('totalWeight').setValidators([Validators.required]);
        this.form?.get('totalVolume').setValidators([Validators.required]);
        this.form?.get('totalGrossWeight').setValidators([Validators.required]);
        this.form?.get('totalVolWeight').setValidators([Validators.required]);
        this.form?.get('totalChargWeight').setValidators([Validators.required]);
    }

    get isContainerChoosed(): boolean {
        return this.amountOfContainers > 0;
    }

    get amountOfContainers(): number {
        if (!this.form) {
            return;
        }
        return parseInt(this.form?.get('hsSmall').value) +
            parseInt(this.form?.get('hsBig').value) +
            parseInt(this.form?.get('dvSmall').value) +
            parseInt(this.form?.get('dvBig').value);
    }

    fixDecimal(formControlName: any, numberAfterDot): void {
        const valueToSet = parseFloat(this.form?.get(formControlName)?.value)?.toFixed(numberAfterDot);
        this.form?.get(formControlName)?.setValue(valueToSet);
    }
}
