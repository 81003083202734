import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { IConstantItem } from 'src/app/core/models/available-types.interface';

@Pipe({ name: 'batchState', pure: false })
export class BatchStatePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case 0: return 'New Batch';
            case 1: return 'Open Regular';
            case 2: return 'Close Regular';
            case 3: return 'Open Return';
            case 4: return 'Close Batch';
        }
    }
}