import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { ApiService } from '../../../core/services/api.service';
import { map } from 'rxjs/operators';
@Injectable()
export class HsCodesService extends ApiService {
    public checkRoutItem:boolean = false;
    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService
    ) {
        super(http, storageBrowser);
    }

    getList(page: number = 1, limit: number = 100, q: string = '', description: string = '', hscode: string = '', countryCodes: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `hs-codes`,
            query: {
                q: q,
                description: description,
                limit: limit,
                page: page,
                hscode: hscode,
                countryCodes: countryCodes
            }
        });
    }

    getListHsCode(q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `hs-codes/search-hs-code`,
            query: {
                q: q,
            }
        });
    }
    createfileData (body:any): Observable<any> {
        return this.request({
            path: `hs-codes`,
            method: 'POST',
            body: body
        })
    }

    generateXl(searchValue:any, searchDescription: any, countryCodes: any): any {
        this.requestFile({
            path: `hs-codes/generate-excel`,
            method: 'GET',
            query: {
                q: searchValue,
                description: searchDescription,
                countryCodes: countryCodes
            }
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').subscribe(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = 'hscode.xlsx';
            a.click();
            URL.revokeObjectURL(objectUrl);
        });
    }

    public applyHsCodes(body:any):Observable<any> {
        this.checkRoutItem = true;
        return this.request({
            path:'hs-codes/apply-hs-code',
            method:'POST',
            body:body
        })
    }

    public hsCodeStatus(id): any{
        return this.request({
            method: 'GET',
            path: `hs-codes/hs-code-status/${id}`
        });
    }

    public updateHsCodes(id, s):Observable<any> {
        return this.request({
            path:`hs-codes/update-hs-code`,
            method:'POST',
            body: {
                id: id,
                status: s
            }
        })
    }
    delete(ids): Observable<any> {
        return this.request({
            path: `hs-codes`,
            method: 'DELETE',
            body: {
                ids
            }
        }).pipe(map(value => {
            return value;
        }));
    }

    getHscodeById(id):Observable<any> {
        return this.request({
            method: 'GET',
            path: `hs-codes/${id}`
        });
    }

    updateHscodeById(id, body: any):Observable<any> {
        return this.request({
            method: 'PUT',
            path: `hs-codes/update/${id}`,
            body: body
        });
    }

    getMultipleHsCodes(body: any):Observable<any> {
        return this.request({
            method: 'POST',
            path: `hs-codes/multi-hscodes`,
            body: body
        });
    }

    updateHsCodesDutyTaxRate(body: any):Observable<any> {
        return this.request({
            method: 'POST',
            path: `hs-codes/update-duty-rates`,
            body: body
        });
    }

    calculateHsCodesDutyTaxRate(body: any):Observable<any> {
        return this.request({
            method: 'POST',
            path: `hs-codes/duty-rate/caluclate`,
            body: body
        });
    }
 }
