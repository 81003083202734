import {DatePipe} from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {PageParams} from '../../../../core/models/page-params.model';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {EInvManagerTabType} from '../../invoice-management.service';

@Component({
    selector: 'eci-inv-man-table-sale-order',
    templateUrl: './inv-man-table-sale-order.component.html',
    styleUrls: ['./inv-man-table-sale-order.component.scss']
})
export class InvManTableSaleOrderComponent implements OnInit {

    @Input()
    set dataSource(data) {
        this._dataSource = data;
        if (this.isSelectedAll) {
            this._dataSource.map((e) => {
                e.isChecked = true;
                if (!this.selectedItems.find(si => si.id === e.id)){
                    this.selectedItems.push(e);
                    // this.selectedItemsChange.emit(this.selectedItems);
                }
            })
        } else {
            if (this.selectedItems.length > 0) {
                this._dataSource.map((e) => {
                    if (this.selectedItems.find(si => si.id === e.id)){
                        e.isChecked = true;
                    }
                });
            }
        }
    };

    _dataSource;
    isSelectedAll = false;
    @Input() selectedItems: any[] = [];

    @Input() type: EInvManagerTabType;
    @Input() invoiceTypes = [];
    @Input() sumValues;
    @Input() displayedColumns;
    @Input() pageParams: PageParams = new PageParams();
    @Input() statuses = [];

    @Output() pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>(null);
    @Output() sortRows: EventEmitter<Sort> = new EventEmitter<Sort>(null);
    @Output() action: EventEmitter<any> = new EventEmitter<any>(null);

    @ViewChild('table', { static: true, read: ElementRef }) table: ElementRef;

    constructor(
        private datePipe: DatePipe,
        private router: Router,
    ) {}

    ngOnInit(): void {
    }

    pageChanged(e): void {
        this.pageChange.emit(e);
        this.table.nativeElement.scrollIntoView();
    }

    sortData(e: Sort): void {
        this.sortRows.emit(e);
    }

    dateConvert(e: any): string {
        if (e.order) {
            return this.datePipe.transform(new Date(e.order.createdAt).toISOString().slice(0, 16), 'dd/MM/yyyy');
        }
        if (e.saleOrder) {
            return this.datePipe.transform(new Date(e.saleOrder.orderDate).toISOString().slice(0, 16), 'dd/MM/yyyy');
        }
        if (e.shipmentConsol) {
            return this.datePipe.transform(new Date(e.shipmentConsol.createdAt).toISOString().slice(0, 16), 'dd/MM/yyyy');
        }

    }

    navigateTo(id) {

    }

    getIMType(typeIM: any) {
        switch (this.type) {
            case EInvManagerTabType.so:
                switch (typeIM) {
                    case 'b2c': return 'B2C SO';
                    case 'b2b': return 'B2B SO';
                    case 'empact_shop': return 'Empact Shop SO';
                    case 'reportingTool': return 'Reporting Tool SO';
                }
                break;
            case EInvManagerTabType.shipment:
                return 'Shipment';
            case EInvManagerTabType.consolidate_shipment:
                return 'Consol Shipment';
        }
    }

    getOrderNumber(element) {
        switch (this.type) {
            case EInvManagerTabType.so:
                return element.saleOrder?.orderId;
            case EInvManagerTabType.shipment:
                return element.order?.orderId;
            case EInvManagerTabType.consolidate_shipment:
                return element.shipmentConsol?.consolNumber;
        }
    }

    navigateToOrder(e) {
        switch (this.type) {
            case EInvManagerTabType.so:
                this.router.navigateByUrl(`/sales-order-management/order-details/${e.saleOrder?.id}`);
                break;
            case EInvManagerTabType.shipment:
                this.router.navigateByUrl(`/customers-order/order-details/${e.order?.id}`);
                break;
            case EInvManagerTabType.consolidate_shipment:
                this.router.navigateByUrl(`/consolidate-shipments/consol-shipment-details/${e.shipmentConsol?.id}`);
                break;
        }
    }

    isSelected(element): boolean {
        return !!this.selectedItems.find(e => e.id === element.id);
    }

    get isAllSelected(): boolean {
        return this._dataSource?.filter(e => e.isChecked).length === this._dataSource?.length;
    }

    selectAllChanged(event: MatCheckboxChange): void {
        if (event.checked) {
            this._dataSource.map((e) => {
                e.isChecked = true;
                if (!this.selectedItems.find(si => si.id === e.id)){
                    this.selectedItems.push(e);
                }
            })
            this.isSelectedAll = true;
        } else {
            this._dataSource.map((e) => {e.isChecked = false;})
            this.selectedItems = [];
            this.isSelectedAll = false;
        }
    }

    selectionChanged(event: MatCheckboxChange, item): void {
        item.isChecked = event.checked;
        if (item.isChecked) {
            if (!this.selectedItems.find(si => si.id === item.id)){
                this.selectedItems.push(item);
            }
        } else {
            const index = this.selectedItems.findIndex(e => e.id === item.id);
            if (index > -1) {
                this.selectedItems.splice(index, 1);
                this.isSelectedAll = false;
            }
        }
    }

    navigateToCompany(e) {

    }

    getStatus(element) {
        if (element.order) {
            return element.order.status;
        } else if (element.saleOrder) {
            return element.saleOrder.status;
        } else if(element.shipmentConsol) {
            return element.shipmentConsol.status
        }
        return '';
    }

    get selectedItemsIds() {
        return this.selectedItems.map(i => {
            return {
                id: i.id,
                type: i.hasOwnProperty('type') ? SaleOrderInvoiceType[i.type] : SaleOrderInvoiceType[i.invoiceType]
            }
        });
    }
}
