import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PAYMENT_STATUS_LIST} from '../../../payments-constants';
import {IPayment} from '../../../payments-interfaces';
import {EPaymentStatus} from '../../../payments-type.enum';

export interface IPaymentStatusChangeDialog {
    status: EPaymentStatus
}

@Component({
    selector: 'eci-change-status',
    templateUrl: './change-status.component.html',
    styleUrls: ['./change-status.component.scss']
})
export class ChangeStatusComponent implements OnInit {

    payment: IPayment;
    isLoad = false;
    status: EPaymentStatus;
    statusList = PAYMENT_STATUS_LIST;

    constructor(
        public dialogRef: MatDialogRef<ChangeStatusComponent, IPaymentStatusChangeDialog>,
    ) {
    }

    ngOnInit(): void {

    }

    onChange() {
        this.dialogRef.close({
            status: this.status
        });
    }

    onCancel() {
        this.dialogRef.close();
    }
}
