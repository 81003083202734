import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChange,
} from '@angular/core';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { IOrderDetails } from '../../../models/order-details.model';
import { UploadFileService } from 'src/app/core/services/upload-file.service';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/core/services/user.service';
import { ConsolidateShipmentService } from '../../../../../core/services/consolidate-shipments.service';
import { ConsolShipmentInvoiceType } from 'src/app/shared/enums/consol-shipment-invoice-type.enum';
import { OrderInvoicesService } from '../../../services/consolidate-shipments-invoices.service';
import { InvoiceType } from 'src/app/shared/enums/invoice-type.enum';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PaymentsService } from 'src/app/modules/payments/payments.service';
import { InvoiceManagementService } from 'src/app/modules/invoice-manager/invoice-management.service';
import { forkJoin } from 'rxjs';
import { SaleOrderInvoiceType } from 'src/app/shared/enums/sale-order-invoice-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceFormComponent } from 'src/app/modules/invoice-manager/components/invoice-forms/invoice-form.component';
import { ShipmentConsolDocumentType } from 'src/app/shared/enums/shipment-consol-document-type.enum';
import { FileStatus } from 'src/app/shared/enums/file-status.enum';
import { OrderDocumentType } from 'src/app/shared/enums/order-document-type.enum';
import { ShipmentType } from 'src/app/shared/enums/shipment-type.enum';
import { OrderDocumentStatus } from 'src/app/shared/enums/order-document-status.enum';
import { ShipmentConsolStatus } from 'src/app/shared/enums/shipment-consol-status.enum';
@Component({
    selector: 'eci-consolidate-shipments-invoice-document-list',
    templateUrl: './consolidate-shipments-invoice-document-list.component.html',
    styleUrls: ['./../consolidate-shipments-details.component.scss'],
})
export class ConsolidateShipmentsInvoiceDocumentListComponent
    implements OnInit
{
    pageParams: PageParams = new PageParams();
    showLoader: boolean = false;
    documentList: any[];
    @Input() summaryPage = false;
    @Input() info: any;
    @Input() isCreateMode: boolean = true;
    @Input() availableTypes: IAvailableTypes;
    @Input() availableFields: string[];
    @Input() shipmentType: number;

    @Output() infoChange = new EventEmitter<IOrderDetails>();

    invoiceList: any[];
    //dataSource: MatTableDataSource<any>;
    dataSource;
    serviceInvoice = InvoiceType.SERVICE_INVOICE;
    commercialInvoice = InvoiceType.SHIPPING_INVOICE;

    listOfSelectedItems: any[] = [];
    isFedex: boolean = false;

    displayedColumns: string[] = [
        'dateCreated',
        'invoiceType',
        // 'invoiceStatus',
        //'invoiceAmount',
        'status',
        'actions',
        'linkToView',
        // 'delete'
    ];

    constructor(
        public uploadFileSvc: UploadFileService,
        private userSvc: UserService,
        private consolidateShipmentSvc: ConsolidateShipmentService,
        private orderInvoicesService: OrderInvoicesService,
        private notify: NotificationService,
        private notifySvc: NotificationService,
        private paymentsService: PaymentsService,
        private invoiceManagementService: InvoiceManagementService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        if (this.info && this.info.id && this.info.invoices) {
            if (this.info?.company?.isFedex) {
                this.isFedex = true;
                this.displayedColumns.splice(0, 0, 'checkboxes');
            }

            // To avoid Duplicate entries in case BE get any error while generation invoice
            const highestIdMap = new Map();
            this.info.invoices.forEach((invoice) => {
                const currentId = invoice.id;
                const currentType = invoice.invoiceType;
                if (!highestIdMap.has(currentType) || highestIdMap.get(currentType) < currentId) {
                    highestIdMap.set(currentType, currentId);
                }
            });

            this.info.invoices = this.info.invoices.filter((invoice) => {
                const currentId = invoice.id;
                const currentType = invoice.invoiceType;
                return currentId == highestIdMap.get(currentType)
            });
            // ends here

            this.invoiceList = [
                    ...this.info.invoices?.filter((el) => {
                    return (
                        el.isSpecific ||
                        el.invoiceType !==
                            (ConsolShipmentInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE &&
                                ConsolShipmentInvoiceType.PACKING_LIST)
                    );
                }),
            ];
           // console.log(this.invoiceList);
        }

        //this.dataSource = new MatTableDataSource(this.invoiceList);
        this.prepareDocumentList();
        if (this.info && this.info.id && this.info.documents) {
            if (this.info?.company?.isFedex) {
                this.isFedex = true;
                this.displayedColumns.splice(0, 0, 'checkboxes');
            }

            this.info.documents = this.info?.documents?.map((el) => {
                return {
                    id: el.id,
                    type: el.type,
                    status: !!el.id ? FileStatus.UPLOADED : FileStatus.PENDING,
                    createdAt: el.createdAt,
                };
            });

            const additionalDocList = this.documentList?.filter((el) => {
                return !this.info.documents
                    .map((val) => val.type)
                    .includes(el.type);
            });

            additionalDocList.forEach((additionalDoc) => {
                const matchingDoc = this.info.documents.find(
                    (doc) =>
                        ShipmentConsolDocumentType[doc.type] ===
                        additionalDoc.type
                );
                if (matchingDoc) {
                    additionalDoc.createdAt = matchingDoc.createdAt;
                    additionalDoc.id = matchingDoc.id;
                    additionalDoc.status = matchingDoc.status;
                }
            });

            this.documentList = [...additionalDocList];
        }

        this.dataSource = new MatTableDataSource();
        this.dataSource.data =
            this.info?.status === ShipmentConsolStatus.DRAFT ||
            this.info?.status === ShipmentConsolStatus.PENDING
                ? [...this.documentList.filter((x) => x.type !== 'PackingList')]
                : [...this.invoiceList, ...this.documentList];

        // console.log('[...this.invoiceList, ...this.documentList]', [
        //     ...this.invoiceList,
        //     ...this.documentList,
        // ]);
        // console.log(this.dataSource.data);
    }

    invoiceSave(id): void {
        this.consolidateShipmentSvc.downloadInvoice(id);
    }

    uploadSave(id): void {
        this.consolidateShipmentSvc.uploadInvoice(id);
    }

    public uploadInvoice(e: any, invoiceId) {
        if (!e.target.files) {
            return;
        }
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        this.uploadFileSvc.uploadSeveralFile(
            files,
            `uploads/consol-shipments-invoice/${invoiceId}`,
            invoiceId
        );
        this.uploadFileSvc.allFilesLoaded().subscribe((data) => {
            if (!data) return;
            const file = data.find((el) => el.fileId === invoiceId);
            if (!file) return;

            const fileDetails = JSON.parse(file.data);
            const invoice = this.invoiceList.find((el) => el.id === invoiceId);

            if (invoice && fileDetails) {
                invoice.asset.fileName = fileDetails.fileName;
                invoice.asset.file = fileDetails.file;
                invoice.asset.fileType = fileDetails.fileType;
            }

            this.dataSource.filteredData = [
                ...this.invoiceList,
                ...this.documentList,
            ];
        });
        setTimeout(() => {
            this.notify.showSuccessMessage('Document uploaded');
        }, 3000);
    }

    get model(): any {
        return {
            ...this.info,
            invoices: this.invoiceList,
        };
    }

    get hideCol(): boolean {
        return !this.userSvc.isAdmin;
    }

    deleteShow(element): boolean {
        if (element.invoiceType !== InvoiceType.SHIPPING_INVOICE) {
            return false;
        } else {
            return this.userSvc.isAdmin && !!element.asset?.file;
        }
    }

    deleteInvoice(invoiceId, id): void {
        this.consolidateShipmentSvc.deleteInvoice(id).subscribe((value) => {
            const invoice = this.invoiceList.find((el) => el.id === invoiceId);

            invoice.asset.fileName = '';
            invoice.asset.file = '';
            invoice.asset.fileType = '';

            this.dataSource.filteredData = [
                ...this.invoiceList,
                ...this.documentList,
            ];
            this.notify.showSuccessMessage('Document deleted');
        });
    }

    selectAllChanged(event: MatCheckboxChange): void {
        if (event.checked) {
            this.listOfSelectedItems = this.dataSource?.filteredData
                ?.filter((val) => !!val.asset.file)
                .map((el) => el.asset?.id);
        } else {
            this.listOfSelectedItems = [];
        }
    }

    selectionChanged(event: MatCheckboxChange, element): void {
        if (!element.asset?.file) return;
        if (event.checked) {
            if (!this.isChecked(element?.asset?.id)) {
                this.listOfSelectedItems.push(element?.asset?.id);
            }
        } else {
            if (this.isChecked(element?.asset?.id)) {
                this.listOfSelectedItems.splice(
                    this.listOfSelectedItems.findIndex(
                        (el) => el === element?.asset?.id
                    ),
                    1
                );
            }
        }
    }

    isChecked(id: number): boolean {
        return !!this.listOfSelectedItems.find((el) => el === id);
    }

    sendMails(): void {
        this.consolidateShipmentSvc
            .sendInvoices({
                ids: this.listOfSelectedItems,
            })
            .subscribe((res) => {
                this.notifySvc.showSuccessMessage('Mail sent to Fedex');
                this.listOfSelectedItems = [];
            });
    }

    get isAdmin(): boolean {
        return this.userSvc.isAdmin;
    }

    regenerateInvoice() {
        this.showLoader = true;
        this.orderInvoicesService
            .regenerateShipmentInvoice(this.info.id)
            .subscribe(
                (data) => {
                    if (data) {
                        // To avoid Duplicate entries in case BE get any error while generation invoice
                        const highestIdMap = new Map();
                        data.invoices.forEach((invoice) => {
                            const currentId = invoice.id;
                            const currentType = invoice.invoiceType;
                            if (!highestIdMap.has(currentType) || highestIdMap.get(currentType) < currentId) {
                                highestIdMap.set(currentType, currentId);
                            }
                        });

                        data.invoices = data.invoices.filter((invoice) => {
                            const currentId = invoice.id;
                            const currentType = invoice.invoiceType;
                            return currentId == highestIdMap.get(currentType)
                        });
                        // ends here
                        this.info.invoices = data.invoices;
                        data.invoices = data.invoices.filter(
                                (el) =>
                                    el.invoiceType !==
                                    (ConsolShipmentInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE &&
                                        ConsolShipmentInvoiceType.PACKING_LIST)
                        );
                        data.invoices.sort((a, b) => {
                            if (a.invoiceType === b.invoiceType) {
                                return b.id - a.id;
                            }
                            return a.invoiceType - b.invoiceType;
                        });
                        this.dataSource.data = [
                            ...data.invoices,
                            ...this.documentList,
                        ];
                        this.notify.showSuccessMessage(
                            'Invoice Regenerated successfully'
                        );
                    }
                    this.showLoader = false;
                },
                (err) => {
                    this.showLoader = false;
                    this.notify.showError('Something went wrong');
                }
            );
    }

    public isShowRegenerate() {
        return this.info?.status === ShipmentConsolStatus.DRAFT ||
            this.info?.status === ShipmentConsolStatus.PENDING
            ? false
            : true;
    }

    public editInvoice(data: any) {
        this.showLoader = false;
        forkJoin([
            this.consolidateShipmentSvc.getInvoice(data.id),
            this.invoiceManagementService.getTemplateData(
                SaleOrderInvoiceType[data.invoiceType]
            ),
        ]).subscribe(
            (res) => {
                let dialogRef = this.dialog.open(InvoiceFormComponent, {
                    maxHeight: '80vh',
                    width: '890px',
                    panelClass: 'inv-man-dialog',
                });
                dialogRef.componentInstance.type = data.invoiceType;
                dialogRef.componentInstance.tabType = 0;
                dialogRef.componentInstance.invoice = res[0];
                dialogRef.componentInstance.template = res[1].data?.template;
                dialogRef.componentInstance.isInvoice = true;
                dialogRef.componentInstance.isPaymentEdit = true;
                dialogRef.afterClosed().subscribe((value) => {
                    if (!value) return;
                    this.showLoader = true;
                    const payload = {
                        ...value,
                        invoiceId: data?.id,
                        invoiceType: data?.invoiceType,
                    };
                    this.consolidateShipmentSvc
                        .updateInvoice(payload)
                        .subscribe(
                            (res) => {
                                if (res) {
                                    this.showLoader = false;
                                    this.notify.showSuccessMessage(
                                        'Updated successfully'
                                    );
                                }
                            },
                            (error) => {
                                this.showLoader = false;
                                this.notify.showError(error.message);
                            }
                        );
                });
            },
            (error) => {
                this.notify.showError(
                    'Invoice is not available due to some error'
                );
                this.showLoader = false;
            }
        );
    }

    startEditing(element: any) {
        element.isEditing = true;
        const amount = this.formatAmount(element.grandTotal);
        element.updatedAmount = amount;
    }

    updateAmount(element: any) {
        element.grandTotal = element.updatedAmount;
        element.isEditing = false;

        const payload = {
            invoiceId: element?.id,
            grandTotal: element?.grandTotal,
        };
        this.consolidateShipmentSvc
            .updateServiceInvoiceTotal(payload)
            .subscribe(
                (res) => {
                    if (res) {
                        this.showLoader = false;
                        this.notify.showSuccessMessage('Updated successfully');
                    }
                },
                (error) => {
                    this.showLoader = false;
                    this.notify.showError(error.message);
                }
            );
    }

    cancelEditing(element: any) {
        element.isEditing = false;
    }

    formatAmount(amount: any): string {
        const numericAmount = parseFloat(amount);
        const roundedAmount = isNaN(numericAmount)
            ? 0
            : parseFloat(numericAmount.toFixed(2));
        return isNaN(numericAmount)
            ? 'Invalid Amount'
            : roundedAmount.toString();
    }

    prepareDocumentList(): void {
        this.documentList = [
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.MasterAWB
                ],
                isRequired: true,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.CustomDeclaration
                ],
                isRequired: true,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.CMR
                ],
                isRequired: true,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.PackingList
                ],
                isRequired: true,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.SignedPod
                ],
                isRequired: true,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.ConsolManifest
                ],
                isRequired: false,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[
                    ShipmentConsolDocumentType.PriceQuote
                ],
                isRequired: true,
                status: FileStatus.PENDING,
                createdAt: '',
            },
            {
                id: undefined,
                type: ShipmentConsolDocumentType[ShipmentConsolDocumentType.T1],
                isRequired: false,
                status: FileStatus.PENDING,
                createdAt: '',
            },
        ];
    }

    isRequired(element): boolean {
        if (element.type === OrderDocumentType.SignedPod) {
            return true;
        }
        if (this.shipmentType === ShipmentType.Air) {
            if (
                element.type === OrderDocumentType.AWB &&
                !this.dataSource.find(
                    (el) =>
                        el.type === OrderDocumentType.AWB &&
                        el.status === OrderDocumentStatus.Uploaded
                )
            ) {
                return true;
            }
            if (
                element.type === OrderDocumentType.MAWB &&
                !this.dataSource.find(
                    (el) =>
                        el.type === OrderDocumentType.AWB &&
                        el.status === OrderDocumentStatus.Uploaded
                ) &&
                !this.dataSource.find(
                    (el) =>
                        el.type === OrderDocumentType.HAWB &&
                        el.status === OrderDocumentStatus.Uploaded
                )
            ) {
                return true;
            }
            if (
                element.type === OrderDocumentType.HAWB &&
                this.dataSource.find(
                    (el) =>
                        el.type === OrderDocumentType.MAWB &&
                        el.status === OrderDocumentStatus.Pending
                )
            ) {
                return true;
            }
        }

        return element.isRequired;
    }

    downloadShow(status: number): boolean {
        return status === FileStatus.UPLOADED;
    }

    documentSave(id): void {
        this.consolidateShipmentSvc.downloadFile(this.info.id, id);
    }

    downloadPackingList(id): void {
        this.consolidateShipmentSvc.downloadInvoice(id);
    }

    viewPackingList() {
        if (this.info?.invoices && this.info?.invoices.length > 0) {
            // console.log(this.info?.invoices);
            this.info?.invoices.map((data) => {
                if (
                    data.invoiceType === ConsolShipmentInvoiceType.PACKING_LIST
                ) {
                    this.downloadPackingList(data?.id);
                }
            });
        }
    }

    uploadShow(element): boolean {
        return element.status === FileStatus.PENDING;
    }

    public fileUpload(e: any, element) {
        if (!e.target.files) {
            return;
        }
        const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        this.consolidateShipmentSvc.uploadFile(
            files,
            this.info.id,
            element?.type
        );
        this.consolidateShipmentSvc.fileLoadedObserver.subscribe((data) => {
            if (!data) return;
            const filDetails = JSON.parse(data);
            // const document = this.documentList.find(el => el.type === element?.type);
            // if (document && filDetails) {
            //   document.id = filDetails.id;
            //   document.status = FileStatus.UPLOADED;
            //   document.createdAt = filDetails.createdAt;
            // }
            if (filDetails) {
                for (let i = 0; i < this.documentList.length; i++) {
                    if (
                        this.documentList[i]?.type === element?.type &&
                        !this.documentList[i]?.id
                    ) {
                        this.documentList[i].id = filDetails.id;
                        this.documentList[i].status = FileStatus.UPLOADED;
                        this.documentList[i].createdAt = filDetails.createdAt;
                        break;
                    }
                }
            }
            this.info = this.modelDocs;
            this.infoChange.emit(this.info);
            this.dataSource = [...this.invoiceList, ...this.documentList];

            if (this.info && this.info.id) {
                this.updateDocs();
            }
        });
        this.notifySvc.showSuccessMessage('Document uploaded');
    }

    get modelDocs(): any {
        return {
            ...this.info,
            documents: this.documentList,
        };
    }

    updateDocs(): void {
        if (!this.info || !this.info?.id) {
            return;
        }

        this.consolidateShipmentSvc
            .updateShipment(this.info.id, this.info)
            .subscribe((value) => {
                //this.reload.emit(true);
            });
    }

    deleteShowDocs(element): boolean {
        if (
            element.type !==
            ShipmentConsolDocumentType[
                ShipmentConsolDocumentType.ConsolManifest
            ]
        ) {
            return true;
        } else {
            return this.isAdmin;
        }
    }

    deleteDoc(id): void {
        this.consolidateShipmentSvc.deleteDocument(id).subscribe((value) => {
            if (value?.affected > 0) {
                const idx = this.documentList.findIndex((el) => el.id === id);

                if (idx !== -1) {
                    this.documentList[idx].id = null;
                    this.documentList[idx].status = FileStatus.PENDING;
                    this.documentList[idx].createdAt = null;
                }
            }
            this.notifySvc.showSuccessMessage('Document deleted');
        });
    }
}

