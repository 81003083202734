import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BaseElement} from '../base-element';
import {IColumn} from '../elements-interfaces';

@Component({
    selector: 'eci-grid-item-header',
    templateUrl: './grid-item-header.component.html',
    styleUrls: ['./grid-item-header.component.scss']
})
export class GridItemHeaderComponent extends BaseElement implements OnChanges {

    @Input() disabled: boolean;
    @Input() columns: IColumn[] = [];
    @Output() onHeaderChange: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    onMoveHeader(event: CdkDragDrop<any>) {
        this.onHeaderChange.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 10;
        }
    }
}
