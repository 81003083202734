import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {IPayment} from '../../../payments-interfaces';

@Component({
    selector: 'eci-on-hold',
    templateUrl: './on-hold.component.html',
    styleUrls: ['./on-hold.component.scss']
})
export class OnHoldComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<OnHoldComponent>,
    ) {
    }

    public payments: IPayment;
    text: string = '';

    ngOnInit(): void {
    }

    onAddRemark() {
        this.dialogRef.close({
            id: this.payments.id,
            text: this.text
        });
    }
}
