import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {IAvailableTypes, IConstantItem} from 'src/app/core/models/available-types.interface';
import {NotificationService} from 'src/app/core/services/notification.service';
import {UserService} from 'src/app/core/services/user.service';
import {ICompany} from 'src/app/modules/customer-registration/models/company.model';
import {RegistrationService} from 'src/app/modules/customer-registration/services/registration.service';
import {FileStatus} from 'src/app/shared/enums/file-status.enum';
import {OrderType} from 'src/app/shared/enums/order-type.enum';
import {Roles} from 'src/app/shared/enums/role-types.enum';
import {IOrderDetails} from '../../../models/order-details.model';
import { SaleOrderType } from 'src/app/shared/enums/sale-order-type.enum';
import { ShipmentSubType } from 'src/app/shared/enums/shipment-subtype.enum';
import { ShipmentConsolStatus } from 'src/app/shared/enums/shipment-consol-status.enum';
import {MatDialog} from '@angular/material/dialog';
import {AddNewStatusComponent} from '../../../../../shared/components/add-new-status/add-new-status.component';
import {ConsolidateShipmentService} from 'src/app/core/services/consolidate-shipments.service';
import { OrdersService } from 'src/app/modules/customers-order-management/services/orders.service';

@Component({
    selector: 'eci-main-consolidate-shipments-details',
    templateUrl: './main-consolidate-shipments-details.component.html',
    styleUrls: ['./main-consolidate-shipments-details.component.scss'],
    providers: [DatePipe]
})
export class MainConsolidateShipmentsDetailsComponent implements OnInit {

    @Input() summaryPage = false;
    @Input() info: any;
    @Input() isCreateMode: boolean = true;
    @Input() shipmentOrderType: number = OrderType.Import;
    @Input() availableTypes: IAvailableTypes;
    @Input() hasError: boolean;
    @Input() currentOrderStatus: string | number;
    @Input() showErrors: boolean;
    @Input() isEditable: boolean;
    @Input() soOrderType: any;
    @Input() subtype: ShipmentSubType;

    @Output() infoChange = new EventEmitter<IOrderDetails>();
    @Output() companyIdChange = new EventEmitter<number>();
    @Output() companyInfoChange = new EventEmitter<ICompany>();
    @Output() hasErrorChange = new EventEmitter<boolean>();
    @Output() changeType = new EventEmitter<number>();
    @Output() orderStatusChanged = new EventEmitter<boolean>();
    @Output() getOrder = new EventEmitter<number>();
    @Input() currentOrderType: number;
    @Input() allDocumentNotUploaded: boolean = false;
    searchCustomerName: FormControl = new FormControl('');
    searchorderType: FormControl = new FormControl('');

    form: FormGroup;
    orderType: number;
    today: Date;
    consolShipmentStatus = ShipmentConsolStatus;

    private readonly RELOAD_TOP_SCROLL_POSITION = 10;
    @ViewChild('companySelect') selectElem: MatSelect;

    companyList: any[] = [];
    companyPage: number = 1;
    searchorderStatus: FormControl = new FormControl('');
    selectedStatusId : any;

    orderSubTypes = [{
        id: ShipmentSubType.Regular,
        name: 'Regular',
    }, {
        id: ShipmentSubType.Return,
        name: 'Return',
    }];

    constructor(
        public userSvc: UserService,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe,
        private regSvc: RegistrationService,
        private notify: NotificationService,
        private consolShipmentSvc: ConsolidateShipmentService,
        private dialog: MatDialog,
        private ordersService: OrdersService
    ) {
    }

    ngOnInit(): void {
        this.getCompanyList();
        this.form = this.initializeForm(this.info);
        this.info = this.model;

        this.changeType.emit(this.info.type);
        this.infoChange.emit(this.info);
        this.hasErrorChange.emit(this.form.invalid);

        this.form.get('orderType').valueChanges.subscribe(val => {
            if (!val) {
                return;
            }
            this.changeType.emit(val);
            this.orderType = val;
        });

        if (this.isAdmin) {
            this.form.get('customerName').valueChanges.subscribe(val => {
                if (!val) {
                    return;
                }
                this.validateConsolShipmentCreationByCompanyId(val).then((validationResponse) => {
                    if (!validationResponse) {
                        this.companyIdChange.emit(null);
                        return;
                    }
                    this.companyIdChange.emit(val);
                    this.regSvc.getCompanyById(val).subscribe(value => {
                        this.companyInfoChange.emit(value);
                    });
                    this.userSvc.changeCustomerInConsolShipment.next(val);
                });
            });
        } else {
            this.companyInfoChange.emit(this.userSvc.userStorage?.company);
        }

        this.form.valueChanges.subscribe(_ => {
            this.info = this.model;

            this.infoChange.emit(this.info);
            this.hasErrorChange.emit(!this.form.valid);
        });

        this.today = new Date();
        this.companyIdChange.emit(this.userSvc.isAdmin ? this.info?.companyId : this.userSvc.userStorage?.company?.id);

        if (this.info) {
            const companyId = this.form.get('customerName').value ? this.form.get('customerName').value : this.info?.company?.id;
            if (companyId) {
                this.regSvc.getCompanyById(companyId).subscribe(value => {
                    this.companyInfoChange.emit(value);
                });
            }
        }
        this.form.get('status')?.valueChanges.subscribe((value) => {
            this.selectedStatusId = value;
        });
    }

    ngOnChanges(changes: SimpleChange): void {
        if (changes['showErrors']) {
            if (this.showErrors) {
                this.form?.markAllAsTouched();
            }
        }
        // console.log('changesssssssssss');
        // console.log(changes);
        // if (changes['subtype']) {
        //     if (changes['subtype'] && changes['subtype'].currentValue != null) {
        //         console.log(changes['subtype'].currentValue);
        //         this.form.get('subtype').setValue(changes['subtype'].currentValue);
        //         console.log(changes['subtype']);
        //     }
        // }
    }

    get isAdmin(): boolean {
        return this.userSvc.user.role == Roles.Admin;
    }

    get orderTypes(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }

        return this.availableTypes.OrderType;
    }

    get orderStates(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.OrderStatus;
    }

    get paymentStatus(): IConstantItem[] {
        if (!this.availableTypes) {
            return;
        }
        return this.availableTypes.PaymentStatus;
    }

    initializeForm(info: any): FormGroup {
        console.log('this.info');
        console.log(this.info?.subtype);
        console.log(this.info);
        return this.formBuilder.group({
            orderId: [{disabled: !this.isCreateMode, value: info?.consolNumber || ''}],
            orderType: [
                {
                    disabled: false,
                    // value: this.info.type
                    value: this.isCreateMode ? this.shipmentOrderType : this.info.type  // need to ask
                },
                [Validators.required]
            ],
            subtype: [{
                disabled: !this.isCreateMode || this.isEditable,
                //value: this.info?.subtype || this.subtype
                value: this.info?.subtype !== undefined ? this.info?.subtype : this.subtype
            }, [Validators.required]],
            customerName: [
                {
                    disabled: !this.isCreateMode || this.isEditable,
                    value: (this.userSvc.isAdmin ? info?.companyId.toString() : this.userSvc.userStorage.company.name) || null
                },
                [Validators.required]
            ],
            readyOn: [{disabled: !this.isCreateMode, value: !!info?.readyOn ? new Date(info?.readyOn) : new Date()}, [Validators.required]],
            reference: [{disabled: false, value: info?.reference}],
            status: [{disabled: false, value: info?.status}],
            shipmentStatus: [{disabled: false, value: info?.shipmentStatus}],
        });
    }

    get model(): any {
        return {
            ...this.info,
            type: this.form.get('orderType').value,
            subtype: Number(this.form.get('subtype').value),
            companyId: this.userSvc.isAdmin ? parseInt(this.form.get('customerName').value) : parseInt(this.userSvc.userStorage.company.id),
            readyOn: this.form.get('readyOn').value !== 'Invalid Date' ? this.datePipe.transform(this.form.get('readyOn').value, 'yyy-MM-dd') : null,
            reference: this.form.get('reference').value == '' ? 'none' : this.form.get('reference').value,
            status: Number(this.form.get('status').value),
            shipmentStatus: Number(this.form.get('shipmentStatus').value),
        };
    }

    get showReturnNote(): boolean {
        if (!this.info) {
            return false;
        }

        return this.info?.subtype === ShipmentSubType.Return &&
            (this.info?.status === ShipmentConsolStatus.DRAFT ||
                this.info?.status === ShipmentConsolStatus.NEW ||
                this.info?.status === ShipmentConsolStatus.PENDING);
    }

    registerPanelScrollEvent() {
        const panel = this.selectElem.panel.nativeElement;
        panel.addEventListener('scroll', event => this.loadAllOnScroll(event));
    }

    loadAllOnScroll(event) {
        if (event.target.scrollTop > this.RELOAD_TOP_SCROLL_POSITION) {
            this.companyPage++;
            this.getCompanyList(this.companyPage, 2);
        }
    }

    async validateConsolShipmentCreationByCompanyId(companyId: any): Promise<boolean> {
        if (!companyId) {
          return false;
        }

        try {
          const res = await this.userSvc.getPackageTypeUserByCompanyId(companyId).toPromise();

          if (!res) { return false; }

          if (res?.packages?.packageName === "B2B") {
            if (this.soOrderType === SaleOrderType.IsB2C) {
              this.notify.showError('The consolidated shipment is not created with the B2C sale order for B2B as a package user');
              return false;
            }
          } else if (res?.packages?.packageName === "B2C") {
            if (this.soOrderType === SaleOrderType.IsB2B) {
              this.notify.showError('The consolidated shipment is not created with the B2B sale order for B2C as package user');
              return false;
            }
          }

          return true;
        } catch (error) {
          console.error('Error validating consol shipment creation:', error);
          return false;
        }
      }

    getCompanyList(limit?, page?): void {
        this.regSvc.getCompanyList(limit, page).subscribe(data => {
            if (!data || !data?.items) {
                return;
            }
            data.items = data.items.filter(element => element.name.length > 0);
            this.companyList = [...data.items];
        });
    }

    changeTo0(field: string): void {
        this.form.get(field).value < 0 ? this.form.get(field).setValue(0) : '';
    }

    updateOrderstatus(newOrderStatus: string): void {
        const isFileNotUploaded = this.info.orderDocuments.find(el => el.isRequired === true && el.status === FileStatus.PENDING);
        if (isFileNotUploaded) {
            this.notify.showError('You cannot change order status until order documents will not be uploaded');
            return;
        }
    }

    orderStatusName(id): string {
        if (!this.availableTypes) {
            return;
        }
        const orderStatus = this.availableTypes.OrderStatus.find(el => el.id == id);
        return orderStatus ? orderStatus.name : '';
    }

    compareWithFn(listOfItems, selectedItem) {
        return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
    }

    scrollProgressBar(): void {
        if (!document.getElementsByClassName('progress-bar')[0]) {
            return;
        }
        document.getElementsByClassName('progress-bar')[0].scrollLeft = 1000000;
        document.getElementsByClassName('progress-bar')[0].scroll();
    }

    addMore(): void {
        if (!document.getElementsByClassName('progress-bar')[0]) {
            return;
        }
        document.getElementsByClassName('progress-bar')[0].scrollLeft += 350;
    }

    addNewStatus() {
        const dialog = this.dialog.open(AddNewStatusComponent, {
            hasBackdrop: false
        });
        dialog.afterClosed().subscribe(value => {
            if (value) {
                const newStatus = {
                    id: value.value,
                    name: value.name
                }
                this.availableTypes.ShipmentConsolStatus.push(newStatus);
                this.form.get('status').setValue(newStatus.id);
            }
        });
    }

    removeCustomStatus(status: any) {
        if (!this.consolShipmentStatus[status.id]) {
            this.availableTypes.ShipmentConsolStatus = this.availableTypes.ShipmentConsolStatus.filter(
                item => item.id !== status.id
            );
            this.ordersService.deleteNewStatus(status.id).subscribe(res => {
                if (res) {
                    this.notify.showSuccessMessage("Deleted Successfully")
                }
            });
        }
    }
}
