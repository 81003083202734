import {CdkDragDrop, copyArrayItem, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, Output} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
  selector: 'eci-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent extends BaseElement {

    @Output() onDrop: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
        super()
    }

    ngOnInit(): void {
    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }
}
