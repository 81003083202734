import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {EElementTypes} from '../../../../invoice-management.service';
import {BaseElement} from '../base-element';
import {IElement} from '../elements-interfaces';

@Component({
    selector: 'eci-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class ItemComponent extends BaseElement implements OnChanges{

    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && changes.item.currentValue) {
            if (!changes.item.currentValue.model) {
                this.item.model = {
                    label: '',
                    value: '',
                    key: '',
                    replace: false
                } as any;
            }
        }
    }
}
