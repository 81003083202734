import {CdkDragDrop, copyArrayItem, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {NotificationService} from '../../../../core/services/notification.service';
import {EElementTypes, InvoiceManagementService} from '../../invoice-management.service';
import {IElement} from '../invoice-forms/constructor/elements-interfaces';

@Component({
    selector: 'eci-template-editor',
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit, OnChanges {

    model: IElement[] = [];
    selectedBlock: IElement;
    dataSource;
    templateId;
    name;
    errors: string[] = [];

    elements: IElement[] = [
        {
            name: 'Main container',
            drop_list_id: null,
            type: EElementTypes.MainContainer,
            selected: false,
            items: [],
            align: 'horizontal'
        },
        {
            name: 'Header',
            type: EElementTypes.Header,
            selected: false,
        },
        {
            name: 'Customer - Company',
            type: EElementTypes.CustomerCompany,
            selected: false,
        },
        {
            name: 'Address',
            drop_list_id: null,
            type: EElementTypes.Address,
            selected: false,
            items: [],
            align: 'vertical',
            background: '#ECECEC',
            fontSize: 14
        },
        {
            name: 'Items block',
            type: EElementTypes.ItemsBlock,
            selected: false,
            items: [],
        },
        {
            name: 'Customer',
            type: EElementTypes.Customer,
            selected: false,
            items: [],
            align: 'vertical',
            background: '#ffffff',
        },
        {
            name: 'Table',
            type: EElementTypes.Table,
            selected: false,
            background: '#ffffff',
            items: [],
            columns: [],
        },
        {
            name: 'Payment terms',
            type: EElementTypes.PaymentTerms,
            selected: false,
            background: '#575D6C',
            items: [],
            columns: [],
        },
        {
            name: 'Space',
            type: EElementTypes.Space,
            selected: false,
            background: '#FFFFFFFF',
        },
        {
            name: 'Free text',
            type: EElementTypes.FreeText,
            selected: false,
            background: '#FFFFFFFF',
        }
    ];
    displayedElements: IElement[] = [];

    items: IElement[] = [
        {
            name: 'Items container',
            drop_list_id: null,
            type: EElementTypes.ContainerItem,
            selected: false,
            items: [],
            align: 'horizontal'
        }, {
            name: 'Item',
            drop_list_id: null,
            type: EElementTypes.SingleItem,
            selected: false,
        }, {
            name: 'Block item',
            drop_list_id: null,
            type: EElementTypes.SingleItemBlock,
            selected: false,
        }, {
            name: 'Grid item header',
            drop_list_id: null,
            type: EElementTypes.GridItemHeader,
            selected: false,
            background: '#575D6C'
        }, {
            name: 'Grid item static',
            drop_list_id: null,
            type: EElementTypes.GridItemStatic,
            selected: false,
            background: '#FAFAFA',
            model: [],
        }, {
            name: 'Grid item dynamic',
            drop_list_id: null,
            type: EElementTypes.GridItemDynamic,
            selected: false,
            background: '#FAFAFA',
            model: [],
        }, {
            name: 'Space item',
            type: EElementTypes.Space,
            selected: false,
            background: '#FFFFFFFF',
        }, {
            name: 'Free text item',
            type: EElementTypes.FreeText,
            selected: false,
            background: '#FFFFFFFF',
        }
    ];
    displayedItems: IElement[] = [];

    cdkDropListConnectedTo: any[] = [
        'main_drop_list'
    ];
    cdkDropListItemsConnectedTo: any[] = [];

    actionTemplates: any;
    propsTemplates: any;

    mainSheet: IElement;

    constructor(
        public dialogRef: MatDialogRef<TemplateEditorComponent>,
        public invoiceManagementService: InvoiceManagementService,
        private notify: NotificationService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit(): void {
        this.invoiceManagementService.getMaps().subscribe(maps => {
            this.dataSource = maps;
        });
        const sheetIndex = this.model.findIndex(m => m.type === EElementTypes.MainSheet);
        if (sheetIndex > -1) {
            this.mainSheet = {...this.model[sheetIndex]};
            this.model.splice(sheetIndex, 1);
        }
        this.displayedElements = [...this.elements];
    }

    drop(event: CdkDragDrop<IElement[]>) {
        if (event.previousContainer.id === event.container.id) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            console.log(event);
            console.log(event.container.data);
            console.log(this.model);
        } else {
            const prevData = JSON.stringify(event.previousContainer.data);
            copyArrayItem(
                JSON.parse(prevData),
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            if (event.container.id !== 'main_drop_list' && event.container.orientation === 'horizontal') {
                event.container.data.map(e => e.width = 100 / event.container.data.length);
            }
        }
    }

    onSelectElement(item: IElement) {
        this.selectElement(item);
    }

    onDeleteElement() {
        this.cdkDropListConnectedTo = ['main_drop_list'];
        this.cdkDropListItemsConnectedTo = [];
    }

    private selectElement(item: IElement) {
        if (item.selected) {
            if (this.selectedBlock) {
                this.selectedBlock.selected = false;
            }
            this.selectedBlock = item;
            switch (item.type) {
                case EElementTypes.MainContainer:
                    this.displayedItems = [];
                    this.displayedElements = this.filterElements([
                        EElementTypes.Address,
                        EElementTypes.Space,
                        EElementTypes.FreeText,
                        EElementTypes.ItemsBlock,
                        EElementTypes.Customer,
                    ]);
                    this.cdkDropListConnectedTo = [item.drop_list_id];

                    break;
                case EElementTypes.Header:
                case EElementTypes.CustomerCompany:
                    this.displayedItems = [];
                    this.displayedElements = [];
                    break;
                case EElementTypes.Address:
                    this.displayedItems = this.filterItems([EElementTypes.SingleItem, EElementTypes.ContainerItem]);
                    this.displayedElements = [];
                    this.cdkDropListItemsConnectedTo = [item.drop_list_id];
                    break;
                case EElementTypes.ItemsBlock:
                    this.displayedItems = this.filterItems([EElementTypes.SingleItemBlock]);
                    this.displayedElements = [];
                    this.cdkDropListItemsConnectedTo = [item.drop_list_id];
                    break;
                case EElementTypes.Customer:
                    this.displayedItems = this.filterItems([EElementTypes.SingleItem, EElementTypes.ContainerItem]);
                    this.displayedElements = [];
                    this.cdkDropListItemsConnectedTo = [item.drop_list_id];
                    break;
                case EElementTypes.ContainerItem:
                    this.displayedItems = this.filterItems([EElementTypes.SingleItem]);
                    this.displayedElements = [];
                    this.cdkDropListItemsConnectedTo = [item.drop_list_id];
                    break;
                case EElementTypes.Table:
                    this.displayedItems = this.filterItems([EElementTypes.GridItemHeader, EElementTypes.GridItemDynamic, EElementTypes.GridItemStatic]);
                    this.displayedElements = [];
                    this.cdkDropListItemsConnectedTo = [item.drop_list_id];
                    break;
                case EElementTypes.PaymentTerms:
                case EElementTypes.Space:
                case EElementTypes.FreeText:
                    this.displayedItems = [];
                    this.displayedElements = [];
                    break;
                default:
                    this.displayedItems = [];
                    this.displayedElements = [];
                    this.cdkDropListConnectedTo = ['main_drop_list'];
                    break;
            }
        } else {
            this.selectedBlock = null;
            this.cdkDropListConnectedTo = ['main_drop_list'];
            this.cdkDropListItemsConnectedTo = [];
            this.displayedElements = [...this.elements];
            this.displayedItems = [];
        }
    }

    onSave() {
        if (this.selectedBlock) {
            this.selectedBlock.selected = false;
            this.selectedBlock = null;
        }
        this.model.push(this.mainSheet);
        this.invoiceManagementService.saveOneNewSpecificTemplate(this.templateId, this.model).subscribe(res => {
            this.notify.showSuccessMessage('Specific template saved successfully!');
            this.dialogRef.close(res);
        }, error => {
            this.notify.showError(error.error.message);
        });
    }

    onView() {
        this.invoiceManagementService.previewInvoice(this.model);
    }

    onInputZoom(e: any) {
        if (e > 1) {
            setTimeout(() => {
                this.mainSheet.model.zoom = 1;
            }, 0);
        }

        if (e < 0) {
            setTimeout(() => {
                this.mainSheet.model.zoom = 0;
            }, 0);
        }
    }

    filterItems(types: EElementTypes[]): IElement[] {
        return this.items.filter(i => types.includes(i.type));
    }

    filterElements(types: EElementTypes[]): IElement[] {
        return this.elements.filter(i => types.includes(i.type));
    }
}
