import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IInvoiceCompanyNames} from '../../invoice-form.component';

@Component({
    selector: 'eci-customer-company-block',
    templateUrl: './customer-company-block.component.html',
    styleUrls: ['./customer-company-block.component.scss']
})
export class CustomerCompanyBlockComponent implements OnInit {

    @Input() isUKDelivery: boolean = null;
    @Input() isB2BSaleOrder: boolean = false;
    @Input() isPaymentEdit: boolean = false;
    @Input() companyName: IInvoiceCompanyNames = {
        name_eu: '',
        name_uk: '',
        shipper: '',
        shipment: ''
    };
    @Input() invoiceType: string;
    @Output() companyNameChange = new EventEmitter<IInvoiceCompanyNames>();

    constructor() {
    }

    ngOnInit(): void {
    }

    onChangeName(field: string, e: any) {
        this.companyName[field] = e;
        this.companyNameChange.emit(this.companyName);
    }
}
