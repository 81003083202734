import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {ConstantService} from '../../../../core/services/constant.service';
import {NotificationService} from '../../../../core/services/notification.service';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {RegistrationService} from '../../../customer-registration/services/registration.service';
import {OrdersService} from '../../../customers-order-management/services/orders.service';
import {EInvManagerTabType, InvoiceManagementService} from '../../invoice-management.service';
import {InvManagerTab} from '../inv-manager-tab';
import {InvoiceFormComponent} from '../invoice-forms/invoice-form.component';

@Component({
    selector: 'eci-shipment-tab',
    templateUrl: './shipment-tab.component.html',
    styleUrls: ['./shipment-tab.component.scss']
})
export class ShipmentTabComponent extends InvManagerTab implements OnInit {

    constructor(
        public invoiceManagementService: InvoiceManagementService,
        public datePipe: DatePipe,
        public constSvc: ConstantService,
        public orderSvc: OrdersService,
        private dialog: MatDialog,
        public regSvc: RegistrationService,
        public notify: NotificationService,
    ) {
        super(
            invoiceManagementService,
            datePipe,
            constSvc,
            EInvManagerTabType.shipment,
            regSvc,
            notify,
        );
    }

    ngOnInit(): void {
        this.search();
        this.onInit();
    }

    onAction(e: { action: string; item: any, itemIds?: string[] }) {
        switch (e.action) {
            case 'view':
                this.orderSvc.downloadInvoice(e.item.id);
                break;
            case 'edit':
                this.editShipmentInvoice(e.item);
                break;
            case 're-issue':
                this.invoiceManagementService.reIssueInvoice(e.item.id, this.tabType).subscribe(res => {
                    this.search(this.prepareSearchParams());
                });
                break;
            case 're-issue-all':
                this.isLoad.emit(true);
                this.invoiceManagementService.reIssueAllInvoice(e.itemIds, this.tabType).subscribe(res => {
                    this.isLoad.emit(false);
                    this.search(this.prepareSearchParams());
                    this.table.selectedItems = [];
                    this.notify.showSuccessMessage('Invoice Regenerated successfully');
                });
                break;
            case 'upload':
                this.uploadedInvoice = e;
                this.invoiceFileInput.nativeElement.click();
                break;
        }
    }

    editShipmentInvoice(item) {
        this.isLoad.emit(true);
        forkJoin([
            this.invoiceManagementService.getOrderInvoiceData(item.order.id, item.id),
            this.invoiceManagementService.getTemplateData(SaleOrderInvoiceType[item.type])
        ]).subscribe(res => {
            this.isLoad.emit(false);
            let dialogRef  = this.dialog.open(InvoiceFormComponent, {
                maxHeight: '80vh',
                width: '890px',
                panelClass: 'inv-man-dialog'
            });

            dialogRef.componentInstance.tabType = 1;
            dialogRef.componentInstance.type = item.type;
            dialogRef.componentInstance.invoice = res[0];
            dialogRef.componentInstance.template = res[1].data?.template;
            dialogRef.componentInstance.isInvoice = true;

            dialogRef.afterClosed().subscribe(value => {
                if (!value) return;
                this.invoiceManagementService.saveShipment(value).subscribe(res => {
                    this.search(this.prepareSearchParams());
                    this.notify.showSuccessMessage('Updated successfully');
                });
            });
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
            this.isLoad.emit(false);
        });
    }

    onChange(e: Event) {
        this.isLoad.emit(true);
        this.invoiceUpload(e).subscribe(res => {
            this.isLoad.emit(false);
            this.search(this.prepareSearchParams());
        }, error => {
            this.isLoad.emit(false);
        })
    }
}
