import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BaseElement} from '../base-element';
import {IRowField} from '../elements-interfaces';

@Component({
    selector: 'eci-payment-terms',
    templateUrl: './payment-terms.component.html',
    styleUrls: ['./payment-terms.component.scss']
})
export class PaymentTermsComponent extends BaseElement implements OnChanges{

    searchSourceKey: FormControl = new FormControl('');
    searchSourceValue: FormControl = new FormControl('');
    constructor() {
        super();
    }

    ngOnInit(): void {
    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && changes.item.currentValue) {
            if (!changes.item.currentValue.model) {
                this.item.model = {
                    label: {
                        key: '',
                        value: 'Payment terms:',
                        replace: true,
                    },
                    value: {
                        key: 'paymentTerms',
                        value: '',
                        replace: false,
                    }
                } as any;
                this.item.background = '#575D6C';
            }
        }

        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 12;
        }
    }

    sources(searchSource) {
        const dataSource =  (Array.from(this.dataSource) as any).filter(ds => {
            if (searchSource.value) {
                const values = ds.values.filter(v => {
                    return v.name.toLowerCase().includes(searchSource.value.toLowerCase());
                });
                ds.displayedValues = values;
                return values.length;
            } else {
                ds.displayedValues = ds.values;
                return true;
            }
        });
        return dataSource;
    }

    get sourcesKey() {
        return this.sources(this.searchSourceKey);
    }

    get sourcesValue() {
        return this.sources(this.searchSourceValue);
    }

}
