import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import {NotificationService} from '../../../../core/services/notification.service';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';

export interface IInvoiceLogoTemplate {
    data: string;
    isHide: boolean;
}

export interface IInvoiceBaseTemplate {
    logo: IInvoiceLogoTemplate;
    title: string;
    note: string;
}

export interface IInvoiceCompanyNames {
    name_eu: string;
    name_uk: string;
    shipper: string;
    shipment: string;
    [key: string]: any;
}

export interface IInvoiceBaseTemplateData {
    logo?: IInvoiceLogoTemplate;
    title?: string;
    note?: string;
    labels: {
        [key: string]: string;
    };
    table: {
        [key: string]: any;
    };
    shipmentLabels?: {
        [key: string]: string;
    }
    customerCompany: IInvoiceCompanyNames;
    [key: string]: any;
}

@Component({
    selector: 'eci-invoice-form',
    templateUrl: './invoice-form.component.html',
    styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit {

    tabType: number;
    type: number;
    types = SaleOrderInvoiceType;
    isInvoice = false;
    isPaymentEdit = false;
    isLogoEdit: boolean = false;
    imageError: string;
    logoModel: IInvoiceLogoTemplate = {
        data: null,
        isHide: false
    };

    model: IInvoiceBaseTemplate;
    template: IInvoiceBaseTemplateData;
    invoice: any;

    constructor(
        public dialogRef: MatDialogRef<InvoiceFormComponent>,
        private notify: NotificationService
    ) {}

    ngOnInit(): void {
        const {logo, title, note, ...other} = this.template;
        this.model = { logo, title, note, }
    }

    onOKEditLogo(): void {
        this.model.logo.data = this.logoModel.data;
        this.model.logo.isHide = this.logoModel.isHide;
        this.isLogoEdit = false;
    }

    onEditLogo(data, isHide) {
        this.logoModel.data = data;
        this.logoModel.isHide = isHide;
        this.isLogoEdit = true;
    }

    logoChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            // Size Filter Bytes
            const max_size = 20971520;
            const allowed_types = ['image/png', 'image/jpeg'];
            const max_height = 15200;
            const max_width = 25600;

            if (fileInput.target.files[0].size > max_size) {
                this.imageError =
                    'Maximum size allowed is ' + max_size / 1000 + 'Mb';

                return false;
            }

            if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
                this.imageError = 'Only Images are allowed ( JPG | PNG )';
                return false;
            }
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const img_height = rs.currentTarget['height'];
                    const img_width = rs.currentTarget['width'];

                    if (img_height > max_height && img_width > max_width) {
                        this.imageError =
                            'Maximum dimentions allowed ' +
                            max_height +
                            '*' +
                            max_width +
                            'px';
                        return false;
                    } else {
                        const imgBase64Path = e.target.result;
                        this.logoModel.data = imgBase64Path;
                    }
                };
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
    }

    prepareModel(data: any): IInvoiceBaseTemplateData {
        return {
            ...data,
            title: this.model.title,
            logo: this.model.logo,
            note: this.model.note,
        }
    }

    getDefaultTitle(): string {
        switch (this.type) {
            case SaleOrderInvoiceType.SELL_INVOICE: return 'SALE INVOICE';
            case SaleOrderInvoiceType.CANCEL_NOTE: return 'CREDIT INVOICE';
            case SaleOrderInvoiceType.COMMERCIAL_INVOICE: return 'COMMERCIAL INVOICE';
            case SaleOrderInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE: return 'VAT INVOICE';
            case SaleOrderInvoiceType.DOMESTIC_INVOICE: return 'DOMESTIC INVOICE';
            case SaleOrderInvoiceType.DOMESTIC_INVOICE_AIR: return 'SERVICE INVOICE (DOM)';
            case SaleOrderInvoiceType.EXPORT_INVOICE: return 'EXPORT INVOICE';
            case SaleOrderInvoiceType.EXPORT_INVOICE_AIR: return 'SERVICE INVOICE (EXP)';
            case SaleOrderInvoiceType.FINAL_PURCHASE_INVOICE: return 'FINAL PURCHASE INVOICE';
            case SaleOrderInvoiceType.IMPORT_INVOICE: return 'IMPORT INVOICE';
            case SaleOrderInvoiceType.IMPORT_INVOICE_AIR: return 'SERVICE INVOICE (IMP)';
            case SaleOrderInvoiceType.MONTHLY_SUBSCRIPTION_PAYMENT: return 'MONTHLY SUBSCRIPTION PAYMENT INVOICE';
            case SaleOrderInvoiceType.PROFORMA_INVOICE: return 'PROFORMA INVOICE';
            case SaleOrderInvoiceType.REGISTRATION_FEES: return 'ONE TIME REGISTRATION PAYMENT INVOICE';
            case SaleOrderInvoiceType.SERVICE_INVOICE: return 'SERVICE INVOICE (CON)';
            case SaleOrderInvoiceType.SHIPPING_INVOICE: return 'COMMERCIAL INVOICE';
            case SaleOrderInvoiceType.VAT: return `VAT INVOICE ${this.invoice?.orderId ? this.invoice?.orderId : ''}`;
            case SaleOrderInvoiceType.PACKING_LIST: return 'PACKING LIST';
        }
    }

    onSubmit(e: any) {
        this.dialogRef.close(this.prepareModel(e));
    }

    onEditNote(e: string) {
        if (e.length > 200) {
            this.notify.showInfo(
                'Max length of a Note must be less than or equal to 200 characters'
            );
            e = e.substr(0, 199);
        }
        this.model.note = e;
    }
}
