import {Component, HostBinding, Input} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
    selector: 'eci-free-text',
    templateUrl: './free-text.component.html',
    styleUrls: ['./free-text.component.scss']
})
export class FreeTextComponent extends BaseElement {

    @Input() alignParent: string;

    constructor() {
        super();
    }

    @HostBinding('style.width.%')
    width;
    alignList: any[] = [
        { value:'center', name: 'Center'},
        { value:'flex-start', name: 'Start'},
        { value:'flex-end', name: 'End'},
    ];

    ngOnInit(): void {
        if (!this.item.model) {
            this.item.model = {
                width: this.item.width,
                height: 40,
                textAlign: 'center'
            } as any;
        }
        console.log(this.alignParent);
    }

    onChangeWidth(e: any) {
        this.width = e;
        this.item.width = e;
        this.item.model.width = e;
    }

}
