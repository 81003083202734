import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { PageParams } from 'src/app/core/models/page-params.model';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { UserService } from 'src/app/core/services/user.service';
import { IOrderDetails, IProduct } from '../../../models/order-details.model';
import { ICategory } from './../../../../../core/models/category.interface';
@Component({
  selector: 'eci-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {

  pageParams: PageParams = new PageParams();

  productList: IProduct[];
  @Input() summaryPage = false;
  @Input() orderId: number;
  @Input() companyId: number;
  @Input() info: IOrderDetails;
  @Input() isCreateMode: boolean = true;
  @Input() availableTypes: IAvailableTypes;
  @Input() availableFields: string[];
  @Input() hasError: boolean;
  @Input() saleOrderProducts: IProduct[];

  availableCategories: ICategory[];
  currentSort: Sort;
  showLoader: boolean = false;
  showFooter: boolean = true;

  @Output() infoChange = new EventEmitter<IOrderDetails>();
  @Output() hasErrorChange = new EventEmitter<boolean>();

  pageChanged(e: PageParams): void {
    this.pageParams.pageIndex = e.pageIndex;
    this.pageParams.pageSize = e.pageSize;
    // this.getList(e.pageIndex + 1, e.pageSize);
    this.paginate(this.productList, e.pageIndex + 1, e.pageSize);
  }

  constructor(
    public userSvc: UserService,
    private categoriesSvc: CategoriesService  ) { }


  displayedColumns: string[] = [
    'numberOfUnits',
    'productName',
    'productCategory',
    'sku',
    'price',
    'hscode'
  ];
  dataSource: MatTableDataSource<IProduct>;

  ngOnInit(): void {
    this.productList = this.saleOrderProducts;
    this.dataSource = new MatTableDataSource<IProduct>(this.productList);
    this.categoriesSvc.getAvailableCategories().subscribe(data => {
      this.availableCategories = data.items;
    });
    this.paginate(this.productList, 1, 100);
  }

  findCategoryName(id): string {
    if (!this.availableCategories) return;
    const category = this.availableCategories.find(el => el.id == id);
    return category ? category.name : '';
  }

  sortData(sort: Sort): void {
    const data = this.productList.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource<IProduct>(this.dataSource.data);
      return;
    }

    this.localSort(sort, data);
    this.currentSort = sort;
  }

  paginate(array, page_number, page_size) {
    const newArr = array.slice((page_number - 1) * page_size, page_number * page_size);
    this.dataSource = newArr;
    this.pageParams.pageSize = page_size;
    this.pageParams.pageIndex = page_number - 1;
    this.pageParams.length = this.productList.length;
    return newArr
  }

  localSort(sort: Sort, data: any): void {
    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sku': return compare(a?.product?.sku, b?.product?.sku, isAsc);
        case 'productName': return compare(a?.description || a.product?.name, b?.description || b.product?.name, isAsc);
        default: return 0;
      }
    });

    this.dataSource = new MatTableDataSource<IProduct>(this.dataSource.data);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
