export enum SPItemSizeRestrictionDimensions {
    mm = 0,
    cm = 1,
    m = 2,
    dm = 3,
    km = 4,
}

export enum SPISizeRestrictionWeightUnits {
    g = 0,
    kg = 1,
    t = 2,
}

export enum SPISizeRestrictionVolumeUnits {
    liters = 1,
    mm3 = 2,
    cm3 = 3,
    m3 = 4,
}

export enum UploadStatus {
    'FullUploadSuccess' = 'Full Upload Success',
    'PartialUploadSuccess' = 'Partial Upload Success',
    'FullUploadFailed' = 'Full Upload Failed',
}

export enum UploadMethod {
    'UIUpload' ,
}
