import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { formConstant } from '../../../../config';
import { ConstantService } from 'src/app/core/services/constant.service';
import { IAvailableTypes } from 'src/app/core/models/available-types.interface';
import { ICountry } from 'src/app/core/models/country.interface';
import { ShipmentType } from '../../enums/shipment-type.enum';
import { PortDirection } from '../../enums/port-direction.enum';

@Component({
  selector: 'add-new-port-popup',
  templateUrl: './add-new-port-popup.component.html',
  styleUrls: ['./add-new-port-popup.component.scss'],
})
export class AddNewPortPopupDialog implements OnInit {

  form: FormGroup;
  formConstant = formConstant;

  availableTypes: IAvailableTypes;
  allCountries: ICountry[];
  countries: ICountry[];
  searchCountry: FormControl = new FormControl('');

  /** Label of the search placeholder */
  @Input() placeholderLabel = 'Search...';
  @Input() noEntriesFoundLabel = 'No results';
  @Input() shipmentType: ShipmentType;
//   @Input() companyId: number;
  @Input() directionPort: PortDirection;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNewPortPopupDialog>,
    private constSvc: ConstantService,
  ) {
  }

  ngOnInit(): void {
    this.form = this.initializeForm();
    this.subcribeOnAvailableTypes();

    this.searchCountry.valueChanges.subscribe(value => {
      let searchText = value.toLowerCase();
      this.countries = this.allCountries.filter( it => {
        return it.name.toLowerCase().substring(0, searchText.length) == searchText;
      });
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value);
  }

  initializeForm(): FormGroup {
    return this.formBuilder.group({
      name: [null, [Validators.required]],
      code: [null, [Validators.required]],
      country: [null, [Validators.required]],
    //   companyId: [this.companyId, [Validators.required]],
    });
  }

  subcribeOnAvailableTypes(): void {
    this.constSvc.getAvailableCountries().subscribe(data => {
      if (!data) return;
      if(this.directionPort === PortDirection.Origin)
      {
        this.countries = data;
        this.allCountries = data;
      }else{
        this.countries = data;
        this.allCountries = this.countries;
      }
    });
  }

  getPhoneCode(country): string {
    if (!this.countries) return '';
    const countryInfo = this.countries.find(el => el.code == country)
    return countryInfo ? countryInfo?.phoneCode : '';
  }

  compareWithFn(listOfItems, selectedItem) {
    return listOfItems && selectedItem && listOfItems.id === selectedItem.id;
  }
}
