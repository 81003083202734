import { Component, Input, OnInit } from '@angular/core';
import { CompanyMarketplaceService } from 'src/app/core/services/company-marketplaces.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';

@Component({
  selector: 'eci-view-warehouse-shop-popup',
  templateUrl: './view-warehouse-shop-popup.component.html',
  styleUrls: ['./view-warehouse-shop-popup.component.scss']
})
export class ViewWarehouseShopPopupComponent implements OnInit {
  @Input() info: IWarehouseInfo;
  warehouseShops: any;
  showLoader = false;

  displayedColumns: string[] = [
    'sno',
    'shopId',
    'shopName',
    'shopAction'
  ];

  constructor(private companyMarketplaceSvc: CompanyMarketplaceService,
    private notify: NotificationService) { }

  ngOnInit(): void {
    this.viewWarehouseShops();
  }

  viewWarehouseShops() {
    this.showLoader = true;
    this.companyMarketplaceSvc.viewWarehouseShop(this.info.id, this.info.companyId).subscribe(data => {
      this.warehouseShops = data;
      this.showLoader = false;
    });
  }

  deleteWarehouseShop(id)
  {
    this.showLoader = true;
    this.companyMarketplaceSvc.deleteWarehouseShop(id, this.info.companyId).subscribe(data => {
      this.notify.showSuccessMessage("Shop removed for this warehouse successfully.");  
      this.showLoader = false;
      this.viewWarehouseShops();
    });
  }
}
