import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
    selector: 'eci-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends BaseElement implements OnChanges {

    constructor() {
        super();
    }

    ngOnInit(): void {

    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && changes.item.currentValue) {
            if (!changes.item.currentValue.model) {
                this.item.model = {
                    title: 'Customer'
                } as any;
            }
        }
        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 14;
        }
    }
}
