import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { IProduct } from '../../../modules/customers-order-management/models/order-details.model';
import { ProductAllocation, ProductAllocationCollapsed } from '../../../modules/customers-order-management/models/product-allocation.interface';
import { ProductAllocationConfirmComponent } from '../../../modules/customers-order-management/components/order-details/order-package-details/product-allocation-confirm/product-allocation-confirm.component';
import { NotificationService } from '../../../core/services/notification.service';

const packingTypeArray = [
  { name: 'Single Carton', checked: false },
  { name: 'Pallet', checked: false },
];
@Component({
  selector: 'eci-product-allocation-modal',
  templateUrl: './product-allocation-modal.component.html',
  styleUrls: ['./product-allocation-modal.component.scss']
})
export class ProductAllocationModalComponent implements OnInit {
  paletform!: FormGroup;
  cartonform!: FormGroup;
  palets = new FormArray([]);
  catoon = new FormArray([]);
  cartonFormArray = new FormArray([]);
  productList!: IProduct[];
  filteredProductList!: IProduct[];
  tableData: any;
  detailedArray: any[] = [];
  collapsedArray: any[] = [];
  mainCollapsedData: any[] = [];
  displayedColumns: string[] = ['pallets', 'cartons', 'product_name', 'sku', 'not_allocated', 'allocated'];
  displayedColumns2: string[] = ['product_name', 'sku', 'not_allocated', 'allocated'];
  productAllocation: ProductAllocation[];
  dataSource;
  dataSourceCollapsed;
  detailedView: boolean;
  // searchPallet: FormControl = new FormControl('');
  // searchCarton: FormControl = new FormControl('');
  searchProduct: FormControl = new FormControl('');
  packingTypeArray = packingTypeArray;
  packingType = '';
  @ViewChild('palletSelect') palletElem: MatSelect;
  @ViewChild("productSelect") productElem: MatSelect;
  @ViewChild("cartonSelect") cartonElem: MatSelect;

  palletIndex: number;
  cartonIndex: number;
  singleCartonIndex:number;

  form: FormGroup;

  userSelectedPallet: any = [];
  userSelectedCarton: any = [];
  userSelectedSingleCarton: any = [];
  userSelectedProduct: any = [];

  palletCartonsArray: any = [];

  collapsedView = false;
  isCreateMode: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProductAllocationModalComponent>,
    public dialog :MatDialog,
    private notify: NotificationService
  ) {
    this.form = this.formBuilder.group({
      palletId: [],
      cartonId: [],
      singleCartonId:[],
      productId: [],
      totalPallets: [],
      totalCartons: [],
      totalSingleCartons:[],
      palletQty:0,
      singleCartonQty:0
    });
  }
  ngOnInit(): void {
    // if(this.isCreateMode)
    // {
    //   this.packingTypeArray.forEach(el => el.checked = false);
    // }
    if(this.paletform?.value?.pallets?.length===0)
    this.paletform = new FormGroup({
      palets: new FormArray([]),
    });
    if (this.packingType === 'SingleCarton') {
      this.packingTypeArray[0].checked= true;
    } else if (this.packingType === 'Pallets'){
      this.packingTypeArray[1].checked= true;
    }
    else if (this.packingType === 'Both'){
      this.packingTypeArray[0].checked= true;
      this.packingTypeArray[1].checked= true;
    }
    else{
      this.packingTypeArray[0].checked= false;
      this.packingTypeArray[1].checked= false;
    }
    if(this.cartonform?.value?.pallets?.cartons===0)
    this.cartonform = new FormGroup({
      cartons: new FormArray([]),
    });
    this.form.get('palletId').valueChanges.subscribe(val => {
      this.palletCartonsArray = [];
      this.userSelectedPallet = val;
      if (val.length > 0) {
        this.palletData(this.tableData).forEach((element, i) => {
          if (element.cartonNumbers && val.includes(i)) {
            this.palletCartonsArray.push(element);
          }
        });
      }
      this.filterData();
    });

    this.form.get('cartonId').valueChanges.subscribe(val => {
      this.userSelectedCarton = val;
      this.filterData();
    });
    this.form.get('singleCartonId').valueChanges.subscribe(val => {
      this.userSelectedSingleCarton = val;
      this.filterData();
    });

    this.form.get('productId').valueChanges.subscribe(val => {
      this.userSelectedProduct = val;
      this.filterData();
    });
    this.inititalizeData();

    if(!this.detailedView){
      this.collapsedView = true;
      this.populateData();
    }
  }
palletData(data=undefined){
  return data?data.filter(x=>x.cartonNumbers):this.tableData.filter(x=>x.cartonNumbers);
}
singlecartonData(data=undefined){
  return data?data.filter(x=>!x.cartonNumbers):this.tableData.filter(x=>!x.cartonNumbers);
}
  changePackingStatus(packingTypeSingle,makeEmpty=true) {
    if(makeEmpty)
      this.productAllocation = [];
    packingTypeSingle.checked = !packingTypeSingle.checked;

    if (packingTypeArray[0].checked && packingTypeArray[1].checked){
      this.packingType = 'Both';
    }else if(packingTypeArray[0].checked) {
      this.packingType = 'SingleCarton';
    } else if (packingTypeArray[1].checked){
      this.packingType = 'Pallets';
    }
  }
  onChangePallet() {
    // this.clearFormArray(this.palletForm());
    this.addPallet();
    this.form.get('totalPallets').setValue((this.paletform.get('palets').value.length));
    this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
  }
  onChangePallets(value) {
    let keys = [];
    if (value.includes('all')) {
      keys.push('all');
      this.palletData().forEach((element, i) => {
        keys.push(i);
      });
      this.form.get('palletId').setValue(keys);
    } else {
      this.palletData().forEach((element, i) => {
        if (value.includes(i)) {
          keys.push(i);
        }
      });
      this.form.get('palletId').setValue(keys);
    }
  }

  onChangeProducts(value) {
    let keys = [];
    if (value.includes('all')) {
      keys.push('all');
      this.productList.forEach((element, i) => {
        keys.push(element.productId);
      });
      this.form.get('productId').setValue(keys);
    } else {
      this.productList.forEach((element, i) => {
        if (value.includes(element.productId)) {
          keys.push(element.productId);
        }
      });
      this.form.get('productId').setValue(keys);
    }
    this.userSelectedProduct = keys;
  }

  onChangeCartoons(value) {
    let keys = [];
    if (value.includes('all')) {
      keys.push('all');
      this.palletData().forEach((element, i) => {
        if(element.cartonNumbers)
        {
        element.cartonNumbers.forEach((obj,index) => {
          if(i===0)
            keys.push(index);
          else
          keys.push(+(i+''+index));
        });
      }
      });
      this.form.get('cartonId').setValue(keys);
    } else {
      this.palletData().forEach((element, i) => {
        if (value.includes(element.cartons)) {
          keys.push(element.cartons);
        }
      });
      this.form.get('cartonId').setValue(keys);
    }
  }

  onChangeSingleCartoons(value) {
    let keys = [];
    if (value.includes('all')) {
      keys.push('all');
      this.singlecartonData().forEach((element, i) => {
        keys.push(i);
      });
      this.form.get('singleCartonId').setValue(keys);
    } else {
      this.singlecartonData().forEach((element, i) => {
        if (value.includes(i)) {
          keys.push(i);
        }
      });
      this.form.get('singleCartonId').setValue(keys);
    }
  }

  countTotalCartons(data) {
    let totalCartons = 0;
    data.forEach((element) => {
      if(element.cartonNumbers)
      {
        element.cartonNumbers.forEach((element2) => {
          totalCartons++;
        });
      }
    });
    return totalCartons;
  }
  changePalletNumber(index: any) {
    this.palletForm().at(index).get('palletIndex').setValue(index);
    this.addCarton(index);
    this.palletForm().at(index).get('palletNumber').setValue((this.getcartoons(index).value.length));
    this.inititalizeData();
  }
  palletForm(): FormArray {
    return this.paletform.get('palets') as FormArray;
  }
  addPallet(): void {
    this.palets = this.paletform.get('palets') as FormArray;
    this.palets.push(this.createPallet());
  }

  getcartoons(empIndex: number): FormArray {
    return this.palletForm()
      .at(empIndex)
      .get('cartonNumbers') as FormArray;
  }

  addCarton(empIndex: number) {
    this.getcartoons(empIndex).push(this.createCarton());
  }
  createPallet(): FormGroup {
    return this.formBuilder.group({
      palletNumber: '',
      palletIndex: '',
      cartonNumbers: new FormArray([]),
    });
  }

  createCarton(): FormGroup {
    return this.formBuilder.group({
      catoon: ''
    });
  }
  removePallet(i) {
    let allocateCount=0;
    for(let j=0;j<this.getcartoons(i).controls.length;j++){
      allocateCount= allocateCount + this.checkAllocation(i,j);
    }
    if(allocateCount===0) {
      this.palletForm().removeAt(i);
      this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
      this.form.get("totalPallets").setValue((this.paletform.get('palets').value.length));
      //this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
      return;
    }
      if(confirm('Removing this Pallet will deallocate the products Allocated in this pallet.')) {
        const length = this.getcartoons(i).controls.length;
        for(let j=0;j<length;j++)
        {
          this.removePalletCarton(null,i,j,true);
        }
        while (this.getcartoons(i).length !== 0) {
          this.getcartoons(i).removeAt(0)
        }
           this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
           this.palletForm().removeAt(i);
           this.form.get('palletQty').setValue((this.paletform.get('palets').value.length));
           this.form.get("totalPallets").setValue((this.paletform.get('palets').value.length));
           //this.palletDetailComponent.updateRecords(this.productAllocation, this.pallets);
           //this.pallets = this.pallets.splice(i,1);
      }
  }


  removePalletCarton(event, i, j,removePallet=false) {
    if (this.productAllocation.length === 0) {
      this.getcartoons(i).removeAt(j);
      this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
    } else if(this.checkAllocation(i,j))
    {
     if(removePallet || confirm('Removing this Pallet will deallocate the products Allocated in this pallet.')){
      this.productAllocation.forEach(element => {
        if (Number(element.pallets) === i && Number(element.cartons) === j) {
          if (Number(element.allocated) !== 0) {
            this.onChangeProductQtyByData(element);
          }
        }
      });
      if(!removePallet)
      {
        this.getcartoons(i).removeAt(j);
        this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
      }
      }
    }
    else {
      if(!removePallet)
      {
        this.getcartoons(i).removeAt(j);
        this.palletForm().at(i).get('palletNumber').setValue((this.getcartoons(i).value.length));
      }
    }
    this.inititalizeData();
  }

checkAllocation(i,j){
  let flag=0;
  this.productAllocation.forEach(element => {
    if (Number(element.pallets) === i && Number(element.cartons) === j) {
      if (Number(element.allocated) !== 0) {
        flag ++;
        }
      }
    });
  return flag;
}
onChangeProductQtyByData(data: any) {
  // when user remove value
  const index2 = this.productAllocation.indexOf(data);
  if (index2 != -1) {
    this.productAllocation[index2].allocated = 0;
  }
  const data2 = this.productAllocation.filter(pro => {
    return pro.product_id == data.product_id;
  })
  if (data2.length > 0) {
    let allocated = 0;
    data2.map(item => {
      allocated = allocated + +item.allocated
    })
    for (let i = 0; i < data2.length; i++) {
      const index = this.productAllocation.indexOf(data2[i]);
      if (index != -1) {
        this.productAllocation[index].not_allocated = this.productAllocation[index].total_qty - allocated;
      }
    }
  }
}

removeSingleCarton(event, i) {
if (this.productAllocation.length == 0) {
  this.cartoonForm().removeAt(i);
  this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
} else {
  let flag = 0;
  this.productAllocation.forEach(element => {
    if (Number(element.cartons) === i) {
      if (Number(element.allocated) !== 0) {
        flag++;
      }
    }
  });

  if (flag === 0) {
    this.cartoonForm().removeAt(i);
    this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
  }
  else {
    this.notify.showError('Product is allocated to this Carton');
  }
}
this.form.get('totalSingleCartons').setValue(this.cartonform.value.cartons.length);
this.inititalizeData();
}
onChangeCartons() {
  //  this.clearFormArray(this.cartoonForm())
  this.catoon = this.cartonform.get('cartons') as FormArray;
  this.catoon.push(this.createCarton());
  this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
  this.inititalizeData();
}

cartoonForm(): FormArray {
  return this.cartonform.get('cartons') as FormArray;
}
getPalletName(element){
  return element.pallets!=null?'Pallet '+(element.pallets+1):'-';
}
getSingleCartonName(element){
  return element.pallets!=null?'Carton' + (element.cartons+1) :'Single Carton' + (element.cartons+1);
}
  inititalizeData() {
    this.tableData = this.paletform.value.palets.concat(this.cartonform.value.cartons);
    this.form.get('palletQty').setValue(this.paletform.get('palets').value.length);
    this.form.get("totalPallets").setValue(this.paletform.get('palets').value.length);
    this.form.get("totalSingleCartons").setValue(this.cartonform.value.cartons.length);
    this.form.get("totalCartons").setValue(this.countTotalCartons(this.tableData));
    this.form.get('singleCartonQty').setValue((this.cartonform.get('cartons').value.length));
    this.detailedArray =[];
    if (this.productAllocation.length > 0) {
      this.productList.forEach((elepro: any) => {
        this.singlecartonData(this.tableData).forEach((eleData: any, index: any) => {
         if(packingTypeArray[0].checked && eleData.catoon!=null) 
          this.cartoonAllocationLogic(elepro,index);
        });
        this.palletData(this.tableData).forEach((eleData: any, index: any) => {
          if(packingTypeArray[1].checked && eleData.cartonNumbers) 
           this.palletAllocationLogic(elepro,eleData,index);
         });

      });
    }
    else {
      this.productList.forEach((elepro: any) => {
        this.singlecartonData(this.tableData).forEach((eleData: any, index: any) => {
          if(packingTypeArray[0].checked && eleData.catoon!=null){ 
          this.detailedArray.push({
            'cartons': index,
            'product_name': elepro.description,
            'product_id': elepro.productId,
            'sku': elepro.itemNumber,
            'total_qty': elepro.units,
            'not_allocated': elepro.units,
            'allocated': 0
          });
        }
        });
        this.palletData(this.tableData).forEach((eleData: any, index: any) => {
          if(packingTypeArray[1].checked && eleData.cartonNumbers){ 
          eleData.cartonNumbers.forEach((cartoo: any, indexc: any) => {
            this.detailedArray.push({
              'pallets': index,
              'cartons': indexc,
              'pallet_carton': ((Number(eleData.palletIndex) * 10) + indexc),
              'product_name': elepro.description ? elepro.description : elepro?.product?.description,
              'product_id': elepro.productId,
              'sku': elepro.itemNumber,
              'total_qty': elepro.units,
              "not_allocated": elepro.units,
              "allocated": 0
            });
          });
        }
        });

      });
      
    }
    this.productAllocation = this.detailedArray;
    this.dataSource = this.detailedArray as ProductAllocation[];
    this.dataSourceCollapsed = this.collapsedArray as ProductAllocationCollapsed[];
    this.userSelectedPallet = ['all'];
    this.userSelectedCarton = ['all'];
    this.userSelectedSingleCarton = ['all'];
    this.onChangePallets('all');
    this.onChangeCartoons('all');
    this.onChangeProducts('all');
    this.onChangeSingleCartoons('all')
    //this.form.get("palletId").setValue(this.userSelectedPallet);
    //this.form.get("cartonId").setValue(this.userSelectedCarton);
    this.filterData();
    this.populateData();
  }

palletAllocationLogic(elepro,eleData,index){
  eleData.cartonNumbers.forEach((cartoo: any, indexc: any) => {

    const existProductAllocation = this.productAllocation.filter((data) => ((Number(data.pallets) == index) && (Number(data.cartons) == indexc) && (Number(data.product_id) == Number(elepro.productId))));
    const singleProductAllocation = this.productAllocation.filter((data) => (Number(data.product_id) == Number(elepro.productId)));

    if (existProductAllocation[0]) {
      let tolQty = 0;
      let notAlcted = 0;
      if (existProductAllocation[0]?.total_qty) {
        if (elepro.units > existProductAllocation[0].total_qty) {
          tolQty = elepro.units;
          notAlcted = elepro.units - existProductAllocation[0].allocated;
        } else {
          tolQty = existProductAllocation[0].total_qty;
          notAlcted = existProductAllocation[0].not_allocated;
        }
      } else {
        tolQty = elepro.units;
        notAlcted = elepro.units;
      }
      this.detailedArray.push({
        'pallets': existProductAllocation[0].pallets,
        'cartons': existProductAllocation[0].cartons,
        'pallet_carton': existProductAllocation[0].pallet_carton,
        'product_name': existProductAllocation[0].product_name,
        'product_id': existProductAllocation[0].product_id,
        'sku': existProductAllocation[0].sku,
        'total_qty': tolQty,
        'not_allocated': notAlcted,
        'allocated': existProductAllocation[0].allocated
      });
    } else {
      let notAlcted = 0;
      if (singleProductAllocation[0]) {
        let totalAlcted = 0;
        singleProductAllocation.forEach((sngPrdAlct: any) =>{
          totalAlcted += Number(sngPrdAlct.allocated);
        });
        notAlcted = elepro.units - totalAlcted;
      } else {
        notAlcted = elepro.units;
      }

      this.detailedArray.push({
        'pallets': index,
        'cartons': indexc,
        'pallet_carton': ((Number(eleData.palletIndex) * 10) + indexc),
        'product_name': elepro.description,
        'product_id': elepro.productId,
        'sku': elepro.itemNumber,
        'total_qty': elepro.units,
        'not_allocated': notAlcted,
        'allocated': 0
      });
    }
  });
}

cartoonAllocationLogic(elepro,index){
  const existProductAllocation = this.productAllocation.filter((data) => (data.pallets==null && (Number(data.cartons) == index) && (Number(data.product_id) == Number(elepro.productId))));
          const singleProductAllocation = this.productAllocation.filter((data) => (Number(data.product_id) == Number(elepro.productId)));

          if (existProductAllocation[0]) {
            let tolQty = 0;
            let notAlcted = 0;
            if (existProductAllocation[0]?.total_qty) {
              if (elepro.units > existProductAllocation[0].total_qty) {
                tolQty = elepro.units;
                notAlcted = elepro.units - existProductAllocation[0].allocated;
              } else {
                tolQty = existProductAllocation[0].total_qty;
                notAlcted = existProductAllocation[0].not_allocated;
              }
            } else {
              tolQty = elepro.units;
              notAlcted = elepro.units;
            }
            this.detailedArray.push({
              'cartons': existProductAllocation[0].cartons,
              'product_name': existProductAllocation[0].product_name,
              'product_id': existProductAllocation[0].product_id,
              'sku': existProductAllocation[0].sku,
              'total_qty': tolQty,
              'not_allocated': notAlcted,
              'allocated': existProductAllocation[0].allocated
            });
          } else {
            let notAlcted = 0;
            if (singleProductAllocation[0]) {
              let totalAlcted = 0;
              singleProductAllocation.forEach((sngPrdAlct: any) => {
                totalAlcted += Number(sngPrdAlct.allocated);
              });
              notAlcted = elepro.units - totalAlcted;
            } else {
              notAlcted = elepro.units;
            }
            this.detailedArray.push({
              'cartons': index,
              'product_name': elepro.description,
              'product_id': elepro.productId,
              'sku': elepro.itemNumber,
              'total_qty': elepro.units,
              'not_allocated': notAlcted,
              'allocated': 0
            });
          }
}
  filterData() {
    let newArrayData = this.detailedArray;
    if (this.userSelectedPallet.length > 0 && !this.userSelectedPallet.includes('all')) {
      newArrayData = newArrayData.filter((data) => (this.userSelectedPallet !== '') && (this.userSelectedPallet.includes(data.pallets)));
    }
    if (this.userSelectedProduct.length > 0 && !this.userSelectedProduct.includes('all')) {
      newArrayData = newArrayData.filter((data) => (this.userSelectedProduct !== '') && (this.userSelectedProduct.includes(data.product_id)));
    }
    if (this.userSelectedCarton.length > 0 && !this.userSelectedCarton.includes('all')) {
      newArrayData = newArrayData.filter(data => (this.userSelectedCarton !== '') && (this.userSelectedCarton.includes(data.pallet_carton)));
    }
    if (this.userSelectedSingleCarton.length > 0 && !this.userSelectedSingleCarton.includes('all')) {
      newArrayData = newArrayData.filter(data => (this.userSelectedSingleCarton !== '') && (this.userSelectedSingleCarton.includes(data.cartons)));
    }
    this.dataSource = newArrayData as ProductAllocation[];
    console.log('filter',this.detailedArray,this.dataSource,this.tableData)
  }

  onChangeProductQty(event: any, data: any) {
    if (event.target.value) {
      if ((event.target.value - data.allocated) > data.not_allocated) {
        event.target.value = data.allocated;
        return;
      }
      const index2 = this.detailedArray.indexOf(data);
      if (index2 != -1) {
        this.detailedArray[index2].allocated = event.target.value;
      }
      const data2 = this.detailedArray.filter(pro => {
        return pro.product_id == data.product_id;
      });
      if (data2.length > 0) {
        let allocated = 0;
        data2.map(item => {
          allocated = allocated + parseInt(item.allocated);
        })

        for (let i = 0; i < data2.length; i++) {
          const index = this.detailedArray.indexOf(data2[i])
          if (index != -1) {
            if (this.detailedArray[index].total_qty != this.detailedArray[index].not_allocated) {
              this.detailedArray[index].not_allocated = this.detailedArray[index].total_qty - allocated;
            }
            else {
              // when user press enter 0
              this.detailedArray[index].not_allocated = this.detailedArray[index].total_qty - event.target.value;
            }
          }
        }
      }
    }
    else {
      // when user remove value
      const index2 = this.detailedArray.indexOf(data);
      if (index2 != -1) {
        this.detailedArray[index2].allocated = 0;
      }
      const data2 = this.detailedArray.filter(pro => {
        return pro.product_id == data.product_id;
      })
      if (data2.length > 0) {
        let allocated = 0;
        data2.map(item => {
          allocated = allocated + parseInt(item.allocated)
        })
        for (let i = 0; i < data2.length; i++) {
          const index = this.detailedArray.indexOf(data2[i]);
          if (index != -1) {
            this.detailedArray[index].not_allocated = this.detailedArray[index].total_qty - allocated;
          }
        }
      }
    }
    this.populateData();
  }

  onChangeState(event) {
    this.collapsedView = event.checked;
    this.populateData();
  }

  populateData(){
    this.collapsedArray = [];
    this.productList.forEach((elepro: any) => {
      var allocated = 0;
      var notAllocated = 0;
      this.detailedArray.forEach(element => {
        if (elepro.productId == element.product_id) {
          notAllocated = element.not_allocated;
          allocated += Number(element.allocated);
        }
      });
      this.collapsedArray.push({
        'product_name': elepro.description ? elepro.description : elepro?.product?.description,
        'sku': elepro.itemNumber,
        "not_allocated": notAllocated,
        "allocated": allocated
      });
    });
  }

  save(): void {
    let isAllocated = []
    this.detailedArray.map(item => {
      if (item.not_allocated > 0 && isAllocated.findIndex(el => el.sku === item.sku) === -1) {
        isAllocated.push(item);
      }
    });
    const objData:any={
      productAllocation: this.detailedArray, 
      palets: this.palets,
      packingType: this.packingType,
      paletform : this.paletform,
      cartonform: this.cartonform
    }
    if (isAllocated.length) {
      const dialogRef = this.dialog.open(ProductAllocationConfirmComponent, {
        maxHeight: '600px',
        disableClose: true
      });
      dialogRef.componentInstance.items = isAllocated;
     
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close({ type: 'save', data: objData });
        }
      });
    } else {
      this.dialogRef.close({ type: 'save', data: objData });
    }
  }

  exit(): void {
    const objData:any={
      productAllocation: this.detailedArray, 
      palets: this.palets,
      packingType: this.packingType,
      paletform : this.paletform,
      cartonform: this.cartonform
    }
    this.dialogRef.close({ type: 'close', data: objData });
  }
}
