import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/core/helpers/helpers.function';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/modules/empact-shop/core/services/user.service';
import { formConstant } from '../../../../../config';
import { IAvailableTypes } from '../../../../core/models/available-types.interface';
import { ICountry, IState } from '../../../../core/models/country.interface';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { FilterPipe } from '../../core/pipes/filter.pipe';
import { ConstantService } from '../../core/services/constant.service';
import { AuthType } from '../../core/enums/auth-type.enum';

@Component({
    selector: 'ecommerce-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
    providers: [FilterPipe],
})
export class RegistrationComponent implements OnInit {

    @Input() isCheckout: boolean = false;
    @Output() typeChanged: EventEmitter<AuthType> = new EventEmitter<AuthType>(null);
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>(null);

    form: FormGroup;

    public searchCountry: FormControl = new FormControl('');
    public searchState: FormControl = new FormControl('');
    public searchCity: FormControl = new FormControl('');
    public searchPaymentsTerms: FormControl = new FormControl('');

    public paymentsTerms = [
        {name: 'Upon delivery'},
        {name: '50% prepaid, 50% upon delivery'},
        {name: 'Net +30'},
        {name: 'Net +60'},
        {name: 'Other'}
    ];
    phoneCodes: IAvailableTypes;
    allCountries: ICountry[] = [];
    public shippingStates: IState[];
    public cities: any[] = [];
    public textFieldMaxLength = formConstant.textFieldMaxLength30
    constructor(
        private fb: FormBuilder,
        private constantService: ConstantService,
        private userSvc: UserService,
        private notify: NotificationService,
        private localStorageSvc: LocalStorageService,
    ) {
    }

    ngOnInit(): void {
        // TO DO - NEED SEPARETED SERVICE FOR COUNTRIES FOR ECOMMERCE
        this.constantService.getAvailablePhoneCodes().subscribe(values => {
            this.phoneCodes = values;
        });
        this.getAvailableCountries();
        this.form = this.initializeForm();
        this.changesSubscribe();
    }

    goToLogin(): void {
        this.typeChanged.emit(AuthType.Login);
    }

    initializeForm(): FormGroup {
        // Todo: use new FormGroup and new FormControl
        return this.fb.group({
            firstName: ['',
                [
                    Validators.required,
                    Validators.maxLength(this.textFieldMaxLength),
                    Validators.pattern(/^[A-Za-z0-9 ]+$/)
                ]],
            lastName: ['',
                [
                    Validators.required,
                    Validators.maxLength(this.textFieldMaxLength),
                    Validators.pattern(/^[A-Za-z0-9 ]+$/)
                ]],
            vatNumber: ['', [
                    Validators.required,
                    Validators.minLength(formConstant.VATMinLength),
                    Validators.maxLength(formConstant.VATMaxMinLength),
                    Validators.pattern(/^[A-Za-z0-9]+$/),
                ]],
            phoneCode: ['', [Validators.required]],
            mobileNumber: ['',
                [
                    Validators.required,
                    Validators.pattern(/^\+?\d*$/),
                ]],
            email: ['', [Validators.required, Validators.email, emailValidator()]],
            password: ['', [Validators.required,
                Validators.minLength(formConstant.passwordMinLength),
                Validators.pattern(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)]],
            confirmPassword: ['', [Validators.pattern(/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))[0-9a-zA-Z_@./#$^*%&+-]{8,}$/)]],
            companyName: ['', [Validators.required,
                Validators.maxLength(this.textFieldMaxLength)]],

            country: [undefined, [Validators.required]],
            state: [undefined, [Validators.required]],
            city: [undefined, [Validators.required]],
            address: ['', [
                Validators.required,
                Validators.maxLength(this.textFieldMaxLength),
            ]],
            zipCode: ['', [
                Validators.required,
                Validators.maxLength(this.textFieldMaxLength),
                Validators.minLength(formConstant.minZipCodeLength)
            ]],
            paymentsTerms: [undefined , Validators.required,],

        }, {validator: this.checkPasswords});
    }

    checkPasswords(group: FormGroup) {
        const pass = group.controls.password.value;
        const confirmPass = group.controls.confirmPassword.value;
        return pass === confirmPass ? null : {notSame: true};
    }

    register(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }

        this.userSvc.register(this.form.value).subscribe(result => {
            this.notify.showSuccessMessage('User created successfully');
            this.goToLogin();
        }, (err) => {
            this.notify.showValidationError(err);
        });
    }

    public fieldValidation(name, errorName: string = 'required'): boolean {
        return this.form?.controls?.[name]?.touched && this.form?.controls?.[name]?.hasError(errorName);
    }

    private changesSubscribe(): void {
        this.form.get('country').valueChanges
            .subscribe((value) => {
                if (value) {
                    this.updateState(value);
                }
            });
    }

    private getAvailableCountries(): void {
        this.constantService.availableCountries.subscribe((data) => {
            if (data) {
                this.allCountries = data;
            }
        });
    }

    private updateState(value): void {
        const selectedCountry = this.allCountries?.find((el) => el.id === value);
        this.getCitiesByCountryId(selectedCountry?.id);

        this.constantService.getAvailableStatesById(selectedCountry.code).subscribe((states) => {
            if (!states) {
                this.form.get('state').setValue(null);
                return;
            }
            this.shippingStates = states;
        });
    }

    private getCitiesByCountryId(countryID: string | number): void {
        if (!countryID) {
            return;
        }
        this.constantService.getAllByCountryId(countryID).subscribe((cities) => {
            if (!cities) {
                return;
            }
            this.cities = cities;
        });
    }

}
