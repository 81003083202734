import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { ICountry, IState } from 'src/app/core/models/country.interface';
import { ConstantService } from 'src/app/core/services/constant.service';
import { UserService } from 'src/app/core/services/user.service';
import { IProduct, IProductExt } from 'src/app/modules/customers-order-management/models/order-details.model';
import { ICategory } from 'src/app/core/models/category.interface';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { ProductsService } from 'src/app/modules/products/services/products.service';
import { IProductDetails } from 'src/app/modules/products/models/product-details.interface';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { formConstant } from 'src/config';
import { SaleProductService } from 'src/app/modules/sales-order-management/services/sale-product.service';
import { ProductSearchType } from 'src/app/shared/enums/product-search-type.enum';
import { ProductStatus } from 'src/app/shared/enums/product-status.enum';
import { OfflineProductComponent } from '../../../modules/offline-shop-admin/components/product-details/offline-product.component';

import { ProductDetailsComponent as ProductDetailsComponent } from '../product-details/product-details.component';
import { debounceTime } from 'rxjs/operators';
import { OfflineShopService } from 'src/app/modules/offline-shop-admin/services/offline-shop.service';
import { HsCodesService } from 'src/app/modules/hs-codes/services/hs-code-services';

@Component({
  selector: 'eci-product-popup-table',
  templateUrl: './product-popup-table.component.html',
  styleUrls: ['./product-popup-table.component.scss']
})
export class ProductPopupTableComponent implements OnInit {

    form2: FormGroup;
    form: FormGroup
    items: FormArray;
    textFieldMaxLength = formConstant.textFieldMaxLength;

    availableTypes: IAvailableTypes;
    availableCategories: ICategory[];
    countries: ICountry[];
    states: IState[] = [];
    showLoader: boolean = false;
    selectedProducts: any[] = [];
    existingProducts: any[] = [];


    @Input() info: any;
    @Input() isCreateMode: boolean;
    @Input() isEditable: boolean = false;
    @Input() isSaleOrder: boolean = false;
    @Input() isB2BSaleOrder: boolean = false;
    @Input() isMarketplaceSO: boolean = false;
    @Input() title: string = 'Shipment - Add Product List';
    @Input() isShipment: boolean = false;
    @Input() country: any;

    minDate: Date;

    previousePageSearch: number = 0;
    searchCategory: string = '';
    totalPagesCat: number = 1;

    listOfProducts: any[] = [];
    previousePageProducts: number = 0;
    totalPagesProducts: number = 1;
    modelToShow: IProductExt[];
    companyId: number;
    currentProduct: IProductDetails;
    searchProduct: FormControl = new FormControl('');
    empactShopId: number | string;
    unitPrice;
    discountAmount;
    selectedItem: any[] = [];
    dataSource: any;

    displayedColumns: any[] = [
        'product',
        'sku',
        'quantity',
        'currency',
        // 'salePrice',
        'costPrice',
        'total',
        'hscode',
        'dutytax',
        'dutytaxamount',
        'delete'
    ];

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ProductPopupTableComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private constSvc: ConstantService,
        public userSvc: UserService,
        private catSvc: CategoriesService,
        private productSvc: ProductsService,
        private localStorageSvc: LocalStorageService,
        private saleProductService: SaleProductService,
        private dialog: MatDialog,
        private offlineShopService: OfflineShopService,
        private hsCodesService: HsCodesService
    ) {

    }

    get warehouseTypes(): IConstantItem[] {
        if (!this.availableTypes) return;
        return this.availableTypes.WarehouseType;
    }

    get locales(): IConstantItem[] {
        if (!this.availableTypes) return;
        return this.availableTypes.WarehouseLocation;
    }

    get orderTypes(): IConstantItem[] {
        if (!this.availableTypes) return;
        return this.availableTypes.CompanyType;
    }

    get customerTypes(): IConstantItem[] {
        if (!this.availableTypes) return;
        return this.availableTypes.CompanyType;
    }

    get orderStatuses(): IConstantItem[] {
        if (!this.availableTypes) return;
        return this.availableTypes.CompanyType;
    }

    get paymentStatuses(): IConstantItem[] {
        if (!this.availableTypes) return;
        return this.availableTypes.CompanyType;
    }

    ngOnInit(): void {
        this.isCreateMode = this.data['isCreateMode'];
        this.isEditable = this.data['isEditable'];
        this.isSaleOrder = this.data['isSaleOrder'];
        this.isB2BSaleOrder = this.data['isB2BSaleOrder'];
        this.isMarketplaceSO = this.data['isMarketplaceSO'];
        this.companyId = this.data['companyId'];
        this.isShipment = this.data['isShipment'] ?? false;
        this.empactShopId = this.data['shopId'];
        this.country = this.data['country'];
        this.form2 = this.initializeForm();
        this.form = this.formBuilder.group({
            items: this.formBuilder.array([])
        });

        this.minDate = new Date();
        this.subcribeOnAvailableTypes();
        if (!this.empactShopId && this.isMarketplaceSO) {
            this.getEShops();
        }

        this.searchProduct.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.previousePageProducts = 0;
            this.getListOfProducts();
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
        const selectedProducts: IProduct[] = this.model;
        this.info = this.model;
        this.modelToShow = selectedProducts.map((product) => ({
            ...product,
            product: this.listOfProducts.find((el) => el.id === product.productId),
        }));
        this.dialogRef.close({ info: this.info, modelToShow: this.modelToShow });

        this.items = this.form.get('items') as FormArray;
        this.modelToShow?.map((item, index) => {
            if (item.product?.costPriceUnit !== parseInt(this.items.at(index).get('costPrice').value)) {
                const payload = {
                    id: item.product?.id,
                    name: item.product.name,
                    sku: item.product?.sku,
                    costPriceUnit: parseInt(this.items.at(index).get('costPrice').value),
                    salePrice: item.product?.salePrice,
                    status: item.product?.status,
                    categoryId: item.product?.categoryId,
                    description: item.product?.description,
                    eanUpc: item.product?.eanUpc,
                    shopId: item.product?.shopId,
                    hsCodeId: item.product?.hsCodeId,
                    categoryBy: item.product?.categoryBy,
                    createdBy: item.product?.createdBy,
                    updatedBy: item.product?.updatedBy,
                    discount: item.product?.discount,
                    currency: item.product?.currency,
                }
                this.productSvc.updateProduct(item.product?.id, payload).subscribe((value) => {
                    console.log(value, "successfully updated");
                });
            }
        });
    }

    initializeForm(): FormGroup {
        return this.formBuilder.group({
            productId: [{ disabled: !this.isCreateMode, value: null }, [Validators.required]],
        });
    }

    updateListOfGoods(id, value) {
        if (this.isAdmin && this.form.valid && id) {
            this.showLoader = true;
            this.saleProductService.updateTaxAmountFromTaxRate(id, value).subscribe(data => {
                if (!data) return;
                this.showLoader = false;
                this.form.get('totalPrice').setValue(data.totalAmount);
            }, err => {
                this.showLoader = false;
            });
        }
    }

    subcribeOnAvailableTypes(): void {
        this.constSvc.availableTypes.subscribe(data => {
            if (!data) return;
            this.availableTypes = data;
        });

        this.catSvc.getAvailableCategories().subscribe(data => {
            this.availableCategories = data.items;
        })

        this.getListOfProducts();
    }

    get isAdmin(): boolean {
        return this.localStorageSvc.get('user').role == Roles.Admin || this.localStorageSvc.get('user').role == Roles.DelicateAdmin  || this.localStorageSvc.get('user').role == Roles.ShipmentsAdmin;
    }

    get model(): IProduct[] {
        const items = this.form.get('items') as FormArray;
        const selectedProducts = items.controls.map((item: FormGroup, index) => {
            const product = this.listOfProducts.find(el => el.id === this.selectedProducts[index]);
            const price = this.isEditable ?
                    (this.isSaleOrder ? Number(item.get('salePrice').value) : Number(item.get('salePrice').value)) :
                    Number(item.get('costPrice').value) || product?.salePrice;

            return {
                hsCode: this.isSaleOrder ? item.get('hscode').value : product.hsCodes?.taricCode,
                simplyDutyTaxRate: item.get('simplyDutyTaxRate').value || 0,
                simplyDutyTaxAmount: item.get('simplyDutyTaxAmount').value || 0,
                discount: item.get('discount')?.value || 0,
                weight: product?.weight,
                volume: product?.volume,
                units: parseInt(item.get('numOfUnits').value),
                productId: product?.id,
                itemNumber: product?.sku,
                description: product?.name,
                price: this.isMarketplaceSO ? +this.unitPrice : price,
                saleUnitPrice: this.isSaleOrder ? item.get('salePrice').value : item.get('salePrice').value,
                costUnitPrice: item.get('costPrice').value,
                costPriceUnit: this.isSaleOrder ? parseInt(item.get('costPrice').value) : product?.costPriceUnit,
                categoryId: product?.categoryId,
                status: product?.status,
                currency: product?.currency,
            }
        });

        return selectedProducts;
    }

    onSelected(event): void {
      this.addItemToTable(event.value);
    }

    changeTo0(field: string, index): void {
        this.items = this.form.get('items') as FormArray;
        this.totalPriceBindData(index);
        this.items.at(index).get(field).value < 0 ? this.items.at(index).get(field)?.setValue(field) : '';
    }

    getListOfProducts(
      page: number = this.previousePageProducts + 1,
      q: string = this.searchProduct.value || '',
      limit: number = 500,
    ): void {
      const status = this.isShipment ? null : (this.isCreateMode ? ProductStatus.Active : null);
      this.productSvc.getProducts(page, limit, q, 'name', 'ASC', this.companyId, null, this.getSearchType(), status)
          .subscribe((data) => {
          if (!data) return;

          if (data.meta.currentPage > 1)
              this.listOfProducts = [...this.listOfProducts, ...data.items];
          else this.listOfProducts = data.items;

          this.listOfProducts = [...this.listOfProducts, ...this.selectedItem];

          this.previousePageProducts = data.meta.currentPage;
          this.totalPagesProducts = data.meta.totalPages;
      });
    }

    private getSearchType(): ProductSearchType {
        switch (true) {
            case this.isShipment:
                return ProductSearchType.All;
            default:
                return ProductSearchType.All;
        }
    }

    changeProduct(value): void {
        let product = this.listOfProducts.find(el => el.id === value?.value);
        let salePrice = product?.salePrice || 0;
        this.selectedItem = [product];
        if (!!this.form) {
            this.form.controls.saleUnitPrice.setValue(salePrice?.toFixed(2));
            this.form.controls.costUnitPrice.setValue(product?.costPriceUnit?.toFixed(2));
            this.form.controls.discount.setValue(product?.discount?.toFixed(2));
            this.form.controls.hsCode.setValue(product?.hsCodes?.taricCode);
            product?.hsCodes?.dutyTaxRate.map((data) => {
                if (data.countryCode === this.country?.codeFull) {
                    this.form.controls.simplyDutyTaxRate.setValue(data?.dutyRate);
                }
            })

            this.form.controls.costUnitPrice.setErrors(null);
        }

    }

    calculateHscodeDutyTaxRate(index) {
      this.items = this.form.get('items') as FormArray;
      const payload = {
          hsCode: this.items.at(index).get('hscode').value,
          originCountryCode: this.country?.codeFull,
          totalProductSKUCost: this.items.at(index).get('total').value ? this.items.at(index).get('total').value : 0
      }
      console.log("calculateHscodeDutyTaxRate-payload", payload, index);
      if (this.items.at(index).get('total').value !== "NaN" && this.items.at(index).get('total').value !== "0.00") {
          this.hsCodesService.calculateHsCodesDutyTaxRate(payload).subscribe((data) => {
              if (data) {
                  this.items.at(index).get('simplyDutyTaxAmount').setValue(data?.hsCodeDutyTaxAmount);
              }
          });
      }
    }

    setUnitPrice(event): void {
      const currentProduct = this.listOfProducts.find(el => el.id === this.form?.controls?.productId?.value);
      this.unitPrice = this.isCreateMode ? (currentProduct?.salePrice || 0).toFixed(2) :
          (this.info.price || this.info?.product?.salePrice || 0).toFixed(2);
      this.form.controls.salePrice.setValue(this.unitPrice);
      this.setDiscountAmount(currentProduct);
    }

    private setDiscountAmount(currentProduct: any): void {
      if (currentProduct?.salePrice && currentProduct?.discount > 0) {
          this.discountAmount = currentProduct?.salePrice / 100 * currentProduct?.discount;
      } else {
          this.discountAmount = 0;
      }
    }

    totalPriceBindData(index) {
      this.items = this.form.get('items') as FormArray;

      if (this.isCreateMode || this.isEditable) {
          const total = (parseFloat(this.items.at(index).get('numOfUnits').value) * parseFloat(this.items.at(index).get('costPrice').value)).toFixed(2);
          this.items.at(index).get('total').setValue(total);
      }
      this.calculateHscodeDutyTaxRate(index);
    }

    public createProduct(): void {
        if (this.isMarketplaceSO) {
            this.ecommerceProductDialog();
        } else {
            this.openAddProductPopup();
        }
    }

    private openAddProductPopup(): void {
        const dialogRef = this.dialog.open(ProductDetailsComponent, {
            maxHeight: '600px',
        });
        dialogRef.componentInstance.allowAdd4Customer = true;
        dialogRef.componentInstance.createMode = true;
        dialogRef.componentInstance.marginTop = '0';
        dialogRef.componentInstance.companyCode = Number(this.companyId);
        dialogRef.componentInstance.isSaleOrder = this.isSaleOrder;
        dialogRef.componentInstance.isShipment = this.isShipment;
        dialogRef.componentInstance.isMarketplaceSO = this.isMarketplaceSO;
        // dialogRef.componentInstance.isMarketplaceSO = true;
        dialogRef.afterClosed().subscribe(result => {
            this.getListOfProducts(1);
        });
    }

    private ecommerceProductDialog(): void {
        const dialogRef = this.dialog.open(OfflineProductComponent,
            {maxHeight: '600px'},
        );
        dialogRef.componentInstance.createMode = true;
        dialogRef.componentInstance.marginTop = '0';
        dialogRef.componentInstance.companyCode = Number(this.companyId);
        dialogRef.componentInstance.shopsId = [this.empactShopId?.toString()]

        dialogRef.afterClosed()
            .subscribe((result) => {
                this.getListOfProducts(1);
            });
    }

    public getEShops(): void {
      this.offlineShopService.getOfflineShop(this.companyId)
        .subscribe((shops) => {
            if (!shops || shops.length === 0) {
                return;
            }
            shops.map((shop) => this.empactShopId = shop.shopId);
        });
    }

    addItemToTable(selectedProducts) {
        const existingProductIds = new Set(this.existingProducts);

        const newSelectedProducts = selectedProducts.filter(productId => !existingProductIds.has(productId));

        for (const productId of newSelectedProducts) {
            const product = this.listOfProducts.find(p => p.id === productId);
            if (product) {
                this.addProductRow(product);
            }
        }

        if (this.existingProducts && this.existingProducts.length > 0) {
            const deselectedProducts = this.existingProducts.filter(productId => !selectedProducts.includes(productId));

            for (const productId of deselectedProducts) {
                this.removeProductRow(productId);
            }
        }

        this.existingProducts = selectedProducts;
    }

    removeProductRow(productId) {
        const indexToRemove = this.items.controls.findIndex(
            (control) => control.get('pId').value === productId
        );

        if (indexToRemove !== -1) {
            this.items.removeAt(indexToRemove);
            this.updateView();
        }
    }

  addProductRow(info) {
    let simplyDutyRate = null;
    this.items = this.form.get('items') as FormArray;
    info?.hsCodes?.dutyTaxRate.map((data) => {
        if (data.countryCode === this.country?.codeFull) {
            simplyDutyRate = data.dutyRate;
        }
    });
    const row = this.formBuilder.group({
        'pId': [{disabled: true, value: info?.id || null}],
        'product': [{disabled: true, value: info?.name || null}],
        'sku': [{disabled: true, value: info?.sku || null}],
        'numOfUnits': [{disabled: false, value: null}, [Validators.required]],
        'currency': [{disabled: true, value: info?.currency || 'EUR'}],
        'salePrice': [{disabled: true, value: info?.salePrice || null}],
        'costPrice': [{disabled: false, value: info?.costPriceUnit || null}],
        'total': [{disabled: true, value: null}],
        'hscode': [{disabled: true, value: info?.hsCodeId || null}],
        'simplyDutyTaxRate': [{disabled: true, value: simplyDutyRate || null}],
        'simplyDutyTaxAmount': [{disabled: true, value: null}]
    });
    this.items.push(row);
    this.updateView();
  }

  updateView() {
    this.dataSource = [...this.items.controls];
  }

  deleteProductItem(index) {
    this.items = this.form.get('items') as FormArray;
    this.items.removeAt(index);
    this.updateView();
  }

}
