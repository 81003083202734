import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ApiService} from '../../core/services/api.service';
import {LocalStorageService} from '../../core/services/local-storage.service';
import {NotificationService} from '../../core/services/notification.service';
import {InvoiceManagerType} from '../../shared/enums/invoice-manager-type.enum';
import {SaleOrderInvoiceType} from '../../shared/enums/sale-order-invoice-type.enum';
import {ISaleOrder} from '../sales-order-management/models/sale-order.interface';

export enum EInvManagerTabType {
    'so',
    'shipment',
    'consolidate_shipment'
}

export interface IGetSaleOrdersParams {
    query: string;
    page: number;
    limit: number;
    field: string;
    direction: string;
    isB2B: number;
    companyId?: string;
    shopId?: string;
    dateFrom?: string;
    dateTo?: string;
    mode?: number;
    type?: number;
    subtype?: number;
}

export interface IAnyPaginate {
    items: any[];
    meta: {
        currentPage: number;
        itemsPerPage: number;
        totalItems: number;
    };
}

export const INVOICE_TYPE_LIST = [
    {id: SaleOrderInvoiceType.IMPORT_INVOICE, name: 'Import Invoice', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.DOMESTIC_INVOICE, name: 'Domestic Invoice', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.EXPORT_INVOICE, name: 'Export Invoice', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.COMMERCIAL_INVOICE, name: 'Commercial Invoice', type: InvoiceManagerType.SaleOrder},
    {id: SaleOrderInvoiceType.VAT, name: 'Vat Invoice', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.PROFORMA_INVOICE, name: 'Proforma Invoice', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.SELL_INVOICE, name: 'Sell Invoice', type: InvoiceManagerType.SaleOrder},
    {id: SaleOrderInvoiceType.IMPORT_INVOICE_AIR, name: 'Service Invoice (IMP)', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.EXPORT_INVOICE_AIR, name: 'Export Invoice Air', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.DOMESTIC_INVOICE_AIR, name: 'Domestic Invoice Air', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.MONTHLY_SUBSCRIPTION_PAYMENT, name: 'Monthly Subscription Payment Invoice', type: InvoiceManagerType.Other},
    {id: SaleOrderInvoiceType.REGISTRATION_FEES, name: 'Registration Fees Invoice', type: InvoiceManagerType.Other},
    {id: SaleOrderInvoiceType.CANCEL_NOTE, name: 'Cancel Note', type: InvoiceManagerType.SaleOrder},
    {id: SaleOrderInvoiceType.FINAL_PURCHASE_INVOICE, name: 'Final Purchase Invoice', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.PACKING_LIST, name: 'Packing List', type: InvoiceManagerType.Shipment},
    {id: SaleOrderInvoiceType.SHIPPING_INVOICE, name: 'Shipping Invoice', type: InvoiceManagerType.ConsolShipment},
    {id: SaleOrderInvoiceType.SERVICE_INVOICE, name: 'Service Invoice', type: InvoiceManagerType.ConsolShipment},
    {
        id: SaleOrderInvoiceType.CONSOL_SHIPMENT_VAT_INVOICE,
        name: 'Consolidate Shipment Vat Invoice',
        type: InvoiceManagerType.ConsolShipment
    },
    {id: SaleOrderInvoiceType.PACKING_LIST, name: 'Packing List', type: InvoiceManagerType.ConsolShipment},
];

export const DATA_SOURCE = {
    itemsBlock: [
        'orderId',
        'date',
        'commissionaireContractNr',
        'companyId',
        'companyName'
    ],
    tables: [
        'fee',
        'discount',
        'totalFee',
        'transactionFee',
        'totalTransactionFee',
        'index',
        'description',
        'units',
        'costPriceUnit',
        'simplyDutyTaxRate',
        'simplyDutyTaxAmount',
        'vatPercent',
        'vatAmount',
        'total',
    ]
};

export enum EElementTypes {
    MainContainer,
    Header,
    CustomerCompany,
    Address,
    ItemsBlock,
    Customer,
    Table,
    Summary,
    PaymentTerms,
    ShipmentInfo,
    SingleItem,
    SingleItemBlock,
    ContainerItem,
    GridItemStatic,
    GridItemDynamic,
    GridItemHeader,
    Space,
    FreeText,
    MainSheet
}

@Injectable({
    providedIn: 'root'
})
export class InvoiceManagementService extends ApiService {

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private notify: NotificationService,
    ) {
        super(http, storageBrowser);
    }

    public getInvoices(
        page: number = 1,
        limit: number = 100,
        searchParams?: any,
    ): Observable<any> {
        let query: any = {
            limit,
            page,
        };
        if (searchParams) {
            query = {...query, ...searchParams};
        }
        return this.request({
            method: 'GET',
            path: `invoices/list`,
            query
        });
    }

    public getTemplate(templateId: string, historyId?: string): void {
        this.requestPdf({
            method: 'GET',
            path: `invoices/template/${templateId}`,
            query: historyId ? {historyId} : null
        }).subscribe(res => {
            let fileURL = URL.createObjectURL(res);
            window.open(`${fileURL}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    public getTemplateData(templateId: string, historyId?: string): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/template/data/${templateId}`,
            query: historyId ? {historyId} : null
        });
    }

    public setTemplateData(templateId: string, data: any, historyId?: string): Observable<any> {
        return this.request({
            method: 'POST',
            path: `invoices/template/data/${templateId}`,
            body: data,
            query: historyId ? {historyId} : null
        });
    }

    public reIssueInvoice(id, type): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/re-issue/${id}`,
            query: {
                type
            }
        });
    }

    public invoiceUpload(file: File, id: any, type: any): Observable<any> {
        return new Observable<any>(obs => {
            const max_size = 20971520;
            if (file.size > max_size) {
                obs.error('Maximum size allowed is ' + max_size / 1000 + 'Mb');
                return;
            }

            const token = this.storageBrowser.get('token');
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${token}`,
            });

            const formData: FormData = new FormData();
            formData.append('invoice', file, file.name);

            let params = new HttpParams();
            params = params.append('type', type);
            params = params.append('id', id);

            this.http.post(
                `${environment.api_url}invoices/upload`,
                formData,
                {headers, params}
            ).subscribe(res => obs.next(res), error => obs.error(error));
        });
    }

    public getTemplateHistory(templateId: string) {
        return this.request({
            method: 'GET',
            path: `invoices/template/history/${templateId}`
        });
    }

    public setTemplateHistoryActive(templateId: string, historyId: string) {
        return this.request({
            method: 'PUT',
            path: `invoices/template/history/${templateId}`,
            body: {historyId}
        });
    }

    public getOrderInvoiceData(orderId: number, invoiceId: number): Observable<ISaleOrder> {
        return this.request({
            path: `invoices/order-invoice`,
            method: 'POST',
            body: {orderId, invoiceId}
        });
    }

    public getConShipmentInvoiceData(invoiceId: number): Observable<ISaleOrder> {
        return this.request({
            path: `invoices/con-shipment-invoice`,
            method: 'POST',
            body: {invoiceId}
        });
    }

    public saveShipment(body: any) {
        return this.request({
            path: `invoices/update-shipment`,
            method: 'POST',
            body
        });
    }

    public saveConShipment(body: any) {
        return this.request({
            path: `invoices/update-con-shipment`,
            method: 'POST',
            body
        });
    }

    public reIssueAllInvoice(itemIds: string[], tabType: number, invoiceType?: string) {
        return this.request({
            method: 'POST',
            path: `invoices/re-issue-all`,
            body: {
                ids: itemIds,
                type: tabType,
                invoiceType
            }
        });
    }

    public createNewSpecificTemplate(data: { templateId: string; name: string; }): Observable<any> {
        return this.request({
            method: 'POST',
            path: `invoices/template/specific`,
            body: data,
        });
    }

    public getAllNewSpecificTemplate(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/template/specific`,
        });
    }

    public getAllNewSpecificTemplateForGeneration(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/template/specific-gen`,
        });
    }

    public getOneNewSpecificTemplate(id): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/template/specific/${id}`,
        });
    }

    public saveOneNewSpecificTemplate(id, data): Observable<any> {
        return this.request({
            method: 'POST',
            path: `invoices/template/specific/${id}`,
            body: data
        });
    }

    public getSpecificTemplate(id: string): void {
        this.requestPdf({
            method: 'GET',
            path: `invoices/template/specific/pdf/${id}`,
        }).subscribe(res => {
            let fileURL = URL.createObjectURL(res);
            window.open(`${fileURL}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    public previewInvoice(data): void {
        this.requestPdf({
            method: 'POST',
            path: `invoices/template/specific/pdf-preview`,
            body: {
                template: data
            }
        }).subscribe(res => {
            let fileURL = URL.createObjectURL(res);
            window.open(`${fileURL}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    public deleteSpecificTemplate(id) {
        return this.request({
            method: 'DELETE',
            path: `invoices/template/specific/${id}`
        });
    }

    public deleteSpecificGenerationTemplate(id) {
        return this.request({
            method: 'DELETE',
            path: `invoices/template/specific-gen/${id}`
        });
    }

    public getSaleOrders(params: Partial<IGetSaleOrdersParams>): Observable<IAnyPaginate> {
        const paramsUpdated: IGetSaleOrdersParams = {
            query: params.query ? params.query : '',
            page: params.page ? params.page : 1,
            limit: params.limit ? params.limit : 50,
            field: params.field ? params.field : '',
            direction: params.direction ? params.direction : 'DESC',
            isB2B: params.isB2B != undefined ? params.isB2B : 0,
        };

        if (params.companyId) {
            paramsUpdated.companyId = params.companyId;
        }

        if (params.shopId) {
            paramsUpdated.shopId = params.shopId;
        }

        if (params.dateFrom && params.dateTo) {
            paramsUpdated.dateFrom = params.dateFrom;
            paramsUpdated.dateTo = params.dateTo;
        }
        return this.request({
            method: 'GET',
            path: `sale-orders/list-for-editor`,
            query: paramsUpdated
        });
    }

    public assignTemplates(orderIds: any[], templateIds: number[], type: number) {

        return this.request({
            method: 'POST',
            path: `invoices/template/assign`,
            body: {
                ids: orderIds,
                templateIds,
                type
            }
        });
    }

    public getShipments(params: Partial<IGetSaleOrdersParams>): Observable<IAnyPaginate> {
        const paramsUpdated: IGetSaleOrdersParams = {
            query: params.query ? params.query : '',
            page: params.page ? params.page : 1,
            limit: params.limit ? params.limit : 50,
            field: params.field ? params.field : '',
            direction: params.direction ? params.direction : 'DESC',
            isB2B: params.isB2B != undefined ? params.isB2B : 0,
        };

        if (params.companyId) {
            paramsUpdated.companyId = params.companyId;
        }

        if (params.type) {
            paramsUpdated.type = params.type;
        }

        if (params.subtype) {
            paramsUpdated.subtype = params.subtype;
        }

        if (params.mode) {
            paramsUpdated.mode = params.mode;
        }

        if (params.dateFrom && params.dateTo) {
            paramsUpdated.dateFrom = params.dateFrom;
            paramsUpdated.dateTo = params.dateTo;
        }
        return this.request({
            method: 'GET',
            path: `orders/list-for-editor`,
            query: paramsUpdated
        });
    }

    public getConShipments(params: Partial<IGetSaleOrdersParams>): Observable<IAnyPaginate> {
        const paramsUpdated: IGetSaleOrdersParams = {
            query: params.query ? params.query : '',
            page: params.page ? params.page : 1,
            limit: params.limit ? params.limit : 50,
            field: params.field ? params.field : '',
            direction: params.direction ? params.direction : 'DESC',
            isB2B: params.isB2B != undefined ? params.isB2B : 0,
        };

        if (params.companyId) {
            paramsUpdated.companyId = params.companyId;
        }

        if (params.type) {
            paramsUpdated.type = params.type;
        }

        if (params.mode) {
            paramsUpdated.mode = params.mode;
        }

        if (params.dateFrom && params.dateTo) {
            paramsUpdated.dateFrom = params.dateFrom;
            paramsUpdated.dateTo = params.dateTo;
        }
        return this.request({
            method: 'GET',
            path: `shipment-consol/list-for-editor`,
            query: paramsUpdated
        });
    }

    public getMaps(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `invoices/template/maps`,
        });
    }

    public generateDefaultValues(model, template): any {
        const generateValues = (model) => {
            switch ((model.type as EElementTypes)) {
                case EElementTypes.MainSheet:
                    model = {
                        model: {
                            zoom:1
                        },
                        ...model
                    }
                    break;
                case EElementTypes.Header:

                    break;
                case EElementTypes.CustomerCompany:
                    model = {
                        ...model,
                        defaultValues: template.customerCompany
                    };
                    break;
                case EElementTypes.MainContainer:

                    break;
                case EElementTypes.Address:

                    break;
                case EElementTypes.ItemsBlock:

                    break;
                case EElementTypes.SingleItem:

                    break;
                case EElementTypes.SingleItemBlock:

                    break;
                case EElementTypes.PaymentTerms:

                    break;
                case EElementTypes.Customer:

                    break;
            }
            return model;
        };
        if (model && Array.isArray(model)) {
            if (model.length === 0) {
                model.push(
                    {
                        name: 'Main sheet',
                        type: EElementTypes.MainSheet,
                        selected: false,
                    },
                )
            }
            model = model.map((val, index) => {
                return generateValues(val);
            });
        }
        console.log(model);
        return model;
    }
}
