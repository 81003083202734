import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BatchService } from 'src/app/modules/batch/service/batch.service';

@Component({
  selector: 'eci-add-to-existing-batch',
  templateUrl: './add-to-existing-batch.component.html',
  styleUrls: ['./add-to-existing-batch.component.scss']
})
export class AddToExistingBatchComponent implements OnInit {

  batchList:[];
  searchBatch:FormControl = new FormControl('');
  batch:FormControl = new FormControl('');

  constructor(
    private dialogRef: MatDialogRef<AddToExistingBatchComponent>,
    private batchService: BatchService,
  ) { }

  ngOnInit(): void {
    this.getListOfBatch();
  }

  onClickClose():void{
    this.dialogRef.close();
  }

  getListOfBatch():void{
    // this.batchService.getBatchIdList().subscribe(result =>{
    //   this.batchList = result;
    //   console.log(this.batchList,"this is list of batch id")
    // })
  }
}
