import {Component, OnChanges, SimpleChanges} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
  selector: 'eci-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends BaseElement implements OnChanges {

    constructor() {
        super();
    }

    ngOnInit(): void {

    }

    onAddTemplatesEvent(event: any) {
        this.onAddTemplates.emit(event);
    }

    onSelectElementEvent(event: any) {
        if (event.selected) {
            this.item.selected = false;
        }
        this.onSelectElement.emit(event);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.addressSource = this.dataSource
        if (changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 14;
        }
    }
}
