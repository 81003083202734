import { Pipe, PipeTransform } from '@angular/core';
import { ICountry } from 'src/app/core/models/country.interface';

@Pipe({ name: 'countryName' })
export class CountryNamePipe implements PipeTransform {
    transform(value: any, countries: ICountry[]) {
        if (!countries) return '';
        return countries.find(el => el.code === value || el.codeFull === value)?.name;
    }
}
