import {Component, HostBinding, Input} from '@angular/core';
import {BaseElement} from '../base-element';

@Component({
    selector: 'eci-space',
    templateUrl: './space.component.html',
    styleUrls: ['./space.component.scss']
})
export class SpaceComponent extends BaseElement {

    @Input() alignParent: string;

    constructor() {
        super();
    }

    @HostBinding('style.width.%')
    width;

    ngOnInit(): void {
        if (!this.item.model) {
            this.item.model = {
                width: this.item.width,
                height: 40
            } as any;
        }
    }

    onChangeWidth(e: any) {
        this.width = e;
        this.item.width = e;
        this.item.model.width = e;
    }
}
