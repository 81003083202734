export enum SaleOrderErrorType {
    'Sale Order Error - Country is not found',
    'Sale Order Error - Country is outside of Europe',
    'Sale Order Error - Account Disabled',
    'Sale Order Error - Company Id should not be blank',
    'Sale Order Error - Company Not Found',
    'Sale Order Error - Marketplace not present in customer marketplace list',
    'Sale Order Error - Marketplace name is not found',
    'Sale Order Error - Product required category',
    'Sale Order Error - Country does not have default tax rate',
    'Sale Order Error - Shipment Country should not be blank',
    'Sale Order Error - VAT number should not be blank when buyer type is Business',
    'Sale Order Error - Shipment Date should not be empty',
    'Sale Order Error - Marketplace VAT charges should not be blank',
    'Sale Order Error - Billing Country should not be blank',
    'Sale Order Error - Buyer type is missing',
    'Sale Order Error - Buyer type must be business for the B2B sale-orders',
    'Sale Order Error - Number of units should be in numerical value only',
    'Sale Order Error - Product was not found',
    'Sale Order Error - Order date should not be blank',
    'Sale Order Error - Shop scope should not be match',
    'Sale Order Error - Shop working mode is B2B but order in not B2B',
    'Sale Order Error - Shop working mode is B2C but order in not B2C',
    'Sale Order Error - Marketplace Shop scope does not match',
    'Sale Order Error - Number of units should not be blank or less than 0',
    'Sale Order Error - Currency should not be blank',
    'Sale Order Error - Marketplace ID should not be blank',
    'Sale Order Error - Grand total in products should not be blank or 0',
    'Sale Order Error - Marketplace connection type mismatch',
    'Sale Order Error - Order date is future date, should be today or past',
    'Sale Order Error - SO already exists for in the system',
    'Sale Order Error - Emapct shop id is missing',
    'Sale Order Error - Shipment country code is missing',
    'Sale Order Error - Duplicate Sale Order'
  }
