import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlaceStatus } from 'src/app/shared/enums/place-status.enum';
import { SaleOrderType } from 'src/app/shared/enums/sale-order-type.enum';
import { ApiService } from '../../../core/services/api.service';

@Injectable()
export class BatchService extends ApiService {
  public chargeCardEvent = new BehaviorSubject<any>(null);

  getBatches(
    page: number = 1,
    limit: number = 100,
    dateFrom?: string,
    dateTo?: string,
    partnerId: string = '',
    batchId: string = '',
    originCountry: string = '',
    destinationCountry: string = ''
  ): Observable<any> {
    return this.request({
      path: `batch`,
      method: 'GET',
      query: {
        page: page,
        limit: limit,
        dateFrom,
        dateTo,
        partnerId,
        batchId,
        originCountry,
        destinationCountry
      }
    });
  }
  //getting list of all batch ids
  getBatchIdList(partnerId): Observable<any> {
    return this.request({
      path: `batch/list/all`,
      method: 'GET',
      query: {
        partnerId
      }
    });
  }

   //getting list of all batch ids
   deleteBatch(batchId): Observable<any> {
    return this.request({
      path: `batch/${batchId}`,
      method: 'DELETE'
    });
  }
  //get batch detail by Id
  getBatchById(id: number): Observable<any> {
    return this.request({
      path: `batch/${id}`,
      method: 'GET',
    })
  }

  getShipments(page: number = 1, limit: number = 100, q: string = '', companyId?: number, searchParams?: any, orderField: string = '', orderDirection: string = '', batchId: string = ''): Observable<any> {
    return this.request({
      method: 'GET',
      path: `shipment-consol`,
      query: {
        q: q,
        limit: limit,
        page: page,
        companyId: companyId ? companyId : '',
        orderTypes: searchParams?.orderTypes,
        companyTypes: searchParams?.companyTypes,
        orderStatuses: searchParams?.orderStatuses,
        handleStatus: searchParams?.handleStatus,
        dateTo: searchParams?.dateTo,
        dateFrom: searchParams?.dateFrom,
        deliveryDateTo: searchParams?.deliveryDateTo,
        deliveryDateFrom: searchParams?.deliveryDateFrom,
        lastMile: '',
        lastMileStatus: '',
        orderField: orderField,
        orderDirection: orderDirection,
        batchId: batchId
      }
    });
  }

  getBatchDetailSO(q: string, id: number, filterSO: string = '', filterReturnSO: string = '',
  filterRegularConsol: string = '', filterReturnConsol: string = '', page: number = 1, limit: number = 100,
  dateTo?: string,
  dateFrom?: string) {
    return this.request({
      method: 'GET',
      path: `batch/list/batch-detail/so/${id}`,
      query: {
        limit: limit,
        page: page,
        q: q,
        filterSO,
        filterReturnSO,
        filterRegularConsol,
        filterReturnConsol,
        dateFrom,
        dateTo
      }
    });
  }

  //export excel
  public downloadBatches(
    dateFrom?: string,
    dateTo?: string,
    partnerId?: any,
    batchId?: any,
  ): Observable<any> {
    return this.requestFile({
      path: `batch/download-batch`,
      method: 'POST',
      query: {
        dateFrom,
        dateTo,
        partnerId: partnerId ? partnerId : '',
        batchId: batchId ? batchId : '',
      }
    }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(res)
      a.href = objectUrl
      a.download = `batch-${new Date().toDateString()}.xlsx`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    }));
  }



  getOrdersB2CTest(
    page: number = 1,
    limit: number = 100,
    q: string = '',
    placeStatus: PlaceStatus = PlaceStatus.Confirmed,
    batchNo: string = ''
  ): Observable<any> {
    return this.request({
      method: 'GET',
      path: `sale-orders/b2c-test`,
      query: {
        q: q,
        limit: limit,
        page: page,
        placeStatus: placeStatus,
        batchNo: batchNo,
        isB2B: SaleOrderType.All.toString(),
      }
    });
  }

  downloadExcelExample(): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Accept', '*')
      .append('responseType', 'application/octet-stream');

    const fileUrl = `/assets/xlsx/batch-template.xlsx`;

    return this.http.get(fileUrl, { headers: headers, responseType: 'blob' });
  }



    //export batch detail excel
    public downloadBatchDetails(
      batchId?: any, q: string = '', filterSO: string = '', 
      filterReturnSO: string = '', filterConsol: string = '',
    ): Observable<any> {
      return this.requestFile({
        path: `batch/download-batch-detail/${batchId}`,
        method: 'POST',
        query: {
          q: q,
          filterSO,
          filterReturnSO,
          filterConsol
        }
      }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(res)
        a.href = objectUrl
        a.download = `batch-detail-${new Date().toDateString()}.xlsx`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }));
    }
  
}
