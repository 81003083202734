import { Pipe, PipeTransform } from "@angular/core";
import { SaleOrderErrorType } from "../enums/sale-order-error-type.enum";

@Pipe({ name: 'saleOrderErrorType' })
export class SaleOrderErrorTypePipe implements PipeTransform {
    transform(value: any) {
        switch (value) {
            case SaleOrderErrorType["Sale Order Error - Country is not found"]: return 'Sale Order Error - Country is not found';
            case SaleOrderErrorType["Sale Order Error - Country is outside of Europe"]: return 'Sale Order Error - Country is outside of Europe';
            case SaleOrderErrorType["Sale Order Error - Account Disabled"]: return 'Sale Order Error - Account Disabled';
            case SaleOrderErrorType["Sale Order Error - Company Id should not be blank"]: return 'Sale Order Error - Company Id should not be blank';
            case SaleOrderErrorType["Sale Order Error - Company Not Found"]: return 'Sale Order Error - Company Not Found';
            case SaleOrderErrorType["Sale Order Error - Marketplace not present in customer marketplace list"]: return 'Sale Order Error - Marketplace not present in customer marketplace list';
            case SaleOrderErrorType["Sale Order Error - Marketplace name is not found"]: return 'Sale Order Error - Marketplace name is not found';
            case SaleOrderErrorType["Sale Order Error - Product required category"]: return 'Sale Order Error - Product required category';
            case SaleOrderErrorType["Sale Order Error - Country does not have default tax rate"]: return 'Sale Order Error - Country does not have default tax rate';
            case SaleOrderErrorType["Sale Order Error - Shipment Country should not be blank"]: return 'Sale Order Error - Shipment Country should not be blank';
            case SaleOrderErrorType["Sale Order Error - VAT number should not be blank when buyer type is Business"]: return 'Sale Order Error - VAT number should not be blank when buyer type is Business';
            case SaleOrderErrorType["Sale Order Error - Shipment Date should not be empty"]: return 'Sale Order Error - Shipment Date should not be empty';
            case SaleOrderErrorType["Sale Order Error - Marketplace VAT charges should not be blank"]: return 'Sale Order Error - Marketplace VAT charges should not be blank';
            case SaleOrderErrorType["Sale Order Error - Billing Country should not be blank"]: return 'Sale Order Error - Billing Country should not be blank';
            case SaleOrderErrorType["Sale Order Error - Buyer type is missing"]: return 'Sale Order Error - Buyer type is missing';
            case SaleOrderErrorType["Sale Order Error - Buyer type must be business for the B2B sale-orders"]: return 'Sale Order Error - Buyer type must be business for the B2B sale-orders';
            case SaleOrderErrorType["Sale Order Error - Number of units should be in numerical value only"]: return 'Sale Order Error - Number of units should be in numerical value only';
            case SaleOrderErrorType["Sale Order Error - Product was not found"]: return 'Sale Order Error - Product was not found';
            case SaleOrderErrorType["Sale Order Error - Order date should not be blank"]: return 'Sale Order Error - Order date should not be blank';
            case SaleOrderErrorType["Sale Order Error - Shop scope should not be match"]: return 'Sale Order Error - Shop scope should not be match';
            case SaleOrderErrorType["Sale Order Error - Shop working mode is B2B but order in not B2B"]: return 'Sale Order Error - Shop working mode is B2B but order in not B2B';
            case SaleOrderErrorType["Sale Order Error - Shop working mode is B2C but order in not B2C"]: return 'Sale Order Error - Shop working mode is B2C but order in not B2C';
            case SaleOrderErrorType["Sale Order Error - Marketplace Shop scope does not match"]: return 'Sale Order Error - Marketplace Shop scope does not match';
            case SaleOrderErrorType["Sale Order Error - Number of units should not be blank or less than 0"]: return 'Sale Order Error - Number of units should not be blank or less than 0';
            case SaleOrderErrorType["Sale Order Error - Currency should not be blank"]: return 'Sale Order Error - Currency should not be blank';
            case SaleOrderErrorType["Sale Order Error - Marketplace ID should not be blank"]: return 'Sale Order Error - Marketplace ID should not be blank';
            case SaleOrderErrorType["Sale Order Error - Grand total in products should not be blank or 0"]: return 'Sale Order Error - Grand total in products should not be blank or 0';
            case SaleOrderErrorType["Sale Order Error - Emapct shop id is missing"]: return 'Sale Order Error - Emapct shop id is missing';
            case SaleOrderErrorType["Sale Order Error - Shipment country code is missing"]: return 'Sale Order Error - Shipment country code is missing';
            case SaleOrderErrorType["Sale Order Error - Marketplace connection type mismatch"]: return 'Sale Order Error - Marketplace connection type mismatch';
            case SaleOrderErrorType["Sale Order Error - Order date is future date, should be today or past"]: return 'Sale Order Error - Order date is future date, should be today or past';
            default: return ''
        }
    }
}
