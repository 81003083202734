import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import * as moment from 'moment';
import {UserService} from '../../../../core/services/user.service';
import {IPayment} from '../../payments-interfaces';
import {EPaymentStatus, EPaymentsType} from '../../payments-type.enum';
import {PaymentsService} from '../../payments.service';

@Component({
    selector: 'eci-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

    paymentId;
    payment: IPayment;
    timezone = moment().format('Z');
    pdfUrl: string;
    types = EPaymentsType;

    constructor(
        private activatedRoute: ActivatedRoute,
        private paymentsService: PaymentsService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            this.paymentId = parseInt(params.id);
            if (this.paymentId) {
                this.paymentsService.getPayment(this.paymentId).subscribe(res => {
                    this.payment = res;
                    if (this.payment.invoice) {
                        this.paymentsService.getInvoicePdf(this.payment.invoice.id).subscribe(res => {
                            this.pdfUrl = res;
                        })
                    }
                })
            }
        });
    }

    get isAdmin(): boolean {
        return this.userService.isAdmin;
    }

    get totalAmount(): string {
        const commission = this.payment?.commissionInfo;
        if (this.payment.company?.users[0]?.packages?.packageName === 'B2C') {
            return this.payment?.commissionInfo?.totalAmountExcludingVatSaleOrder.toFixed(2);
        } else {
            return (commission?.totalAmountExcludingVatSaleOrder + commission?.totalAmountVatSaleOrder).toFixed(2);
        }
    }

    onViewPdf() {
        window.open(this.pdfUrl, '_blank');
    }

    isInvoceDisplayed() {
        return (this.isAdmin && this.payment?.invoice) || (!this.isAdmin && this.payment.status === EPaymentStatus.Paid && this.payment?.invoice);
    }
}
