import {Component, Input, OnInit} from '@angular/core';
import {EElementTypes} from '../../../../invoice-management.service';

@Component({
    selector: 'eci-display-value',
    templateUrl: './display-value.component.html',
    styleUrls: ['./display-value.component.scss']
})
export class DisplayValueComponent implements OnInit {

    @Input() model;
    @Input() fontSize;
    @Input() type = EElementTypes.SingleItem;
    @Input() isInTable: boolean = false;
    types = EElementTypes;

    constructor() {
    }

    ngOnInit(): void {
    }

    isArray(key) {
        return Array.isArray(key);
    }

}
