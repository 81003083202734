export enum EPaymentsType {
    Registration,
    Monthly,
    VAT_COM,
    Other,
    Legacy
    // RegistrationPartner
}

export enum EPaymentStatus {
    Pending,
    Paid,
    Failed,
    OnHold,
    Collection,
    Aborted,
    Refund,
}

export enum EPaymentMode {
    Draft,
    Approved,
}

export enum EPaymentLastAction {
    EntryCreated,
    FirstEmail,
    SecondEmail,
    Disabled
}

export enum EPaymentActions {
    EditInvoice,
    ChargedPayment,
    ChangeStatus,
    ExportToExcel,
    Refund,
    CreatePayment
}

export enum ERemarkPaymentType {
    Charge,
    OnHold,
    Refund
}

export enum EEmailType {
    success,
    failed_1,
    failed_2,
    registration,
    disable
}
