import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import * as _moment from 'moment/moment';

export interface IHistoryClose {
    event: 'active' | 'edit' | 'view';
    data: any;
}

const moment = _moment;

@Component({
    selector: 'eci-invoice-history',
    templateUrl: './invoice-history.component.html',
    styleUrls: ['./invoice-history.component.scss']
})
export class InvoiceHistoryComponent implements OnInit {

    history: any[] = [];
    invoiceName = '';
    displayedColumns = [
        'status',
        'note',
        'created',
        'updated',
        'actions'
    ];

    constructor(
        public dialogRef: MatDialogRef<InvoiceHistoryComponent, IHistoryClose>,
    ) {
    }

    ngOnInit(): void {
    }

    getTemplate(element) {
        return element.note;
    }

    getStatus(element) {
        return element.active;
    }

    onSetActive(element) {
        this.closeDialog('active', element);
    }

    onEdit(element) {
        this.closeDialog('edit', element);
    }

    onView(element) {
        this.closeDialog('view', element);
    }

    private closeDialog(event: 'active' | 'edit' | 'view', data: any) {
        this.dialogRef.close({ event, data });
    }

    getFormatDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }
}
