import { Pipe, PipeTransform } from '@angular/core';
import { ExpirationPolicy } from '../enums/expiration-policy.enum';

@Pipe({ name: 'expirationPolicy' })
export class ExpirationPolicyTypePipe implements PipeTransform {
    transform(value: any) {
        switch (Number(value)) {
            case ExpirationPolicy['5 min']: return '5 min';
            case ExpirationPolicy['1 day']: return '1 day';
            case ExpirationPolicy['3 days']: return '3 days';
            case ExpirationPolicy['7 days']: return '7 days';
            case ExpirationPolicy['14 days']: return '14 days';
            case ExpirationPolicy['30 days']: return '30 days';
            case ExpirationPolicy['60 days']: return '60 days';
            case ExpirationPolicy['120 days']: return '120 days';
            default: return 'forever';
        }
    }
}
