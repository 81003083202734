import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SOType } from 'src/app/shared/enums/so-type.enum';
import { ShipmentSubType } from '../../enums/shipment-subtype.enum';
import { StringIsNumber } from 'src/app/core/helpers/helpers.function';

@Component({
    selector: 'shipment-subtype-select-popup',
    templateUrl: './shipment-subtype-select-popup.component.html',
    styleUrls: ['./shipment-subtype-select-popup.component.scss']
})
export class ShipmentSubtypeSelectPopupComponent implements OnInit {

    listOfAvailableShipmentTypes: any[] = [];

    selectedType: ShipmentSubType = undefined;
    isOnlyReturn: boolean = false;

    constructor(
        private dialog: MatDialogRef<ShipmentSubtypeSelectPopupComponent>,
        private notify: NotificationService,
    ) {
    }

    ngOnInit(): void {
        if (this.isOnlyReturn) {
            this.listOfAvailableShipmentTypes = this.shipmentSubType.filter(el => el.id === ShipmentSubType.Regular);
        } else {
            this.listOfAvailableShipmentTypes = this.shipmentSubType;
        }
        if (this.listOfAvailableShipmentTypes.length === 1) {
            this.selectedType = this.listOfAvailableShipmentTypes[0].id;
            this.proceed();
        }
    }

    proceed(): void {
        if (this.selectedType === undefined) {
            this.notify.showError('Select Shipment Type');
            return;
        }

        this.dialog.close(this.selectedType);
    }

    get shipmentSubType(): any[] {
        return Object.keys(ShipmentSubType)
            .filter(StringIsNumber)
            .map(key => {
                return {id: Number(key), name: ShipmentSubType[key]};
            });
    }
}
