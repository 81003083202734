import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyMarketplaceService } from 'src/app/core/services/company-marketplaces.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IWarehouseInfo } from 'src/app/modules/customer-management/models/warehouse';

@Component({
  selector: 'eci-add-warehouse-shop-popup',
  templateUrl: './add-warehouse-shop-popup.component.html',
  styleUrls: ['./add-warehouse-shop-popup.component.scss']
})
export class AddWarehouseShopPopupComponent implements OnInit {
  @Input() info: IWarehouseInfo;
  marketplaceList: any[];
  form: FormGroup;
  searchmarketplaceName: FormControl = new FormControl('');
  showLoader = false;
  constructor(
    private companyMarketplaceSvc: CompanyMarketplaceService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddWarehouseShopPopupComponent>,
    private notify: NotificationService
  ) { }

  ngOnInit(): void {
    this.form = this.initializeForm(this.info);
    this.loadShops();
  }

  initializeForm(data: any): FormGroup {

    // this.datePipe.transform(info?.orderDate.toString(),'dd/MM/YYYY')
    return this.formBuilder.group({
      companyMarketplacesId: ['', [Validators.required]],
      warehouseId: [data.id, [Validators.required]],
      companyId: [data.companyId, [Validators.required]],
    });
  }

  loadShops() {
    this.showLoader = true;
    this.companyMarketplaceSvc.getListOfWHSMarketplaces(1, 100, this.info.companyId).subscribe(data => {
      this.marketplaceList = data;
      this.showLoader = false;
      console.log(this.marketplaceList);     
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.form.value.company_marketplaces_id = Number(this.form.value.company_marketplaces_id);
    this.form.value.warehouse_id = Number(this.form.value.warehouse_id);
    this.companyMarketplaceSvc.addWarehouseShop(this.form.value).subscribe((result: any)=>{
      if(result?.error){
        this.notify.showError("This shop already exist in the warehouses.");
      }else{
        this.notify.showSuccessMessage("Shop added for this warehouse successfully.");
        this.dialogRef.close();
      }     
    });
  }
}
