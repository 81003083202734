import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPaymentSummary} from '../../payments-interfaces';
import {EPaymentStatus} from '../../payments-type.enum';

@Component({
    selector: 'eci-history-payments-summary-table',
    templateUrl: './history-payments-summary-table.component.html',
    styleUrls: ['./history-payments-summary-table.component.scss']
})
export class HistoryPaymentsSummaryTableComponent implements OnInit {

    @Input() data: IPaymentSummary;
    @Output() onSelectRow = new EventEmitter();
    statuses = EPaymentStatus;

    constructor() {
    }

    ngOnInit(): void {
    }

    onClick(type: EPaymentStatus[] | string) {
        this.onSelectRow.emit(type);
    }

    getQty(type: EPaymentStatus | string) {
        if (this.data) {
            return this.data[type].qty;
        } else return '';
    }

    getAmount(type: EPaymentStatus | string) {
        if (this.data) {
            return this.data[type].amount.toFixed(2);
        } else return '';
    }
}
