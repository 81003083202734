import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PlaceStatus } from 'src/app/shared/enums/place-status.enum';
import { SaleOrderType } from 'src/app/shared/enums/sale-order-type.enum';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IPaginatorProperties } from '../../../core/models/paginator.interface';
import { IShopBase, IShopFilter } from '../../../core/models/shop.interface';
import { ISaleOrder } from '../models/sale-order.interface';

@Injectable()
export class SaleOrderService extends ApiService {

    public fileLoadedObserver = new Subject<any>();
    public unselectCheckbox = new BehaviorSubject<any>(null);

    constructor(
        public http: HttpClient,
        public storageBrowser: LocalStorageService,
        private notify: NotificationService
    ) {
        super(http, storageBrowser);
    }

    getOrders(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: any,
        amountType?: string,
        customerCountry?: string,
        shopId?: string,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        forPayouts: boolean = false,
        isB2B: string = SaleOrderType.All.toString(),
        isIndVat?: boolean,
        placeStatus: PlaceStatus = PlaceStatus.Confirmed,
        newFilters: {
            originCountry?: string;
            destinationCountry?: string;
            dateToEmpact?: string;
            dateFromEmpact?: string;
            deliveryDateTo?: string;
            deliveryDateFrom?: string;
        } = {}
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders`,
            query: {
                ...newFilters,
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                marketplaces: searchParams?.marketplaces,
                //customerTypes: searchParams?.companyTypes,
                ShopId: shopId,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                amountType: amountType,
                customerCountry: customerCountry,
                orderField: orderField,
                orderDirection: orderDirection,
                statuses: searchParams?.orderStatus,
                forPayouts: forPayouts ? forPayouts : false,
                isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
                isIndVat: isIndVat ? isIndVat : false,
                placeStatus: placeStatus,
            }
        });
    }

    getOrdersB2CTest(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: any,
        batchId?:any,
        partnerId?: any,
        fileId?: any,
        amountType?: string,
        customerCountry?: string,
        shopId?: string,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        forPayouts: boolean = false,
        isB2B: string = SaleOrderType.All.toString(),
        isIndVat?: boolean,
        placeStatus?: PlaceStatus,
        newFilters: {
            originCountry?: string;
            destinationCountry?: string;
            batchCountryOrigin?: string;
            batchCountryDestination?: string;
            dateToEmpact?: string;
            dateFromEmpact?: string;
            deliveryDateTo?: string;
            deliveryDateFrom?: string;
        } = {}
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/b2c-test`,
            query: {
                ...newFilters,
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                batchId: batchId ? batchId: '',
                batchPartnerId: partnerId ? partnerId: '',
                fileId: fileId ? fileId: '',
                marketplaces: searchParams?.marketplaces,
                customerTypes: searchParams?.companyTypes,
                ShopId: shopId,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                amountType: amountType,
                customerCountry: customerCountry,
                orderField: orderField,
                orderDirection: orderDirection,
                statuses: searchParams?.orderStatus,
                forPayouts: forPayouts ? forPayouts : false,
                isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
                isIndVat: isIndVat ? isIndVat : false,
                placeStatus: placeStatus,
            }
        });
    }

    getMarketplaceOrders(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: any,
        amountType?: string,
        customerCountry?: string,
        shopId?: string,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        forPayouts: boolean = false,
        isB2B: string = SaleOrderType.All.toString(),
        isIndVat?: boolean,
        placeStatus: PlaceStatus = PlaceStatus.Confirmed,
        newFilters: {
            originCountry?: string;
            destinationCountry?: string;
            dateToEmpact?: string;
            dateFromEmpact?: string;
        } = {}
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/ecommerce-sale-orders`,
            query: {
                ...newFilters,
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                marketplaces: searchParams?.marketplaces,
                customerTypes: searchParams?.companyTypes,
                ShopId: shopId,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                amountType: amountType,
                customerCountry: customerCountry,
                orderField: orderField,
                orderDirection: orderDirection,
                statuses: searchParams?.orderStatus,
                forPayouts: forPayouts ? forPayouts : false,
                isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
                isIndVat: isIndVat ? isIndVat : null,
                placeStatus: placeStatus,
            }
        });
    }

    getSalesOrderUploadHistory(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sales-orders-upload-history/upload-history`,
            query: {
                q: q,
                limit: limit,
                page: page,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                orderField: orderField,
                orderDirection: orderDirection,
            }
        });
    }

    // B2B SO Total
    getOrdersTotalB2B(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: number,
        amountType?: string,
        customerCountry?: string,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        includingCancelled: boolean = false,
        forPayouts: boolean = false,
        isB2B: boolean = true,
        isIndVat?: boolean,
        placeStatus: PlaceStatus = PlaceStatus.Confirmed,
        newFilters: {
            originCountry?: string;
            destinationCountry?: string;
            dateToEmpact?: string;
            dateFromEmpact?: string;
        } = {},
        isEmpact: boolean = false,
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/filterB2BSOTotal`,
            query: {
                ...newFilters,
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                marketplaces: searchParams?.marketplaces,
                customerTypes: searchParams?.companyTypes,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                amountType: amountType,
                customerCountry: customerCountry,
                orderField: orderField,
                orderDirection: orderDirection,
                statuses: searchParams?.orderStatus,
                includingCancelled: includingCancelled ? includingCancelled : false,
                forPayouts: forPayouts ? forPayouts : false,
                isB2B: true,
                isIndVat: isIndVat ? isIndVat : false,
                placeStatus: placeStatus,
                isEmpact,
            }
        });
    }

    getOrdersTotal(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: number,
        amountType?: string,
        customerCountry?: string,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        includingCancelled: boolean = false,
        forPayouts: boolean = false,
        isB2B: string = SaleOrderType.All.toString(),
        isIndVat?: boolean,
        placeStatus: PlaceStatus = PlaceStatus.Confirmed,
        newFilters: {
            originCountry?: string;
            destinationCountry?: string;
            dateToEmpact?: string;
            dateFromEmpact?: string;
            ShopId?: any;
        } = {}
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/total`,
            query: {
                ...newFilters,
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                marketplaces: searchParams?.marketplaces,
                customerTypes: searchParams?.companyTypes,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                amountType: amountType,
                customerCountry: customerCountry,
                orderField: orderField,
                orderDirection: orderDirection,
                statuses: searchParams?.orderStatus,
                includingCancelled: includingCancelled ? includingCancelled : false,
                forPayouts: forPayouts ? forPayouts : false,
                isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
                isIndVat: isIndVat ? isIndVat : false,
                placeStatus: placeStatus,
            }
        });
    }

    // B2C SO total
    getB2COrdersTotal(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: number,
        amountType?: string,
        customerCountry?: string,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        // includingCancelled: boolean = false,
        forPayouts: boolean = false,
        isB2B: boolean = false,
        isIndVat: boolean = false,
    ): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/totalB2C`,
            query: {
                q: q,
                limit: limit,
                page: page,
                companyId: companyId ? companyId : '',
                marketplaces: searchParams?.marketplaces,
                customerTypes: searchParams?.companyTypes,
                dateTo: searchParams?.dateTo,
                dateFrom: searchParams?.dateFrom,
                amountType: amountType,
                customerCountry: customerCountry,
                orderField: orderField,
                orderDirection: orderDirection,
                statuses: searchParams?.orderStatus,
                forPayouts: forPayouts ? forPayouts : false,
                isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
                isIndVat: isIndVat ? isIndVat : false,
            }
        });
    }

    // B2C SO total
    // getB2COrdersTotal(
    //     page: number = 1,
    //     limit: number = 100,
    //     q: string = '',
    //     companyId?: number,
    //     amountType?: string,
    //     customerCountry?: string,
    //     searchParams?: any,
    //     orderField: string = '',
    //     orderDirection: string = '',
    //     // includingCancelled: boolean = false,
    //     forPayouts: boolean = false,
    //     isB2B: boolean = false,
    //     isIndVat: boolean = false,
    // ): Observable<any> {
    //     return this.request({
    //         method: 'GET',
    //         path: `sale-orders/totalB2C`,
    //         query: {
    //             q: q,
    //             limit: limit,
    //             page: page,
    //             companyId: companyId ? companyId : '',
    //             marketplaces: searchParams?.marketplaces,
    //             customerTypes: searchParams?.companyTypes,
    //             dateTo: searchParams?.dateTo,
    //             dateFrom: searchParams?.dateFrom,
    //             amountType: amountType,
    //             customerCountry: customerCountry,
    //             orderField: orderField,
    //             orderDirection: orderDirection,
    //             statuses: searchParams?.orderStatus,
    //             forPayouts: forPayouts ? forPayouts : false,
    //             isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
    //             isIndVat: isIndVat ? isIndVat : null,
    //         }
    //     });
    // }

    getfileName():Observable<any>{
        return this.request({
            path:'xlsx-sale-order-files/list/files',
            method: 'GET'
        });
    }

    getOrderById(id: number): Observable<ISaleOrder> {
        return this.request({
            path: `sale-orders/${id}/details`,
            method: 'GET',
        });
    }

    getOrderByMarketPlaceId(id: string): Observable<ISaleOrder> {
        return this.request({
            path: `sale-orders/market-place/${id}`,
            method: 'GET',
        });
    }


    getSaleOrderByCompnayId(companyId: number): Observable<any> {
        return this.request({
            path: `sale-orders/get-sale-orders-by-company-id/${companyId}`,
            method: 'GET',
        });
    }

    getPartnerSOByCompnayId(companyId: number, invoiceId: any): Observable<any> {
        return this.request({
            path: `partner/get-partner-so-by-company-id/get/${companyId}/${invoiceId}`,
            method: 'GET',
        });
    }

    createOrder(body): Observable<any> {
        return this.request({
            path: `sale-orders`,
            method: 'POST',
            body: body
        });
    }

    updateDraftOrder(body): Observable<any> {
        return this.request({
            path: `sale-orders/draft-to-actual`,
            method: 'POST',
            body: body
        });
    }

    createDraftOrder(body): Observable<any> {
        return this.request({
            path: `sale-orders/draft-sale-orders`,
            method: 'POST',
            body: body
        });
    }

    changeTrackingNumber(body): Observable<any> {
        return this.request({
            path: `sale-orders/change-tracking-number`,
            method: 'POST',
            body: body
        });
    }

    createMultipleOrder(body): Observable<any> {
        return this.request({
            path: `sale-orders/create-for-all`,
            method: 'POST',
            body: body
        });
    }

    applyChanges(body): Observable<any> {
        return this.request({
            path: `sale-orders/apply-changes`,
            method: 'POST',
            body: body
        });
    }

    /**
     * get shops for dropdown infinity scroll
     * @old getAllShops
     */
    public getShops(filter: IShopFilter): Observable<IPaginatorProperties<IShopBase>> {
        return this.request({
            path: `company-marketplaces/shops/get-shops`,
            method: 'GET',
            query: filter,
        });
    }

    /**
     * bed solution select all shops by one call
     * @Deprecated
     * @new getShops
     */
    getAllShops(q): Observable<any> {
        return this.request({
            path: `company-marketplaces/shops/get-all`,
            method: 'GET',
            query: {
                q: q
            }
        });
    }

    reorder(id, body): Observable<any> {
        return this.request({
            path: `sale-orders/reorder/${id}`,
            method: 'PUT',
            body
        });
    }
    // test
    deleteMultipleOrder(body): Observable<any> {
        return this.request({
            path: `sale-orders/duplicates`,
            method: 'DELETE',
            body: body
        });
    }

    regenrateOrdersInvoice(ids: number[]): Observable<any> {
        return this.request({
            path: `sale-orders/regenerate-sale-orders-invoice`,
            method: 'POST',
            body: ids
        });
    }
    deleteOrders(ids: number[]): Observable<any> {
        return this.request({
            path: `sale-orders`,
            method: 'DELETE',
            body: ids
        });
    }

    updateOrder(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `sale-orders/${id}`,
            body: body
        });
    }

    // TO DO TEMPORARY SOLUTION FOR PROD NEED TO REMOVE
    updateOrderStripeAmount(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `sale-orders/${id}/stripe-amount`,
        });
    }

    updateOrderValue(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `sale-orders/${id}/sale-order`,
            body: body
        });
    }

    resyncOrderValue(id: number, body): Observable<any> {
        return this.request({
            method: 'PUT',
            path: `sale-orders/${id}/sale-order/resync`,
            body: body
        });
    }

    public downloadInvoice(orderId): any {
        this.request({
            path: `sale-orders/download`,
            method: 'POST',
            body: {
                invoiceId: orderId,
                type: 'PDF'
            }
        }).subscribe(res => {
            // const fileURL = URL.createObjectURL(res);
            window.open(`${res.file}`, '_blank');
        }, error => {
            this.notify.showError('Invoice is not available due to some error');
        });
    }

    getAvailableOrderStates(orderId: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/${orderId}/states`,
        });
    }

    getSaleOrderCheckpoints(orderId: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/aftership-checkpoints/${orderId}`,
        });
    }

    getSaleOrderErrors(page: number, limit: number, q: string = ''): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/sale-order-errors/error`,
            query: {
                q: q,
                limit: limit,
                page: page,
            }
        });
    }

    getSaleOrderInvoiceData(id: number, invoiceId: number): Observable<ISaleOrder> {
        return this.request({
            path: `sale-orders/${id}/sale-order-invoice`,
            method: 'POST',
            body: {
                invoiceId: invoiceId
            }
        });
    }

    updateSaleOrderInvoiceData(body: any): Observable<ISaleOrder> {
        return this.request({
            path: `sale-orders/update-sale-order-invoice`,
            method: 'POST',
            body
        });
    }

    updateInvoiceDataWithoutDatabaseChanges(body: any): Observable<ISaleOrder> {
        return this.request({
            path: `sale-orders/update-sale-order-invoice-without-database-changes`,
            method: 'POST',
            body
        });
    }

    //#endregion

    public downloadFile(orderId, id): any {
        this.request({
            path: `sale-orders/${orderId}/documents/${id}`,
            method: 'GET',
        }).subscribe(res => {
            window.open(`${res.file}`, '_blank');
        });
    }

    public deleteOrderDoc(orderId, id): Observable<any> {
        return this.request({
            path: `sale-orders/${orderId}/documents/${id}`,
            method: 'DELETE',
        })
    }

    uploadFile(files: any, orderId, docId, fieldName = 'content') {
        if (files.length > 0) {
            const file: File = files[0];
            const formData: FormData = new FormData();
            formData.append(fieldName, file, file.name);
            const token = this.storageBrowser.get('token');
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', `${environment.api_url}sale-orders/${orderId}/documents/${docId}`, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        // Use an observer to notify competion
                        this.fileLoadedObserver.next(xhr.responseText);

                    } else {
                        this.fileLoadedObserver.error(xhr.statusText);
                        console.error(xhr.statusText);
                    }
                }
            };
            xhr.onerror = () => {
                this.notify.showError('Something went Wrong!');
                console.error(xhr.statusText);
            };
            xhr.send(formData);
            this.notify.showSuccessMessage('Document Uploaded');
        }
    }

    public updateOrderDoc(orderId, id, body): Observable<any> {
        return this.request({
            path: `sale-orders/${orderId}/documents/${id}`,
            method: 'PUT',
            body: body
        })
    }

    public confirmOrder(orderIds: number[]): Observable<any> {
        return this.request({
            path: `sale-orders/confirm`,
            method: 'POST',
            body: {
                ids: orderIds,
            }
        }).pipe(
            timeout(300000),
            catchError((error) => {
              if (error.name === 'TimeoutError') {
                console.log('The request timed out');
              } else {
                console.log('Error making API request:', error.message);
              }
              return throwError(error);
            })
          );
          // just testing with timeout , need another better solution
    }

    getOrderDocs(orderId): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/${orderId}/documents`,
        });
    }

    public downloadSaleOrders(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        companyId?: number,
        searchParams?: any,
        orderField: string = '',
        orderDirection: string = '',
        forPayouts: boolean = false,
        isB2B: string = SaleOrderType.All.toString(),
        settingsType: string = null,
        placeStatus: PlaceStatus = PlaceStatus.Confirmed,
        isIndVat: boolean = null,
        newFilters: {
            originCountry?: string;
            destinationCountry?: string;
            dateToEmpact?: string;
            dateFromEmpact?: string;
            amountType?: any;
            ShopId?: any;
            customerCountry?: any;
            deliveryDateTo?: any;
            deliveryDateFrom?: any;
        } = {},
        isEmpact: boolean = false,
    ): Observable<any> {
        const query = {
            ...newFilters,
            q: q,
            limit: limit,
            page: page,
            companyId: companyId ? companyId : '',
            marketplaces: searchParams?.marketplaces,
            customerTypes: searchParams?.companyTypes,
            dateTo: searchParams?.dateTo,
            dateFrom: searchParams?.dateFrom,
            orderField: orderField,
            orderDirection: orderDirection,
            statuses: searchParams?.orderStatus,
            forPayouts: forPayouts ? forPayouts : false,
            isB2B: isB2B ? isB2B : SaleOrderType.All.toString(),
            placeStatus: placeStatus,
            isIndVat,
            isEmpact: isEmpact || "",
        };
        if (settingsType) {
            query['settingsType'] = settingsType;
        }
        return this.requestFile({
            path: `sale-orders/download-sale-orders`,
            method: 'POST',
            query: query
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `Sale-Orders${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    recalculateTaxReport(soIds: any[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `sale-orders/recalculate-tex-rep`,
            body: soIds
        });
    }

    syncWithAfterShip(soIds: any[]): Observable<any> {
        return this.request({
            method: 'POST',
            path: `sale-orders/sync-aftership`,
            body: soIds
        });
    }

    getMultipleSOByIds(soIds: any): Observable<any> {
        return this.request({
            method: 'POST',
            path: `sale-orders/get-mulitple-so-byids`,
            body: {
                ids: soIds
            }
        });
    }

    countNewSO(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/new-marketplace-so/count`,
        });
    }

    getVatRateByproductCategoryId(categoryId: Number, countryCode: String): Observable<ISaleOrder> {
        return this.request({
            path: `sale-orders/getVatRate`,
            method: 'POST',
            body: {
                categoryId,
                countryCode
            }
        });
    }
    downloadCheckPoints(id: number): any {
        this.requestFile({
            path: `sale-orders/download-check-points/${id}`,
            method: 'GET',
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').subscribe(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = 'saleOrderCheckPoints.xlsx';
            a.click();
            URL.revokeObjectURL(objectUrl);
        });
    }

    getSaleOrderConsols(orderId): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/${orderId}/consols`,
        });
    }

    getOutboundLogs(id: number): Observable<any> {
        return this.request({
            method: 'GET',
            path: `sale-orders/${+id}/outbound-logs`,
        });
    }

    createMovementByAuditLog(id: number): Observable<any> {
        return this.request({
            method: 'POST',
            path: `sale-orders/create-movement-by-logs`,
            body: {
                id
            }
        });
    }
}
