import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import {IPaymentRemark} from '../../../payments-interfaces';
import {PaymentsService} from '../../../payments.service';

@Component({
    selector: 'eci-remarks',
    templateUrl: './remarks.component.html',
    styleUrls: ['./remarks.component.scss']
})
export class RemarksComponent implements OnInit {

    @ViewChild(MatSort) sort: MatSort;
    remarks: IPaymentRemark[] = [];
    dataSource = new MatTableDataSource([]);
    displayedColumns = [
        'text',
        'createdAt',
        'type',
        'createdBy'
    ];

    constructor(
        public dialogRef: MatDialogRef<RemarksComponent>,
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
        this.dataSource.data = this.remarks;
    }


    sortData(e: Sort) {

    }

    onClose() {
        this.dialogRef.close();
    }
}
