import {Component, Input, OnInit} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialogRef} from '@angular/material/dialog';
import {InvoiceManagerType} from '../../../../shared/enums/invoice-manager-type.enum';
import {SaleOrderInvoiceType} from '../../../../shared/enums/sale-order-invoice-type.enum';
import {INVOICE_TYPE_LIST, InvoiceManagementService} from '../../invoice-management.service';

@Component({
    selector: 'eci-specific-invoice-list-dialog',
    templateUrl: './specific-invoice-list-dialog.component.html',
    styleUrls: ['./specific-invoice-list-dialog.component.scss']
})
export class SpecificInvoiceListDialogComponent implements OnInit {

    ordersIds = [];
    templates: any[] = [];
    displayedColumns = [
        'checkboxes',
        'name',
        'type',
    ];
    selectedItems: any[] = [];

    constructor(
        private invoiceManagementService: InvoiceManagementService,
        public dialogRef: MatDialogRef<SpecificInvoiceListDialogComponent>,
    ) {
    }

    get isAllSelected(): boolean {
        return this.selectedItems?.length > 0 && this.selectedItems?.length === this.templates?.length;
    }

    ngOnInit(): void {
        this.invoiceManagementService.getAllNewSpecificTemplate().subscribe(res => {
            this.templates = res;
        });
    }

    selectAllChanged(event: MatCheckboxChange): void {
        if (event.checked) {
            this.selectedItems = this.templates.map(el => el);
        } else {
            this.selectedItems = [];
        }
    }

    selectionChanged(event: MatCheckboxChange, item): void {
        if (event.checked) {
            if (!this.isChecked(item.id)) {
                this.selectedItems.push(item);
            }
        } else {
            if (this.isChecked(item)) {
                this.selectedItems.splice(this.selectedItems.findIndex(el => el.id === item.id), 1);
            }
        }
    }

    isChecked(item): boolean {
        return !!this.selectedItems.find(el => el.id === item.id);
    }

    getType(element) {
        const invoice_type = INVOICE_TYPE_LIST.find(i => SaleOrderInvoiceType[i.id] === element.templateId)?.type;
        switch (invoice_type) {
            case InvoiceManagerType.SaleOrder: return 'B2C SO / B2B SO / Reporting Tool SO';
            case InvoiceManagerType.Shipment: return 'Shipment';
            case InvoiceManagerType.ConsolShipment: return 'Consol Shipment';
            case InvoiceManagerType.Other: return 'Other';
            default: return '';
        }
    }

    onCreate() {
        this.dialogRef.close(this.selectedItems);
    }
}
