import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EmpactShopService } from '../../core/services/empact-shop.service';

@Component({
    selector: 'eci-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input() header: string = '';
    @Input() text: string = '';
    @Input() hidden: boolean = true;
    @Output() iconsColor: EventEmitter<boolean> = new EventEmitter<boolean>();

    public banner = '';
    get isHome(): boolean {
        return this.router.url.includes('/home-page');
    }

    constructor(
        public router: Router,
        private shopService: EmpactShopService,

    ) {
        this.getBanner();
    }

    ngOnInit(): void {
    }

    private getBanner(): void {
        this.shopService.getBanner()
            .subscribe((data) => {
                if (!data || !data.images) {
                    return;
                }
                this.banner = data.images[0].file;
                this.iconsColor.emit(data.iconsColor);
            });
    }

}
