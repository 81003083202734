import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaginatorProperties } from '../../../core/models/paginator.interface';
import { IVendor } from '../../../modules/shipment-price/models/shipment-price.interface';
import { ShipmentPriceService } from '../../../modules/shipment-price/services/shipment-price.service';

@Component({
    selector: 'eci-assign-vendor-popup',
    templateUrl: './assign-vendor-popup.component.html',
    styleUrls: ['./assign-vendor-popup.component.scss']
})
export class AssignVendorPopupComponent implements OnInit {
    public vendorSearch: FormControl = new FormControl('');
    public allVendors: IVendor[] = [];
    public vendors: IVendor[] = [];
    public selectedVendor: IVendor[] = [];
    public header: string = 'Assign vendor';
    public message: string = '';
    public leftBtnText: string = 'Cancel';
    public rightBtnText: string = 'Save';
    public form: FormGroup;

    constructor(public dialogRef: MatDialogRef<AssignVendorPopupComponent>,
                private shipmentPriceSvc: ShipmentPriceService,
    ) {
    }

    ngOnInit(): void {
        this.getVendors();
        this.form = this.initializeForm();

        this.vendorSearch.valueChanges.subscribe((value) => {
            const searchText = value.toLowerCase();
            this.vendors = this.allVendors.filter((vendor) => {
                return vendor.name?.toLowerCase().includes(searchText?.toLowerCase());
            });
        });
    }

    private initializeForm(): FormGroup {
        return new FormGroup({
            vendors: new FormControl(this.selectedVendor),
        });

    }

    private getVendors(page: number = 1, limit: number = 200, q: string = ''): void {
        this.shipmentPriceSvc.get<IPaginatorProperties<IVendor>>('vendor', page, limit, q).subscribe((data) => {
            if (!data) {
                return;
            }
            this.allVendors = data.items;
            this.vendors = data.items;
        });
    }

    public compareServices = (o1: any, o2: any) => {
        return o1.vendorId === o2.vendorId;

    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            return;
        }
        this.dialogRef.close(this.form.controls.vendors.value);
    }
}
