import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IOrderDetails } from 'src/app/modules/customers-order-management/models/order-details.model';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../../environments/environment';

declare let Stripe: any;

@Component({
  selector: 'eci-stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss']
})
export class StripeFormComponent {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  totalAmount: number = 0;
  card: any;
  cardHandler = this.onChange.bind(this);
  cardError: string;
  dataOrder: IOrderDetails;
  clientSecret: string;
  type: string
  strikeCheckout: any = null;
  strikeCheckoutSandbox: any = null;

  stripe: any;

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private notify: NotificationService,
    private dialogRef: MatDialogRef<StripeFormComponent>,
  ) {
    this.dataOrder = data;
  }

  ngOnDestroy() {
    if (this.card) {
      // We remove event listener here to keep memory clean
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  ngAfterViewInit() {
    this.initiateCardElement();
  }

  initiateCardElement() {
    // Giving a base style here, but most of the style is in scss file
    const cardStyle = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };

    var style = {
      base: {
        color: "#32325d",
      }
    };

    this.stripe = Stripe(environment.pk_stripe, {
        locale: 'en'
    });
    const elements = this.stripe.elements();
    this.card = elements.create('card', { style: style });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  onChange({ error }) {
    if (error) {
      this.cardError = error.message;
    } else {
      this.cardError = null;
    }
    this.cd.detectChanges();
  }

  createStripeToken() {
    this.stripe.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.dataOrder?.orderId
        }
      }
    }).then(res => {
      this.dialogRef.close(res);
    });
  }

  onSuccess(token) {
    this.dialogRef.close({ token });
  }

  onError(error) {
    if (error.message) {
      this.cardError = error.message;
      this.dialogRef.close(error.message);
    }
  }
}
