import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { formConstant } from '../../../../config';

@Component({
    selector: 'eci-reason-popup',
    templateUrl: './reason-popup.component.html',
    styleUrls: ['./reason-popup.component.scss']
})
export class ReasonPopupComponent implements OnInit {

    form: FormGroup;
    public nameMaxLength = formConstant.textFieldMaxLength200;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ReasonPopupComponent>,
    ) {
    }

    private initializeForm(): FormGroup {
        return this.formBuilder.group({
            reason: [null, [Validators.required, Validators.maxLength(this.nameMaxLength)]],
        });
    }

    ngOnInit(): void {
        this.form = this.initializeForm();
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onSubmit(): void {
        if (this.form.invalid) {
            return;
        }

        this.dialogRef.close(this.form.controls.reason.value);
    }

}
