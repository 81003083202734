import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../core/services/api.service';
import { LocalStorageService } from '../../../core/services/local-storage.service';
import { ICompany } from '../models/company.model';
import { saveAs } from 'file-saver';

@Injectable()
export class RegistrationService extends ApiService {

    private _currentcontactableId: number;
    private _currentPhoneCode: string;

    get currentcontactableId(): number {
        return this._currentcontactableId;
    }

    set currentcontactableId(value: number) {
        this._currentcontactableId = value;
    }

    get currentPhoneCode(): string {
        return this._currentPhoneCode;
    }

    set currentPhoneCode(value: string) {
        this._currentPhoneCode = value;
    }

    constructor(
        public http: HttpClient,
        public localStorage: LocalStorageService,
    ) {
        super(http, localStorage);
    }

    //#region COMPANY

    getCompany(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        withSkusOnly: boolean = false,
        showIncompleted: boolean = false,
        type: number[] = [],
        orderField: string = '',
        orderDirection: string = '',
        dateFrom?: string,
        dateTo?: string,
        shopType?: string,
        accountType?: string
    ): Observable<any> {
        if (!type) type = [];
        return this.request({
            path: `company`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                showIncompleted,
                q: q,
                withSkusOnly: withSkusOnly,
                type: type,
                orderField: orderField,
                orderDirection: orderDirection,
                dateFrom,
                dateTo,
                shopType,
                accountType
            }
        });
    }

    addCompany(companyBody: ICompany): Observable<any> {
        return this.request({
            path: `company`,
            method: 'POST',
            body: companyBody
        });
    }

    getCompanyById(id: number): Observable<any> {
        return this.request({
            path: `company/${id}`,
            method: 'GET',
        });
    }

    getCompanyList(limit: number = 1000, page: number = 1, q: string = ''): Observable<any> {
        return this.request({
            path: `company/list`,
            method: 'POST',
            query: {
                limit,
                page,
                q
            }
        });
    }

    getEcommerceCompanyList(q: string = '', limit: number = 1000, page: number = 1): Observable<any> {
        return this.request({
            path: `company/ecommerce/list`,
            method: 'GET',
            query: {
                limit,
                page,
                q,
            }
        });
    }

    getCompanyListByPartner(limit: number = 1000, page: number = 1, q: string = '', partnerId = ''): Observable<any> {
        return this.request({
            path: `company/partner-customer-list`,
            method: 'POST',
            query: {
                limit,
                page,
                q,
                partnerId
            }
        });
    }

    getCompanyListForSales(q: string = '', isInVat?: boolean): Observable<any> {
        const data: any = {
            path: `company/list-for-sales`,
            method: 'POST',
            query: {q}
        };

        if (isInVat && isInVat === true) {
            data.query['isIndVat'] = true;
        } else {
            // data.query['isIndVat'] = false;
        }

        return this.request(data);
    }

    getCompanyListForRepTool(q: string = '', isInVat?: boolean): Observable<any> {
        const data: any = {
            path: `company/list-for-reptool`,
            method: 'POST',
            query: {q}
        };

        if (isInVat && isInVat === true) {
            data.query['isIndVat'] = true;
        } else {
            // data.query['isIndVat'] = false;
        }

        return this.request(data);
    }

    getAgrrementsByCompanyId(id: number): Observable<any> {
        return this.request({
            path: `company/${id}/agreement`,
            method: 'GET',
        });
    }

    getAmountOfNewCustomers(): Observable<any> {
        return this.request({
            path: `company/new-customers/amount`,
            method: 'GET',
        });
    }

    getAmountOfNewIndVatCustomers(): Observable<any> {
        return this.request({
            path: `company/new-indvat-customers/amount`,
            method: 'GET',
        });
    }

    updateCompany(id: number, body): Observable<any> {
        return this.request({
            path: `company/${id}`,
            method: 'PUT',
            body: body
        });
    }

    setCompanyRegistrationStep(id: number, registrationStep: number): Observable<any> {
        return this.request({
            path: `company/set-registration-step`,
            method: 'POST',
            body: {
                companyId: id,
                registrationStep
            }
        });
    }

    deleteCompany(id): Observable<any> {
        return this.request({
            path: `company/${id}`,
            method: 'DELETE',
        });
    }

    monthlyPaymentFee(id): Observable<any> {
        return this.request({
            path: `company/charge-monthly-fee/${id}`,
            method: 'POST',
            body: {}
        });
    }

    completeRegistration(id: number, isOffline?: boolean, partnerID = '', isExistingCustomer = false): Observable<any> {
        return this.request({
            path: `company/complete`,
            method: 'POST',
            body: {
                companyId: id,
                partnerId: partnerID,
                isExistingCustomer: isExistingCustomer,
                isCompleted: true,
                isOffline: isOffline
            }
        });
    }

    setReportGenerateDate(id: number, reportDay): Observable<any> {
        return this.request({
            path: `company/set-report`,
            method: 'POST',
            body: {
                companyId: id,
                reportDay: reportDay
            }
        });
    }

    sendComissionaryAgreement(body: any): Observable<any> {
        return this.requestPdf({
            method: 'POST',
            path: `company/comissionary-agreement`,
            body
        }).pipe(map(res => {
            const fileURL = URL.createObjectURL(res);
            saveAs(fileURL, 'agreements.pdf');
            // window.open(fileURL, '_blank');
        }));
    }

    checkAndAssignCoupon(body: any): Observable<any> {
        return this.request({
            method: 'POST',
            path: `company/check-assign-coupon-code`,
            body
        });
    }

    checkPartnerCoupon(body: any): Observable<any> {
        return this.request({
            method: 'POST',
            path: `company/validate-partner-coupon-code`,
            body
        });
    }

    //#endregion


    //#region BANK DETAILS

    getBankDetailsList(page: number = 1, limit: number = 1, q: string = '', companyId?: number): Observable<any> {
        return this.request({
            path: `bankDetails`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                companyId: companyId ? companyId : ''
            }
        });
    }

    addBankDetails(body: ICompany): Observable<any> {
        return this.request({
            path: `bankDetails`,
            method: 'POST',
            body: body
        });
    }

    getBankDetailsById(id: number): Observable<any> {
        return this.request({
            path: `bankDetails/${id}`,
            method: 'GET',
        });
    }

    updateBankDetails(id: number, body): Observable<any> {
        return this.request({
            path: `bankDetails/${id}`,
            method: 'PUT',
            body: body
        });
    }

    deleteBankDetails(id): Observable<any> {
        return this.request({
            path: `bankDetails/${id}`,
            method: 'DELETE',
        });
    }

    getStripeAccountList(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        profileId?: string | number,
        orderField: string = '',
        orderDirection?: string
    ): Observable<any> {
        return this.request({
            path: `company/stripe/account_list`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                profileId: profileId,
                orderField: orderField,
                orderDirection: orderDirection,
            }
        });
    }
    getPayonerVbanList(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        profileId?: string | number,
        orderField: string = '',
        orderDirection?: string
    ): Observable<any> {
        return this.request({
            path: `payoneer-payin-history`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                orderField: orderField,
                orderDirection: orderDirection,
            }
        });
    }
    getPayonerVbanFreeAllocatList(
        page: number = 1,
        limit: number = 100,
        q: string = '',
        isAllocated?:any,
    ): Observable<any> {
        return this.request({
            path: `payoneer-payin-history/freeVbans`,
            method: 'GET',
            query: {
                page: page,
                limit: limit,
                q: q,
                isAllocated: isAllocated,
            }
        });
    }
    //#endregion


    partnerRegistrationFee(id): Observable<any> {
        return this.request({
            path: `partner/generate/partner-registration-fee/${id}`,
            method: 'GET',
            body: {}
        });
    }

    partnerMonthlyFee(id): Observable<any> {
        return this.request({
            path: `partner/generate/partner-monthly-fee/${id}`,
            method: 'GET',
            body: {}
        });
    }

}
