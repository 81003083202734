import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IConstantItem} from 'src/app/core/models/available-types.interface';
import {IPalette} from 'src/app/modules/customers-order-management/models/palette.interface';
import {
    IPackage_ProductAllocation, PackingTypeNew,
    UnitsOfMeasure
} from '../../../../../../shared/components/product-allocation-new/product-allocation-new.component';

@Component({
    selector: 'eci-package-details-table',
    templateUrl: './package-details-table.component.html',
    styleUrls: ['./package-details-table.component.scss']
})
export class PackageDetailsTableComponent implements OnInit {
    constructor() {}

    @Input() summaryPage = false;
    @Input() data: IPackage_ProductAllocation[] | any;
    @Input() weightUnits: IConstantItem[];
    @Input() isCreateMode: boolean;
    @Input() denominator: number = 1;

    @Output() dataChange: EventEmitter<IPalette[]> = new EventEmitter<IPalette[]>();

    displayedColumns: string[] = [
        'packingTypeText',
        'packingQuantity',
        'length',
        'width',
        'height',
        'units',
        'unitsOfMeasure',
        'grossWeight',
        'volume'
    ];

    ngOnInit(): void {

    }

    getPackingType(element) {
        switch (element.packingType){
            case PackingTypeNew.single_carton:
                return 'Single carton';
            case PackingTypeNew.pallet:
                return 'Pallet';
            default: return '';
        }
    }

    getNumberOfCarton(element) {
        if (element.packingType === PackingTypeNew.single_carton) return 1;
        else return element?.cartons?.length;
    }

    getUnit(unitsOfMeasure: number) {
        return UnitsOfMeasure[unitsOfMeasure];
    }
}
