import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/services/api.service';

@Injectable({
    providedIn: 'root',
})
export class NavMenuService extends ApiService {

    countOfShipments(): Observable<any> {
        return this.request({
            method:'GET',
            path:'shipment-consol/total-shipments'
        })
    }

    getPaymentHistory(): Observable<any> {
        return this.request({
            method: 'GET',
            path: `payments-history`,
            query: {
                limit: 1,
                page: 1,
                isIndVat: false,
                partnerAccount: false
            }
        });
    }
}
