import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {
    IPackage_ProductAllocation,
    IPackagesEvent,
    IProduct_ProductAllocation,
    PackingTypeNew,
    UnitsOfMeasure
} from '../../product-allocation-new.component';

export function moreThen(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return parseFloat(control.value) > 0 ? null : {wrongValue: true};
    };
}

@Component({
    selector: 'eci-pack-items',
    templateUrl: './pack-items.component.html',
    styleUrls: ['./pack-items.component.scss']
})
export class PackItemsComponent implements OnInit, OnDestroy {

    @Input() set packagesEvents(event: BehaviorSubject<IPackagesEvent>) {
        if (event) {
            this.event = event;
            this.packagesSubscription = this.event.subscribe(event => {
                this.eventsDispatcher(event);
            });
        }
    }
    @Input() isSimply: boolean = false;
    @Input() notAllocated: number = 0;
    @Input() denominator: number = 1;
    @Input() products: IProduct_ProductAllocation[] = [];
    @Input() packages: IPackage_ProductAllocation[] = [];
    @Input() isViewing: boolean = false;
    // @Output() packagesChange: EventEmitter<IPackage_ProductAllocation[]> = new EventEmitter<IPackage_ProductAllocation[]>();
    @Output() packagesChange: EventEmitter<{
        pack: IPackage_ProductAllocation,
        index: number
    }> = new EventEmitter<{
        pack: IPackage_ProductAllocation,
        index: number
    }>();
    @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();

    packagesSubscription: Subscription;
    event: BehaviorSubject<IPackagesEvent>;

    lastPalletIndex = 0;
    lastCartonIndex = 0;

    constructor() {
    }

    ngOnInit(): void {
        if (this.packages.length) {
            const single_cartons = this.packages.filter(p => p.packingType === PackingTypeNew.single_carton);
            single_cartons.length && (this.lastCartonIndex = single_cartons.length);
            const pallets = this.packages.filter(p => p.packingType === PackingTypeNew.pallet);
            pallets.length && (this.lastPalletIndex = pallets.length);
        }
    }

    ngOnDestroy(): void {
        this.packagesSubscription && this.packagesSubscription.unsubscribe();
    }

    eventsDispatcher(event: IPackagesEvent) {
        if (event) {
            switch (event.event) {
                case 'new_pallet':
                    this.addNewPallet();
                    break;
                case 'new_carton':
                    this.addNewCarton();
                    break;
            }
        }
    }

    onCancelPack(event: IPackage_ProductAllocation, index: number) {
        this.packages.splice(index, 1);
        this.lastPalletIndex = 0;
        this.lastCartonIndex = 0;

        this.packages.map(pl => {
            if (pl.packingType === PackingTypeNew.pallet) {
                pl.index = ++this.lastPalletIndex;
            } else {
                pl.index = ++this.lastCartonIndex;
            }
        })
        this.packagesChange.emit({
            pack: event,
            index
        });
    }

    private addNewPallet() {
        this.packages.push({
            packingType: PackingTypeNew.pallet,
            productsQuantity: 0,
            length: 0,
            width: 0,
            height: 0,
            cartons_qty: 0,
            unitsOfMeasure: UnitsOfMeasure.Kg,
            grossWeight: 0,
            volumetricWeight: 0,
            expand: true,
            cartons: [],
            index: ++this.lastPalletIndex,
            isNew: true,
        });
    }

    public addNewCarton() {
        this.packages.push({
            packingType: PackingTypeNew.single_carton,
            productsQuantity: 0,
            length: 0,
            width: 0,
            height: 0,
            cartons_qty: 0,
            unitsOfMeasure: UnitsOfMeasure.Kg,
            grossWeight: 0,
            volumetricWeight: 0,
            expand: true,
            products: [
                {item: {}, allocated: 0}
            ],
            index: ++this.lastCartonIndex,
            isNew: true,
        })
    }

    onPackChange(e: IPackage_ProductAllocation, index: number) {
        this.packagesChange.emit({
            pack: e,
            index
        });
    }

    onDuplicate(e: any) {
        switch (e.packingType) {
            case PackingTypeNew.pallet:

                break;
            case PackingTypeNew.single_carton:
                this.packages.push({
                    packingType: PackingTypeNew.single_carton,
                    productsQuantity: e.productsQuantity,
                    length: e.length,
                    width: e.width,
                    height: e.height,
                    cartons_qty: 0,
                    unitsOfMeasure: UnitsOfMeasure.Kg,
                    grossWeight: e.grossWeight,
                    volumetricWeight: e.volumetricWeight,
                    expand: true,
                    products: [
                        ...e.products
                    ],
                    index: this.lastCartonIndex++,
                    isNew: true,
                })
                break;
        }
    }
}
