import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BaseElement} from '../base-element';
import {IColumn, IRowField} from '../elements-interfaces';

@Component({
    selector: 'eci-grid-item-static',
    templateUrl: './grid-item-static.component.html',
    styleUrls: ['./grid-item-static.component.scss']
})
export class GridItemStaticComponent extends BaseElement implements OnChanges{

    @Input() columns: IColumn[] = [];
    @Input() disabled: boolean;
    @Input() model: any;
    aligns: string[] = ['start', 'center', 'end'];

    constructor(
        private dialog: MatDialog,
    ) {
        super();
    }

    ngOnInit(): void {
    }

    checkIndex(index: number): boolean {
        return !!this.model.find(i => i.index === index);
    }

    getValue(index: number): IRowField {
        return this.model.find(i => i.index === index);
    }

    isArray(key) {
        return Array.isArray(key);
    }

    onAddColumn() {
        this.model.push({
            colspan: 1,
            selected: false,
            key: '',
            value: '',
            replace: false,
            align: 'start'
        });
    }

    onRemoveColumn() {
        const dialogRef = this.dialog.open(ManipulateStaticColsDialog);
        dialogRef.componentInstance.title = 'Remove columns';
        dialogRef.componentInstance.cols = this.model;
        dialogRef.afterClosed().subscribe(res => {
            const removeValue = (value, index, arr) => {
                if (value.selected) {
                    arr.splice(index, 1);
                    return false;
                }
                return true;
            }
            this.model.filter(removeValue);
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.item && !changes.item.currentValue.fontSize) {
            this.item.fontSize = 10;
        }
    }
}

@Component({
    selector: 'manipulate-static-cols-dialog',
    templateUrl: 'manipulate-static-cols.html',
    styleUrls: ['./grid-item-static.component.scss']
})
export class ManipulateStaticColsDialog {
    title = '';
    cols: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<ManipulateStaticColsDialog>,
    ) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

    onAccept() {
        this.dialogRef.close();
    }
}
