import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MatExpansionPanel} from '@angular/material/expansion';
import {
    IPackage_ProductAllocation,
    IProduct_ProductAllocation,
    PackingTypeNew,
    UnitsOfMeasure
} from '../../product-allocation-new.component';
import {moreThen} from '../pack-items/pack-items.component';

export function productsItemValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value?.productId) {
            return {
                empty: true
            }
        }
        return null;
    };
}

@Component({
    selector: 'eci-carton-item',
    templateUrl: './carton-item.component.html',
    styleUrls: ['./carton-item.component.scss']
})
export class CartonItemComponent implements OnInit, OnChanges {

    @Input() package: IPackage_ProductAllocation;
    @Output() packageChange: EventEmitter<IPackage_ProductAllocation> = new EventEmitter<IPackage_ProductAllocation>();

    @Input() isSimply: boolean = false;
    @Input() notAllocated: number = 0;
    @Input() denominator: number = 1;
    @Input() canCancel: boolean = false;
    @Input() products: IProduct_ProductAllocation[] = [];
    @Input() isViewing: boolean = false;
    @Input() namePrefix: string = '';
    @Output() onCancelEvent: EventEmitter<IPackage_ProductAllocation> = new EventEmitter<IPackage_ProductAllocation>();
    @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDuplicateChange: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild(MatExpansionPanel) matExpansionPanel: MatExpansionPanel;


    cartonForm: FormGroup;
    validate = false;
    searchProduct: FormControl = new FormControl('');


    constructor(
        private formBuilder: FormBuilder,
    ) {}

    get allProducts() {
        return this.products.filter(p => {
            return p.description.toLowerCase().includes(this.searchProduct.value.toLowerCase()) || p.itemNumber.toLowerCase().includes(this.searchProduct.value.toLowerCase());
        });
    }

    get productsInPackage() {
        return (this.cartonForm.get('products') as FormArray).length;
    }

    get productQuantity() {
        return (this.cartonForm.get('products') as FormArray).value.reduce((sum, val) => {
            return sum + val.allocated;
        }, 0);
    }

    get cartonProducts() {
        return this.cartonForm.controls['products'] as FormArray;
    }

    ngOnInit(): void {
        this.cartonForm = this.formBuilder.group({
            length: [{value: this.package.length, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            width: [{value: this.package.width, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            height: [{value: this.package.height, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            grossWeight: [{value: this.package.grossWeight, disabled: this.isViewing}, {validators: [Validators.required, moreThen()]}],
            volumetricWeight: [this.package.volumetricWeight],
        });

        const products = this.formBuilder.array([]);
        this.cartonForm.addControl('products', products);
        this.subscribeProducts();
        this.formatProducts();

        this.cartonForm.get('length').valueChanges.subscribe(values => this.onChangeInfo());
        this.cartonForm.get('width').valueChanges.subscribe(values => this.onChangeInfo());
        this.cartonForm.get('height').valueChanges.subscribe(values => this.onChangeInfo());
    }

    formatProducts(withClear: boolean = false) {
        if (this.isSimply) {
            this.package.products = [{
                allocated: withClear ?
                    null :
                    (this.package.products[0]?.allocated ? this.package.products[0].allocated : this.package.productsQuantity)
            }]
        } else {
            this.package.products = this.package.products.map(p => {
                if ((p.hasOwnProperty('item') && p.hasOwnProperty('allocated'))) {
                    return p;
                } else {
                    return {
                        item: withClear ? null : this.products.find(_p => {
                            if (_p.product) {
                                return _p.product?.id === (p as any).productId;
                            } else {
                                return _p.productId === (p as any).productId;
                            }

                        }),
                        allocated: withClear ? null : (p as any).quantity
                    }
                }
            })
        }

        if (this.cartonForm) {
            (this.cartonForm.get('products') as FormArray).clear();
            for (const product of this.package.products) {
                const prodForm = this.isSimply ? this.formBuilder.group({
                    allocated: [{value: product.allocated, disabled: this.isViewing}, {
                        validators: [
                            Validators.required,
                            Validators.min(1),
                            Validators.pattern(/^[0-9]*$/),
                        ]
                    }],
                }) : this.formBuilder.group({
                    item: [{value: product.item, disabled: this.isViewing}, {
                        validators: [
                            Validators.required,
                            productsItemValidator()
                        ]}],
                    allocated: [{value: product.allocated, disabled: this.isViewing}, {
                        validators: [
                            Validators.required,
                            Validators.min(1),
                            Validators.pattern(/^[0-9]*$/),
                        ]
                    }],
                });
                (this.cartonForm.get('products') as FormArray).push(prodForm);
            }
        }

    }

    subscribeProducts() {
        this.cartonForm.get('products').valueChanges.subscribe(value => {
            if (this.isSimply) {
                if (value.length > 0) {
                    this.onQuantityChange.emit({
                        name: this.namePrefix + this.getType() + ' ' + this.package.index,
                        value: value[0].allocated ? value[0].allocated : 0
                    })
                }
            } else {
                const res = (value as any[]).filter(i => i.item?.productId);
                if (res.length > 0) {
                    this.onQuantityChange.emit({
                        name: this.namePrefix + this.getType() + ' ' + this.package.index,
                        value: res.map(i => {
                            return {[i.item.productId]: i.allocated != null ? i.allocated : 0}
                        })
                    })
                }
            }
            this.package.productsQuantity = this.productQuantity;
            this.onChangeInfo();
        });
    }

    addChild() {
        const prodForm = this.formBuilder.group({
            item: [{}, {
                validators: [
                    Validators.required,
                    productsItemValidator()
                ]}],
            allocated: [null, {
                validators: [
                    Validators.required,
                    Validators.pattern(/^[0-9]*$/),
                ]}]
        });
        (this.cartonForm.get('products') as FormArray).push(prodForm);
    }

    onSave() {
        if (this.validatePackage()) {
            this.package = {
                ...this.package,
                ...this.cartonForm.value,
                productsQuantity: this.productQuantity,
                expand: false,
                isNew: false
            };
            this.packageChange.emit(this.package);
            this.matExpansionPanel.close();
        }
    }

    onCancel() {
        this.onCancelEvent.emit(this.package);
    }

    private validatePackage() {
        this.validate = true;
        return this.cartonForm.valid;
    }

    onChangeInfo() {
        this.cartonForm.get('volumetricWeight').setValue(this.calculateVolumetricWeight());
    }

    deleteChild(index: number) {
        (this.cartonForm.get('products') as FormArray).removeAt(index);
    }

    calculateVolumetricWeight() {
        const volumetricWeight =
            this.cartonForm.get('height').value *
            this.cartonForm.get('width').value *
            this.cartonForm.get('length').value / this.denominator;
        return parseFloat(volumetricWeight.toFixed(3));
    }

    getType() {
        switch (this.package.packingType) {
            case PackingTypeNew.single_carton:
                return `Single carton`;
            case PackingTypeNew.carton:
                return `Carton`;
        }
    }

    checkOptionDisabled(p: IProduct_ProductAllocation) {
        let ids = this.cartonProducts.value.map(v => v.item?.productId);
        return ids.includes(p.productId);
    }

    checkAddBtnDisabled() {
        return this.cartonProducts.length === this.products.length;
    }

    onDuplicate(e: MouseEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.onDuplicateChange.emit(this.package)
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.products) {
            const product = changes.products.currentValue.find(p => p.notAllocated < 0);
            if (product) {
                const controlIndex = (this.cartonProducts.value as any[]).findIndex(v => v.item.productId === product.productId);
                if (controlIndex > -1) {
                    const control = this.cartonProducts.at(controlIndex).get('allocated');
                    control.setErrors({overflow: true});
                    control.markAsTouched();

                }
            }
        }

        if (changes.notAllocated) {
            if (changes.notAllocated.currentValue < 0) {
                const control = this.cartonProducts.at(0).get('allocated');
                control.setErrors({overflow: true});
                control.markAsTouched();
            }
        }

        if (changes.isSimply && changes.isSimply.firstChange == false) {
            this.formatProducts(true);
        }
    }

    getUnit(unitsOfMeasure: number) {
        return UnitsOfMeasure[unitsOfMeasure];
    }

}
