import { debounceTime } from 'rxjs/operators';
import { StringIsNumber } from 'src/app/core/helpers/helpers.function';
import { IAvailableTypes, IConstantItem } from 'src/app/core/models/available-types.interface';
import { ICountry } from 'src/app/core/models/country.interface';
import { CompanyMarketplaceService } from 'src/app/core/services/company-marketplaces.service';
import { ConstantService } from 'src/app/core/services/constant.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
    IMarketplaceDetails
} from 'src/app/modules/customer-registration/components/marketplaces-form/models/marketplace-details.interface';
import {
    RegistrationService
} from 'src/app/modules/customer-registration/services/registration.service';
import { GlobalSettingsService } from 'src/app/modules/setting/services/global-settings.service';
import { AmazonServiceType } from 'src/app/shared/enums/amazon-service-type.enum';
import { MarketplaceType } from 'src/app/shared/enums/marketplace-enum';
import { OrderSync } from 'src/app/shared/enums/orderSync.enum';
import { ProductSync } from 'src/app/shared/enums/productSync.enum';
import { Roles } from 'src/app/shared/enums/role-types.enum';
import { VatOption } from 'src/app/shared/enums/vat-option';

import { DatePipe } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

import { IBanner } from '../../../core/models/image.interface';
import { ICategoryMarketplace } from '../../../core/models/shop-category.interface';
import {
    ErrorMatcher
} from '../../../modules/registration/components/indvat-registration/components/user-main-details/user-main-details.component';
import { ReportingToolService } from '../../../modules/reporting-tool/reporting-tool.service';
import { PaymentProcessors } from '../../enums/payment-processors.enum';
import { ShopConnectionType } from '../../enums/shop-connection-type.enum';
import { ShopifyAdditionalChoose } from '../../enums/shopify-additional-choose.enum';
import { ShopWorkModes } from '../../enums/work-mode.enum';
import { FilterPipe } from '../../pipes/filter.pipe';
import { PaymentsGatewayComponent } from '../payments-gateway/payments-gateway.component';

// TO DO LOGIC IN COMPONENET WAS CHANGED NEED TO REMOVE UNNECCESSARY CODE HERE
@Component({
    selector: 'eci-marketplace-details',
    templateUrl: './marketplace-details.component.html',
    styleUrls: ['./marketplace-details.component.scss'],
    providers: [
        DatePipe,
        FilterPipe
    ]
})
export class MarketplaceDetailsComponent {

    header: string = 'Shop Details';
    userInfo: any;
    // additionalCodes: any;
    newCode: any;
    mainCountryId: any;
    createMode: boolean = false;
    isthirdPartyConnection: boolean = false;
    payoneerSelect: boolean = false;
    showFreeVbanReadonlyText: boolean = false;
    addBtn: boolean = true;
    freeVbanValue: string = '';
    info: any;
    isOffline: boolean = false;
    countries: ICountry[];
    AlllistOfCountries: any[]; // TO DO NEED TO CHANGE TYPE
    availableTypes: IAvailableTypes;
    AllavailableTypes: any;
    AlllistOfMarketplaces: any[]; // TO DO NEED TO CHANGE TYPE
    listOfMarketplaces: any[]; // TO DO NEED TO CHANGE TYPE
    listOfPayments: any[] = []; // TO DO NEED TO ADD TYPE
    listOfStripe: any[] = [];
    listOfVBAN: any[] = [];
    listOfVBANsFreeAllocate: any[] = [];
    stripeProfile: any;
    isStripe: boolean = true;
    form: FormGroup;
    isDialog: boolean = true;
    marketplacedAlreadyUsed: any;
    companyCode: number;
    marketplaceCheck: any;
    searchMarketPlace: FormControl = new FormControl('');
    countryProductSourceSearch: FormControl = new FormControl('');
    searchActiveMarketPlace: FormControl = new FormControl('');
    searchYears: FormControl = new FormControl('');
    searchCategory: FormControl = new FormControl('');
    searchForCountries: FormControl = new FormControl('');
    selectedTabIndex: number = 0;
    years: number[] = [];
    stripeDetails: string;
    payoneerDetails: string;
    isStripeChecked: boolean;
    isPayoneerChecked: boolean;
    skuVaultChannelIdCheck: boolean = true;
    skuVaultShopIdCheck: boolean = true;
    marketplaceCheckIs: boolean = true;
    dataList: any[] = [];
    skuShopData: any[] = [];
    shopCategory: ICategoryMarketplace[] = [];
    displayedColumns = [
        'paymentGatewayCompany',
        'paymentTransferCompany',
        'delete'
    ];
    numberRegEx = /\-?\d*\.?\d{1,2}/;
    isSellProduct = false;
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    paymentProcessors: any[];
    shopifyAdditional: ShopifyAdditionalChoose = ShopifyAdditionalChoose.NoDefault; // fix for #ECI-4720
    ShopifyAdditionalChoose = ShopifyAdditionalChoose;

    get listOfActiveInMarketplace(): IConstantItem[] {
        return this.availableTypes?.ActivityTimeRage;
    }

    public get quickShopIntegration(): boolean {
        return this.shopifyAdditional === ShopifyAdditionalChoose.NotShow;
    }

    matcher = new ErrorMatcher();
    shopSourceCountry: any[];
    euCountry: any[];
    shopSourceCountryFiltered: any[];
    showRepTypeSwitcher = false;
    showAddNewVAT = false;
    company;
    listConnectionTypes: any;
    displayWarehouses: any = [];
    isErrWName;
    isErrWCountry;
    isErrWCity;
    isErrWAddress;
    isWarehousesBtns: boolean = true;
    public bannerData: IBanner = { iconsColor: true, images: [] };
    isWarehouseCountryExist: false = [] as any;
    euVatArray = [];

    get showOtherPaymentProcessor(): boolean {
        return Number(this.form?.controls?.paymentProcessor?.value) === PaymentProcessors.Other;
    }

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<MarketplaceDetailsComponent>,
        private notify: NotificationService,
        private dialog: MatDialog,
        private companyMarketplaceSvc: CompanyMarketplaceService,
        private regSvc: RegistrationService,
        public filterPipe: FilterPipe,
        private localStorageSvc: LocalStorageService,
        private constSvc: ConstantService,
        public reportingToolService: ReportingToolService,
        public globalSettingsSvc: GlobalSettingsService
    ) {
    }

    ngOnInit(): void {
        this.loadSecondaryVatCountries();
        this.getData();
        this.getListOfYears();
        this.getInfo();
        this.getStripeAccountList();
        this.getPayonerVbanList();
        this.getPayonerVbanFreeAllocatList();
        if (this.isOffline && this.shopId) {
            this.getB2BOfflineBanner();
        }
        this.listConnectionTypes = ShopConnectionType;

        if (this.listOfMarketplaces) {
            if (this.isOffline) {
                const defaultMarketplace = this.listOfMarketplaces.find((item) => item.name === 'Empact');
                this.form.get('marketplaceId').setValue(defaultMarketplace?.id);
            }
            const others = this.listOfMarketplaces.find((item) => item.name === 'Other' || item.name === 'other');
            const data = this.listOfMarketplaces.filter((item) => item.name !== 'Other' && item.name !== 'other');
            this.listOfMarketplaces = [...data, others];
            this.listOfMarketplaces = this.listOfMarketplaces.filter(ele => {
                if (ele.userId == this.company.id || ele.userId == null) {
                    return ele;
                }
            })
        }

        this.AlllistOfMarketplaces = this.listOfMarketplaces;

        this.searchMarketPlace.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            let searchText = value.toLowerCase();
            this.listOfMarketplaces = this.AlllistOfMarketplaces.filter(it => {
                return it.name.toLowerCase().substring(0, searchText.length) == searchText;
            });
        });

        this.searchForCountries.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            let searchTextCountries = value.toLowerCase();
            this.countries = this.AlllistOfCountries.filter(it => {
                return it.name.toLowerCase().substring(0, searchTextCountries.length) == searchTextCountries;
            });
        });

        this.AllavailableTypes = this.availableTypes.ActivityTimeRage;

        this.searchActiveMarketPlace.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            let searchText = value.toLowerCase();
            this.availableTypes['ActivityTimeRage'] = this.AllavailableTypes.filter(it => {
                return it.name.toLowerCase().substring(0, searchText.length) == searchText;
            });
        });
        this.paymentProcessors = this.getTypeOfPaymentProcessors();

        // if (this.userInfo.role === Roles.IndVatAccount) {
        this.form.controls.countryProductSource.valueChanges.pipe(debounceTime(500)).subscribe(data => {
            const selectedCountry = this.shopSourceCountryFiltered.find(c => c.id === data);
            this.checkSourceCountry(selectedCountry);
        });

        this.countryProductSourceSearch.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            let searchTextCountries = value.toLowerCase();
            this.shopSourceCountryFiltered = this.shopSourceCountry.filter(it => {
                return it.name.toLowerCase().substring(0, searchTextCountries.length) == searchTextCountries;
            });
        });

        this.form.controls.sellProduct.valueChanges.subscribe(data => {
            if (!this.form.controls.sellProduct.value) {
                this.isSellProduct = true;
            } else {
                this.isSellProduct = false;
            }
        })

        // }
        this.getCountriesListForShopSource();
    }

    onPaymentChange(data: any) {
        if (data.value == PaymentProcessors.Payoneer) {
            if (this.isAdmin) {
                return this.payoneerSelect = true;
            }
        } else {
            this.showFreeVbanReadonlyText = false;
            return this.payoneerSelect = false;

        }
    }

    getStripeAccountList(
        page: number = 1,
        limit: number = 1000,
        q: string = '',
    ): void {
        if (this.stripeProfile?.profile?.id) {
            this.regSvc.getStripeAccountList(page, limit, q, this.stripeProfile?.profile?.id).subscribe(data => {
                if (!data) {
                    return;
                }

                this.listOfStripe = data?.items;
            });
        }
    }

    private getB2BOfflineBanner(): void {
        this.companyMarketplaceSvc.getBanner(this.shopId).subscribe((data) => {
            if (!data || !data.images) {
                return;
            }
            this.bannerData = data;
            // console.log(data);
        }, (error) => {
            // console.log('banne not found');
        });

    }

    getPayonerVbanList(
        page: number = 1,
        limit: number = 1000,
        q: string = '',
    ): void {
        if (this.stripeProfile?.profile?.id) {
            this.regSvc.getPayonerVbanList(page, limit, q, this.stripeProfile?.profile?.id).subscribe(data => {
                if (!data) {
                    return;
                }
                this.listOfVBAN = data?.items;
            });
        }
    }

    getPayonerVbanFreeAllocatList(
        page: number = 1,
        limit: number = 1000,
        q: string = '',
    ): void {
        if (this.stripeProfile?.profile?.id) {
            this.regSvc.getPayonerVbanFreeAllocatList(page, limit, q, 0).subscribe(data => {
                if (!data) {
                    return;
                }
                this.listOfVBANsFreeAllocate = data;
            });
        }
    }

    get isAdmin(): boolean {
        return this.localStorageSvc.get('user').role == Roles.Admin || this.localStorageSvc.get('user').role == Roles.DelicateAdmin;
    }

    loadSecondaryVatCountries(): void {
        this.globalSettingsSvc.getVatDetails('', 1, 100, '').subscribe(data => {
            if (!data) return;
            this.euVatArray = data.items;
        });
    }

    selectedWarehouseCountry(event, i){
        this.isWarehouseCountryExist[i] = "INVALID";
        this.euVatArray?.forEach(euVat => {
            if(Number(euVat.countryId) == Number(event.value)){
                this.isWarehouseCountryExist[i] = "VALID";
            }
        });
    }

    getInfo(): void {
        if (this.info) {
            this.dataSource = this.info?.companyMarketplacePayment;
            this.listOfPayments = this.info?.companyMarketplacePayment;
            if (!!this.info.payoneerDetails && this.info.paymentProcessor == PaymentProcessors.Payoneer) {
                this.showFreeVbanReadonlyText = true;
                this.freeVbanValue = this.info.payoneerDetails;
            }
            if (this.info.paymentProcessor == PaymentProcessors.Payoneer && !this.info.payoneerDetails) {
                this.payoneerSelect = true;
            }
        }
        if (this.info?.connectionType !== ShopConnectionType.INTEGRATION_LAYER) {
            this.isthirdPartyConnection = true;
        } else {
            this.isthirdPartyConnection = false;
        }
        this.form = this.initializeForm(this.info);
        console.log(this.form);
        this.shopifyAdditional = (!!this.info?.login || !!this.info?.password) ? ShopifyAdditionalChoose.Show : ShopifyAdditionalChoose.NotShow;
        this.getCountries();

        this.form.controls.marketplaceId.valueChanges.subscribe(value => {
            this.setValidators();
            if (this.showRegion || this.showMarketplaceCountries) {
                // this.form.controls.marketplaceCountries.setValidators([Validators.required]);
                this.form.controls.marketplaceCountries.updateValueAndValidity();
            } else {
                this.form.controls.marketplaceCountries.setValue(null);
            }
        });

        this.form.controls.amazonServiceType.valueChanges.subscribe(data => {
            // if (this.showMarketplaceCountries) {
            //     // this.form.controls.marketplaceCountries.setValidators(this.showMarketplaceCountries ? [Validators.required] : null);
            // }
            this.getCountries();
        });

        // this.form.controls.useLocalInventory.valueChanges.subscribe(data => {
        //     if (this.form.controls.warehouses.value.length == 0) {
        //         this.onAddWarehouses();
        //     }
        //     if (this.showMarketplaceCountries && this.form.controls.useLocalInventory.value == 1) {
        //         this.form.controls.marketplaceCountries.setValidators(this.showMarketplaceCountries ? [Validators.required] : null);
        //     } else {
        //         this.form.controls.marketplaceCountries.clearValidators();
        //         this.form.controls.marketplaceCountries.updateValueAndValidity();
        //     }
        //     this.getCountries();
        // });

        this.form.controls.useLocalInventory.valueChanges.subscribe(data => {
            if (this.showMarketplaceCountries && this.form.controls.useLocalInventory.value == 1) {
                if (this.form.controls.warehouses.value.length == 0) {
                    this.onAddWarehouses();
                }
            } else {
                const control = <FormArray>this.form.controls['warehouses'];
                for (let i = control.length - 1; i >= 0; i--) {
                    control.removeAt(i)
                }

            }
            this.getCountries();
        });

        // To auto select the selected value for countries
        if (this.info?.countries?.length > 0) {
            const selectedCountries: any = [];
            this.info.countries.forEach(countryData => {
                selectedCountries.push(countryData.id);
            });
            this.form.controls.marketplaceCountries.setValue(selectedCountries);
        }

        this.patch();
    }

    // To get the countries based on conditions
    getCountries(): void {
        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'amazon':
                if (this.form.controls.amazonServiceType.value === AmazonServiceType.FBA) {
                    // Only specific countries which are allowed for FBA
                    this.getCountriesList(1, 1000, false, false, '', true);
                } else {
                    // All European countries
                    this.getCountriesList(1, 1000, true);
                }
                break;
            default:
                if (this.showMarketplaceCountries && (this.form.controls.useLocalInventory.value == 1)) {
                    // All European countries
                    this.getCountriesList(1, 1000, true);
                }
                ;
        }
    }

    // TO DO NEED TO REVISE LOGIC HERE
    initializeForm(info: IMarketplaceDetails): FormGroup {
        this.isPayoneerChecked = !!info?.payoneerDetails;
        this.payoneerDetails = info?.payoneerDetails;
        this.isStripeChecked = !!info?.stripeDetails;
        this.isStripe = !!info?.type;
        this.stripeDetails = info?.stripeDetails;
        this.isSellProduct = info?.sellProduct ? false : true;
        if (!!info?.warehouses) {
            const warehousesExist = typeof info?.warehouses === 'string' ? JSON.parse(info?.warehouses) : info?.warehouses;
            if (warehousesExist) {
                this.displayWarehouses = warehousesExist.map(ele => ele);
            }
            info.warehouses = warehousesExist;
        }
        return this.formBuilder.group({
            marketplaceId: new FormControl({disabled: this.isOffline , value: info?.marketplaceId?.toString()}, [Validators.required]),
            otherMarketplaceName: new FormControl({disabled: false, value: info?.otherMarketplaceName?.toString()}),
            amazonServiceType: new FormControl({disabled: false, value: info?.amazonServiceType}),
            useLocalInventory: new FormControl({disabled: false, value: info?.useLocalInventory}),
           // marketplaceCategory: new FormControl({disabled: this.isOffline, value: info?.marketplaceCategory}),
            // Todo: marketplaceCategory only for ecommerce ms1. need to remove
            marketplaceCategory: new FormControl({disabled: this.isOffline,
                value: this.shopCategory.find((el) => el.name == 'Electronics (Accessories)')}),
            categoryMarketplaceId: new FormControl({disabled: false, value: info?.categoryMarketplaceId}),
            marketplaceCountries: new FormControl({disabled: false, value: info?.marketplaceCountries}),
            warehouseName: new FormControl({disabled: false, value: info?.warehouseName}),
            consumerKey: new FormControl({disabled: false, value: info?.consumerKey}),
            consumerSecret: new FormControl({disabled: false, value: info?.consumerSecret}),
            apiKey: new FormControl({disabled: false, value: info?.apiKey}),
            shopName: new FormControl({disabled: false, value: info?.shopName}, [Validators.required]),
            marketplaceUrl: new FormControl({disabled: false, value: info?.marketplaceUrl}, !this.isOffline ? [Validators.required] : null),
            activeMarketplace: new FormControl({disabled: false, value: info?.activeMarketplace}),
            type: new FormControl({disabled: false, value: info?.type || 0}),
            privateKey: new FormControl({disabled: false, value: info?.privateKey}),
            warehouses: this.formBuilder.array([]),
            sellProduct: new FormControl({ disabled: false, value: info?.sellProduct === true ? 1 : 0}, [Validators.required]),
            // shopUrl: new FormControl({ disabled: false, value: info?.shopUrl}),
            clientId: new FormControl({ disabled: false, value: info?.clientId }),
            clientSecret: new FormControl({ disabled: false, value: info?.clientSecret }),
            accessToken: new FormControl({ disabled: false, value: info?.accessToken }),
            accountName: new FormControl({ disabled: false, value: info?.accountName }),
            shopifyAdditional: new FormControl({ disabled: false, value: this?.shopifyAdditional/* ? ShopifyAdditionalChoose.Show : ShopifyAdditionalChoose.NotShow*/ }, [Validators.required]), // fix for #ECI-4720
            login: new FormControl({ disabled: false, value: info?.login }),
            password: new FormControl({ disabled: false, value: info?.password }),

            skuVaultShopId: new FormControl({ disabled: false, value: info?.skuVaultShopId }),
            skuVaultChannelId: new FormControl({ disabled: false, value: info?.skuVaultChannelId }),

            year: new FormControl({ disabled: false, value: Number(info?.startYearOfOperation) }),
            paymentProcessorSearch: new FormControl(''),
            paymentProcessor: new FormControl({ disabled: false, value: info?.paymentProcessor }),
            paymentProcessorOther: new FormControl({ disabled: false, value: info?.paymentProcessorOther }),
            payoneerDetails: new FormControl({ disabled: false, value: info?.payoneerDetails }),
            sellerId: new FormControl({ disabled: false, value: info?.sellerId }),
            region: new FormControl({ disabled: false, value: info?.region }),
            verificationCode: new FormControl({ disabled: false, value: info?.verificationCode }),
            soapAPIUserName: new FormControl({ disabled: false, value: info?.soapAPIUserName }),
            platform: new FormControl({ disabled: false, value: info?.platform }),
            fulfillmentLagTime: new FormControl({ disabled: false, value: info?.fulfillmentLagTime }),
            vatOptions: new FormControl({
                disabled: false,
                value: info?.withWithoutVat ? `${VatOption[info?.withWithoutVat]}` : `${VatOption.withoutVat}`
            }),
            orderSync: new FormControl({ disabled: false, value: info?.orderSync ? `${OrderSync.Enable}` : `${OrderSync.Disable}` }),
            productSync: new FormControl({ disabled: false, value: info?.productSync ? `${ProductSync.Enable}` : `${ProductSync.Disable}` }),
            workingMode: new FormControl({
                disabled: false,
                value: info?.workingMode ? `${ShopWorkModes[info?.workingMode]}` : this.isOffline ? `${ShopWorkModes.b2bMode}` : `${ShopWorkModes.mixed}`,
            }),
            connectionType: new FormControl({
                disabled: false,
                //disabled: (info) ? true : false,
                //value: info?.connectionType ? `${ShopConnectionType[info?.connectionType]}` : `${ShopConnectionType[ShopConnectionType.INTEGRATION_LAYER]}`
                //value: info?.connectionType ? `${info?.connectionType}` : `${ShopConnectionType.INTEGRATION_LAYER}`
                value: (info?.connectionType !== '' && info?.connectionType !== null) ? Number(info?.connectionType) : Number(ShopConnectionType.NONE),
                //value: Number(info?.connectionType)
                //value: `${ShopConnectionType[info?.connectionType]}`
            }),
            shopScope: new FormControl({disabled: false, value: info?.shopScope.toString() || '0'}),
            amountOfProducts: new FormControl({
                disabled: false,
                value: info?.amountOfProducts
            }, !this.isOffline ? [Validators.required] : []),
            countryProductSource: new FormControl({
                disabled: false,
                value: info?.sourceCountry ? info?.sourceCountry?.id : info?.countryProductSource
            }, [Validators.required]),
            shopReportType: new FormControl({ disabled: false, value: info?.shopReportType ? info?.shopReportType : 'oss' })
        });
    }

    getCountriesList(page: number = 1, limit: number = 1000, isEurope: boolean = false, withTaxRate: boolean = false, q: string = '', isFBA: boolean = false): void {
        this.constSvc.getAvailableCountries(page, limit, isEurope, withTaxRate, q, isFBA).subscribe(data => {
            if (!data) {
                return;
            }
            this.countries = data;
            this.AlllistOfCountries = data;
            this.getUkCountry();
        });
    }

    getUkCountry(page: number = 1, limit: number = 1000, isEurope: boolean = false, withTaxRate: boolean = true, q: string = 'GBR', isFBA: boolean = true): void {
        this.constSvc.getAvailableCountries(page, limit, isEurope, withTaxRate, q, isFBA).subscribe(async res => {
            if (res) {
                if (this.countries.filter(ele => ele.code == 'GB').length == 0) {
                    this.countries = [...this.countries, ...res];
                    this.AlllistOfCountries = [...this.AlllistOfCountries, ...res];
                }
            }
        })
    }
    save(): void {
        if (this.form.invalid) {
            this.notify.showError('Not all fields are filled',);
            this.selectedTabIndex = 0;
            this.form.markAllAsTouched();
            return;
        }
        if (this.showOtherMarketplaceName) {
            const existingMarketpalce = this.AlllistOfMarketplaces.filter(it => {
                it.userId = this.company.id;
                return it.name.toLowerCase() == this.form?.controls?.otherMarketplaceName.value.toLowerCase();
            });
            if (existingMarketpalce.length) {
                this.notify.showError(`Please select the marketplace named \"${this.form?.controls?.otherMarketplaceName.value.toLowerCase()}\" from the dropdown list`);
                return;
            }
            if(this.form?.controls?.otherMarketplaceName.value.trim() === ''){
                this.notify.showError(`Please enter the marketplace name.`);
                return;
            }
        }

        if (this.prepareBody.paymentProcessor == PaymentProcessors.Payoneer && this.prepareBody.payoneerDetails == undefined && this.isAdmin) {
            this.notify.showError('Please select vban !!!');
            return;
        }
        if (this.prepareBody.paymentProcessor != PaymentProcessors.Payoneer) {
            this.form?.controls?.payoneerDetails.setValue('');
        }

        if (this.shopifyAdditional === ShopifyAdditionalChoose.NotShow) {
            this.prepareBody.password = null;
            this.prepareBody.login = null;
        }
        // console.log({...this.info.warehouses});
        // this.info.warehouses = this.displayWarehouses;
        // console.log(this.form.get('warehouses').value);
        // console.log(this.formBuilder.controls['warehouses'].value);
        // this.info.warehouses = <FormArray>this.form.get('warehouses').value
        // console.log(this.prepareBody.warehouses);
        // return
        this.dialogRef.close({
            ...this.prepareBody,
            userId: this?.prepareBody?.otherMarketplaceName ? this.company.id : null,
            login: this.shopifyAdditional === ShopifyAdditionalChoose.NotShow ? null : this.prepareBody.login,
            password: this.shopifyAdditional === ShopifyAdditionalChoose.NotShow ? null : this.prepareBody.password,
            companyCodeAdd: this.company?.companyCodeAdd,
            newCode: this.newCode,
            banner: this.bannerData,
        });
    }

    onOptionsSelected(event) {
        this.marketplaceCheck = event.value;
        const name = this.listOfMarketplaces.find(el => el.id === event?.value)?.name?.toLowerCase();
        /*if (name === 'woocommerce' || name === 'shopify') {
            this.isthirdPartyConnection = true;
        } else {
            this.isthirdPartyConnection = false;
        }*/

        if (this.marketplaceCheck == MarketplaceType.AMAZON) {
            this.marketplaceCheckIs = true;
            this.skuVaultShopIdCheck = true;
        } else {
            const skuVaultShopId = this.form.get('skuVaultShopId').value;
            if (skuVaultShopId) {
                const shopIdFilterData = this.skuShopData.find(item => item == skuVaultShopId);
                if (shopIdFilterData) {
                    this.skuVaultShopIdCheck = false;
                } else {
                    this.skuVaultShopIdCheck = true;
                }
                // this.companyMarketplaceSvc.getskuVaultShopId(skuVaultShopId).subscribe(result => {
                //   if (result === false) {
                //     this.skuVaultShopIdCheck = false;
                //   } else {
                //     this.skuVaultShopIdCheck = true;
                //   }
                // })
            }
        }
    }
    onConnectionTypeSelected(event) {
        const conn_type_val = event.value;
        if (conn_type_val !== ShopConnectionType.INTEGRATION_LAYER) {
            this.isthirdPartyConnection = true;
        } else {
            this.isthirdPartyConnection = false;
        }
        if (conn_type_val === ShopConnectionType.WORDPRESS_PLUGIN) {
            this.form?.controls?.marketplaceId.setValue('20');
        }
        if (conn_type_val === ShopConnectionType.SHOPIFY_APP) {
            this.form?.controls?.marketplaceId.setValue('16');
        }
    }

    getData() {
        const skuVaultChannelId = true;
        this.companyMarketplaceSvc.getSkuVaultChannelId(skuVaultChannelId).subscribe(result => {
            result.map(item => {
                if (item.skuVaultChannelId !== null) {
                    this.dataList.push(item.skuVaultChannelId);
                }
                if (item.skuVaultShopId !== null) {
                    this.skuShopData.push(item.skuVaultShopId);
                }
            });
        });
    }

    skuVaultChannelId(data) {
        const skuVaultChannelId = data.target.value ? data.target.value : true;
        const filterData = this.dataList.find(item => item == skuVaultChannelId);
        if (filterData) {
            this.skuVaultChannelIdCheck = false;
        } else {
            this.skuVaultChannelIdCheck = true;
        }
    }

    skuVaultShopId(data) {
        const skuVaultShopId = data.target.value ? data.target.value : true;
        if (this.marketplaceCheck != MarketplaceType.AMAZON) {
            const shopIdFilterData = this.skuShopData.find(item => item == skuVaultShopId);
            if (shopIdFilterData) {
                this.skuVaultShopIdCheck = false;
            } else {
                this.skuVaultShopIdCheck = true;
            }
        }
    }

    exit(): void {
        if (!this.isDialog) {
            history.back();
        } else {
            this.dialogRef.close();
        }
    }

    get shopId(): any {
        return this.info?.shopId;
    }

    get prepareBody(): IMarketplaceDetails {
        this.paymentProcessors.map(key => {
            if (key.name == 'Payoneer') {
                this.isPayoneerChecked = true;
            }
        });
        return {
            ...this.info,
            ...this.form?.getRawValue(),
            stripeDetails: this.isStripeChecked ? this.stripeDetails : null,
            // payoneerDetails: this.isPayoneerChecked && typeof this.payoneerDetails != 'undefined' ? this.payoneerDetails : null,
            type: this.form?.controls?.paymentProcessor?.value,
            startYearOfOperation: this.form?.controls?.year?.value,
            paymentProcessor: this.form?.controls?.paymentProcessor?.value,
            paymentProcessorOther: this.form?.controls?.paymentProcessorOther?.value,
            payoneerDetails: this.form?.controls?.payoneerDetails?.value,
            otherMarketplaceName: this.form?.controls?.otherMarketplaceName?.value || '',
            withWithoutVat: VatOption[this.form?.controls?.vatOptions?.value] || '',
            marketplaceCategory: this.form?.controls?.marketplaceCategory?.value,
            marketplaceId: this.form?.controls?.marketplaceId?.value,
            categoryMarketplaceId: this.form?.controls?.marketplaceCategory?.value?.id,
            isMarketplaceOffline: this.isOffline,
            orderSync: this.form?.controls?.orderSync?.value && this.form?.controls?.orderSync?.value == '1' ? true : false,
            productSync: this.form?.controls?.productSync?.value && this.form?.controls?.productSync?.value == '1' ? true : false,
            workingMode: ShopWorkModes[this.form?.controls?.workingMode?.value] || '',
            shopScope: this.form?.controls?.shopScope?.value || '0',
            amountOfProducts: Number(this.form?.controls?.amountOfProducts?.value || 0),
        };
    }

    openDialog(i?): void {
        const dialogRef = this.dialog.open(PaymentsGatewayComponent);
        dialogRef.componentInstance.info = i !== undefined && i !== null ? this.listOfPayments[i] : undefined;

        dialogRef.afterClosed().subscribe(value => {
            if (!value) {
                return;
            }
            if (i !== undefined && i !== null) {
                this.listOfPayments.splice(i, 1);
            }

            this.listOfPayments.push(value);
            this.dataSource = new MatTableDataSource(this.listOfPayments);
        });
    }

    delete(index): void {
        const paymentItem = this.listOfPayments[index];

        if (paymentItem?.id) {
            this.companyMarketplaceSvc.deletePaymentDetails(paymentItem.id).subscribe(value => {
            });
        }

        this.listOfPayments.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.listOfPayments);
    }

    // TO DO NEED TO CHECK THE IMPLEMENTATION
    get showConsumerKey(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'wix':
                return true;
            case 'woocommerce':
                if(this.isthirdPartyConnection){
                    return false;
                } else {
                    return true;
                }
            case 'walmart':
                return true;
            default:
                return false;
        }
    }

    get disableConnectionType(): number {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'shopify':
                return ShopConnectionType.WORDPRESS_PLUGIN;
            case 'woocommerce':
                return ShopConnectionType.SHOPIFY_APP;
            default:
                return undefined;
        }
    }


    get showSellerID(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'amazon':
                return true;
            default:
                return false;
        }
    }

    get showRegion(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'amazon':
                return true;
            default:
                return false;
        }
    }

    get showMWSAuthToken(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'amazon':
                return true;
            default:
                return false;
        }
    }

    get showVerificationCode(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'etsy':
                return true;
            default:
                return false;
        }
    }

    get showSoapAPIUserName(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'magento':
                return true;
            default:
                return false;
        }
    }

    get showSoapAPIKey(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'magento':
                return true;
            default:
                return false;
        }
    }

    get showPlatform(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'nevegg':
                return true;
            default:
                return false;
        }
    }

    get showNeveggSellerId(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'nevegg':
                return true;
            default:
                return false;
        }
    }

    get showNeveggSecretKey(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'nevegg':
                return true;
            default:
                return false;
        }
    }

    get showReverbToken(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'reverb':
                return true;
            default:
                return false;
        }
    }

    get showFulfillmentLagTime(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'walmart':
                return true;
            default:
                return false;
        }
    }

    get showWalmartClientID(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'walmart':
                return true;
            default:
                return false;
        }
    }

    get showWalmartClientSecret(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'walmart':
                return true;
            default:
                return false;
        }
    }

    get showConsumerSecret(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'wix':
                return true;
            case 'woocommerce':
                if(this.isthirdPartyConnection){
                    return false;
                } else {
                    return true;
                }
            default:
                return false;
        }
    }

    get showApiKey(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }
        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'wix':
                return true;
            case 'ebay':
                return true;
            case 'opencart':
                return true;
            case 'reverb':
                return true;
            default:
                return false;
        }
    }

    get showPrivateKey(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'walmart':
                return true;
            default:
                return false;
        }
    }

    get showShopUrl(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'woocommerce':
                return true;
            case 'opencart':
                return true;
            case 'magento':
                return true;
            default:
                return false;
        }
    }

    get showClientId(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'bigcommerce':
                return true;
            case 'nevegg':
                return true;
            default:
                return false;
        }
    }

    get showClientSecret(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'bigcommerce':
                return true;
            default:
                return false;
        }
    }

    get showAccessToken(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'bigcommerce':
                return true;
            case 'etsy':
                return true;
            default:
                return false;
        }
    }

    get showAccountName(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'ebay':
                return true;
            default:
                return false;
        }
    }

    get showOtherMarketplaceName(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        const name = this.listOfMarketplaces.find(el => el.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();
        switch (name) {
            case 'other':
                return true;
            default:
                return false;
        }
    }

    get showMarketplaceCountries(): boolean {
        if (this.showRegion) {
            return !!this.form?.controls?.amazonServiceType?.value;
        } else if (this.form?.controls?.useLocalInventory?.value == 1) {
            return true;
        }
    }

    get isShopify(): boolean {
        const name = this.listOfMarketplaces?.find(el => el?.id === this.form?.controls?.marketplaceId?.value)?.name?.toLowerCase();

        return name === 'shopify';
    }

    get showCredentials(): boolean {
        if (!this.form?.controls?.marketplaceId?.value) {
            return;
        }

        if (this.shopifyAdditional === ShopifyAdditionalChoose.Show) {
            return true;
        }
    }

    setValidators(): void {
        this.form.controls.consumerKey.setValidators(this.showConsumerKey ? [Validators.required] : null);
        this.form.controls.consumerSecret.setValidators(this.showConsumerSecret ? [Validators.required] : null);
        this.form.controls.apiKey.setValidators(this.showApiKey && !this.isShopify ? [Validators.required] : null);
        this.form.controls.privateKey.setValidators(this.showPrivateKey ? [Validators.required] : null);
        this.form.controls.marketplaceUrl.setValidators(!this.isOffline ? [Validators.required] : null);
        this.form.controls.clientId.setValidators(this.showClientId ? [Validators.required] : null);
        this.form.controls.clientSecret.setValidators(this.showClientSecret ? [Validators.required] : null);
        this.form.controls.accessToken.setValidators(this.showAccessToken ? [Validators.required] : null);
        this.form.controls.accountName.setValidators(this.showAccountName ? [Validators.required] : null);
        this.form.controls.shopifyAdditional.setValidators([Validators.required]);
        this.form.controls.login.setValidators(this.showCredentials ? [Validators.required] : null);
        this.form.controls.password.setValidators(this.showCredentials ? [Validators.required] : null);
        this.form.controls.otherMarketplaceName.setValidators(this.showOtherMarketplaceName ? [Validators.required] : null);
        this.form.controls.amazonServiceType.setValidators(this.showRegion ? [Validators.required] : null);
        // this.form.controls.marketplaceCountries.setValidators(this.showMarketplaceCountries ? [Validators.required] : null);
        this.form.controls.useLocalInventory.setValidators(!this.showRegion ? [Validators.required] : null);
        this.form.controls.consumerKey.updateValueAndValidity();
        this.form.controls.consumerSecret.updateValueAndValidity();
        this.form.controls.apiKey.updateValueAndValidity();
        this.form.controls.privateKey.updateValueAndValidity();
        this.form.controls.marketplaceUrl.updateValueAndValidity();
        this.form.controls.clientId.updateValueAndValidity();
        this.form.controls.clientSecret.updateValueAndValidity();
        this.form.controls.accessToken.updateValueAndValidity();
        this.form.controls.accountName.updateValueAndValidity();
        this.form.controls.login.updateValueAndValidity();
        this.form.controls.password.updateValueAndValidity();
        this.form.controls.amazonServiceType.updateValueAndValidity();
        this.form.controls.marketplaceCountries.updateValueAndValidity();
        this.form.controls.useLocalInventory.updateValueAndValidity();
    }

    getTypeOfPaymentProcessors(): any[] {
        return Object.keys(PaymentProcessors)
            .filter(StringIsNumber)
            .map(key => {
                return { id: key, name: PaymentProcessors[key] };
            });
    }

    getListOfYears(): void {
        const selectedYear = new Date().getFullYear();
        for (let year = selectedYear; year >= 1950; year--) {
            this.years.push(year);
        }
    }

    getPayments() {
        return this.isOffline ?
            this.paymentProcessors.filter(el => el.name === 'Stripe' || el.name === 'Payoneer' || el.name === 'Rapyd') :
            this.filterPipe.transform(this.paymentProcessors, this.form.controls?.paymentProcessorSearch?.value);
    }

    radioChange(e): void {
        this.shopifyAdditional = e.value;
        this.form.controls.login.setValidators(e.value === ShopifyAdditionalChoose.Show ? [Validators.required] : null);
        this.form.controls.password.setValidators(e.value === ShopifyAdditionalChoose.Show ? [Validators.required] : null);
        this.form.controls.login.updateValueAndValidity();
        this.form.controls.password.updateValueAndValidity();
    }

    getFlagClass(code: string) {
        if (!code) return '';
        const c = this.shopSourceCountryFiltered?.find(c => c.id === code)?.code;
        return `iti__${c?.toLowerCase()}`;
    }

    getCountryNameByCode(value: any) {
        return this.shopSourceCountryFiltered?.find(c => c.id === value)?.name;
    }

    getCountriesListForShopSource(): void {
        this.constSvc.getAvailableCountries(1, 1000, false, false, '', false).subscribe(data => {
            if (!data) {
                return;
            }
            this.shopSourceCountry = data;
            this.shopSourceCountryFiltered = data;

            const selectedCountry = this.shopSourceCountryFiltered.find(c => c.id === this.form.get('countryProductSource').value);
            this.checkSourceCountry(selectedCountry);
        });
        this.constSvc.getAvailableCountries(1, 1000, true, true).subscribe(data => {
            if (!data) {
                return;
            }
            this.euCountry = data;
        });
    }

    checkSourceCountry(selectedCountry) {
        this.showRepTypeSwitcher = false;
        this.showAddNewVAT = false;

        if (selectedCountry &&
            selectedCountry.isEurope &&
            this.euCountry?.find(euC => euC.code === selectedCountry.code)
        ) {
            this.showRepTypeSwitcher = true;
            if (
                (selectedCountry.id !== this.mainCountryId) &&
                (!this.company?.companyCodeAdd?.find(rna => {
                    const countryCode = rna.countryCode ? rna.countryCode : rna.country.code;
                    return countryCode === selectedCountry.code;
                }))
            ) {
                this.showAddNewVAT = true;
                this.form.get('shopReportType').setValue('oss');
            }
        }
    }

    onVATAdded(data: { company: any, countryCode: string, newCode: any }) {
        const newRegistrationNumber = {
            code: data.newCode.code,
            countryCode: data.newCode.country.code,
            eoriCode: data.newCode.eoriCode,
            searchCountry: ''
        };
        this.company.companyCodeAdd.push(newRegistrationNumber);
        this.newCode = data.newCode;
        const selectedCountry = this.shopSourceCountry.find(c => c.code === data.countryCode);
        this.checkSourceCountry(selectedCountry);
        this.reportingToolService.userNewCodeEmitter.next(newRegistrationNumber);
    }


    onAddWarehouses() {
        const warehousesForm = this.formBuilder.group({
            marketplaceCountries: new FormControl(null, [Validators.required]),
            warehouseName: new FormControl(null, [Validators.required]),
            warehouseCity: new FormControl(null, [Validators.required]),
            warehouseAddress: new FormControl(null, [Validators.required]),
        });
        (<FormArray>this.form.get('warehouses')).push(warehousesForm);
    }

    removeWarehouses() {
        const warehouses = this.form.get('warehouses') as FormArray
        if (!this.isAdmin && (this.displayWarehouses?.length || 0) < warehouses?.length) {
            if (warehouses.length > 1) {
                warehouses.removeAt(warehouses.length - 1)
            } else {
                warehouses.clear();
            }
        } else if(this.isAdmin) {
            if (warehouses.length > 1) {
                warehouses.removeAt(warehouses.length - 1)
            } else {
                warehouses.clear();
            }
        }
    }

    @HostListener('document:mousedown', ['$event'])
    public documentClick(event) {
        if (this.form.controls.warehouses['controls'].length > 0) {
            let lengthOfWarehouses = this.form.controls.warehouses['controls'].length;
            this.isErrWCountry = this.form.controls.warehouses['controls'][lengthOfWarehouses - 1].controls.marketplaceCountries.status;
            this.isErrWName = this.form.controls.warehouses['controls'][lengthOfWarehouses - 1].controls.warehouseName.status;
            this.isErrWCity = this.form.controls.warehouses['controls'][lengthOfWarehouses - 1].controls.warehouseCity.status;
            this.isErrWAddress = this.form.controls.warehouses['controls'][lengthOfWarehouses - 1].controls.warehouseAddress.status;
        }
    }

    patch() {
        this.displayWarehouses?.map(ele => {
            const control = this.formBuilder.group({
                marketplaceCountries: new FormControl({ value: ele?.marketplaceCountries, disabled: !this.isAdmin }, [Validators.required]),
                warehouseName: new FormControl({ value: ele?.warehouseName, disabled: !this.isAdmin }, [Validators.required]),
                warehouseCity: new FormControl({ value: ele?.warehouseCity, disabled: !this.isAdmin }, [Validators.required]),
                warehouseAddress: new FormControl({ value: ele?.warehouseAddress, disabled: !this.isAdmin }, [Validators.required]),
            });
/*            if (!this.isAdmin) {
                control.disable();
            }*/
            (<FormArray> this.form.get('warehouses')).push(control);
        });
    }
}
