import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HsCodesService } from '../../../modules/hs-codes/services/hs-code-services';


@Component({
  selector: 'eci-hscode-update-popup',
  templateUrl: './hscode-update-popup.component.html',
  styleUrls: ['./hscode-update-popup.component.scss']
})
export class HscodeUpdatePopupComponent implements OnInit {

  form: FormGroup;
  info : any;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<HscodeUpdatePopupComponent>,
    private hsCodesService: HsCodesService,
    ) { }

  ngOnInit(): void {
    this.getInfo();
  }

  getInfo() {
    this.form = this.initializeForm(this.info);
  }

  initializeForm(info: any): FormGroup {
    return this.fb.group({
      taricCode: new FormControl({ disabled: true, value: info?.taricCode || null }),
      description: new FormControl ({ disabled: true, value: info?.description || null }),
      dutyExpression: new FormControl({ disabled: false, value: info?.dutyExpression || null }),
      supplementaryUnit: new FormControl({ disabled: false, value: info?.supplementaryUnit || null })
    });
  }


  updateHsCode(): void {
    const data = {
      taricCode: this.info?.taricCode,
      description: this.info?.description,
      dutyExpression : this.form.get("dutyExpression").value,
      supplementaryUnit: this.form.get("supplementaryUnit").value
    }
    this.hsCodesService.updateHscodeById(JSON.stringify(this.info?.taricCode), data).subscribe((res) => {
      this.dialogRef.close({status: "updated", response: data});
    })
  }

  exit(): void {
    this.dialogRef.close();
}
}