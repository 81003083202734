import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ApiService} from '../../core/services/api.service';
import {
    IPayment, IPaymentCreateRequest,
    IPaymentCreateResponse, IPaymentInvoice, IPaymentSummary,
    IPaymentsUpdate,
    IPaymentUpdateResponse
} from './payments-interfaces';
import {EEmailType, EPaymentMode, EPaymentStatus} from './payments-type.enum';

@Injectable({
    providedIn: 'root'
})
export class PaymentsService extends ApiService {

    public showPaymentAlert$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    createPayment(data: IPaymentCreateRequest): Observable<IPaymentCreateResponse> {
        return this.request({
            path: `pay/payment-create`,
            method: 'POST',
            body: data
        });
    }

    updatePayment(data: IPaymentsUpdate): Observable<IPaymentUpdateResponse> {
        return this.request({
            path: `pay/payment-update`,
            method: 'POST',
            body: data
        });
    }

    updateInvoice(data: any): Observable<IPaymentInvoice> {
        return this.request({
            path: `pay/invoice-update`,
            method: 'POST',
            body: data
        });
    }

    getSummaryData(): Observable<IPaymentSummary> {
        return this.request({
            path: `pay/get-summary`,
            method: 'GET'
        }).pipe(
            tap(val => {
                if (val && val.pending?.qty > 0) {
                    this.showPaymentAlert$.next(true);
                } else {
                    this.showPaymentAlert$.next(false);
                }
            })
        );
    }

    getPaymentsData(params): Observable<any> {
        return this.request({
            path: `pay/get-payments`,
            method: 'GET',
            query: params
        });
    }

    exportToExcel(params): Observable<any> {
        return this.requestFile({
            path: `pay/export-excel`,
            method: 'POST',
            query: params
        }, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').pipe(map(res => {
            const a = document.createElement('a')
            const objectUrl = URL.createObjectURL(res)
            a.href = objectUrl
            a.download = `Payments_${new Date().toDateString()}.xlsx`;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }));
    }

    getPayment(paymentId: number | string): Observable<IPayment> {
        return this.request({
            path: `pay/payments/${paymentId}`,
            method: 'GET',
        });
    }

    getInvoice(invoiceId: string): Observable<any> {
        return this.request({
            path: `pay/invoice/${invoiceId}`,
            method: 'GET',
        });
    }

    getInvoicePdf(invoiceId: string): Observable<any> {
        return this.request({
            path: `pay/invoice-pdf/${invoiceId}`,
            method: 'GET',
        });
    }

    onHold(data: {id: string; text: string;}): Observable<any> {
        return this.request({
            path: `pay/onhold`,
            method: 'POST',
            body: data
        });
    }

    getRemarks(paymentId: number): Observable<any> {
        return this.request({
            path: `pay/remarks/${paymentId}`,
            method: 'GET'
        });
    }

    addRemark(data: {id: string; text: string;}) {
        return this.request({
            path: `pay/add-remark`,
            method: 'POST',
            body: data
        });
    }

    getUserPaymentToPay(): Observable<IPayment> {
        return this.request({
            path: `pay/get-payment-to-pay`,
            method: 'GET'
        });
    }

    sendEmail(data: {paymentId: number, type: EEmailType}): Observable<boolean> {
        return this.request({
            path: `pay/send-email`,
            method: 'POST',
            body: data
        });
    }

    setStatus(data: {paymentId: number; status: EPaymentStatus; mode?: EPaymentMode}) {
        return this.request({
            path: `pay/set-status`,
            method: 'POST',
            body: data
        });
    }

    public generateMonthlyPayments(customerIds: number[]): Observable<boolean> {
        return this.request({
            path: `pay/generate-monthly-payment`,
            method: 'POST',
            body: customerIds,
        });
    }

    setRefund(data: {id: number; text: string}) {
        return this.request({
            path: `pay/set-refund`,
            method: 'POST',
            body: data
        });
    }

    createPaymentManually(data: {companyId: number, paymentDescription: string, paymentAmount: number}): Observable<any> {
        return this.request({
            path: `pay/create-payment-manually`,
            method: 'POST',
            body: data
        });
    }
}
